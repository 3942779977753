.main {
   
    padding: 1rem;
    margin-bottom: 6rem;
    background: #f6f7f8;
}
.innerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    /* max-height: 60vh; */
}
.headingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
   margin-bottom: 2rem;
}
.f {
    font-weight: 600;
    letter-spacing: -2px;
    font-size: 46px;
    line-height: 1;
    font-family: Roobert, sans-serif;
    color: #383838;
    margin-bottom: 0;
    text-align: center;
    max-width: 900px;
}
 .side {
  width: 4px;
  margin-right: 2rem;
  background: #ebecf0;
  border-radius: 100px;
 }
  .activeside {
    background: linear-gradient(180deg, #00c4cc, #7d2ae8);
    width: 4%;
  }

 
.rightSection {
    width: 43%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
   
    border-radius: 10px;
}
.rightSection img{
    border-radius: 10px;
    
    /* padding: 1rem; */
} 

.leftSection {
    width: 30%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding: 1rem;
   
} 
.inacitvecontaner {
    padding: .6rem;
    border-radius: 10px;
    display: flex;
  
    cursor: pointer;
    
    height: auto;
}
.inacitvecontaner span {
    font-size: 1.6;
    font-weight: 600;
    line-height: 30px;

}

.actice {
    background-color: #eff2f9;
}



.activeDiv {
    animation: slide 0.5s ease;
    transition: opacity 0.5s ease;
    margin-top: 10px;
    font-family: TT Commons, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  
@media only screen and (max-width: 747px) {
  .main {
 
    margin-bottom: 0rem; 
  }
    .f {
      font-size: 32px;
    }
    .innerContainer { 
        flex-direction: column;
    }
    .leftSection {
        width: 100%;
        padding: 0rem;
    }
    .rightSection {
        width: 100%; 
        margin-top: 0rem;
 
    }
    .inacitvecontaner span {
        font-size: 18px; 
    }
    .activeDiv {
        font-size: 13px;
    
    } 
    .inactiveDiv {
      display: none;
    }
    .headingContainer {
    
      padding: 0rem;
     margin-bottom: 0rem;
  }
   }

   /* Add fade animation for images */
@keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .imgfluid {
    animation: fade 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
  }
  
  .imgfluid:hover {
    transform: scale(1.05); /* Optional zoom effect on hover */
  }
  
  /* Slide animation for divs */
  @keyframes slide {
    from {
      transform: translateY(-10px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  

 
  