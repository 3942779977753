.main {
    margin-top: 32px;
    width: 100%;
    min-height: 150px; 
    position: relative;
    margin-left: 32px;
    cursor: pointer;
}
.headingtitle {
    font-size: 18px;
    font-family: "inter";
    font-weight: 600;
}


.recomandedContainer {
    height: 137px;
    width: 100%;
    display: flex;
    display: flex;
    gap: 20px;
    flex-wrap: nowrap;  
    overflow-x: auto;
    margin-bottom: 1rem;
      margin-top: 1rem;
  /* Hide the scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */ 

}

.recomandedCard {
    width: 253px;
    height: 137px;
    background-color: #ffffff;
    border-radius: 16px;
    flex: 0 0 auto; /* Ensures the items don’t shrink or grow */
    box-sizing: border-box;
    /* Optional overflow properties if the content inside each container overflows */
    overflow-y: hidden;
    overflow-x: hidden;
    padding: 1rem;

}
.scroll {
    position: absolute;
    top: 50%;
    right: 2rem;
}
.scroll img {
    height: 3.5rem;
}
.cardImage {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cardImage img {
    height: 50px;
    width: 60px;
    object-fit: contain;

border-radius: 10px;}

.cardCompany {
    color: #A29EAC;
    font-size: 14px;
}
.cardTitle {
    font-size: 14px;
    color: #535D79;
}



@media (max-width: 1068px) {
    .main {
       margin-left: 20px;
    
    }
  .eachContainermain {
   display: none;
  }
  .ScrollContainer {
   width: 100%;
  }
}


.recomandedCard {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
  .cardImage {
    position: relative;
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }