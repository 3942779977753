@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

.heading{
  font-family: "Rubik";
  color: #2d3639;
}

.dropdownwrapper {
    position: relative;
     
  }
  
  .dropdownmenu {
    position: absolute;
    background-color: white;
    z-index: 1; 
    box-shadow: 0px 2px 20px rgba(0,0,0,.1);
    border-radius: 5px;
    cursor: pointer;
    padding:  0 !important;
    margin: 1rem!important;

  }

  .dropdownmenu li {
    list-style: none;
    width: 100%;
    padding: 6px 14px;
    height: auto;
    width: 12rem;
    
  }
   
  .dropdownmenu li:hover {
    background-color: #8c7cdb;
    color: white;
    border-radius: 5px;
  }
  .heading_div {
    display: flex;
    justify-content: space-between;
  }

  .card {
    display: flex;
    flex-direction: row;
    width: 100%;
    cursor: pointer;
   }
   .cardinner {
    width: 100%;
    background-color: #eaf9f4;
    border: 1px solid #2dc08d;
    border-radius: 2px;
    position: relative;
    /* aspect-ratio: 211 / 78; */
    padding: 2rem;
   }
  @media only screen and (max-width: 767px) {

    .heading_div {
        display: flex;
        flex-direction: column;
      }
      .card {
        display: flex;
        flex-direction: column
      }
   }


   .jobBottom{
    padding: 8px 20px 8px 16px;
    border: none;
    font-size: 1rem;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
    font-style: normal;
    margin: 3px;
    transition: .1s;
    white-space: nowrap;
    background: linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%);
    color: #fff;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.1s;
    animation: gradientAnimation 5s infinite alternate; /* Animation for gradient */
   }

   @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }

   .viewDetails {
    border: 1px solid #ebf0f5;
    border-radius: 5px;
    color: #566474;
    padding: 9px 15px;
    margin: 3px 0;
     text-decoration: none;
    cursor: pointer;
    height: 44px;
    white-space: nowrap;
   }

   .skill {
    background-color: #f7f8fa;
    text-align: center;
    border-radius: 8px;
    min-width: 3rem;
    margin-right: 1rem;
    color: #566474;
    padding: 4.5px 10px;
    
   }

   .search {
    border-radius: 12px;
    padding: 12px 16px;
    background-color: #fff;
    display: flex;
    gap: 12px;
    width: 100%;
    margin-top: 1rem;
    position: relative;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    border: 1px solid #E2E2E2;
    transition: all 0.2s ease;
    backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.95);
  }

  .search:focus-within {
    border-color: #5811af;
    box-shadow: 0 0 0 4px rgba(88, 17, 175, 0.1);
  }

  .des {
    flex: 1;
    border: none;
    outline: none;
    font-size: 15px;
    padding: 8px 12px;
    border-radius: 8px;
    background: transparent;
    color: #2d3639;
  }

  .des::placeholder {
    color: #9ca3af;
  }

  .searchButton {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border: none;
    border-radius: 8px;
    background: linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%);
    color: white;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .searchButton::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      270deg,
      rgba(160, 102, 255, 0.5),
      rgba(102, 108, 255, 0.5)
    );
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .searchButton:hover::before {
    opacity: 1;
  }

  .searchButtonText {
    display: none;
  }

  @media (min-width: 640px) {
    .searchButtonText {
      display: inline;
    }
    
    .search {
      padding: 16px 20px;
    }
    
    .des {
      font-size: 16px;
    }
  }

   .esi {
    border-radius: 5px;
    padding: 0px 20px 0px;
    background-color: #f7f8fa;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    /* margin-bottom: 2rem; */
    width: 100%;
   position: relative;
   
   }
   .dee{
    background: #fff;
    border: 1px solid #d8dfe8;
    border-radius: 10px 0 0 10px;
    font-weight: 400;
    font-size: .875rem;
    line-height: 1.125rem;
    color: grey;
    height: 35px;
    min-width: 100px;
    grid-gap: 10px;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
   }

   .deew{
    background: #fff;
    border: 1px solid #d8dfe8;
    border-radius: 0px 10px 10px 0px;
    font-weight: 400;
    font-size: .875rem;
    line-height: 1.125rem;
    color: grey;
    height: 35px;
    min-width: 100px;
    grid-gap: 10px;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
   }

   .deeww{
    background: #fff;
    border: 1px solid #d8dfe8;
    border-radius: 0px 0px 0px 0px;
    font-weight: 400;
    font-size: .875rem;
    line-height: 1.125rem;
    color: grey;
    height: 35px;
    min-width: 100px;
    grid-gap: 10px;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
   }
   .esi1{
   
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr ;
    
   }
   .sse{
     position: absolute;
     bottom: 20px;
     right: 29px;
     cursor: pointer;
   }
   .sse:hover {
    color: #9a29c0;
    /* applying scale */
    transform: scale(1.2);

   }
   .des{
    border-radius: 12px;
    padding: 0.5rem 1rem;
    box-shadow:rgba(30, 10, 58, 0.04) 0px 6px 12px ;
    border: 1px solid #E2E2E2;
    font-weight: 400;
    /* padding-left: 2rem; */

   }

   /* In your CSS file or CSS module */
.suggestionList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  border-top: none; /* Optional: Hide top border */
  border-radius: 0 0 5px 5px; /* Optional: Rounded corners */
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Box shadow for better appearance */
  position: relative;
}

.suggestionList li {
  padding: 8px 16px;
  cursor: pointer;
}

.suggestionList li:hover {
  background-color: #f2f2f2;
}

.FiltersJob_container__Xg7n0 {
  /* border: 1px solid #ebf0f5; */
  border-radius: 10px;
  /* padding: 30px; */
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}


   /* CSS Module */
.searchResult {
  margin-top: 20px;
  color: #333;
  font-size: 1.1em; 
  text-align: center; 
  display: flex;
  justify-content: center;
}

.searchResult strong {
  color: #4caf50;
}
.searcch{
 margin-bottom:1rem ;
  width: 100%;
}
.job_search_page_banner {
  background: linear-gradient(114deg, #5811af -9.09%, #9a29c0 97.53%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 114px;
}
.banner_content {
  width: 736px;
  margin-right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 40px;
}

.banner_text_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
}
.banner_text_container .banner_text_1 {
  color: #fff;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.banner_content .banner_text_container .banner_text_2 {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.banner_content .banner_image_container {
  height: 100%;
  display: block;
}

 .banner_image {
  background-image: url(https://www.awareim.com/wp-content/uploads/2021/11/businessman-meditating@2x.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center  ;
  height: 80%;
  min-width: 236px;
  transform:translateY(20px) ;
  animation: upDown 3s ease-in-out infinite; /* Apply the animation */
  margin-top: 1.3rem;
}

@keyframes upDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); /* Move up by 20px */
  }
  100% {
    transform: translateY(0);
  }
}


@media (min-width: 767px) { 
 .esi {
  display: none;
 }
 
}

@media (max-width: 767px) {
   .banner_content {
      width: unset;
      max-width: 736px;
      gap: 0px;
  }
  .banner_text_2{
    display: none;
   }
   .job_search_page_banner {
       height: 80px ;
   }
}

@media (max-width: 767px) {
   .banner_content .banner_text_container {
      padding-left: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      gap: 6px;
     
  }

  .jobBottom{
    display: none;
  }
}

@media (max-width: 767px) {
   .banner_content .banner_text_container .banner_text_1 {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -.25px;
  }
}
.sfas  {
  font-size: 36px !important;
  font-weight: 700;
  /* margin-top: 2rem; */
  
}
.sfass {
  font-size: 25px !important;
  font-weight: 700;
  margin-top: 2rem;
  
}
.sfs {

  margin-bottom: 40px;
}
@media (max-width: 767px) {
   .banner_content .banner_text_container .banner_text_2 {
      font-size: 12px;
      letter-spacing: 0;
      text-align: left;
  }

  .banner_image{
   min-width: 177px;
  } 
  .sfas  {
    font-size: 25px !important; 
    text-align: start;
  }
  .sfs {
    font-size: 14px !important;
  }
}  


.rsw>span {
  margin-top: 1rem;
  font-weight: 550;
  font-size: 10px;
  /* line-height: 18px; */
  color: #121224;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} 

.aiheading {
  font-size: 34px;
  margin-top:2rem;
  font-weight: 700;
  text-align: center;
 margin-left: 50px;
 margin-bottom: 3rem;
} 

 .mai {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; 
   
 }
.sweaph {
  font-size: 18px; 
  font-weight: 600; 
  text-align: center;
  margin-top: 1rem;
}

.aipara {
  font-size: 15px;
  font-weight: 500;
}

.jobscardCompanyName {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
}
.jobscardCompanyLogo {
  padding: 8px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(88, 17, 175, 0.08);
}
.jobscardCompanyLogoImage {
  width: 3.5rem;
  height: 3.5rem;
  object-fit: contain;  
}
.jobscardJobNature {
  background: rgba(88, 17, 175, 0.08);
  padding: 4px 12px;
  border-radius: 20px;
  color: #5811af;
  font-size: 0.875rem;
  font-weight: 500;
}
@media only screen and (max-width: 747px) {
  .aiheading {
    font-size: 20px;
     
    font-weight: 700;
     
   margin-left: 0px; 
   margin-bottom: 3rem;
  } 
  .aipara{
    font-size: 10px;
  }
  .sweaph {
    font-size: 18px; 
     
  }
  .rsw>span { 
    font-size: 10px;
  }
 }

 
.teammy {
  margin-bottom: 1rem;
  font-family: "DM Sans", sans-serif;
  padding: 1.5rem;
  font-weight: 500;
  list-style: none;
  line-height: 21px;
  font-size: 14px;
  border-radius: 15px;
  background: #fff8e6;
} 

.maif {
  border-radius: 20px;
  box-shadow: 0 6px 12px rgba(30,10,58,.04);
  font-size: 14px; 
  padding-top: 2rem;
  padding-left: 1rem;
  padding-bottom: 2rem;
}

.mainsa{ 
 
  
  display: flex;
  justify-content: center;
 align-items: center; 
 flex-direction: column;
}


.hddh {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2d3639;
  margin-bottom: 0.5rem;
  transition: color 0.3s ease;
}


.jas {
 
  padding: 0px;
  font-size: 12px;
   display: flex; 
   justify-content: space-between;
}
.posted {
  color:#13800D ;
  background-color: #F1FFE5;
  padding: .4rem;
  border-radius: 1rem;
}
.savedicon{
  background-color: red;
}
.iconssharebookmarkmain{
  gap: 20px;
  cursor: pointer;
}
.down {
  font-size: 14px;
  color:#474d6a ;
}
.pard{
  font-size: 14px;
  font-weight: 500;
}
@media (max-width: 600px) { 
  .mainsa{ 
    margin-bottom: 0rem;
  }
.hddh {
  font-size: 14px;
}
.down { 
  font-size: 12px !important;
}
.jas {
  font-size: 12px;
}
.viewDetails{
  font-size: 12px;
}
.jobBottom{
  font-size: 12px;
}
.pard{
  font-size: 12px;
}
 
 
}
.numbercss{
  padding: 3px;
  color:#007CC0 ;
  font-weight: 700;
  cursor: pointer;
}

.jobsmaindiv{
  border-radius: 20px;
  box-shadow: 0 6px 12px rgba(30,10,58,.04);
  border:2px solid white; 
  font-size: 14px;
  border:2px solid red; 
  transition: transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease; /* Smooth transition for transform, box-shadow, and border */
}
.jobsmaindiv:hover{
  box-shadow: 0 2px 4px rgba(0,0,0,.08)!important;
  border:2px solid #59b9ec;
  cursor: pointer;
  transform: scale(1.01); /* Scales the element to 120% */
  }

  .iconstyle {
    margin-right: .5rem;
    color: #739ee8;
  }

.skkii {
  border: 1px solid #E2E2E2;
  z-index: 1;
  font-weight: 600;
  padding: 10px 20px 10px 20px;
  border-radius: 20px;
}
.innerj {
  color: #676767; 
  cursor: pointer;
  position: relative;
  z-index: 1;
  
}
.innerj:hover {
 color:  #5811af; 
 cursor: pointer;

}
.activenum{
  color: #5811af;
  border-bottom: 2px solid #5811af;
  
}
.actived{
  color: #5811af;
  border: 2px solid #5811af;
  font-weight: 500;
  
}

.fdasf{
  text-align: center;
  padding: 10px;
  font-weight: 500;
  font-size: 16px;
}
.arrowdd{
  position: absolute;
  top: 10%; /* Position below the innerj element */
  /* right: 50%; */
  transform: translateX(50%) rotate(45deg);
  width: 16px;
  height: 16px;
  border: 8px solid #5811af;
  z-index: -9999; /* Adjusted for practical stacking */
}
 
.dashbaordMain {
  font-size: 14px;
  background-color:#EFF6FC ;
  height: auto;
   
}
.middlewala{
  width: 50%;
  /* border: 2px solid red; */
  height: auto;
}
.firstwala {
  width: 25%;
  margin-top: 8rem;
  
}
.upperwaladasj{
  /* border: 2px solid red; */
  display: flex;
   
} 

.dashoutourImg {
  
 object-fit: cover !important;
  
}
.swipper {
 
  height: 330px!important;
  width: 240px !important;
}

.recomandedMain {
    /* background-color: white; */
    /* background: var(--Gradient-New-Home, linear-gradient(86deg, #F0F8FF 1.71%, #FFFCEF 99.56%)); */
    margin-top: 2rem;
     border-radius: 20px;
    box-shadow: 0 6px 12px rgba(30, 10, 58, .04);
    font-size: 14px;
    padding: 1rem;
    padding-top: 2rem;
    border-radius: 12px;
    background: linear-gradient(310deg, rgba(125, 129, 251, .3) .01%, rgba(244, 197, 204, .3));
    box-shadow: 0 0 4px 0 rgba(16, 24, 64, .12);
}

.primiumMain {
  background-color: white;
  margin-top: 2rem;
   border-radius: 20px;
  box-shadow: 0 6px 12px rgba(30, 10, 58, .04);
  font-size: 14px;
  padding: 1rem;
  padding-top: 2rem;
  border-radius: 12px;
  
  box-shadow: 0 0 4px 0 rgba(16, 24, 64, .12);
}

.companyMain{
  margin-top: 2rem;
  padding: 1rem;
  border-radius: 20px;
    background: #f6f6f6;
    border: 1px solid var(--A200, #EFEDFF);

}
.chalhat{
     color:#121224;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    margin-bottom: 10px;
} 

 
.downchalhat{
  color: var(--nuetral-n-600-d, #717B9E);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 24px;
}

.downchalhat2{
  color: var(--nuetral-n-600-d, #717B9E);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    text-align: center;
    margin-left: 2rem;
}

.paradd{
  color: var(--nuetral-n-600-d, #717B9E);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 24px;
}
.greendot{
  
  background-color: #34C759;
   border-radius: 50%;
   width: 8px;
   height: 8px;
   margin-right: 8px;   
   animation: blink 1s infinite; /* Add animation here */
  
}
@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

 .aaai{
  position: absolute;
 }
.buttonshow{
  background: linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%) ;
  padding: 7px;
   color: white;
  font-weight: 500;
  border-radius: 5px;
  display: none;
  content: ''; 
  font-size: 14px;
   position: relative;
   bottom: 2rem;
   left: 50%;
} 
.swipper:hover .buttonshow{
  display: flex;
}
@media (max-width: 1200px) {  
  .middlewala{
    width: 100%;
    padding: 1rem;
  }
.firstwala{
  display: none;
}
.chalhat{
  font-size: 14px;
}
.downchalhat2{
  margin-left: 1rem;
}
}
.clicktocopy{
  border:none;
  padding:.4rem;
  background-color: white;
  border-radius: 10px;
  border:2px solid #5811af;
  margin: 1rem;
  color: #5811af;
  font-weight: 400;
}
 
.imagepremium{
  width: 7rem;
 height: auto;
}

.PrimiumImage {
 
  display: flex;
  justify-content: center;
  align-items: center;
}
.primiumMainsub{
  display: flex;
  width: 100%;
}

.premiumcontenn{
  flex: 1; /* This will take all the remaining space */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.primumbuttoncont{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
 }

 .premumbutton{
   cursor: pointer;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 8px;
    margin-right: 10px;
    margin-bottom: 4px;
    background: var(--N100, #fff);
    border: 1px solid var(--N400, #E7E7F1);
    border-radius: 50px;
    color: var(--N700, #474D6A);
    height: 32px;
 }

 .dwo{
  margin: 0px;
  padding: 0px;
 }

@media (max-width: 1200px) {  
  .imagepremium{
    width: 6rem;
   height: auto;
  }
.primumbuttoncont{
  flex-direction: column;
} 
.dwo{
  min-height: 100vh;
  min-width: 100vw;
}
 }


 .deo {
  font-size: 1.5rem;
  font-weight: 700;
  color: #1e272e;
 }
 .doo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-bottom: 2rem;
 }
 .ldo {
  height: 50px;
  width: 50px;
  border: 2px solid #0072b1;
  padding: 2px;
  border-radius: 50%;
 }
 .llo {
  height: 10rem;
 }


 .dlod{
  display: flex;
  flex-direction: column;
  width: 35%;
  justify-content: center;
  align-items: center;
   
 }
 .cdlod{
  display: flex;
  flex-direction: column;
  width: 60%;
  justify-content: center;
  align-items: center;
   
 }
 .ll{
  color: #142683;
  font-weight: 600; 
  text-align: center;
  margin-top: 1rem;
 }

 .dloo {
  display: flex;

 }
.copyurl{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px 8px 16px;
  width: 10rem;
  border: none;
  font-size: 1rem;
  border-radius: 5px;
  font-weight: 700;
  cursor: pointer;
  font-style: normal;
  margin: 3px;
  transition: .1s;
  white-space: nowrap;
  background-color: #142683;
  color: #fff; 
  margin-top: 1rem;
}
.dd {
  position: absolute;
  top: 10px; /* Adjust based on your needs */
  right: 10px; /* Adjust based on your needs */
  background: transparent; /* Ensure the button has no background */
  border: none; /* Remove default button border */
  cursor: pointer; /* Change cursor to pointer on hover */
  z-index: 1; /* Ensure the button is on top */
}

.nough{
  background-color:rgb(248 250 252) ;
   display: flex;
   justify-content: center;
}
.fjj {
  width: 80%;
}

.deldo{
  position: absolute;
 
  width: 120%;
  left: -20px;
  top: -20px;
  height: 4rem !important;
 

}

.color1 {
  background-color: #f3f1ff;
}
.color2{
  background-color: #f3f1ff;
} 
.color3{
  background-color: #f3f1ff;
}


 @media (max-width: 800px) {  
  .ldo {
    height: 40px;
    width: 40px;
   }
   .doo {
    gap: 10px;
   }

   .deo {
    font-size: 1rem; }

    .dlod{ 
      width: 100%;
    }
    .dloo{
      flex-direction: column;
    }
    .cdl{
      display: none;
    }
    .cdlod{
       width: 100%;
    }
    .fjj {
      width: 90%;
    }
 }

 .sslo{
  position:relative !important;
  right: 0px !important;
  bottom:-30px !important;
}
.fosloes {
  /* color:#275DF5 ;  */
  padding: 7px 15px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  background-color: #EDF4FF;
  line-height: 20px;
}  
.xo {
  color: #474d6a;
}

.fosloes:hover { 
  background-color:#6267ff; 
  color: white;
}

.fosloes:hover  .xo{ 
  color: white;
} 
.searchcontainer {
  border-radius: 20px;
  box-shadow: 0 6px 12px rgba(30,10,58,.04);
  border: 0px solid white;
  font-size: 14px;
  background-color: #edf4ff;
  padding: .5rem;
}
.heear {
  font-size: 16px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 700;
  line-height: 16px;
}
.sssd {
  background-clip: text;
  font-weight: 600;
  color:rgb(160, 102, 255) ;
}
.fontbold {
  font-weight: 600;
}
.sogr {
  position: sticky;
  top: 7rem;
  margin-top: 6rem;
}
.golao {
  background-color: white;
  border-radius: 40px;
  padding: 8px 8px 9px 9px;
  margin: 1rem;
  font-weight: bold;
  cursor: pointer;
}
.sala {
  margin: .5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.dorog {
  width: 100%;
  height: 80%;
  background-color: white;
  border-radius: 10px;
}

.activeIndicator {
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 2px;
  background: #5811af;
  border-radius: 2px;
}

.innerj {
  position: relative;
  cursor: pointer;
  padding: 12px 20px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.activenum {
  background: rgba(88, 17, 175, 0.1);
  color: #5811af;
}

.jobTypeContainer {
  width: 100%;
  margin: .5rem 0;
  padding: 0.5rem;
  position: relative;
}

.glowBackground {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 0.5rem;
  box-shadow: 
    0 4px 24px -1px rgba(183, 186, 236, 0.2),
    0 0 0 1px rgba(183, 186, 236, 0.2),
    inset 0 0 0 1px rgba(255, 255, 255, 0.2);
  position: relative;
  overflow: hidden;
}

/* .glowBackground::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle,
    rgba(183, 186, 236, 0.15) 0%,
    transparent 70%
  );
  animation: rotate 15s linear infinite;
} */

.jobTypeWrapper {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  position: relative;
  z-index: 1;
}

.jobTypeButton {
  position: relative;
  padding: 0.8rem 1.2rem;
  border: none;
  background: transparent;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #666;
  flex: 1;
  min-width: max-content;
  overflow: hidden;
}

.jobTypeButton::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    45deg,
    rgba(183, 186, 236, 0.15),
    rgba(183, 186, 236, 0.08)
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.jobTypeButton:hover::before {
  opacity: 1;
}

.buttonContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem;
  position: relative;
  z-index: 1;
}

.label {
  font-weight: 500;
  font-size: 0.9rem;
  color: #333;
}

.count {
  font-size: 0.8rem;
  color: #7c80d0;  /* Slightly darker shade of #b7baec for better contrast */
  font-weight: 600;
  opacity: 0.8;
}

.active {
  background: linear-gradient(
    135deg,
    rgba(183, 186, 236, 0.25),
    rgba(183, 186, 236, 0.1)
  );
}

.active .label {
  color: #7c80d0;  /* Slightly darker shade for better readability */
}

.active .count {
  opacity: 1;
}

.activeIndicator {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(
    90deg,
    #b7baec,
    rgba(183, 186, 236, 0.6)
  );
  border-radius: 2px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.jobscardJobDetails {
   padding: 12px;
   background: rgba(88, 17, 175, 0.03);
   border-radius: 12px;
   height: 100%;
   border: 1px solid rgba(88, 17, 175, 0.08);
}


.jobscardJobDetailsIcon {
  color: #5811af;
  margin-bottom: 4px;

}
.jobscardJobDetailsValue {
  font-size: 0.9rem;
  color: #2d3639;
  font-weight: 500;
}
@media (max-width: 768px) {
  .jobTypeWrapper {
    flex-wrap: wrap;
  }
  
  .jobTypeButton {
    flex: 1 1 calc(50% - 0.5rem);
  }
}
.jobsmaindiv {
  transition: all 0.3s ease;
  margin: 0.5rem 0;
  overflow: hidden;
}

.jobTitleWrapper h3 {
  transition: color 0.3s ease;
}

.actionButton {
  transition: all 0.2s ease;
}

.actionButton:hover {
  background: #5811af20 !important;
}

.gradientOverlay {
  transform-origin: left;
}

.companyInfo {
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.jobsmaindiv:hover .companyInfo {
  opacity: 1;
}

/* Add or update these styles */

.jobsmaindiv {
  transition: all 0.3s ease;
  margin: 0.5rem 0;
  overflow: hidden;
}

.jobscardMainContainer{
  background: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  border: 1px solid rgba(88, 17, 175, 0.1);
  box-shadow: 0 4px 20px rgba(88, 17, 175, 0.08);
  overflow: hidden;
  position: relative;

}
.jobscardGradientOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #5811af 0%, #9a29c0 100%);
}
.jobscardJobDetailsLabels {
  margin-left: 8px;
  font-weight: 500;
}
.jobscardJobDetailsButton {
  border: none;
  background: rgba(88, 17, 175, 0.05);
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  color: #5811af;
  font-weight: 500;
}
.jobscardJobDetaildate {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #666;
  font-size: 0.875rem;
}
/* Card responsiveness */
@media (max-width: 768px) {
  .jobsmaindiv {
    margin: 0.5rem;
    padding: 0;
  }
  .jobscardCompanyName ,.jobscardJobNature {
    font-size: 12px;
  }
  .card-body {
    padding: 1rem !important;
  }
.jobscardJobDetailsButton {
  font-size: 12px;
}
.jobscardJobDetaildate {
  font-size: 12px;
}
  /* Company info section */
  .companyInfo {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .jobNature {
    font-size: 0.8rem !important;
  }
 .jobscardJobDetailsValue{
  font-size: 12px;
 }
 .jobscardJobDetailsIconFontAwesome {
  font-size: 13px;
 }
 .jobscardJobDetailsLabels{
  font-size: 12px;
 }
  /* Job title */ 

  /* Company logo */
  .jobsmaindiv img {
    width: 3rem !important;
    height: 3rem !important;
  }

  /* Details grid */
  .row.g-3 {
    margin: -0.5rem;
  }

  .row.g-3 > div {
    padding: 0.5rem;
  }

  /* Action buttons */
  .actionButton {
    padding: 6px 12px !important;
    font-size: 0.9rem !important;
  }

  .actionButton span {
    display: none; /* Hide button text on mobile */
  }

  /* Posted date and actions container */
  .jas {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start !important;
  }

  .iconssharebookmarkmain {
    width: 100%;
    justify-content: space-between;
  }
}

/* Job type selector responsiveness */
@media (max-width: 768px) {
  .jobTypeContainer {
    margin: 0.25rem 0;
    padding: 0.25rem;
  }

  .jobTypeWrapper {
    flex-wrap: wrap;
    gap: 0.25rem;
  }

  .jobTypeButton {
    flex: 1 1 calc(50% - 0.25rem);
    padding: 0.6rem 0.8rem;
  }

  .label {
    font-size: 0.8rem;
  }

  .count {
    font-size: 0.7rem;
  }
}

/* Search bar responsiveness */
@media (max-width: 768px) {
  .search {
    margin: 0.5rem;
    padding: 8px 12px;
    flex-direction: column;
    gap: 0.5rem;
  }

  .des {
    width: 100%;
    font-size: 0.9rem;
  }

  .searchButton {
    width: 100%;
    justify-content: center;
  }
}

/* Details grid items */
.detailItem {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 12px;
  height: 100%;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .detailItem {
    padding: 8px;
  }

  .detailItem .icon {
    font-size: 0.9rem;
  }

  .detailItem .label {
    font-size: 0.75rem;
  }

  .detailItem .value {
    font-size: 0.85rem;
  }
}

/* Gradient overlay animation */
@media (max-width: 768px) {
  .gradientOverlay {
    height: 4px;
  }
}

/* Action buttons container */
.actionButtonsContainer {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .actionButtonsContainer {
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  .actionButton {
    flex: 1;
    min-width: calc(50% - 0.25rem);
  }
}

/* Loading skeleton for job cards */
.skeleton {
  background: linear-gradient(
    90deg,
    #f0f0f0 25%,
    #f8f8f8 50%,
    #f0f0f0 75%
  );
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

/* Responsive container padding */
.container-fluid {
  padding: 0 15px;
}

@media (max-width: 768px) {
  .container-fluid {
    padding: 0 10px;
  }
}

/* Filter section responsiveness */
.filterSection {
  position: sticky;
  top: 0;
  z-index: 10;
  background: white;
  padding: 1rem 0;
}

@media (max-width: 768px) {
  .filterSection {
    position: fixed;
    bottom: 0;
    top: auto;
    left: 0;
    right: 0;
    padding: 0.5rem;
    box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
  }
}

/* Responsive typography */
@media (max-width: 768px) {
  .heading {
    font-size: 1.5rem;
  }

  .subheading {
    font-size: 1rem;
  }

  .text-small {
    font-size: 0.85rem;
  }
}

/* Card hover effects for touch devices */
@media (hover: none) {
  .jobsmaindiv:hover {
    transform: none;
    box-shadow: none;
  }

  .actionButton:hover {
    transform: none;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.6;
  }
}

.skeleton-pulse {
  animation: pulse 1.5s ease-in-out infinite;
}
.shareModal {
  backdrop-filter: blur(8px);
}

.modalHeader {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  border: none;
}

.closeButton {
  background: none;
  border: none;
  color: #666;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.modalBody {
  padding: 0 1.5rem 1.5rem;
}

.shareTitle {
  font-size: 1.75rem;
  font-weight: 600;
  color: #2d3639;
  text-align: center;
  margin-bottom: 2rem;
}

.shareButtonsContainer {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  flex-wrap: wrap;
  margin-bottom: 2.5rem;
}

.shareButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
}

.shareIcon {
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.shareName {
  font-size: 0.875rem;
  color: #666;
  font-weight: 500;
}

.shareDetailsContainer {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 2rem;
  background: rgba(88, 17, 175, 0.03);
  border-radius: 16px;
  padding: 1.5rem;
  border: 1px solid rgba(88, 17, 175, 0.1);
}

.qrCodeSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(88, 17, 175, 0.08);
}

.qrCode {
  padding: 8px;
  border-radius: 8px;
}

.qrLabel {
  font-size: 0.875rem;
  color: #666;
  margin: 0;
  font-weight: 500;
}

.messageSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.shareMessage {
  width: 100%;
  min-height: 150px;
  padding: 1rem;
  border-radius: 12px;
  border: 1px solid rgba(88, 17, 175, 0.2);
  background: white;
  font-size: 0.95rem;
  line-height: 1.5;
  resize: none;
  color: #2d3639;
}

.copyButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  background: #5811af;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .shareDetailsContainer {
    grid-template-columns: 1fr;
  }
  
  .shareButtonsContainer {
    gap: 1rem;
  }
  
  .shareTitle {
    font-size: 1.5rem;
  }
}