@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Charm:wght@400;700&family=Inter:wght@100..900&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');
.resume {
  min-width: 44rem;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Libre Baskerville", serif;  
  letter-spacing: 0.01rem;
  gap: 0.7rem;
}

.resume a {
  color: hsl(208, 72%, 45%); 
}

.header {
  grid-area: header;
  display: flex;
  align-items: center;
  gap: 3rem;
  justify-content: center;
}
.header_bewlowname{
  display: flex;
  flex-direction: row;
  align-items: center;
    justify-content: center;
    font-weight: 500;
}
.user_details {
  display: flex;
  flex-direction: column;
  /* gap: 4.2rem; */
  font-size: 0.85rem;  
  font-weight: 600;
  flex: 1;
  justify-content: center;
}

.user_details .left {
  display: flex;
  flex-direction: column;
}

.user_details .right {
  display: flex;
  flex-direction: column;
  justify-content:end ;
  align-items: end;
}

.user_details .header_text {
  font-size: 0.85rem;
  /* font-weight:300; */
  color: #000;
  margin: 0.1rem;
}

.header_text a {
  text-decoration: none;
  color: black;
  border-bottom: 1px solid black;
}
.secondline a {
  text-decoration: none;
  color: black;
  /* border-bottom: 1px solid black; */
}

.iitb_logo {
  width: 100%;
  max-width: 5.5rem;
  height: auto;
  margin-left: 1rem;
}

.iitb_logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
 
.section {
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.4;
  width: 100%;
  text-align: justify;
}

.section_container {
  display: flex;
  flex-direction: column;
}

.section h2 {
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
  padding: 0.03rem;
  display: flex;
  align-items: baseline;
  /* margin-top: -1.4rem; */
  letter-spacing: 0.05rem;
}

.section h2.format1 {
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
  padding: 0.03rem;
  padding-left: 0.3rem;
  letter-spacing: 0.05rem;
  
 
}

.section h2 span {
  font-size: 1.2rem;
}

.section h2 span:not(:first-child) {
  margin-left: 0.3rem;
}

.section h2 .divider {
  flex: 1;
  height: 1px;
  background-color: #000;
  margin-left: 1rem;
  margin-top: 1rem;
}

.section h3 {
  font-size: 0.87rem;
  font-weight: 600;
  line-height: 1.6;
  margin: 0;
}

.section h4 {
  font-size: 0.71rem;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.3px;
  margin: 0;
}

.section ul {
  padding-left: 1rem;
  margin-top: -0.1rem;
}

.section ul li {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.section ul li::before {
  content: "•";
  position: absolute;
  left: -1rem;
  top: 0;
  font-size: 0.9rem;
  font-weight: 900;
}

.section_container span {
  font-weight: 600;
}

.section .table {
  width: 100%;
  border-collapse: collapse;
  font-weight: 500;
  color: #000;
  text-align: start;
  margin-bottom: 1rem;
}

.section table tr th {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  padding: 0.1rem 0;
  font-size: 0.8rem;
  font-weight: 600;
  text-align: start;
}

.section table tr:last-child {
  border-bottom: 1px solid #000;
}

.firstline,
.secondline {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.secondline {
  margin-top: -0.2rem;
  font-size: 0.77rem;
  margin-bottom: 0.2rem;
}

.header_name {
  font-size: 1.6rem;
  font-weight: 550;
  color: #000;
  margin: 0.1rem 0;
  text-align: center;
}

.hr {
  color:black;
  height: 1px;
  width: 100%;
  background-color: black;
  margin-top: 5px; 
  margin-bottom: 5px;
}

.font2 {
  font-family: "Charm", cursive;
  font-weight: 400;
  font-style: normal;
}
.description {
  line-height: 1.15;
}

.description p {
  margin: 0;
  padding: 0;
}

/* Add styles for bullet points */
.description ul {
 display: flex;
 flex-direction: column;
 /* gap: 0.5rem; */
}

.description li {
  margin: 0;
  padding: 0;
  line-height: 1.15;
  display: inline-block !important;
  text-align: left !important;
}

 

 