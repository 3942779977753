.main {
    padding: 70px 0;
    display: flex;
    justify-content: center; 
    align-items: center;
    /* background: var(--Gradient-New-Home, linear-gradient(86deg, #F0F8FF 1.71%, #FFFCEF 99.56%)); */
} 

.inner{
    width: 80%
}

.headd {
    font-weight: 600;
    font-size: 34px;
    line-height: 140%;
    color:#383838 ;
}

.mak {
    display: flex;
    flex-direction: row;
    align-items: center;
     gap: 1rem;
     overflow: hidden;  
     margin-top: 1rem;
     
}  
 


.inn {
    display: inline-flex; /* Use inline-flex to work with white-space: nowrap */
    border: 1px solid #E7E7F1; 
    padding: 1rem; 
    width: 20rem;
    gap: 1rem; 
    border-radius: 10px; 
    /* justify-content: center; */
    align-items: center;
    flex: none; /* Prevents flexbox from overriding the width */
    animation: fromlefttoright 35s linear infinite; /* Apply the animation */
    white-space: nowrap; /* Prevent line breaks */
    cursor: pointer;
}

 


.inne {
    display: inline-flex; /* Use inline-flex to work with white-space: nowrap */
    border: 1px solid #E7E7F1; 
    padding: 1rem; 
    width: 20rem;
    gap: 1rem; 
    border-radius: 10px; 
    /* justify-content: center; */
    align-items: center;
    flex: none; /* Prevents flexbox from overriding the width */
    animation: righttoleft 35s  linear infinite;
    cursor: pointer;
} 


 
.eloo {
    /* background-color: #f3f1ff; */
    border-radius: 100%; 
    padding: 6px;
}

.innni{
    font-size: 14px; 
    font-weight: 500;
    color: rgb(51 65 85); 
   
} 

.imgagee{
  height: 230px !important;
  object-fit: contain;
  object-position: center;
  border: 1px solid #eee;
  border-radius: 12px;
  background: white;
   
}
.sliderContainer {
  padding: 10px;
  border: none;
  transition: transform 0.3s ease;
  
}

.sliderContainer:hover {
  transform: translateY(-3px);
  box-shadow: none!important;
 
}

.dioro {
  overflow: hidden;
  border-radius: 10px;
  padding:1px 1px 2px 3px;
 
} 
.tagcont {
  white-space: nowrap; /* Ensures content stays on a single line */
  overflow-x: scroll; /* Enables horizontal scrolling */
  scrollbar-width: none; /* Hides scrollbar in Firefox */
}
.tagcont::-webkit-scrollbar {
  display: none; /* Hides scrollbar in WebKit browsers (Chrome, Safari, etc.) */
}

.headings {
  font-size: 20px;
  font-weight: 800;
  margin-top: .5rem;
  color:#05264E;
  cursor: pointer;
}
.headings:hover { 
  color:#1d5dab;
}

.short {
  color: #4F5E64;
  font-size: 14px;
}
.ineoro {
  display: flex;
  justify-content: space-between;
   
}
.trs {
  color: #66789C;
  font-size: 12px;
  font-weight: 500; 
  height: 100%;
  
 padding: 0px;
 margin: 0px;
  display:flex;
  /* justify-content: baseline; */
  align-items:last baseline;
}
.trscontianer{
   
   align-items: baseline;
  display: flex;
  justify-content: baseline;
  align-items: baseline;
  
   
}
.myimages {
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 100%;
}
.prohfi {
  display: flex;
  gap: 10px;
}
.name {
  line-height: 18px !important;
  font-size: 14px !important;
  font-weight: 600;
  color: #66789C;
  margin: 0px;
  
}

.time {
  font-size: 12px;
  font-weight: 500;
  color: #66789C;
  margin: 0px;
  line-height: 18px;

}




.tagItem {
  background-color: #3eb0ef2b;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 5px;
  display: inline-block;
  font-size: 10px;
  margin-top: .5rem;
}




@keyframes fromlefttoright {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
  }


  @keyframes righttoleft {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }


@media only screen and (max-width: 747px) { 

    .ngcontent{
        font-size: 25px;
    }
    ._ngcontents {
        font-size: 15px;
    }
    
    .headd {
        font-size: 25px;
    }
    .inner{
        width: 90%
    }
}

.sslo{
    position:relative !important;
    right: 0px !important;
    bottom:-30px !important;
  }
  .fosloes {
    /* color:#275DF5 ;  */
    padding: 7px 15px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    background-color: #EDF4FF;
    line-height: 20px;
  }  
  .xo {
    color: #474d6a;
  }
  
  .fosloes:hover { 
    background-color:#6267ff; 
    color: white;
  }
  
  .fosloes:hover  .xo{ 
    color: white;
  }