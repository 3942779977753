.main {
    width: 128px;
    height: 100vh;
    margin-top: 32px;
}
.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 16px;
    background-color: #ffffff;
    position: relative;
    height: 100%;
}
.logo {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    padding: 16px;
    cursor: pointer;
}
.dorcolor {
    color: rgb(249, 111, 89);
}

.arrow {
    position: absolute;
    right: -20px;
    top: 80px;
}

.subcontainer {
    width: 100%;
 }
.profileimage {
    border-radius: 50%;
    height: 70px;
}
.penimage {
     height: 40px;
}
.profilecontainer {
    margin-top: 2rem;
 display: flex;
 justify-content: center;
}
.imageContainer {
    position: relative;
}
.imageContainer span {
    position: absolute;
    top:70%;
    right: -12%;
}
.detailsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 1rem;
    text-align: center;
}
.name {
    color: #373F54;
    font-size: 18px;
    font-weight: 600;
}
.email {
    color: #545C72;
    
}
.exp {
    color: #545C72;
    line-height: 18px;
}
.navigationContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;
    margin-top: 2rem;
    padding: 0 8px;
    width: 100%;
}
.ee{
    position: relative;
    width: 100%;
    font-size: 14px;
    color: #909AB6;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 12px 8px;
    border-radius: 8px;
    transition: all 0.3s ease;
    cursor: pointer;
}
.ee:hover {
    color: #5860FC;
    transform: translateY(-2px);
    background-color: #f5f6ff;
}
.ee svg, 
.ee img {
    width: 24px;
    height: 24px;
    transition: all 0.3s ease;
}
.iconLabel {
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    transition: all 0.3s ease;
}
.active {
    color: #5860FC;
    background-color: #f5f6ff;
}
.bar {
    position: absolute;
    right: -8px;
    top: 0;
    background-color: #5860FC;
    height: 100%; 
    width: 4px;
    border-radius: 10px;
    display: none;
}
.baractive {
    display: block;
}