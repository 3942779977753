.progressBar {
  will-change: transform;
  backdrop-filter: blur(4px);
}

.progressBarTrack {
  backdrop-filter: blur(4px);
}

@keyframes shine {
  from {
    background-position: 200% 0;
  }
  to {
    background-position: -200% 0;
  }
}