.main {
 
  display: flex;
  background: linear-gradient(86deg, #f0f8ff 1.71%, #fffcef 99.56%);
  display: flex;
  border-radius: 1rem;
  z-index: -999;
  position: relative;
  padding-bottom: 4rem;
}

.left {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
   justify-content: center;
  padding: 1rem;


}
.right {
  width: 50%;
  max-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(https://res.cloudinary.com/dvepwlz9j/image/upload/v1725635644/all/gv7y5h82ytwvzzrf8i28.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-blend-mode: normal; /* This ensures the color and image coexist */
}
.cooro {
    width: 86%
}
.imgcontinaer {
  height: 95%;
   width: 90%;
  overflow: hidden;
  border-radius: 1rem;
  object-fit: contain;
  display: flex;
  justify-content: center;
 margin-top: 2rem;
}
.location {
  margin-top: 16px;
}
.imgii  {
    overflow: hidden;
}
.name {
    font-weight: 600;
    font-size: 48px;
    font-family: Rubik;
        font-style: normal;
        color: #2d3639;
        margin-bottom: 16px;
 
}
.button {
    background: linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%);
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 32px;
    font-weight: 600;

}
.imgcontainer img {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: top;
  }
/* add media query for the mobile view */
@media only screen and (max-width: 768px) {
  .left {
    width: 100%;
    overflow: hidden;
  }
  .right {
     display: none;
  }
  .main {
    flex-direction: column;
  }
  .cooro {
    width: 100%
}
.name{
  font-size: 38px;
    text-align: center;
    line-height: 46px;
    margin-bottom: 16px;
    margin-top: 24px;
}
.ddo {
  text-align: center;
  display: flex;
  justify-content: center;
  color: #60696c;
}
.location {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.doroowoo {
  display: flex;
  justify-content: center;
  align-items: center;
}
}
