/* @import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap"); */

.container {
  width: 100%;
  height: calc(100vh - 128px);
  display: flex;
  overflow: hidden;
  margin-top: 10px;
 
}
.main {
  background-color: #F3F6FD;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
}
.containerP {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
  grid-template-areas: "left right";
}

.left {
  overflow-y: auto;
  width: 44%;
  margin-left: 32px;  
  margin-right: 32px;
  scrollbar-width: none; /* Hide scrollbar in Firefox */
   
}
.left::-webkit-scrollbar {
  display: none;
  width: 1rem;
  
}
.sidenavbarContainer {
  width: 10%;
}
.right {
  background-color: hsl(222, 13%, 45%);
  background-color: #e9e9e9;
  color: #fff;
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
  padding: 1rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rightt {
  width: 50%;
  color: #fff;
  padding: 3rem;
  padding-top: 1rem;
  border-radius: 13.3px;
  overflow-y: auto;    
  box-sizing: border-box; /* Include padding in the element's width and height */
  border: 1px solid #eeee;
  scrollbar-width: none; /* Hide scrollbar in Firefox */

  background-color: #b7baec;
}

.rightana {
  /* background-color: hsl(222, 13%, 45%); */
  /* color: #fff; */
  width: 50%;
 
  overflow-y: auto; /* Enable scrolling within the right section */
  box-sizing: border-box; /* Include padding in the element's width and height */
}

@media (max-width: 768px) {
  .rightt {
    padding: 1rem; 
    width: 94%;
  }
}

.topbar {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  border-bottom: 1px solid #eee;
  z-index: 9;
}

.ddi {
  padding: 0.5rem;
  /* background-color: #e1f3ff;  */
  border-radius: 5px;
  cursor: pointer;
   color: #909AB6;
  font-weight: 500;
}
.ddi:hover { 
  color: #8061DD !important;
}

.dlee {
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  height: 37px;
  font-weight: 500;
}
.ProfileButtonBox {
  border-radius: 5px;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  height: 37px;
  font-weight: 400;
}
.ProfileButtonBox:hover {
  background-color: #e1f3ff;
}
.buttonsall {
  color: black;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
}
.slso {
 
  background-color: white;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  width: 160px;
  right: -2rem;
  top: 2rem;
  /* height: 4rem;  */
  position: absolute;
  font-size: 14px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.profileButtonMainContainer {
 
  background-color: white;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  width: 160px;
  right: -2rem;
  top: 2rem;
  /* height: 4rem;  */
  position: absolute;
  font-size: 14px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 99;
}
.logout {
   color: #f44336;
  

}
.allos {
  position: relative;
}

.ddiactive {
 
  
  color: #8061DD !important;
}

.profileEachButton {
  font-size: 14px;
  position: relative;
  margin-left: 16px;
}
.profileEachButton img {
  border-radius: 50%;
    margin-right: 10px;
    width: 22px;
}

.sore {
  position: absolute;
  background-color: rgb(209, 84, 84);
  color: white;
  border-radius: 50%;
  height: 1.2rem;
  width: 1.2rem;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -14px;
  right: -14px;
  font-weight: bold;
}

.dldo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  gap: 56px;
  height: 64px;
  width: calc(100% -  32px);
  border-radius: 16px;
  margin-top: 32px;
  margin-bottom: 32px;
}

.leftdo {
  display: flex;
  gap: 56px;
  margin-left: 32px;
}

.rightdo {
  display: flex;
  gap:12px;
  margin-right: 32px;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
  }
  .dldo {
    display: none;
  }
  .left {
    overflow-y: auto;
    width: 100%;
  }

  .right {
    position: relative;
    overflow-y: auto;
  }
  .left {
    padding: 0rem;
  }
}

/* ai css start */

#settingstrigger {
  position: fixed;
  bottom: 40px;
  right: 30px;
  height: 80px;
  width: 80px;
  background: #4b49ac;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.closeButton {
  width: 29%;
  display: flex;
  justify-content: end;
  /* border:2px solid black */
}
.messageDiv {
  height: 75%;
  background-color: white;
  padding: 0.4rem;
  overflow: scroll;
}

.sendDiv {
  height: 12%;
  margin-top: 0.4rem;
  background-color: white;
}

@keyframes slideOutToBottom {
  100% {
    transform: translateY(0);
  }
  0% {
    transform: translateY(100%);
  }
}

.closingAnimation {
  animation: slideOutToBottom 0.5s ease-out;
}

/* Add these styles to your CSS module */

.trigger {
  cursor: pointer;
}

/* Styling for the chat box */
.chatBot {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 500px;
  margin: 20px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  animation-duration: 0.5s;
}

.header {
  background-color: #4e8cff;
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading {
  font-size: 16px;
  font-weight: bolder;
}

.closeButton img {
  width: 20px;
}

.messageDiv {
  padding: 10px;
  max-height: 500px;
  overflow-y: auto;
}

.message {
  background-color: #e1f3ff;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
}

.inputMessage {
  display: inline-block;
  text-align: right;
  background-color: #4e8cff;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
  color: white;
}

.sendDiv {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #eeeeee;
}

input {
  flex: 1;
  border: none;
  border-radius: 5px;
  padding: 8px;
  margin-right: 8px;
  overflow: visible;
}
input:focus {
  outline: none;
}
.sendButton {
  background-color: white;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  cursor: pointer;
}

.sendButton:hover {
  background-color: #4e8cff;
}

.livedot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: rgb(116, 231, 116);
  border-radius: 50%;
  position: absolute;
  left: 0.5rem;
  top: 1rem;
  animation: blink 1s infinite; /* Blink animation */
}
@keyframes blink {
  50% {
    opacity: 0;
  }
}
/* ai css end  */

.template {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin: 0 auto;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  background-color: #e7ecf5;
  padding: 1rem;
}
.template .preview {
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 0 24px hsl(222, 13%, 80%);
}

.template .preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.template:hover .preview {
  outline: 3px solid hsl(207, 88%, 52%);
  outline-offset: 3px;
}

.template .name {
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0.01rem;
  text-align: center;
}

.template:hover .name {
  color: hsl(207, 88%, 52%);
}
.saved_state {
  color: white;
  

  justify-content: end;
}
.saved {

  display: block;
  width: 70%;
}


.maintop {
  min-height: 100vh;
  width: 100vw;
  display: flex; 
  box-sizing: border-box;
  margin: 0;
  background-color: #F4F7FE;
  overflow: hidden;
   
}

.lefttop {
  top: 0;
  left: 32px;
  position: fixed;
  
}

.righttop {
  /* width: 88%; */
  min-height: 100vh;
  margin-left: 192px; 
  
}

.mobilenav {
  display: none;
}




.navigationContainer {
  display: flex;
  position: relative;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 5rem;
}
.navigationContainerMobile {
  display: flex;
  position: absolute;
}
.navigationPrev {
   border: 1px solid #BFC7DC;
  color: #535D79;
  background-color: #F3F6FD;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 248px;
  height: 44px;
  border-radius: 2rem;
  padding: .6rem;
  position: absolute;
  top: 50%; 
  left: 0;
  cursor: pointer;
}
.navigationPreviewMobile {
  border: 1px solid #8061DD;
 color: #8061DD;
 background-color: #F3F6FD;
 font-size: 14px;
 display: flex;
 justify-content: center;
 align-items: center;
 
 border-radius: 2rem;
 padding-left: 24px;
 padding-right: 24px;
 padding-top: 12px;
 padding-bottom: 12px;
 cursor: pointer;
}
.navigationNext {
  background-color: #8061DD;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 248px;
  height: 44px;
  border-radius: 2rem;
  padding: .6rem;
  position: absolute;
  top: 50%; 
  right: 0;
  cursor: pointer;
}
.navigationNextMobile {
  background-color: #8061DD;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  cursor: pointer;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.sparkleContainer {
  color: #734AED;
  border: 1.5px solid #734AED;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5rem;
  font-size: 12px;
  padding: 0.4rem;
  height: 35px;
  padding-left: .8rem;
  padding-right: .8rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.sparkleContainer img {
  margin-right: .3rem;
 }
.sparkleContainerMian {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logoutContainer {
  height: 45px;
}
.hr {
  border: .4px solid #C5C5C5;
}
.stepContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}
.StepBox {
  background-color: #8061DD;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  border-radius: 10rem;
  font-family: "inter";
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.filledDot {
  height: 13.5px;
  width: 13.5px;
  border-radius: 100%;
  background-color: #8061DD;
  border: 1.5px solid #B69FFB;
}
.emptyDot {
  height: 13.5px;
  width: 13.5px;
  border-radius: 100%;
  background-color: #ffffff;
  border: 1.5px solid #D3DBF1;
}
.dotsContainer {
  display: flex;
   gap: 10px;
  }


.navigationContainerMobile {
  display: none  ;
  position: fixed;
  bottom: 0px;
  height: 68px;
  width: 100%; left: 0px;
  background-color: #F4F7FE; 
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 12px;
  padding-bottom: 12px; 
}
.mobilenavMain {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  height: 56px
}
.profileImage {
  height: 24px;
  width: 24px;
  border-radius: 100%;
}
.backButton {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: #535D79;
}

.previewContent {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.previewClose {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  transition: background-color 0.2s ease;
}

.previewClose:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.previewContentInner {
  width: 100%;
  max-width: 800px;
  height: 90vh;
  background-color: #B7BAEC;
  border-radius: 8px;
  overflow-y: auto;
  padding: 20px;
  margin: 20px;
}

.floatingPreviewBtn {
  position: fixed;
  bottom: 80px;
  right: 20px;
  background: #2563eb;
  color: white;
  padding: 12px 20px;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
  z-index: 1000;
  display: none;
}

.floatingPreviewBtn:hover {
  background: #1d4ed8;
  transform: translateY(-2px);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.previewBtnContent {
  display: flex;
  align-items: center;
  gap: 8px;
}

.previewBtnContent span {
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .floatingPreviewBtn {
    bottom: 100px;
    padding: 10px;
    border-radius: 50%;
    display: block;
  }
  
  .previewBtnContent span {
    display: none;
  }
}

  @media screen and (max-width: 768px) { 
    .lefttop {
      width:10%;
      top: 0;
      left: 0px;
      min-height: 70vh;
      display: none;
    }
    .righttop {
      width: 100%;
      min-height: 100vh;
      margin-left: 0%; 
      
    }
    .mobilenav {
      display: block;
    }
    .rightt{
      display: none;
    }
    .left {
      margin-left: 0px;  
      margin-right: 0px;
      display: flex;
      padding: 10px;
      justify-content: center; 
      flex-direction: column;
      margin-bottom: 64px;
    }
    .navigationContainer {
      display: none;
    }
    .navigationContainerMobile {
      display: flex  ;

    } 
    .maintop {
       
      width: 100vw;
    }
    .container {
      height: calc(100vh - 42px);
    }
  }

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
}

.inputGroup {
  margin-bottom: 1rem;
}

.inputGroup label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.inputGroup input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.submitButton {
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submitButton:hover {
  background-color: #0056b3;
}

.adminButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.adminButton:hover {
  background-color: #e0e0e0;
}

.inputGroupWithPreview {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  margin-bottom: 15px;
}

.inputSide {
  flex: 1;
}

.previewSide {
  width: 150px;
}

.imagePreview {
  width: 100%;
  height: auto;
  border-radius: 4px;
  object-fit: cover;
}

.fullScreenModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f8fafc;
  z-index: 1000;
  display: flex;
}

.modalSidebar {
  width: 380px;
  background: #fff;
  border-right: 1px solid #e2e8f0;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.05);
}

.sidebarHeader {
  padding: 1.5rem;
  border-bottom: 1px solid #e2e8f0;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebarHeaderLeft h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 0.25rem;
}

.templateCount {
  font-size: 0.875rem;
  color: #64748b;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.sidebarSearch {
  padding: 1rem;
  border-bottom: 1px solid #e2e8f0;
  background: #fff;
}

.searchWrapper {
  position: relative;
  margin-bottom: 1rem;
}

.searchWrapper i {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #94a3b8;
}

.searchInput {
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 2.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.875rem;
  background: #f8fafc;
  transition: all 0.2s;
}

.searchInput:focus {
  border-color: #4f46e5;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
  outline: none;
}

.filterButtons {
  display: flex;
  gap: 0.5rem;
}

.filterButton {
  padding: 0.5rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.875rem;
  color: #64748b;
  background: #fff;
  cursor: pointer;
  transition: all 0.2s;
}

.filterButton:hover {
  background: #f8fafc;
  border-color: #cbd5e1;
}

.filterButton.active {
  background: #4f46e5;
  color: #fff;
  border-color: #4f46e5;
}

.historyList {
  padding: 1rem;
  overflow-y: auto;
  flex: 1;
}

.historyItem {
  background: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  padding: 1.25rem;
  margin-bottom: 1rem;
  transition: all 0.2s;
}

.historyItem:hover {
  border-color: #4f46e5;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.historyItemContent {
  display: flex;
  gap: 1rem;
}

.historyItemPreview {
  position: relative;
  width: 120px;
  height: 160px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.historyItemPreview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.templateBadge {
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  padding: 0.25rem 0.5rem;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  font-size: 0.75rem;
  border-radius: 4px;
}

.itemHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.itemHeader h4 {
  font-size: 1rem;
  font-weight: 600;
  color: #1e293b;
}

.favoriteButton {
  background: none;
  border: none;
  color: #94a3b8;
  cursor: pointer;
  padding: 0.25rem;
  transition: color 0.2s;
}

.favoriteButton:hover {
  color: #f59e0b;
}

.templateMeta {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: #64748b;
  margin-bottom: 0.75rem;
}

.templateMeta span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.templateTags {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.tag {
  padding: 0.25rem 0.75rem;
  background: #f1f5f9;
  color: #475569;
  border-radius: 100px;
  font-size: 0.75rem;
}

.historyItemActions {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #e2e8f0;
}

.actionButton {
  padding: 0.5rem 1rem;
  border: 1px solid #e2e8f0;
  background: #fff;
  color: #475569;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
}

.actionButton:hover {
  background: #f8fafc;
  color: #1e293b;
}

.deleteButton {
  composes: actionButton;
  color: #ef4444;
}

.deleteButton:hover {
  background: #fee2e2;
  color: #dc2626;
}

.headerActions {
  display: flex;
  gap: 0.75rem;
}

.formSection {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  border: 1px solid #e2e8f0;
  margin-bottom: 1.5rem;
}

.uploadArea {
  border: 2px dashed #e2e8f0;
  border-radius: 12px;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
}

.uploadArea:hover {
  border-color: #4f46e5;
  background: #f8fafc;
}

.uploadIcon {
  font-size: 2rem;
  color: #94a3b8;
  margin-bottom: 1rem;
}

.supportedFormats {
  font-size: 0.75rem;
  color: #94a3b8;
  margin-top: 0.5rem;
}

.previewContainer {
  position: relative;
}

.previewOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s;
}

.previewContainer:hover .previewOverlay {
  opacity: 1;
}

.changeImageBtn {
  padding: 0.75rem 1.5rem;
  background: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
}

.modalMain {
  flex: 1;
  padding: 2rem;
  overflow-y: auto;
}

.modalHeader {
  margin-bottom: 2rem;
}

.headerDescription {
  color: #6c757d;
  margin-top: 0.5rem;
}

.formGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.inputRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
}

.inputGroup {
  margin-bottom: 1.5rem;
}

.inputGroup label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #344767;
}

.inputGroup input,
.inputGroup textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  font-size: 0.875rem;
}

.uploadPlaceholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  color: #6c757d;
}

.hiddenInput {
  display: none;
}

.previewImage {
  max-width: 100%;
  max-height: 300px;
  border-radius: 8px;
}

.formActions {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.submitButton,
.cancelButton {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.submitButton {
  background: #4318FF;
  color: white;
  border: none;
}

.submitButton:hover {
  background: #3311CC;
}

.cancelButton {
  background: white;
  border: 1px solid #e9ecef;
  color: #344767;
}

.cancelButton:hover {
  background: #f8f9fa;
}

.spinner {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  border: 2px solid rgba(255,255,255,0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}