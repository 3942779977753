.headerSection {
    position: relative;
    min-height: 600px;
    padding: 4rem 2rem;
    overflow: hidden;
    background: linear-gradient(to bottom, #0A1930, #162440);
  }
  
  .backgroundElements {
    position: absolute;
    inset: 0;
    pointer-events: none;
  }
  
  .gradientOrb1 {
    position: absolute;
    top: -10%;
    right: -5%;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    background: radial-gradient(circle at center, rgba(99, 102, 241, 0.15), transparent 70%);
    filter: blur(40px);
  }
  
  .gradientOrb2 {
    position: absolute;
    bottom: -10%;
    left: -5%;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background: radial-gradient(circle at center, rgba(59, 130, 246, 0.15), transparent 70%);
    filter: blur(40px);
  }
  
  .gridPattern {
    position: absolute;
    inset: 0;
    background-image: linear-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px),
                      linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
    background-size: 30px 30px;
    opacity: 0.5;
  }
  
  .headerContent {
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }
  
  .heroWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    align-items: center;
  }
  
  .heroLeft {
    padding-right: 2rem;
  }
  
  .titleWrapper {
    position: relative;
    margin-bottom: 2rem;
  }
  
  .mainTitle {
    font-size: 4rem;
    font-weight: 800;
    line-height: 1.1;
    color: #ffffff;
    margin-bottom: 1.5rem;
  }
  
  .gradientText {
    background: linear-gradient(135deg, #6366f1, #3b82f6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .titleAccent {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100px;
    height: 4px;
    background: linear-gradient(90deg, #6366f1, transparent);
    border-radius: 2px;
  }
  
  .subtitle {
    font-size: 1.25rem;
    color: #94a3b8;
    margin-bottom: 3rem;
    line-height: 1.6;
  }
  
  .statsGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }
  
  .statCard {
    background: rgba(255, 255, 255, 0.05);
    padding: 1.5rem;
    border-radius: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: transform 0.3s ease;
  }
  
  .statCard:hover {
    transform: translateY(-5px);
    background: rgba(255, 255, 255, 0.08);
  }
  
  .statValue {
    font-size: 2rem;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 0.5rem;
  }
  
  .statLabel {
    font-size: 0.875rem;
    color: #94a3b8;
  }
  
  .statProgress {
    height: 3px;
    background: linear-gradient(90deg, #6366f1, #3b82f6);
    border-radius: 1.5px;
    margin-top: 1rem;
    width: 60%;
  }
  
  .heroRight {
    position: relative;
  }
  
  .imageWrapper {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .heroImage {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px;
  }
  
  .imageAccent {
    position: absolute;
    inset: 0;
    pointer-events: none;
  }
  
  .imageContainer {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    padding: 8px;
  }
  
  .decorativeElement {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 15px;
    left: 15px;
    border: 2px solid rgba(80, 63, 205, 0.3);
    border-radius: 12px;
    z-index: -1;
  }
  
  @media (max-width: 1024px) {
    .heroWrapper {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  
    .heroLeft {
      padding-right: 0;
    }
  
    .mainTitle {
      font-size: 3rem;
    }
  }
  
  @media (max-width: 768px) {
    .statsGrid {
      grid-template-columns: 1fr;
    }
  
    .mainTitle {
      font-size: 2.5rem;
    }
  }