.uploadContainer {
    max-width: 600px;
    margin: 2rem auto;
    padding: 0 1rem;
    margin-top: 3rem;
  }
  
  .uploadBox {
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    border: 2px dashed #e0e0e0;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
  }
  
  .uploadBox:hover {
    border-color: #3b82f6;
    background: #f8fafc;
    transform: translateY(-2px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  }
  
  .uploadContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    text-align: center;
  }
  
  .uploadIconWrapper {
    width: 80px;
    height: 80px;
    background: #eff6ff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .uploadIcon {
    font-size: 3rem;
    background: linear-gradient(45deg, #4CAF50, #2196F3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .uploadInstructions {
    margin-bottom: 1rem;
  }
  
  .uploadTitle {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1f2937;
    margin-bottom: 0.5rem;
  }
  
  .uploadDescription {
    color: #6b7280;
    font-size: 1rem;
  }
  
  .uploadActions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
  }
  
  .uploadButton {
    background: #3b82f6;
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s ease;
  }
  
  .uploadButton:hover {
    background: #2563eb;
    transform: translateY(-1px);
  }
  
  .fileInput {
    display: none;
  }
  
  .buttonIcon {
    font-size: 1.25rem;
  }
  
  .dragText {
    color: #6b7280;
    font-size: 0.9rem;
  }
  
  .uploadInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .formatInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: #6b7280;
    font-size: 0.9rem;
  }
  
  .infoIcon {
    color: #ef4444;
  }
  
  .uploadProgress {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .progressBar {
    width: 100%;
    height: 4px;
    background: #e2e8f0;
    border-radius: 2px;
    overflow: hidden;
  }
  
  .progressFill {
    height: 100%;
    width: 30%;
    background: #3b82f6;
    animation: progress 1s ease infinite;
  }
  
  .progressText {
    color: #3b82f6;
    font-size: 0.9rem;
    font-weight: 500;
  }
  
  .uploading {
    pointer-events: none;
  }
  
  .uploading .uploadButton {
    opacity: 0.7;
  }
  
  @keyframes progress {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(400%); }
  }
  
  @media (max-width: 640px) {
    .uploadBox {
      padding: 1.5rem 1rem;
    }
  
    .uploadIconWrapper {
      width: 60px;
      height: 60px;
    }
  
    .uploadIcon {
      font-size: 2rem;
    }
  
    .uploadTitle {
      font-size: 1.25rem;
    }
  }
  
  .dropzoneContainer {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  .uploadBox {
    border: 2px dashed #ccc;
    border-radius: 16px;
    padding: 2rem;
    text-align: center;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  
  .dragActive {
    border-color: #4CAF50;
    background: rgba(76, 175, 80, 0.05);
  }
  
  .uploadIconWrapper {
    width: 80px;
    height: 80px;
    margin: 0 auto 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(76, 175, 80, 0.1);
    border-radius: 50%;
  }
  
  .uploadIcon {
    font-size: 2.5rem;
    color: #4CAF50;
  }
  
  .uploadingIcon {
    color: #2196F3;
  }
  
  .errorMessage {
    color: #f44336;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin: 1rem 0;
  }
  
  .errorIcon {
    font-size: 1.2rem;
  }
  
  .supportedFormats {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: #666;
    margin-top: 1rem;
  }
  
  .supportedIcon {
    color: #4CAF50;
  }
  
  .uploadProgressContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: rgba(33, 150, 243, 0.1);
  }
  
  .uploadProgress {
    height: 100%;
    background: linear-gradient(90deg, #4CAF50, #2196F3);
    border-radius: 8px;
  }
  
  .fileInput {
    display: none;
  }
  
  .uploadText {
    font-size: 1.2rem;
    color: #333;
    margin: 1rem 0;
  }
  
  .stepsContainer {
    margin-bottom: 3rem;
    padding: 0 1rem;
    margin-top: 3rem;
  }
  
  .stepsTitle {
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    color: #1a237e;
    margin-bottom: 2rem;
    background: linear-gradient(120deg, #1a237e, #3949ab);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: titleFade 0.8s ease-in;
  }
  
  .stepsWrapper {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .stepCard {
    flex: 1;
    background: white;
    padding: 1.5rem;
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    backdrop-filter: blur(12px);
    padding: 2rem;
    color: white;
    transition: all 0.3s ease;
  }
  
  .stepCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  }
  
  .stepNumber {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 3rem;
    font-weight: 700;
    color: rgba(59, 130, 246, 0.1);
    line-height: 1;
  }
  
  .stepIconWrapper {
    width: 60px;
    height: 60px;
    background: #eff6ff;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .stepIcon {
    font-size: 1.8rem;
    color: #3b82f6;
  }
  
  .stepTitle {
    font-size: 1.2rem;
    font-weight: 600;
    color: #1f2937;
    margin-bottom: 0.5rem;
  }
  
  .stepDescription {
    color: #6b7280;
    font-size: 0.95rem;
    line-height: 1.5;
  }
  
  @keyframes titleFade {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 768px) {
    .stepsWrapper {
      flex-direction: column;
      gap: 1.5rem;
    }
    
    .stepCard {
      padding: 1.25rem;
    }
    
    .stepNumber {
      font-size: 2.5rem;
    }
  }
  
  /* Add shimmer effect for cards */
  .stepCard::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 200%;
    height: 100%;
    background: linear-gradient(
      to right,
      transparent 0%,
      rgba(255, 255, 255, 0.2) 50%,
      transparent 100%
    );
    transform: translateX(-100%);
    animation: shimmer 3s infinite;
  }
  
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
  
  .gradientText {
    background: linear-gradient(90deg, #4CAF50 0%, #2196F3 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .stepCard {
    backdrop-filter: blur(12px);
    border-radius: 16px;
    padding: 2rem;
    color: white;
    transition: all 0.3s ease;
  }
  
  .uploadBox {
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    border: 2px dashed #e0e0e0;
    transition: all 0.3s ease;
  }
  
  .uploadBox.dragActive {
    background: rgba(76, 175, 80, 0.1);
    border-color: #4CAF50;
  }
  
  .uploadProgress {
    background: linear-gradient(90deg, #4CAF50, #2196F3);
    border-radius: 8px;
  }
  
  .uploadIcon {
    font-size: 3rem;
    background: linear-gradient(45deg, #4CAF50, #2196F3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  /* Add responsive design */
  @media (max-width: 768px) {
    .stepsWrapper {
      flex-direction: column;
    }
    
    .stepCard {
      margin: 1rem 0;
    }
  }