.swiper {
    width: 100%;
    height: 100%; 
    
  }
  
  .swiper-slide {
    
    background: var(--N200, #fff);
    padding: 20px;
    border: 1px solid var(--N400, #E7E7F1);
    -webkit-box-shadow: 0px 6px 12px var(--Ev100, rgba(30, 10, 58, 0.04));
    box-shadow: 0px 6px 12px var(--Ev100, rgba(30, 10, 58, 0.04));
    border-radius: 10px;
    cursor: pointer;
    /* height: 140px; */
     color: #121224;
    
     width: 233.123px;
     margin-right: 16px;
    position: relative;
  }
  .swiper-slide:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  
  .custom-swiper-button-next,
.custom-swiper-button-prev {
   
  color: #000; /* Adjust the color as needed */
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7); /* Adjust background color as needed */
  border-radius: 50%; /* Make buttons circular */
  position: absolute;
  top: 50%; 
  
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer; 
  border:2px solid #EAF1F5
}

.custom-swiper-button-next {
  right: 10px; /* Adjust as needed */
  
}

.custom-swiper-button-prev {
  left: 10px; /* Adjust as needed */
}

.custom-swiper-button-next:after,
.custom-swiper-button-prev:after {
  font-size: 20px;
} 

.nameheading {
    margin: 48px 0 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 8px;
    padding: 0 16px;
}

.main-heading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 4px;
    flex-direction: column;
}

.main-heading h1 {
    font-size: 28px;
    line-height: 1.28571429;
    font-weight: 700;
    margin: 0;
    color: #333;
}

@media (max-width: 1255px) {
    .student-dashboard .main-header h4 {
        font-size: 18px;
        line-height: 1.44444444;
        font-weight: 400;
    }
    .custom-swiper-button-next {
       display: none;
        
      }
      .custom-swiper-button-prev {
        display: none
      }
}

.student-dashboard .main-header h4 {
    font-size: 20px;
    line-height: 1.6;
    font-weight: 400;
    color: #484848;
    margin: 0;
}

.chip-image {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 40px;
    width: 40px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 10px 0 0px;
    border: 1px solid var(--N400, #E7E7F1);
    border-radius: 7px;
    overflow: hidden;
}