.main {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    background-color: rgba(229, 237, 255, 0.5); /* Corrected to rgba */
    margin: 0 !important;
    color:black;
    font-weight: 550; 
    font-size: 13px;
    position: relative;  
    z-index: 10;  
} 
.main > p {
    margin: 5px;
    opacity: 1;
}

.main > .buttonAi {
    background:linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%) ;
    font-weight: 500;
    color: white;
    margin-left: 1rem;
    border-radius: 5px;
    color: white;
    padding: .3rem; 
    cursor: pointer; 
    text-align: center;
}

 


.animation {
    height: 30px;
    background-color: rgba(70, 123, 255,1); /* Added opacity */
    width: 130px;
    filter: blur(30px) ; /* Adjusted filter values */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); /* Soft shadow for depth */
    position: absolute;
    right: 0;
    z-index: 1;
    animation: top-banner 5s linear infinite;
    top:0;
    border-radius: 30rem; /* Circular shape */
    
  }

  @keyframes top-banner {
    0% {
      left: -140px; /* Start off-screen to the left */
    }
    100% {
      left: calc(100% - 130px); /* Moves 130px to the left of the right edge */
    }
  }


  @media only screen and (max-width: 747px) {

    .main { 
      font-size: 10px;
    }
   }
 

   .image {
    animation: rotate 4s infinite linear;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg) scale(1);
    }
    50% {
      transform: rotate(180deg) scale(1.3);
    }
    100% {
      transform: rotate(360deg) scale(1);
    }
  }