.main {
     padding: 80px 0;
     display: flex;
     justify-content: center; 
     align-items: center;
     background: var(--Gradient-New-Home, linear-gradient(86deg, #F0F8FF 1.71%, #FFFCEF 99.56%));
} 

.inner{
    width: 80%
}
.wip {
    /* background-color: #fff8e5; */
}
 .do{
    /* background-color: #fff8e5; */
 }
 .dll {
    height: 3rem !important;
    width: 3rem !important;
    overflow: hidden;
    border-radius: 50%;
    margin-top: 1rem;
 }
 .ell {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
 }
.ngcontent {
    font-weight: 600;
    font-size: 34px;
    line-height: 140%;
    color: #383838;
    margin-bottom: 0;
} 
._ngcontents{
    color: #727272;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    display: block;
    margin-top: 10px;
    margin-bottom: 40px;
} 

.cate {
    padding: 1rem;
    border: 1px solid #E7E7F1;
    border-radius: 20px; 
    /* width: 270px;  */
    display: flex;
    justify-content: center;
    align-items: center;
} 
.cate:hover{
    /* scale: 1.1; */
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
} 

/* .cate:hover::after{
    content: "asdfa";
     
}  */

.icon {
    background-color: #fff1cc;
    padding: 8px;
    margin-right: 10px;
    border-radius: 50px; 
    color: #926e0c;
}

.icon2 {
    background-color: #fce8ef;
    padding: 8px;
    margin-right: 10px;
    border-radius: 50px; 
    color: rgb(228, 15, 15)ef;
}

.icon3 {
    background-color: #ebe8fd;
    padding: 8px;
    margin-right: 10px;
    border-radius: 50px; 
    color: rgb(237, 1, 1)fd;
}

.icon4 {
    background-color: rgb(233 252 241);
    padding: 8px;
    margin-right: 10px;
    border-radius: 50px; 
    /* color: white; */
}

.icon5 {
    background-color: rgb(245, 226, 237);
    padding: 8px;
    margin-right: 10px;
    border-radius: 50px; 
    /* color: rgb(242, 111, 111); */
}

.icon6 {
    background-color: #e8fde9;
    padding: 8px;
    margin-right: 10px;
    border-radius: 50px; 
    /* color: rgb(242, 111, 111); */
}

.headd {
    font-weight: 600;
    font-size: 34px;
    line-height: 140%;
    color:#383838 ;
}

.image{
    height: 2rem !important;
    width: 2rem !important;
    
}
.de{
    font-size: 14px;
    line-height: 140%;
    color: #383838;
    margin-bottom: 0;
    display: -webkit-box;
   
    overflow: hidden;
}

.dllld{
    font-size: 14px;
}

@media only screen and (max-width: 747px) { 

    .ngcontent{
        font-size: 25px;
    }
    ._ngcontents {
        font-size: 15px;
    }
    .cate {
        width: 100%;
        justify-content: start; 
        font-size: 15px;
    } 
    .headd {
        font-size: 25px;
    }
    .inner{
        width: 90%
    }
}