.sliderContainer {
    width: 100%;  
    margin: auto;
    margin-top: 4rem;
    padding: 4rem;
    background: linear-gradient(86deg, #ecf4fb 1.71%, #FFFCEF 99.56%); 
    display: flex; 
    flex-direction: column; 
    object-fit: contain;
    overflow: hidden;
    padding: 2.5rem 1.5rem;
    margin-bottom: 40px;
  }
  
  .eachSlide {
    transition: transform 0.3s ease;  
    padding: 10px; 
    height: 40rem;
  }
  .eachSlide:focus-visible {
    outline:  none;
    
}  
  
  .templateImage {
    width: 100%; 
     margin-top: 4rem;
    border-radius: 8px;  
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  
    margin-bottom: 10px;
   
  }
  .heao{
    font-weight: 600;
    letter-spacing: -2px;
    font-size: 46px;
    line-height: 1;
    font-family: Roobert, sans-serif;
 
    color: #383838;
    margin-bottom: 0;
   text-align: center;
  }
  .ddfaf {
    color: #727272;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    display: block;
    margin-top: 10px;
    margin-bottom: 40px;
    text-align: center;
  }
  :global(.slick-center) {
    position: relative;
    z-index: 1;  
   
  }
  :global(.slick-dots) {
    bottom: -40px;
  }
  :global(.slick-center) .eachSlide {
    transform: scale(1.3);  
  }
  
  :global(.slick-slide img) {
    transition: transform 0.3s ease;
  }
  
 .downContainer {
     display: flex; 
     justify-content: center;
     align-items: center;
    margin-bottom: 2rem;
     margin-top: 6rem;
 }
 .innerCard {
    width: 30%; 
 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
 }
 .data {
    text-align: center;
    font-size: 20px;
    line-height: 34px;
    color: #60696c;

 }
 .doroo {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(107, 87, 255);
    font-weight: 600;
      text-decoration: underline;
      margin-bottom: 2rem;
 }
  @media only screen and (max-width: 747px) {
    .sliderContainer {
        
        /* margin-top: 0rem; */
        padding: 0rem;
        margin-bottom: 0px;
    }
     .heao {
        margin-top: 20px;
        font-size: 32px;
    }
    :global(.slick-dots) {
        display: none!important;
      }
      .downContainer { 
        margin-top: -9rem;
        flex-direction: column;
        gap: 29px;
      }
      .innerCard {
        width: 90%; 
     }
  }