.main {
    min-height: 100vh;
    width: 100 vw;
    display: flex; 
    padding: 0;
    box-sizing: border-box;
    margin: 0;
    background-color: #F4F7FE;
    overflow: hidden;
}

.navbarMobile {
    display: none;
    position: sticky;
    top: 0;
    z-index: 100;
    /* background-color: white; */
    width: 100%;
}

.left {
    width: 260px;
    top: 0;
    min-height: 70vh;
    position: fixed;
   
}

.right {
    width: calc(100% - 292px);
    min-height: 100vh;
    margin-left: 292px; 
    position: relative;
  
    margin-right: 32px;
}
.rightside {
    margin-top: 98px;
}
@media (max-width: 1068px) {
    
    .navbar {
        display: none;
    }
    .navbarMobile {
        display: block;
    }
    .headdesktop{
        display: none;
    }
 }