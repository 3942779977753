 /* General Styling */
  
.resume {
  min-width: 42rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: Roobert, sans-serif;
  letter-spacing: 0.02rem;
  /* background-color: #f4f4f4; */
  /* color: #333; */
  gap: 1.5rem;
}
.nameashu {
   font-weight: 600;
   font-size: 1.5rem;
}
.postionashu {
  font-size: 1.2rem;
  font-weight: 600;
  color: black;
}
.resumesampletext {
  object-fit: contain;
  font-size: .875rem;
  font-weight: 300;
  line-height: 130%;
  color:black; 
  display: flex;
  gap: .3rem;
}
.resumesampletextabout {
  object-fit: contain;
  font-size: .875rem;
  font-weight: 300;
  line-height: 130%;
  color:black; 
  display: flex;
  gap: .3rem;
}
.dot {
  font-weight: 800;
}
.resumesampletexttop {
  object-fit: contain;
  font-size: .875rem;
  font-weight: 400;
  line-height: 90%;
  color:black; 
  display: flex;
  gap: .5rem; 
  flex-wrap: wrap;
}
.resumesampleh2 {
  letter-spacing: 0;
  white-space: normal;
  object-fit: contain;
  align-self: center;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 600;
  line-height: 150%;
} 
.doro {
  font-size: .875rem;
}
.user_details {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  font-size: 0.63rem;
  margin-right: auto;
  font-weight: 600;
  margin-top: -0.3rem;
}
.resume a {
  color: #3498db;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.resume a:hover {
  color: #2980b9;
}
.user_details .name {
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.3px;
  margin: 0;
}

.user_details .degree_branch_college {
  font-size: 0.93rem;
  font-weight: 400;
  line-height: 1.4;
  color: #666;
  margin: 0;
}

.user_details .links_and_info {
  display: grid;
  grid-template-columns: 3fr 5fr 3fr;
  row-gap: 0.55rem;
  column-gap: 0.5rem;
}

.user_details .info span {
  font-weight: 400;
  color: #666;
}

.user_details .link a {
  font-weight: 400;
}

.user_image {
  max-width: 5rem;
  max-height: 6.5rem;
}

.user_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ism_logo {
  width: auto;
  height: 5rem;
}

.ism_logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.points{
  font-size: .875rem;
  padding: 0rem;
   
} 
.li {
  padding-bottom: .5rem;
    padding-left: .25rem;
    line-height: 120%;
    font-weight: 400;
    margin: 0;
    text-decoration: none;
    list-style: none;
    display: flex;
}
.li::before {
  content: "•"; /* Custom bullet character */
  color: black; /* Dark color for the bullet */
  /* font-size: 1.5em; Adjust size */
  font-weight: bold; /* Make it bolder */
  display: inline-block;
  width: 1em;
  /* margin-left: -1em; Align with the text */
}
.title{
  font-size: 15px;
  
  margin-bottom: 0rem;
 
  /* font-weight:bold; */
}
.left {
  height: 100%;
  grid-area: left;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
/* Header Styling */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .5rem;
  /* background-color: #2c3e50; */
  /* color: #ecf0f1; */
}
 
.user_details {
  display: flex;
  flex-direction: column;
}

.user_details .header_text {
  font-size: .8rem; 
  text-align: center;
}

.iitb_logo {
  width: 50px;  
  height: 50px;  
}

 
.section {
  font-size: 0.875rem; 
  font-weight: 400;
  line-height: 1.3;
  width: 100%;
  background-color: #fff;
  padding: .4rem;  
  border-radius: 8px; 
  border:2px solid red
}


.resumesamplsectionheading {
  text-transform: uppercase;
  font-size: .825rem;
  font-weight: 400;
  line-height: 130%;
}
 

 

/* List Styling */
.section ul {
  list-style-type: none;
  padding: 0;
  margin: 0rem 0;
}

 

 

 
.resumesampletextt {
  object-fit: contain;
  font-size: .875rem;
  font-weight: 400;
  line-height: 130%;
}


.resumesampleh3 {
  letter-spacing: 0;
  align-self: center;
  margin-bottom: 0;
  padding-bottom: .25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%;
}


/* Project Styling */
.firstline,
.secondline {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  font-size: .875rem;
}

.doreeoe {
  display: flex;
  justify-content: space-between;
  
}

.her{
  display: flex;
  
  align-items: start;
  justify-content: space-between;
  font-size: 10px;
}
.secondline {
  /* margin-top: .5rem; */
  font-size: 0.9rem; /* Adjusted font size */
  /* color: #7f8c8d; */
  color: #5d5d5d;

}
 
.edud {
  display: flex;
  justify-content: space-between; 
  
}