@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.bodys {
  padding: 15px !important;
  border-radius: 40px !important;
  max-height: 90vh;
  overflow-y: auto;
}
.bodys  *{
    font-family: "inter";
  }
.close {
  display: flex;
  justify-content: end;
  position: absolute;
  top: 1rem;
  right: 1rem; 
}
.skipStepContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    color: #8061DD;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease; margin-bottom: 10px;
  }
.closeButton {
  color:#909AB6;
  cursor: pointer;

  height: 3rem;
  width: 2rem;
  border-radius: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconcut {
  cursor: pointer;
}
.iconcut:hover {
  transform: rotate(90deg);

  transition: transform 0.5s;
}

.second {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 32px;
  padding-top: 54px;
  padding-left: 48px;
  padding-right: 48px;
  min-height: auto;
  max-width: 100%;
  overflow: hidden;
  background-color: #F6F3FF;
  border-radius: 16px;
  padding: 20px 15px;
}
.container {
     display: flex;
     flex-direction: column;
     gap: 48px;
     max-width: 100%;
     padding: 10px;
     overflow: hidden;
}
.topHeadingContainer {
    /* border: 2px solid blue; */
}

.headtitle {
    font-size: 32px; 
    color:#8061DD ;
    font-weight: 600;
    /* line-height: 20px; */
    text-align: center;
}
.headtitlparagraph{
    color: #757F9B;
    font-size: 16px;
    text-align: center;
}
.twoboxContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    /* flex-wrap: wrap; */
}
.SkipStepContainer {
    display: flex;
    justify-content: end;
    color: #909AB6;
    font-size: 16px;
    align-items: center;
    gap: 10px;
}
.SearchContainer { 
  margin-top: 32px;
  height: 48px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdownSecarchMainConatiner {
  background-color: #ffffff;
  border: 1.5px solid #CBD5EC;
  border-radius: 8px;
   width: 493px;
  height: 100%;
  display: flex;
  align-items: center;
   padding-top: 8px;
   padding-bottom: 8px;
   padding-left: 16px;
   padding-right: 16px;
}

.dropdownSecarchConatiner {
  
  border-radius: 8px;
  width:100%;
  position: relative;
  cursor: pointer;
}

.dropdownToggle {
  padding: 8px;
  display: flex;
  align-items: center;
  color: #535D79;
  font-size: 14px;
  gap: 7px;
   text-overflow: ellipsis; 
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  right: -16px;
  width: calc(100% + 32px);
  background-color: #ffffff;
  border: 1.5px solid #CBD5EC;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  font-size: 14px;
  max-height: 430px; /* Optional: limit the height */
  overflow-y: auto; /* Optional: make it scrollable if there are many options */
}

.dropdownItem {
  padding: 8px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}

.dropdownItem:last-child {
  border-bottom: none;
}

.chevronDownIcon {
position: absolute;
right: 0px;
}


.dooo {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selectedTemplate {
  border: 1px solid rgb(102, 108, 255);
  border-radius: 4px;
}
.templateName {
  font-size: 16px;
  color: #535d79;
  margin-bottom: 4px;
  font-weight: 500;
  margin-top: 10px;
}

.ResumeContainer {
display: flex;
flex-wrap: wrap;
gap: 24px;
margin-bottom: 2rem;
align-items: center;
justify-content: center;
}
.resumeImageContainer {
  height: 240px;
  width: 180px;
  overflow: hidden;
  box-shadow: 1.74px 1.74px 5.87px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: flex;
flex-direction: column;
align-items: center;
cursor: pointer;
position: relative;
background-color: #ffffff;
}

.resumeImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  cursor: pointer;
}
@media screen and (max-width: 768px) { 

    .twoboxContainer {
        flex-wrap: wrap;
    }
   }

   @media (min-width: 992px) {
    :global(.modal-lg), :global(.modal-xl) {
        --bs-modal-width: 928px; /* Custom width */
    }
}

.sliderContainer {
  position: relative;
  /* padding: 0 40px; */
  margin: 20px auto;
  max-width: 100%;
  /* width: calc(100% - 20px); */
}

.categoryTitle {
  font-size: 28px;
  color: #8061DD;
  margin-bottom: 30px;
  font-weight: 600;
  text-align: left;
  position: relative;
  padding-left: 20px;
}

.categoryTitle::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 24px;
  background: #8061DD;
  border-radius: 2px;
}

.sliderWrapper {
  overflow: hidden;
  position: relative;
  margin: 0 -10px;
  user-select: none;
  touch-action: pan-y pinch-zoom;
}

.sliderTrack {
  display: flex;
  gap: 0;
  will-change: transform;
  padding: 10px 0;
  cursor: grab;
}

.sliderTrack:active {
  cursor: grabbing;
}

.sliderItem {
  padding: 0 10px;
  flex-shrink: 0;
  width: calc(33.333% - 0px);
  max-width: none; /* Remove max-width constraint */
  transition: all 0.3s ease;
}

.template {
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.06);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid rgba(128, 97, 221, 0.1);
  height: 100%;
}

.template:hover {
  box-shadow: 0 15px 40px rgba(128, 97, 221, 0.15);
  border-color: rgba(128, 97, 221, 0.3);
}

.preview {
  position: relative;
  width: 100%;
  padding-top: 141.42%; /* A4 aspect ratio */
  overflow: hidden;
  background: linear-gradient(145deg, #f8f9ff 0%, #ffffff 100%);
}

.preview img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  transition: transform 0.3s ease-in-out;
}

/* Zoom effect on hover */
 

.name {
  padding: 12px;
  font-size: 14px;
  color: #2D3142;
  text-align: center;
  font-weight: 500;
  background: white;
  border-top: 1px solid rgba(128, 97, 221, 0.08);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  padding-left: 8px;
  padding-right: 8px;
}

.sliderButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #ffffff;
  border: none;
  color: #8061DD;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  box-shadow: 0 4px 20px rgba(128, 97, 221, 0.15);
  transition: all 0.3s ease;
}

.sliderButton:hover {
  background: #8061DD;
  color: white;
  transform: translateY(-50%) scale(1.1);
}

.sliderButton:active {
  transform: translateY(-50%) scale(0.95);
}

.prevButton {
  left: 0;
}

.nextButton {
  right: 0;
}

/* Loading animation */
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.preview::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: shimmer 2s infinite linear;
  background: linear-gradient(
    to right,
    #f6f7f8 4%,
    #edeef1 25%,
    #f6f7f8 36%
  );
  background-size: 1000px 100%;
}

/* Responsive styles */
@media screen and (max-width: 1200px) {
  .sliderItem {
    min-width: 200px;
    max-width: 200px;
  }
  
  .preview {
    height: 283px;
  }
}

@media screen and (max-width: 768px) {
  .sliderItem {
    min-width: 180px;
    max-width: 180px;
  }
  
  .preview {
    height: 255px;
  }
}

@media screen and (max-width: 480px) {
  .sliderItem {
    min-width: 160px;
    max-width: 160px;
  }
  
  .preview {
    height: 226px;
  }
  
  .sliderButton {
    width: 40px;
    height: 40px;
  }
}

/* Tablet Breakpoint */
@media screen and (max-width: 1024px) {
  .sliderContainer {
    padding: 0 35px;
  }
  
  .sliderItem {
    width: calc(33.333% - 0px); /* Keep 3 items */
  }

  .categoryTitle {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

/* Mobile Large Breakpoint */
@media screen and (max-width: 768px) {
  .sliderContainer {
    padding: 0 30px;
    margin: 15px auto;
  }

  .sliderButton {
    width: 36px;
    height: 36px;
    border-radius: 18px;
  }

  .name {
    font-size: 14px;
    padding: 12px;
  }
}

/* Mobile Small Breakpoint */
@media screen and (max-width: 480px) {
  .sliderItem {
    width: 100%;
    max-width: 280px;
    margin: 0 auto;
  }

  .sliderContainer {
    padding: 0 25px;
  }

  .sliderButton {
    width: 32px;
    height: 32px;
    border-radius: 16px;
  }

  .categoryTitle {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .preview {
    border-radius: 8px;
  }
}

/* Handle very small screens */
@media screen and (max-width: 320px) {
  .sliderItem {
    max-width: 240px;
  }

  .sliderContainer {
    padding: 0 20px;
  }
}

/* Additional responsive improvements */
.bodys {
  padding: 15px !important;
}

.second {
  padding: 20px 15px;
}

.container {
  padding: 10px;
}

/* Improve touch targets on mobile */
@media (hover: none) and (pointer: coarse) {
  .sliderButton {
    padding: 12px;
  }

  .template {
    -webkit-tap-highlight-color: transparent;
  }
}

/* Prevent text selection during swipe */
.sliderContainer {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

/* Smooth scrolling for iOS */
.sliderWrapper {
  -webkit-overflow-scrolling: touch;
}

/* Adjust padding for mobile */
@media screen and (max-width: 480px) {
  .name {
    font-size: 13px;
    padding: 10px 8px;
  }
}

.progressBarContainer {
  margin-top: 20px;
  width: 100%;
  height: 4px;
  background: rgba(128, 97, 221, 0.1);
  border-radius: 2px;
  position: relative;
  overflow: hidden;
}

.progressBar {
  position: absolute;
  height: 100%;
  background: #8061DD;
  border-radius: 2px;
  transition: width 0.3s ease, transform 0.3s ease;
}

.progressDots {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(128, 97, 221, 0.2);
  transition: all 0.3s ease;
}

.dotActive {
  background: #8061DD;
  transform: scale(1.2);
}

@media screen and (max-width: 768px) {
  .progressBarContainer {
    margin-top: 16px;
  }
  
  .dot {
    width: 6px;
    height: 6px;
  }
}

.containerMain {
    position: relative;
}
.SkipLine {
    position: absolute;
     z-index: 1000;
    right: 0;
    color: #8061DD;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer !important;
}