.uploadContainer {
    width: 50%;
    margin: 50px auto;
    background-color: #f4f4f4;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 40px;
    text-align: center;
    font-family: 'Arial', sans-serif;
  }
  
  .uploadTitle {
    font-size: 26px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .uploadForm {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .uploadButton {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 12px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s;
  }
  
  .uploadButton:disabled {
    background-color: #ccc;
  }
  
  .uploadButton:hover {
    background-color: #0056b3;
  }
  
  .resumeScore {
    margin-top: 30px;
    text-align: left;
  }
  
  .resumeScore h2 {
    font-size: 24px;
    color: #4caf50;
    margin-bottom: 10px;
  }
  
  .resumeScore h3 {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .scoreBreakdown {
    list-style: none;
    padding: 0;
  }
  
  .scoreItem {
    background-color: #fff;
    padding: 20px;
    margin: 10px 0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .scoreItem strong {
    color: #007bff;
  }
  
  .scoreDetailList {
    margin-left: 20px;
    margin-top: 5px;
  }
  
  .scoreDetailItem {
    font-size: 14px;
    color: #555;
  }
  