.main {

    padding: 20px 0px 80px 0px;
    display: flex;
    justify-content: center; 
    align-items: center;
    /* background: var(--Gradient-New-Home, linear-gradient(86deg, #F0F8FF 1.71%, #FFFCEF 99.56%)); */
} 

.inner{
    width: 80%
}
.headd {
    font-weight: 600;
    font-size: 34px;
    line-height: 140%;
    color:#383838 ;
}
.dldo {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover !important;   
    object-position:left;
     
}

.dlll {
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 230px;
    overflow: hidden; /* Optional: hides any overflow */ 
    padding: 0px;
}

.dlllg{
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 230px;
    overflow: hidden; /* Optional: hides any overflow */ 
    padding: 10px;
}
.llsl {
    display: flex;
    gap: 10px; 
    margin-bottom: 12px; 
    
     
}
.active {
    color: white;
    background-color:#6267ff; 
}
.eii {
    display: flex; 
     
    padding: 5px;
     border-radius: 3px;
     font-size: 14px; 
     cursor: pointer;
} 

.eii:hover {
    color: white;
    background-color:#6267ff; 
}

@media (max-width: 800px) {   

    .dlll { 
        height: auto;
        width: auto;
    }
    .inner{
        width: 90%
    }
    .headd {
        font-size: 25px;
    }
    .main {
        padding: 30px 0; 
    }

    .dle {
        font-size: 12px;
    }
}
