.container {
    position: relative;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    height: 800px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(to right, rgba(255, 107, 107, 0.05), rgba(79, 110, 247, 0.05));
    border-radius: 24px;
    padding: 40px;
  }
  
  .resumeContainer {
    position: relative;
    width: 500px;
    height: 700px;
    margin: 0 auto;
    overflow: hidden;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .leftStats,
  .rightStats {
    position: relative;
    width: 200px;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    z-index: 2;
  }
  
  .leftStats {
    color: #FF6B6B;
    margin-right: 40px;
  }
  
  .rightStats {
    color: #4F6EF7;
    margin-left: 40px;
  }
  
  .score h2,
  .interviews h2,
  .skillMatch h2 {
    font-size: 3rem;
    font-weight: 600;
    margin: 0;
    line-height: 1;
  }
  
  .stats p {
    font-size: 1.2rem;
    color: #666;
    margin: 0.5rem 0 0 0;
    font-weight: 500;
  }
  
  .beforeImage,
  .afterImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .beforeImage img,
  .afterImage img {
    width: 100%;
    /* height: 100%; */
    object-fit: contain;
  }
  
  .afterImage {
    overflow: hidden;
    border-right: 2px solid #4F6EF7;
  }
  
  .imageWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .imageWrapper img {
    max-width: 500px;
    height: auto;
    object-fit: contain;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    background: white;
    border-radius: 12px;
  }
  
  .sliderHandle {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    height: 100vh;
    background: #4F6EF7;
    cursor: ew-resize;
    transform: translateX(-50%);
    z-index: 3;
  }
  
  .sliderButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    background: #4F6EF7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 12px rgba(79, 110, 247, 0.3);
    transition: all 0.2s ease;
    border: 2px solid #ffffff;
  }
  
  .sliderButton:hover {
    transform: translate(-50%, -50%) scale(1.1);
    box-shadow: 0 4px 16px rgba(79, 110, 247, 0.4);
  }
  
  .sliderButton svg {
    width: 20px;
    height: 20px;
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1));
  }
  
  .sliderButton path {
    stroke: #ffffff;
    stroke-width: 2.5;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  
  @media (max-width: 1200px) {
    .container {
      padding: 30px;
    }
    
    .resumeContainer {
      width: 350px;
      height: 460px;
    }
    
    .leftStats,
    .rightStats {
      width: 150px;
    }
    
    .score h2,
    .interviews h2,
    .skillMatch h2 {
      font-size: 2.5rem;
    }
    
    .stats p {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 20px;
      flex-direction: column;
    }

    .resumeContainer {
      width: 300px;
      height: 400px;
    }

    .sliderHandle {
      height: 100%;
    }

    .leftStats,
    .rightStats {
      width: 120px;
      gap: 2rem;
    }
    
    .score h2,
    .interviews h2,
    .skillMatch h2 {
      font-size: 2rem;
    }

    .sliderButton {
      width: 36px;
      height: 36px;
    }

    .sliderButton svg {
      width: 18px;
      height: 18px;
    }
  }
  
  @media (max-width: 480px) {
    .container {
      padding: 15px;
    }

    .resumeContainer {
      width: 90%;
      height: 350px;
    }
  }