/* ResumeBuilder.module.css */

.main {
    
    margin: 0 auto;
    padding: 20px;
    
    border-radius: 10px;
    display: flex;
  }
  
  /* h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 2em;
  } */
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .formGroup label {
    font-weight: bold;
    color: #555;
  }
  
  .formGroup input[type="text"],
  .formGroup select,
  .formGroup input[type="file"] {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1em;
  }
  
  .formGroup input[type="file"] {
    padding: 5px;
  }
  
  .formGroup input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .formGroup div {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .formGroup label input[type="checkbox"] + span {
    vertical-align: middle;
  }
  
  .submitButton {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    font-size: 1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    align-self: flex-end;
  }
  
  .submitButton:hover {
    background-color: #0056b3;
  }
  .fosr {
    background-color: #eee;
    border:1px solid gray;
    border-radius: 10px;
    display: flex ;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 3px;
  }
  .halfleft {
    width: 50%;
    border: 2px solid #eee;
    padding: 10px;
    margin-top: 1rem;
  } 
  .halfright {
    margin-top: 1rem;
    width: 50%;
    border: 2px solid #eee;
    padding: 10px;
  }
  .buttos {
    padding: 10px;
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }
  @media (max-width: 600px) {
    .main {
      padding: 15px;
    }
  
    .submitButton {
      align-self: center;
      width: 100%;
      padding: 15px;
    }
  }


  .blogHistoryContainer {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0) */

   }
   .blogHistroyHeading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    color: #0056b3;

   } 
   .doso {
    font-size: 24px;
    font-weight: bolder;
   }
   /* YourStyles.module.css */

.blogTable {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.blogTable th, .blogTable td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
  font-size: 13px;
}

.blogTable th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.blogTable td {
  vertical-align: top;
}

.blogTable img {
  width: 100px;
  height: auto;
}

.blogTable .actions {
  display: flex;
  gap: 8px;
}

.blogTable button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.blogTable button:hover {
  background-color: #0056b3;
}

.blogTable button:active {
  transform: scale(0.98);
}

.blogTable button.modify {
  background-color: #28a745;
}

.blogTable button.modify:hover {
  background-color: #218838;
}

.blogTable button.delete {
  background-color: #dc3545;
}

.blogTable button.delete:hover {
  background-color: #c82333;
}

/*   
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 13px;
    text-align: left;
    background-color: #f9f9f9;
    overflow: scroll;
  }
  
  th, td {
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background-color: #4CAF50;  
    color: white;
    font-weight: bold;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2; 
  }
  
  tr:hover {
    background-color: #f1f1f1;  
  }
  
  td {
    color: #333; 
  }
  
  table thead tr {
    border-bottom: 2px solid #4CAF50;
  }
  
  table tbody tr:last-child td {
    border-bottom: 2px solid #4CAF50;  
  }
  
  table tbody tr td:first-child {
    font-weight: bold;
  }
  
  caption {
    caption-side: bottom;
    padding-top: 10px;
    font-style: italic;
    color: #777;
  }
   */