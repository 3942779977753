
/*======================
    404 page
=======================*/


.page_404{ padding:10px 0; background:#fff; font-family: 'Arvo', serif; 
   
}

.page_404  img{ width:100%;}

.four_zero_four_bg{
 
 background-image: url(https://cdn.dribbble.com/users/406903/screenshots/6723682/drawkit-grape-pack-illustration-3-dribbble-export-v0.1.gif);
    height: 400px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
     
 }
 
 
 .four_zero_four_bg h1{
 font-size:80px;
 }
 
  .four_zero_four_bg h3{
			 font-size:80px;
			 }
			 
			 .link_404{			 
	color: #fff!important;
    padding: 10px 20px;
    background : linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%);
    margin: 20px 0; 
    font-weight: 600;
    border-radius: 10px;
    text-decoration: none;
    display: inline-block;}
	.contant_box_404{ margin-top:-50px; 
    
    }