@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Protest+Revolution&family=Protest+Strike&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Protest+Revolution&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.topconatiner {
  /* background-color: #eff2f9; */
  background: linear-gradient(86deg, #ecf4fb 1.71%, #fffcef 99.56%);
  position: relative;
  padding: 2.5rem 1.5rem;
  min-height: 90vh;
  background-image: url(https://res.cloudinary.com/dvepwlz9j/image/upload/v1725635644/all/gv7y5h82ytwvzzrf8i28.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-blend-mode: normal; /* This ensures the color and image coexist */
}

.topcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh; /* Adjust this to fit the desired size */
  background-color: #f0f0f0; /* Optional background */
}

.headingw {
  text-align: center;
  font-weight: 600;
  letter-spacing: -2px;
  font-size: 46px;
  line-height: 1;
  margin-bottom: 2rem;
  font-family: Roobert, sans-serif;
  margin-bottom: 2rem;
}

.belowheadingcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.belowheading {
  text-align: center;
  color: #5b5d5e;
  max-width: 42rem;
  text-wrap: balance;
  margin-bottom: 2rem;
  font-size: 18px;
  line-height: 130%;
  font-weight: 500;
}

.background2 {
  background-image: url(../ResumeBuilder/assets/images/back.png);
  min-height: 90vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.templateImage {
  height: 70vh;
  width: 70% !important;
  border-radius: 8px;
  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.1);
}

.videoConatiner {
  width: 45%; /* Default for laptops and larger screens */
  margin: 0 auto; /* Center the video */
  padding: 1rem;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for depth */
  background-color: #f8f9fa; /* Light background for a professional look */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
}
.videoConatiner:hover {
  transform: scale(1.02); /* Slight scale-up on hover for a dynamic feel */
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2); /* More prominent shadow on hover */
}

.videoConatiner video {
  border-radius: 15px; /* Ensures the video itself gets rounded */
}
.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  gap: 1.5rem; /* Reduced gap for a more compact look */
}

.primaryButton {
  padding: 0.8rem 2rem; /* Slightly reduced padding for a cleaner look */
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px; /* Reduced border-radius for a more professional appearance */
  font-family: "Roobert", sans-serif;
  color: #fff;
  background: linear-gradient(270deg, #a066ff 15%, #666cff 90%);
  box-shadow: 0px 4px 8px rgba(102, 108, 255, 0.2); /* Added subtle shadow */
  transition: background 0.3s ease, transform 0.2s ease; /* Smooth transition */
}

.primaryButton:hover {
  background: linear-gradient(270deg, #8c55ff 15%, #505aff 90%);
  transform: scale(1.05); /* Hover effect for better interaction */
}

.secondaryButton {
  padding: 0.8rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;
  border: 1px solid #666cff;
  color: #666cff;
  font-family: "Roobert", sans-serif;
  background-color: transparent;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.secondaryButton:hover {
  background-color: rgba(102, 108, 255, 0.1);
  border-color: #505aff;
}
/* for mobile view  */
@media only screen and (max-width: 600px) {
  .buttonContainer {
    flex-direction: column;
    gap: 1rem;
  }
  .paragraphh {
    font-size: 10rem;
  }
  .videoConatiner {
    width: 100%; /* For screens smaller than 768px (typically mobile devices) */
    padding: 0.5rem; /* Reduce padding on smaller screens */
    border-radius: 8px; /* Adjust border-radius for mobile */
  }
  .belowheading {
    font-size: 19px;
  }
  .topconatiner {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-size: cover;
  }
  .headingw {
    font-size: 32px;
  }
  .templateBox {
    height: auto !important;
    order: -1;
  }
  .middleTOP {
    height: auto !important;
    order: -1;
  }
  .leftbox {
    height: auto !important;
  }

  .templateImage {
    height: 42vh;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .heading {
    font-size: 1.5rem !important;
  }
  .paragraph {
    font-size: 0.8rem !important;
  }
  .arrow {
    display: none !important;
  }
  .primaryButton {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 8px;

    color: #fff;
    border: none;
    cursor: pointer;
  }
  .secondaryButton {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 8px;

    cursor: pointer;
  }
}

/* for laptop view */
@media only screen and (min-width: 600px) {
}
.templateBox {
  display: flex;
  height: 80vh;
  align-items: center;
  justify-content: center;
}
.leftbox {
  height: 70vh;
  display: flex;
  /* border: 2px solid red; */
  align-items: center;
  justify-content: center;
}
.leftboxinner {
  display: flex;
  /* border: 2px solid red; */
  align-items: center;
  justify-content: center;
  justify-content: center;
  flex-direction: column;
}

.heading {
  font-weight: 700;
  /* font-family: Poppins,system-ui,-apple-system,"Segoe UI","Roboto","Helvetica Neue","Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; */
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
  /* font-family: 'Mukta', sans-serif; */
}
.paragraphh {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.8px;
  line-height: 20px;
  margin-bottom: 8px;
  text-transform: uppercase;
}
@media only screen and (max-width: 600px) {
  .paragraphh {
    font-size: 10px;
  }
}
.paragraph {
  color: #4f4851;
}
.section2image {
  height: 20vw;
}

.previewImage {
  height: 96%;
  width: 90%;
  border-radius: 8px;
}

.AIProwered {
  text-transform: capitalize;
  background: linear-gradient(
    90.02deg,
    #6b57ff 39.45%,
    #d735ff 52.31%,
    #ff5c00 73.86%,
    #ffc01d 99.98%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
  text-shadow: 0px 0.5px 1px rgba(102, 55, 201, 0.25);
  display: inline-block;
  padding: 0 10px;
}

.background3 {
  background-image: url(https://softr-assets-eu-prod.s3.eu-central-1.amazonaws.com/applications/8f7af9fb-a550-425d-b327-48195c193a5f/assets/6b8b1aa7-54df-4646-a54d-852caa847833.svg),
    url(https://softr-assets-eu-prod.s3.eu-central-1.amazonaws.com/applications/8f7af9fb-a550-425d-b327-48195c193a5f/assets/8c077d2c-6aae-42ee-96a6-25e1292a60c2.svg);
  background-repeat: no-repeat;
  background-position: 105% center, -29% 180%;
  background-size: 30%, 35%;
  /* height: 20vw;  */
  border-radius: 10px;
  background-color: #2a182e !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  margin-top: 3rem;
  flex-direction: column;
  padding: 3rem;
}

.background3Back {
  background-image: url(https://assets.softr-files.com/applications/8f7af9fb-a550-425d-b327-48195c193a5f/assets/86dd6b2d-6773-499e-99a1-d6c28e9e5338.svg);
  margin-bottom: 3rem;
  margin-top: 3rem;
}
.backgroundBacktest {
  background-image: url(https://static.remove.bg/pattern-rbg.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 6rem;
  margin-top: 6rem;
  /* padding: 4rem; */
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.background4Back {
  background-image: url(https://assets.softr-files.com/applications/8f7af9fb-a550-425d-b327-48195c193a5f/assets/c17e1305-a4a8-45b4-8d9c-216bdde36fba.svg);
  margin-bottom: 3rem;
  margin-top: 3rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  margin-top: 3rem;
  flex-direction: column;
  padding: 3rem;
}

.background4 {
  /* background-image: url(https://softr-assets-eu-prod.s3.eu-central-1.amazonaws.com/applications/8f7af9fb-a550-425d-b327-48195c193a5f/assets/6b8b1aa7-54df-4646-a54d-852caa847833.svg), url(https://softr-assets-eu-prod.s3.eu-central-1.amazonaws.com/applications/8f7af9fb-a550-425d-b327-48195c193a5f/assets/8c077d2c-6aae-42ee-96a6-25e1292a60c2.svg); */
  background-repeat: no-repeat;
  background-position: 105% center, -29% 180%;
  background-size: 30%, 35%;
  /* height: 20vw;  */
  border-radius: 10px;
  /* background-color: rgb(255, 179, 11)!important; */
  background: #025dba;
  background-image: linear-gradient(-319deg, #80c7ff, #1d8fe1 28%, #625eb1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  margin-top: 3rem;
  flex-direction: column;
  padding: 3rem;
  color: white;
}

.background5 {
  /* background-image: url(https://softr-assets-eu-prod.s3.eu-central-1.amazonaws.com/applications/8f7af9fb-a550-425d-b327-48195c193a5f/assets/6b8b1aa7-54df-4646-a54d-852caa847833.svg), url(https://softr-assets-eu-prod.s3.eu-central-1.amazonaws.com/applications/8f7af9fb-a550-425d-b327-48195c193a5f/assets/8c077d2c-6aae-42ee-96a6-25e1292a60c2.svg); */
  background-repeat: no-repeat;
  background-position: 105% center, -29% 180%;
  background-size: 30%, 35%;
  /* height: 20vw;  */
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  /* padding: 3rem; */
}

.testonomial {
  padding: 2rem;
  background: #f7f7f7;
  border-radius: 3px;
  /* margin: 1rem; */
  margin-bottom: 1rem;
  border: 2px solid rgb(237, 240, 242);
  transition: background-color 0.3s ease, color 0.3s ease; /* Added transition for smooth effect */
  border-radius: 8px;
}

.testonomial:hover {
  /* background-color: lightgray; Change background color on hover */
  transform: scale(1.05); /* Scale the element on hover */
}

.box {
  width: 143px;
  height: 74px;
  margin: 0 24px 0 0;
  max-width: 100px;
  min-width: 143px;
  border-radius: 23px;
  display: flex;
  background: white;
  box-shadow: 0px 5px 20px rgba(7, 78, 176, 0.12);
  align-items: center;

  justify-content: center;
}

@media (min-width: 510px) {
  .box {
    width: 143px;
    height: 74px;
    margin: 0 24px 0 0;
    max-width: 100px;
    min-width: 143px;
    border-radius: 23px;
  }
}

.animatedBox {
  /* animation: slideLeft 2s ease infinite alternate; Change duration and timing function as needed */
  animation: moveLeftToRight 5s linear infinite;
}

@keyframes moveLeftToRight {
  0% {
    /* transform: translateX(0%); */
    /* background-color: red; */
  }
  100% {
    /* transform: translateX(100%); */
  }
}

.animatedBoxMain {
  /* animation: moveLeftToRight 10s linear infinite; */
}

.star {
  position: absolute;
  left: 45%;
}

.starsImage {
  animation: rotate 80s infinite linear;
}

@keyframes rotate {
  0% {
    transform: translate(50%, 50%) scale(0.5);
    opacity: 1;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.1);
    opacity: 0;
  }
  100% {
    transform: translate(50%, 50%) scale(0.3);
    opacity: 1;
  }
}

.sundar {
  position: absolute;
  bottom: 6rem;
  left: 0;
  animation: moveRightLeft 15s infinite ease-in-out; /* Smooth back and forth */
}

@keyframes moveRightLeft {
  0% {
    transform: translateX(0); /* Starting position */
  }
  50% {
    transform: translateX(30%); /* Move to the right */
  }
  100% {
    transform: translateX(0); /* Move back to the left */
  }
}
.sundar img {
  width: 70%;
}
@media (max-width: 810px) {
  .sundar {
     display: none;
  }
  
}

.greendotcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 550;
  margin-bottom: 1rem;
  text-align: center;
}
.greendot {
  background-color: #34c759;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  animation: blink 1s infinite; /* Add animation here */
}
@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
