._ngcontents{
    color: #727272;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    display: block;
    margin-top: 10px;
    margin-bottom: 40px; 

} 

.headd {
    font-weight: 600;
    font-size: 34px;
    line-height: 140%;
    color:#383838 ;
}
@media only screen and (max-width: 747px) { 

    .ngcontent{
        font-size: 25px;
    }
    ._ngcontents {
        font-size: 15px;
    }
    .cate {
        width: 100%;
        justify-content: start; 
        font-size: 15px;
    } 
    .headd {
        font-size: 25px;
    }
} 

