.main {
    margin-top: 32px;
    width: 100%;
    min-height: 140px;
    position: relative;
    margin-left: 32px;
}
.headingtitle {
    font-size: 18px;
    font-family: "inter";
    font-weight: 600;
}
.scroll {
    position: absolute;
    top: 50%;
    right: 2rem;
}
.scroll img {
    height: 3.5rem;
}
.Container {
    height: 137px;
    width: 99%;
    display: flex;
    gap: 20px;
    border-radius: 8px;
    flex-wrap: nowrap;  
    overflow-x: auto;
    margin-bottom: 1rem;
    margin-top: 1rem;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  background-color: #ffffff;

}
.scrollableContainer {
 
    display: flex;
    gap: 10px;
    flex: 0 0 auto; /* Ensures the items don’t shrink or grow */
    box-sizing: border-box;
    /* Optional overflow properties if the content inside each container overflows */
    overflow-y: hidden;
    overflow-x: hidden;
}
.card {
    width: 28rem;
    height: 100%;
    position: relative;
}
.verticalBar {
    position: absolute;
    top: 13%;
    right: 0;
    width: 2px;
    border-radius: 10px;
    height: 70%;
    background-color: #CCCCCC;

}
.innerContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contentContainer{
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contentContainer img {
    height: 90px;
    border-radius: 16px;
}
.contenttext {
    width: 60%;
    height: 100%;
    font-size: 14px;
    /* padding-top: 1rem; */
    margin-left: 10px;
    display: flex;
    flex-direction: column;
}
.blogheading {
    font-size: 14px;
    font-family: "inter";
    color: #535D79;
    margin-top: 1rem;
}
.blogheading:hover {
     color: black;
     cursor: pointer;
} 
.date {
    color: #99A3C2;
    font-size: 12px;
}


@media (max-width: 1068px) {
    .main {
       margin-left: 20px;
    }
    .Container {
     height: auto;
     width: 90%;
     flex-wrap: wrap;
     overflow: unset;
     justify-content: start;
    }
    .scrollableContainer {
        width: 100%;
        display: flex;
        margin-top: 10px;
        flex-direction: column; /* Changes layout to column */
        gap: 10px;
        flex: 0 0 auto; /* Ensures items don’t shrink or grow */
        box-sizing: border-box;
        scrollbar-width: 0px;
        overflow-y:hidden; /* Enables vertical scrolling */
        /* overflow-x: hidden; Hides horizontal scroll/ */
        max-height: 100%; /* Optional: Set a max-height to control scroll area */
        scroll-behavior:unset; /* Smooth scrolling */
        -webkit-overflow-scrolling: unset; /* Smooth scrolling on iOS */
        scrollbar-width: unset; /* Hides scrollbar in Firefox */
    }
    .verticalBar {
        position: absolute;
        top: 0%;
        right: 0;
        width: 100%;
        border-radius: 10px;
        height: 2px;
        background-color: #CCCCCC;
    display: none;
    }
    .scroll {
        display: none;
    }
    .card { 
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1px solid #D3D9E8;
 
    
    }

    .card:last-child { 
        
        border-bottom:none;
    }
}