.main {
     padding: 12px;
}
.mainContainer {
    background-color: #8062DD;
    border-radius: 16px;
    padding-top: 24px;
    padding-bottom: 24px;
    gap: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}
.headingContainer {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}
.name {
    color: white;
    font-size: 22px;
    text-align: center;
    z-index: 12;
}
.para {
    color: #D9D0F3; 
    font-size: 14px;
    text-align: center;
    z-index: 12;
}
.buttonai {
    color: white;
    font-size: 13.54px;
    background-color: #6F17FF;
    border-radius: 28.21px;
    width: 204px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #632EE4;
    position: relative;
    z-index: 12;
    overflow: hidden;
}
.circle {
    position: absolute;
    top: 50%;
    left: 10%;
    background-color: rgba(208, 162, 243, 0.4); /* 70% opacity */
    height: 91px;
    width: 107px;
    z-index: -10;
    border-radius: 50%;
    backdrop-filter: blur(24px);
}

.FourBoxContianer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
}
.firstTwoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap:16px;
    width: 100%;

}
.starimage {
    width: 20px;
}

.secondTwoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap:16px; 
    width: 100%;

}

.eachcont {
    width: 165px;
    height: 192px; /* Adjust height if necessary */
    border-radius: 8px;
   z-index: 1; 
   transition: all 0.3s ease; /* Smooth transition */
   position: relative;
}

.eachcont:nth-child(1) {
    background-color: #FFC4DA; /* Example color for the second div */
}

 .box2 .eachcont {
    background-color: #F9E79B; /* Example color for the third div */
}

.box3 .eachcont {
    background-color: #85A5FF; /* Example color for the fourth div */
}

.box4 .eachcont  {
    background-color: #A1DBB7; /* Example color for the fifth div */
}
.rightside img {
    width: 15rem;
    margin-top: 1rem;
}

.eachimageContainer {
    display: flex;
    position: absolute;
    width: 100%;
    justify-content: center;
}
.eachimageContainer img {
    
     height: 133px;
     width: 133px;
}
.beloweachImageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

}

 
.numberContainer {
    background-color: #ffffff;
    border-radius: 8px;
    display: flex;
    width: calc(100% - 15px);
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    bottom: 7px;
    padding: 10px;
}

.numberContainerright {
    width: 65%;
    height: 100%;
}
.leftround {
    height: 30.89px;
    width: 30.89px;
    border-radius: 50%;
    background-color: #5423E7;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11.12px;
}
.contenthead {
    display: flex;
    font-size: 11.23px;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #5423E7;
    font-weight: 500;
    font-family: "inter";
    letter-spacing: -2%;
}
.ContentContainerUp {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.numberContainerleft {
    width: 28%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.numberContainerright {
    width: 65%;
    height: 100%;
}

.title {
    display: flex;
     
    align-items: center;
    width: 100%;
    height: 100%;
    color: #3F4860;
    font-size: 11.23px;
    font-family: "inter";
}

.beloweachImageContainerspecial {
    
     display: flex;
     align-items: center;
     justify-content: center;
    width: 100%;
  
}
.eachimageContainerspecial {
    display: flex;
    position: absolute;
    width: 100%;
    justify-content: center;
}
.eachimageContainerspecial img {
 margin-top: 1rem;
 height: 133px;
 width: 133px;
}
.ContentContainer {
    
    background-color: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 15px);
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    bottom: 7px;
    padding: 10px;
}

.ContentContainerDown {
    width: 100%;
}
.buttonCheck {
    border:1px solid #5423E7 ;
    border-radius: 8px;
    width: 100%;
    padding: 8px 12px 8px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3F4860;
    font-weight: 500;
    font-size: 11.26px;
}

@media (max-width: 425px) {
   
.eachcont {
    width: 140px;
    height: 180px; /* Adjust height if necessary */ 
}
}