.main {
    min-height: 100vh;
    width: 99vw;
    display: flex; 
    padding: 0;
    box-sizing: border-box;
    margin: 0;
    background-color: #F4F7FE;
    overflow: hidden;
}

.left {
    width: 260px;
    top: 0;
    min-height: 70vh;
    position: fixed;
   
}

.right {
    width: calc(100% - 260px);
    min-height: 100vh;
    margin-left: 260px; 
    position: relative;
}

.navbarMobile {
    display: none;
    position: sticky;
    top: 0;
    z-index: 100;
    /* background-color: white; */
    width: 100%;
}
.headcontmobile {
    display: none;
}
@media (max-width: 1068px) {
    .headcontmobile {
        display: block;
    }
    .left {
         display: none;
    }
    .right {
        width: 100%;
        min-height: 100vh;
        margin-left:0px; 
        position: relative;
    }
    .navbar {
        display: none;
    }
    .navbarMobile {
        display: block;
    }
    .headdesktop{
        display: none;
    }
 }