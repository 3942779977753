/* Assuming styles is an object where these styles will be applied */

.main {
    display: flex;
    flex-direction: column;
  }
  
  /* .left, .right {
    flex: 1;  
    padding: 20px;  
    overflow-y: auto;  
    height: 100%; 
  } */
  
  .left {
    padding: 2rem;
    border: 1px solid #ddd;
  }
   
  
  .formGroup {
    margin-bottom: 20px;
  }
  
  .label {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    margin-bottom: 8px;
  }
  
  .input,
  .textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 5px;
  }
  
  .input:focus,
  .textarea:focus {
    border-color: #0056b3;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 86, 179, 0.2);
  }
  
  .textarea {
    min-height: 100px;
    resize: vertical;
  }
  .postButtonContainer {
    margin-top: 20px;

  }
  .postButton {
      background-color: #0056b3;
      color: #fff;
      padding: 8px 15px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 16px;

  } 
  .blogNavbar {
    border: 2px solid #eeee;
    height: 50px;
   position:fixed; 
   background-color: white;
    display: flex; 
    width: 100%;
    align-items: center;
    padding: 1rem;
     gap: 20px;
    
  }
  .dr {
    color: black;
   
  }
  .acticve {
    color: #0056b3;
    text-decoration: underline;
  }
  .blogcontainer {
    margin-top: 20px;
    background-color: white;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .innercontainer {
    width: 70% ;
    padding: 70px 100px 0;
    border: 2px solid #eee;
  }
   .hhdo {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
   }
   .hhddo {
    margin-top: 20px;
    display: flex;
    justify-content: center;
   }
   .imagggo {
     
    border-radius: 4px;
    margin-top: 20px;

   }
   .blogheadi{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    color: #0056b3;
   }
  
   .blogHistoryContainer {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0) */

   }
   .blogHistroyHeading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    color: #0056b3;

   } 
   .doso {
    font-size: 24px;
    font-weight: bolder;
    text-align: center;
    
   }
   /* YourStyles.module.css */

.blogTable {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.blogTable th, .blogTable td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
  font-size: 13px;
}

.blogTable th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.blogTable td {
  vertical-align: top;
}

.blogTable img {
  width: 100px;
  height: auto;
}

.blogTable .actions {
  display: flex;
  gap: 8px;
}

.blogTable button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.blogTable button:hover {
  background-color: #0056b3;
}

.blogTable button:active {
  transform: scale(0.98);
}

.blogTable button.modify {
  background-color: #28a745;
}

.blogTable button.modify:hover {
  background-color: #218838;
}

.blogTable button.delete {
  background-color: #dc3545;
}

.blogTable button.delete:hover {
  background-color: #c82333;
}

.do {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.dele {
  background-color: red!important;
  
}
.dhocontin {
  display: flex;
  justify-content: center;
  align-items: center;
}

.shortDesriptionConatiner {
   /* add border */
   border: 1px solid #ddd;
   padding: 10px;
   border-radius: 5px;
   background-color: #f9f9f9;
   margin-bottom: 20px;
   margin-top: 1rem;
   width: 40%;

}
.tagItem {
  background-color: #3eb0ef2b;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 5px;
  display: inline-block;
}
.dododd {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  font-size: 12px;
  color: #666;
}
