.print_only_resume_page {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.preview {
  position: relative;
}

.container {
  min-width: 400px;
  width: 100%;
  max-width: 210mm;
  aspect-ratio: 1 / 1.4142;
  font-size: 1rem;
  font-weight: 400;
  color: #000;
  font-family: "Merriweather", serif;
}

.preview_container {
  background-color: #fff;
}

.preview_image {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 7.97px 7.97px 15.94px rgba(0, 0, 0, 0.15);
  overflow: hidden;

}

.preview_image img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.dots {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.dot {
  width: 10px;
  height: 10px;
  /* border-radius: 50%; */
  transition: background-color 0.3s ease-in-out;
  /* background-color: hsl(222, 13%, 98%); */
  cursor: pointer;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: .2rem;
  
}

.dot.selected {
  width: 12px;
  height: 12px;
  color: orange;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.imageLoaded {
  animation: fadeIn 0.5s ease-in-out;
}

.sfs {
  font-size: .875rem; /* Adjust size as needed */
  line-height: 1.25rem;
    margin-top: 1rem; 
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    color: black;
    /* font-weight: bold; */
    cursor: pointer;
    
}
.containerButton {
  position: relative;
  display: flex;
  justify-content: center;
  border: 1px solid #D5D7FB;
  border-radius: 2rem;
  width: 317px;
  height: 43px;
  padding: 1px;
  background-color: #AAADDF;
  padding: 2px;
  box-sizing: border-box;

}

.innerbutton {
  color: white;
  width: 317px;
  height: 44px;
  border-radius: 2rem;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;    
  font-weight: 500;
  height: 98%;
   
}

.innerbutton:first-child {
  left: 0;
  transform: translateX(var(--translateXLeft, 0%));
}

.innerbutton:last-child {
  right: 0;
  transform: translateX(var(--translateXRight, 0%));
}

.buttonActive {
  color: #8061DD;
  font-weight: 600;
  background-color: white;
}


.downloadContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.downloadButton {
  font-size: 14px;
  color: #8061DD;
  font-weight: 500;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
   border-radius: 2rem;
   border: 1.5px solid #8061DD;
   background: #ffffff;
   cursor: pointer;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.modalContainer {
  background: white;
  width: 90%;
  max-width: 550px;
  border-radius: 12px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  overflow: hidden;
}

.modalHeader {
  padding: 1.5rem;
  background: #FFF9F6;
  border-bottom: 1px solid #FFE4D9;
  display: flex;
  align-items: center;
  gap: 12px;
}

.modalHeader h3 {
  margin: 0;
  color: #FF4B2B;
  font-size: 1.25rem;
  font-weight: 600;
}

.warningIcon {
  color: #FF4B2B;
}

.modalBody {
  padding: 1.5rem;
}

.modalDescription {
  color: #4B5563;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
}

.tipsContainer {
  background: #F9FAFB;
  padding: 1.25rem;
  border-radius: 8px;
  border: 1px solid #E5E7EB;
}

.tipsContainer h4 {
  color: #374151;
  margin: 0 0 1rem 0;
  font-size: 1rem;
  font-weight: 600;
}

.tipsContainer ul {
  margin: 0;
  padding-left: 1.5rem;
}

.tipsContainer li {
  color: #6B7280;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
  line-height: 1.4;
}

.modalFooter {
  padding: 1.25rem;
  background: #F9FAFB;
  border-top: 1px solid #E5E7EB;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.primaryButton, .secondaryButton {
  padding: 0.625rem 1.25rem;
  border-radius: 6px;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.primaryButton {
  background: #FF4B2B;
  color: white;
  border: none;
}

.primaryButton:hover {
  background: #E43D1D;
}

.secondaryButton {
  background: white;
  color: #4B5563;
  border: 1px solid #D1D5DB;
}

.secondaryButton:hover {
  background: #F3F4F6;
}