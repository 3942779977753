.nodr li {
   list-style: none; 
    

} 

.nodr a {
    text-decoration: none; 
    color: black; 
    font-weight: 500;
    color: #09c;
    font-size: 12px;
} 

.nodr h3 {
    font-weight: 600;
    font-size: 20px;
}