.main {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(20px);
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  z-index: 1000;
  box-shadow: 0 -4px 24px rgba(0, 0, 0, 0.05);
}

.containerall {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 16px;
  position: relative;
}

.iconWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  min-width: 70px;
  isolation: isolate;
  transition: all 0.3s ease;
}

.iconWrapper.middle {
  transform: scale(1.1);
  background: rgba(59, 130, 246, 0.1);
  border: 1px solid rgba(59, 130, 246, 0.2);
}

.iconWrapper.middle .label {
  color: #3b82f6;
  font-weight: 600;
}

.iconWrapper.middle .iconBackground {
  background: rgba(59, 130, 246, 0.15);
}

.iconBackground {
  width: 36px;
  height: 36px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.3s ease;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
}

.label {
  font-size: 11px;
  margin-top: 4px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.glow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  filter: blur(20px);
  z-index: -1;
}

/* Glass effect */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .main {
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(20px) saturate(180%);
  }
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  .main {
    background: rgba(17, 17, 17, 0.85);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .label {
    color: #94a3b8;
  }

  .iconContainer {
    color: #94a3b8;
  }

  .iconWrapper:hover {
    background: rgba(255, 255, 255, 0.05);
  }

  .iconWrapper.middle {
    background: rgba(59, 130, 246, 0.15);
    border: 1px solid rgba(59, 130, 246, 0.3);
  }

  .iconWrapper.middle .label {
    color: #60a5fa;
  }
}

/* Responsive design */
@media (max-width: 480px) {
  .iconWrapper {
    padding: 6px 12px;
    min-width: 60px;
  }

  .iconBackground {
    width: 32px;
    height: 32px;
  }
  
  .label {
    font-size: 10px;
  }
}

/* Hover effects for desktop */
@media (hover: hover) {
  .iconWrapper:hover .iconBackground {
    transform: translateY(-2px);
  }
  .main {
    display: none;
  }
}