.heroSection {
    position: relative;
    height: 70vh;
    width: 100%;
    overflow: hidden;
}

.heroImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.7);
}

.heroOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.7));
}

.heroContent {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: white;
    width: 90%;
    max-width: 900px;
    z-index: 2;
}

.heroTitle {
    font-size: clamp(2rem, 5vw, 3.5rem);
    font-weight: 700;
    margin: 1rem 0;
    line-height: 1.2;
    color: white;
}

.tagContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-bottom: 1.5rem;
}

.tagItem {
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: white;
    border-radius: 20px;
    padding: 6px 16px;
    font-size: 0.875rem;
    transition: all 0.3s ease;
}

.authorInfo {
    display: flex;
    justify-content: center;
    gap: 2rem;
    color: white;
    font-size: 0.875rem;
}

.authorDetails, .postDetails, .readTime {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.main {
    display: grid;
    grid-template-columns: 250px minmax(0, 800px) 300px;
    gap: 2rem;
    max-width: 1400px;
    margin: -60px auto 0;
    padding: 0 1.25rem;
    position: relative;
    z-index: 3;
}

.tableOfContents, .latestOpportunities {
    margin-top: 80px;
}

.tableOfContents {
    --primary-color: #3b82f6;
    --success-color: #10b981;
    --text-color: #4b5563;
    --border-color: #e5e7eb;
    position: relative;
}

.stickyContent {
    position: sticky;
    top: 100px;
    background: white;
    border-radius: 16px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    border: 1px solid var(--border-color);
}

.tocHeader {
    padding: 1.25rem;
    border-bottom: 1px solid var(--border-color);
}

.tocHeaderMain {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 0.75rem;
}

.tocHeaderMain h3 {
    font-size: 1rem;
    font-weight: 600;
    color: #111827;
}

.tocStats {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tocProgress, .estimatedTime {
    font-size: 0.75rem;
    color: var(--text-color);
    padding: 0.25rem 0.75rem;
    background: #f3f4f6;
    border-radius: 12px;
}

.progressContainer {
    height: 2px;
    background: #f3f4f6;
    overflow: hidden;
}

.tocProgressBar {
    height: 100%;
    background: linear-gradient(to right, var(--primary-color), var(--success-color));
    transform-origin: left;
}

.tocNav {
    max-height: 60vh;
    overflow-y: auto;
    /* padding: 0.75rem; */
    padding-right: 0.5rem;
}

.tocItem {
    position: relative;
    margin: 0.5rem 0;
}

.tocItemContent {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem 0.5rem;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    background: transparent;
    margin-right: 2px;
}

.tocIconWrapper {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.tocIconWrapper > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.defaultIcon {
    color: #cbd5e1;
}

.activeIcon {
    color: var(--primary-color);
}

.completedIcon {
    color: var(--success-color);
}

.tocTextContent {
    flex-grow: 1;
    position: relative;
}

.tocText {
    font-size: 0.875rem;
    display: block;
    transition: color 0.2s ease;
}

.sectionIndicator {
    position: absolute;
    bottom: -2px;
    left: 0;
    height: 2px;
    background: var(--primary-color);
}

.arrowIcon {
    color: var(--primary-color);
    flex-shrink: 0;
}

.progressLine {
    position: absolute;
    left: 1.5rem;
    top: 100%;
    width: 1px;
    background: var(--success-color);
    opacity: 0.3;
}

.tocFooter {
    height: 4px;
    background: #f3f4f6;
    position: relative;
}

.readingProgress {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: linear-gradient(to right, var(--primary-color), var(--success-color));
}

.readingStatus {
    position: absolute;
    right: 1rem;
    bottom: -1.5rem;
    font-size: 0.75rem;
    color: var(--text-color);
}

.tocItem:hover .tocItemContent {
    background: #f8fafc;
    transform: translateX(4px);
}

.active .tocItemContent {
    background: #eff6ff;
}

.completed .tocText {
    color: var(--success-color);
}

.tocItem[data-level="h3"] {
    padding-left: 1.5rem;
}

.tocItem[data-level="h3"] .tocText {
    font-size: 0.8125rem;
}

.tocNav::-webkit-scrollbar {
    width: 2px;
}

.tocNav::-webkit-scrollbar-track {
    background: transparent;
}

.tocNav::-webkit-scrollbar-thumb {
    background: #e2e8f0;
    border-radius: 1px;
}

.tocNav::-webkit-scrollbar-thumb:hover {
    background: #cbd5e1;
}

.blogContent {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    margin-top: 20px;
}

.blogDetails {
    padding: 2.5rem;
    position: relative;
}

.londDesc {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #2d3748;
    position: relative;
    z-index: 1;
}

.londDesc h2,
.londDesc h3 {
    position: relative;
    scroll-margin-top: 100px;
    padding-top: 20px;
}

.londDesc h2 {
    font-size: 1.8rem;
    margin: 2rem 0 1rem;
    color: #1a202c;
    font-weight: 600;
}

.londDesc h3 {
    font-size: 1.4rem;
    margin: 1.5rem 0 1rem;
    color: #2d3748;
    font-weight: 600;
}

.londDesc p {
    margin-bottom: 1.5rem;
}

.opportunityCard {
    padding: 1.25rem;
    border-bottom: 1px solid #edf2f7;
}

.opportunityCard h4 {
    font-size: 1.1rem;
    margin-bottom: 0.75rem;
    color: #2d3748;
}

.learnMore {
    display: inline-block;
    margin-top: 0.75rem;
    color: #3C65F5;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.875rem;
}

@media (max-width: 1200px) {
    .main {
        grid-template-columns: 200px minmax(0, 1fr) 250px;
        gap: 1.5rem;
    }
}

@media (max-width: 992px) {
    .main {
        grid-template-columns: 1fr;
        margin-top: -40px;
    }

    .tableOfContents,
    .latestOpportunities {
        display: none;
    }

    .heroSection {
        height: 60vh;
    }

    .blogDetails {
        padding: 1.5rem;
    }

    .authorInfo {
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: center;
    }
}

@media (max-width: 576px) {
    .heroSection {
        height: 50vh;
    }

    .heroTitle {
        font-size: 1.75rem;
    }

    .main {
        padding: 0 1rem;
    }

    .blogDetails {
        padding: 1.25rem;
    }
}

@media (max-width: 1024px) {
    .stickyContent {
        border-radius: 12px;
    }

    .tocHeader {
        padding: 1rem;
    }

    .tocNav {
        padding: 0.75rem;
    }

    .tocItemContent {
        padding: 0.5rem 0.75rem;
    }
}

@media (max-width: 768px) {
    .tableOfContents {
        display: none;
    }
}

* {
    transition: background-color 0.2s ease, color 0.2s ease, transform 0.2s ease;
}

.shareSection {
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px solid #edf2f7;
}

.socialButtons {
    display: flex;
    gap: 10px;
    margin-top: 15px;
}

.shareButton {
    background: #f7fafc;
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
    will-change: transform;
}

.shareButton:hover {
    background: #edf2f7;
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: 100px;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.tableOfContents {
    position: relative;
    /* margin-top: 2rem; */
}

@keyframes fadeSlideIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.tocItem {
    animation: fadeSlideIn 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
    animation-delay: calc(var(--index) * 100ms);
}

.latestOpportunities {
    width: 300px;
    margin-left: 2rem;
    position: relative;
}

.stickyContent {
    position: sticky;
    top: 80px;
    background: white !important;
    border-radius: 12px !important;
    border: 1px solid #eee !important;
    padding: 1rem !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.opportunityHeader {
    display: flex !important;
    align-items: center !important;
    gap: 0.5rem !important;
    margin-bottom: 1.5rem !important;
    padding: 0 0.5rem !important;
}

.opportunityHeader h3 {
    font-size: 1.1rem !important;
    margin: 0 !important;
    font-weight: 600 !important;
    color: #333 !important;
}

.opportunityList {
    display: flex !important;
    flex-direction: column !important;
    gap: 1rem !important;
    margin-bottom: 2rem !important;
}

.opportunityCard {
    display: flex !important;
    align-items: center !important;
    gap: 1rem !important;
    padding: 0.75rem !important;
    cursor: pointer !important;
    border-radius: 8px !important;
    background: white !important;
    border: 1px solid #eee !important;
    transition: transform 0.2s ease !important;
}

.companyLogo {
    min-width: 40px !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 8px !important;
    overflow: hidden !important;
    background: #f5f5f5 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.companyLogo img {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain !important;
}

.cardContent {
    flex: 1 !important;
}

.cardContent h4 {
    margin: 0 !important;
    font-size: 0.9rem !important;
    font-weight: 500 !important;
    margin-bottom: 0.25rem !important;
    color: #333 !important;
    line-height: 1.4 !important;
}

.cardContent span {
    font-size: 0.8rem !important;
    color: #666 !important;
    display: block !important;
}

.alertSection {
    padding-top: 1.5rem !important;
    border-top: 1px solid #eee !important;
}

.subscribeHeader {
    display: flex !important;
    align-items: center !important;
    gap: 1rem !important;
    margin-bottom: 1.25rem !important;
}

.subscribeIcon {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 48px !important;
    height: 48px !important;
    background: #e8f0fe !important;
    border-radius: 12px !important;
    padding: 0.75rem !important;
}

.subscribeText h3 {
    margin: 0 !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    color: #333 !important;
    margin-bottom: 0.25rem !important;
}

.subscribeText p {
    margin: 0 !important;
    font-size: 0.8rem !important;
    color: #666 !important;
}

.inputWrapper {
    position: relative !important;
    width: 100% !important;
    margin-bottom: 0.75rem !important;
}

.inputIcon {
    position: absolute !important;
    left: 12px !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    color: #666 !important;
}

.emailInput input {
    width: 100% !important;
    padding: 0.75rem !important;
    padding-left: 2.5rem !important;
    border: 1px solid #eee !important;
    border-radius: 8px !important;
    font-size: 0.9rem !important;
    background: white !important;
    transition: border-color 0.2s ease !important;
}

.emailInput input:focus {
    outline: none !important;
    border-color: #4285f4 !important;
}

.subscribeButton {
    width: 100% !important;
    padding: 0.75rem !important;
    background: #4285f4 !important;
    color: white !important;
    border: none !important;
    border-radius: 8px !important;
    font-size: 0.9rem !important;
    font-weight: 500 !important;
    cursor: pointer !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 0.5rem !important;
    transition: background 0.2s ease !important;
}

.subscribeButton:hover {
    background: #3b78e7 !important;
}

/* Custom scrollbar */
.opportunityList::-webkit-scrollbar {
    width: 4px;
}

.opportunityList::-webkit-scrollbar-track {
    background: transparent;
}

.opportunityList::-webkit-scrollbar-thumb {
    background-color: var(--border-color);
    border-radius: 4px;
}

.opportunityList::-webkit-scrollbar-thumb:hover {
    background-color: var(--text-secondary);
}

.headerWithBadge {
    display: flex !important;
    align-items: center !important;
    gap: 0.75rem !important;
}

.newBadge {
    background: #4285f4 !important;
    color: white !important;
    padding: 0.2rem 0.5rem !important;
    border-radius: 12px !important;
    font-size: 0.7rem !important;
    font-weight: 500 !important;
}

.opportunityCard {
    display: flex !important;
    align-items: flex-start !important;
    gap: 1rem !important;
    padding: 1rem !important;
    cursor: pointer !important;
    border-radius: 12px !important;
    background: white !important;
    border: 1px solid #eee !important;
    transition: all 0.2s ease !important;
}

.companyLogo {
    min-width: 42px !important;
    width: 42px !important;
    height: 42px !important;
    border-radius: 8px !important;
    overflow: hidden !important;
    background: #f5f5f5 !important;
    border: 1px solid #eee !important;
}

.cardHeader {
    display: flex !important;
    gap: 0.5rem !important;
    margin-bottom: 0.4rem !important;
}

.newTag, .deadlineTag {
    font-size: 0.7rem !important;
    padding: 0.2rem 0.5rem !important;
    border-radius: 4px !important;
    font-weight: 500 !important;
}

.newTag {
    background: #e8f0fe !important;
    color: #4285f4 !important;
}

.deadlineTag {
    background: #fff0e6 !important;
    color: #ff6b00 !important;
}

.cardContent h4 {
    margin: 0 !important;
    font-size: 0.9rem !important;
    font-weight: 500 !important;
    line-height: 1.4 !important;
    color: #333 !important;
    margin-bottom: 0.4rem !important;
}

.companyInfo {
    display: flex !important;
    align-items: center !important;
    gap: 0.5rem !important;
}

.companyInfo span {
    font-size: 0.8rem !important;
    color: #666 !important;
}
