.main {
  width: 100%;
  min-height: 80vh;
  border-radius: 10px;
}

.ColorContainer {
  padding: 24px;
  font-weight: 600;
  background-color: #ffffff;
  border-radius: 16px;
  height: 116px;
}
.ColorContainerHeading {
    font-size: 16px;
    font-weight: 500;
    color: #414B67;
    line-height: 150%;
}
.do {
  color: #e3c04c;
  margin-left: 10px;
}
.custom {
  max-width: 400px;
  height: auto;
  padding: 10px;
}
.dor {
  width: 100%;
  height: 1px;
  background-color: #ffe58f;
  margin-top: 1rem;
}

.ResumeContainer {
    margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 32px;
  margin-bottom: 2rem;
}
.resumeImageContainer {
    height: 240px;
    width: 180px;
    overflow: hidden;
    box-shadow: 1.74px 1.74px 5.87px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
  background-color: #ffffff;
  }
  
  .resumeImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
  
.dooo {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selectedTemplate {
  border: 1px solid rgb(102, 108, 255);
  border-radius: 4px;
}
.templateName {
  font-size: 16px;
  color: #535d79;
  margin-bottom: 4px;
  font-weight: 500;
}

.ColorsBox {
    display: flex;
   gap: 8px;
  flex-wrap: wrap;
  margin-top: 10px;
}
.eachCircle {
    width: 32px;
    height: 32px;
    overflow: hidden;
    border: 2px solid;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.eachCirclePlus {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border: 1.5px solid #C7D0E9;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #737B93;
}
.SearchContainer { 
    margin-top: 32px;
    height: 36px;
    position: relative;
}

.textInputSecarchConatiner {
    width: 300px;
    height: 100%;
    background-color: #ffffff;
    position: absolute;
    left: 0;
    border: 1.5px solid #CBD5EC;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 8px;
}

.textInput {
    flex: 1;
    max-width: 100%; /* Prevents overflow */
    margin-left: 3px;
    border: none;
    outline: none;
    font-size: 14px;
    overflow: hidden;
    padding: 0; /* Ensure no extra padding */
    box-sizing: border-box; /* Include padding and border in the total width */
}

.dropdownSecarchMainConatiner {
    background-color: #ffffff;
    border: 1.5px solid #CBD5EC;
    border-radius: 8px;
    width: 177px;
    height: 100%;
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    padding: 0 8px;
}

.dropdownSecarchConatiner {
   
    border-radius: 8px;
    width: 177px;
    position: relative;
    cursor: pointer;
  }
  
  .dropdownToggle {
    padding: 8px;
    display: flex;
    align-items: center;
    color: #535D79;
    font-size: 14px;
    position: relative;
    gap: 7px;
     text-overflow: ellipsis; 
  }
  
  .dropdownMenu {
    position: absolute;
    top: 100%;
    left: -70px;
    width: 150%;
    background-color: #ffffff;
    border: 1.5px solid #CBD5EC;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    font-size: 14px;
    max-height: 430px; /* Optional: limit the height */
    overflow-y: auto; /* Optional: make it scrollable if there are many options */
  }
  
  .dropdownItem {
    padding: 8px;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
  }
  
  .dropdownItem:hover {
    background-color: #f0f0f0;
  }
  
  .dropdownItem:last-child {
    border-bottom: none;
  }
  
.chevronDownIcon {
  position: absolute;
  right: 0px;
}
.MobileHeading {
  display: none;
}
.headingTitle {
  font-size: 32px;
  font-weight: 500;
  color:#394056;
  margin-bottom: 24px;
}
.searchContainerOnTopWhenMobile {
  display: none;
}
@media (max-width: 1068px) { 
  .MobileHeading {
    display: block;
  }
  .main {
    width: calc( 100% - 40px) ;
     
  }
  .ColorsBox {
    flex-wrap: nowrap;
    overflow: scroll;
   }
   .searchContainerOnTopWhenMobile {
    display: block;
   }
   .showSeachOnBellowinDesktop {
    display: none;
   }
   .SearchContainer {
    margin-bottom: 24px;
   }
   .textInputSecarchConatiner {
    width: 220px; 
   }
   .dropdownSecarchMainConatiner { 
    width: 149px;
   }
} 

 