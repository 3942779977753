@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.paragraph {
  font-weight: 500;
  /* color: white;  */
  line-height: 1.5rem;
  text-align: center;
}

.main {
  /* margin-top: 4rem;  */
  padding: 1rem;
  /* background: url(https://cloudinary-marketing-res.cloudinary.com/images/w_1924,h_599/c_scale,w_auto,dpr_auto/f_auto,q_auto/v1662749086/SignUpFooter_Bkg/SignUpFooter_Bkg-png?_i=AA); */
}
.container {
  margin-right: 140px !important;
  margin-left: 140px !important;
  padding-top: 32px !important;
  padding-right: 16px !important;
  padding-bottom: 32px !important;
  padding-left: 10px !important;
  border-radius: 16px !important;
  border-style: solid !important;
  border-color: #888888 !important;
  border-width: 0 !important;
  position: relative;
}
.Inner {
  border-radius: 16px !important;
  background-color: #f1eafa !important;
  padding: 32px 16px 32px 10px;
  display: flex;
  justify-content: space-between;
}
.head {
  display: flex;
  align-items: center;
  font-size: 29px;
  font-weight: 800;
  margin-top: 0;
  margin-bottom: 11px;
  line-height: 26px;
  padding: 0;
  letter-spacing: -0.6px;
  text-align: left; 
  font-family: "Inter", sans-serif;
}
.center {
  width: 55%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
}
.pad {
  color: #172b4d;
  font-size: 16px;
  
  font-family: "Inter" ;
}

.right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
}
.butot {
  color: white;
  background: linear-gradient(
    270deg,
    rgb(160, 102, 255) 16.03%,
    rgb(102, 108, 255) 88.19%
  );

  font-weight: 400;
  font-size: 15px;
  cursor: pointer;
  height: 44px;
  line-height: 44px;
  padding: 0 26px;
  border-radius: 4px;
}

/* add media query for the mobile vriw
 */
@media only screen and (max-width: 768px) {

    .Inner {
        display: flex;
        flex-direction: column;
      }

  .center {
    width: 100%;
  
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  .right {
 width: 100%;   
  }
  .butot {
    font-size: 13px;
    padding: 0 10px;
    height: 30px;

    line-height: 30px;
  }
  .pad {
    font-size: 12px;
    align-items: center !important;
  
    text-align: center;
  }

  .container {
    margin-right: 0px !important;
    margin-left: 0px !important;
  } 
  .left {
   
    display: flex;
    justify-content: center;
    padding: 10px;
  } 
  .head {
    display: flex;
    justify-content: center; 
    font-size: 19px; 
    text-align: center;
  }
  .main {
    padding: .2rem;
  }
}
