.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 20px;
  }
  
  .modalContent {
    background: linear-gradient(145deg, #1a1a1a, #2d2d2d);
    border-radius: 20px;
    padding: 30px;
    width: 90%;
    max-width: 1000px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  }
  
  .closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.3s;
  }
  
  .closeButton:hover {
    opacity: 1;
  }
  
  .modalHeader {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .scoreCircle {
    background: linear-gradient(145deg, #2a2a2a, #333);
    width: 200px;
    height: 200px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
  }
  
  .scoreCircle::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, transparent, rgba(255, 255, 255, 0.1));
    pointer-events: none;
  }
  
  .scoreValue {
    font-size: 64px;
    font-weight: bold;
    color: #4CAF50;
    text-shadow: 0 2px 10px rgba(76, 175, 80, 0.3);
    z-index: 2;
    position: relative;
    background: -webkit-linear-gradient(45deg, #4CAF50, #45a049);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .scoreLabel {
    font-size: 18px;
    color: #888;
    margin-top: 8px;
    z-index: 2;
    position: relative;
  }
  
  .sectionsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin: 30px 0;
  }
  
  .sectionCard {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 15px;
    padding: 25px;
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    position: relative;
    overflow: hidden;
  }
  
  .sectionCard::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      45deg,
      transparent 0%,
      rgba(255, 255, 255, 0.05) 50%,
      transparent 100%
    );
    transform: translateX(-100%);
    transition: transform 0.6s;
  }
  
  .sectionCard:hover::before {
    transform: translateX(100%);
  }
  
  .sectionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .sectionHeader h3 {
    color: #fff;
    margin: 0;
    font-size: 18px;
    font-weight: 500;
  }
  
  .sectionScore {
    background: linear-gradient(145deg, #4CAF50, #45a049);
    padding: 10px 20px;
    border-radius: 25px;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    box-shadow: 0 2px 8px rgba(76, 175, 80, 0.2);
    position: relative;
    overflow: hidden;
  }
  
  .sectionScore::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transform: rotate(45deg);
    animation: shimmer 2s infinite;
  }
  
  @keyframes shimmer {
    0% {
      transform: translateX(-100%) rotate(45deg);
    }
    100% {
      transform: translateX(100%) rotate(45deg);
    }
  }
  
  .viewAnalysisButton {
    background: linear-gradient(145deg, #4CAF50, #45a049);
    color: white;
    border: none;
    padding: 16px 32px;
    border-radius: 30px;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 30px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: fit-content;
    min-width: 250px;
    position: relative;
    overflow: hidden;
  }
  
  .viewAnalysisButton::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transform: translateX(-100%);
  }
  
  .viewAnalysisButton:hover::before {
    transform: translateX(100%);
    transition: transform 0.8s;
  }
  
  .buttonIcon {
    font-size: 20px;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  }