@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600&display=swap");

.build {
  min-height: 80%;
  display: flex;
  flex-direction: column;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.build .header {
  position: relative;
  text-align: center;
  padding-top: 36px;
}

.desc {
  color: #79839F;
  font-size: 16px;
}
.resumeScore {
  width: 100%;
  height: 4px;
  background-color: hsl(222, 45%, 96%);
  position: relative;
  margin-top: 36px;
}

.section {
  display: flex;
  flex-direction: column;
   margin-top: 24px;
  margin-bottom: 10px;
  position: relative;
}

.headingContainer {
  margin-top: 1rem;
}

.TipsContainer {
  margin-top: 1rem;
  margin-bottom: 48px;
  border-radius: 12px;
  background: white; /* Background color of the main content */
  padding: 1rem;
  min-height: 120px;
  display: flex;
  position: relative;
  z-index: 1; /* Ensure content stays above the pseudo-element */
  overflow: hidden; /* Ensure rounded corners on the gradient border */ 
  align-items: center;
}
.tipleft {
  width: 30%;
  height: 100%; /* Make sure the container has a set height */
}
.tipleft img {
  width: 100%;
  height: 100%; /* Set the image to take up full width and height of container */
  object-fit: cover; /* Stretches the image to cover the container while keeping aspect ratio */
}
.tipright {
  display: flex;

  justify-content: center;
  flex-direction: column;
  width: 70%;
  padding-left: 15px;
}
.tipheading {
  font-size: 12px;
  color: #7c69e8;
  font-weight: 600;
}
.tippara {
  font-size: 14px;
  color: #60539c;
}
.tipparMobile {
   display: none;
}
.TipsContainer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  padding: 1.5px; /* Adjust to control the border thickness */
  background: linear-gradient(
    90deg,
    #9f7fff 0%,
    #81c6fe 18%,
    #2530ff 37%,
    #43ffe6 56%,
    #fc58db 69%,
    #81c6fe 98%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0); /* Creates a mask for padding */
  -webkit-mask-composite: xor;
  mask-composite: exclude; /* Creates the inner transparent area */
}

.section h2 {
  font-size: 32px;
  font-weight: 500;
  color: #394056;
  
  margin-bottom: 12px;
}

.section p {
  font-size: 14px;
  font-weight: 400;
   
  line-height: 20px;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.section_elements {
  display: flex;
  flex-direction: column;
  gap: 48px; 
  position: relative;
}


.section_fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Limit to two columns */
  column-gap: 36px;
  row-gap: 24px;
  animation: slidefadein 0.5s;
}

.section_fields .input_textarea {
  width: 100%; /* Make textarea take full width of its container */
  box-sizing: border-box; /* Ensure padding/border are included in width */
}
.section_fields:has(summary) {
  display: flex;
  flex-direction: column;
}

 

/* .section_elements > * {
  border: 1px solid hsl(226.15deg 37.14% 93.14%);
  border-radius: 6px;
  padding: 16px;
} */

.section_element_actions {
  display: flex;
  align-items: baseline;
  gap: 16px;
  justify-content: flex-end;
}

.section_element_actions button {
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  color: hsl(223, 15%, 57%); 
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 4px; 
  position: absolute;
  right: 0;
}

.section_fields summary {
  display: none;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  color: transparent;
  line-height: 20px;
  cursor: pointer;
  outline: none;
  
}
.top_section_fields{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  color: transparent;
  line-height: 20px;
  cursor: pointer;
  outline: none;
}
.section_fields summary:hover {
  color: #7c69e8;
}

.section label {
  font-size: 15px;
  font-weight: 400;
  color: #3c445c;
  line-height: 20px;
  margin-bottom: 0.1rem;
}

.input_text,
.input_textarea {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  grid-column-end: span 1;
}

.input_textarea {
  grid-column-end: span 2;
}

.input_textarea textarea { 
  padding-top: 2.5rem !important; 
  font-size: 14px !important;
}
.input_text input,
.input_textarea textarea {

  border: none;
  outline: none;
  font-size: 14px;
  line-height: 1.6;
  color: #535D79;
  min-width: none;
  margin: 4px 0;
  padding: 8px;
  background-color: #f8faff;
  margin-bottom: -2px;
  border-radius: 6px;
  border: 1px solid #cbd5ec;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.05);
  font-family: "inter";
}


.input_text input:focus,
.input_textarea textarea:focus {
  border-radius: 6px 6px 0 0;
  /* border-bottom: 2px solid hsl(207, 88%, 52%); */
}

.input_text .input_title {
  background-color: transparent;
  font-size: 20px;
  line-height: 1.6;
  color: #000;
  margin: 0;
  text-align: center;
}

.input_image label {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  gap: 16px;
  padding: 8px;
  background-color: hsl(222, 45%, 96%);
}

.input_image input {
  display: none;
}

.input_image label img {
  height: 100%;
  max-height: 60px;
  width: auto;
  border-radius: 6px;
  object-fit: cover;
}

.input_textarea textarea {
  border: none;
  /* outline: none; */
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  color: #666;
  min-width: none;
  margin: 4px 0;
  padding: 8px;
  background-color: #f8faff;
  margin-bottom: -2px;
  border-radius: 8px;
  border: 1px solid #cbd5ec;
  resize: none;
  height: 160px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.05);
}

.input_dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  grid-column-end: span 1;
}

.input_dropdown select {
  height: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  color: #666;
  min-width: none;
  margin: 4px 0;
  padding: 8px;
  background-color: hsl(222, 45%, 96%);
  caret-color: hsl(207, 88%, 52%);
  margin-bottom: -2px;
  border-radius: 6px;
  border-bottom: 2px solid transparent;
}

 

.input_dropdown label {
  font-size: 15px;
  font-weight: 400;
  color: hsl(223.13deg 14.68% 57.25%);
  line-height: 20px;
  margin-bottom: 0.1rem;
}

.input_toggle {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  grid-column-end: span 1;
}

.input_toggle input {
  display: none;
}

.input_toggle label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 6px;
  gap: 16px;
  padding: 10px;
  background-color: hsl(222, 45%, 96%);
}

.input_toggle label .toggle {
  width: 40px;
  height: 20px;
  border-radius: 20px;
  background-color: hsl(223, 15%, 57%);
  position: relative;
  transition: background-color 0.3s ease-in-out;
}

.input_toggle label .toggle::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;
  top: 2px;
  left: 2px;
  transition: transform 0.3s ease-in-out;
}

.input_toggle input:checked + label .toggle {
  background-color: hsl(207, 88%, 52%);
}

.input_toggle input:checked + label .toggle::after {
  transform: translateX(20px);
}

.input_list_elements {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 12px;
}
.input_list_label_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sections > .input_list > label {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  line-height: 24px;
  margin-top: 16px;
  margin-bottom: 12px;

}

.sections > .input_list {
  margin-bottom: 16px;
}

.input_list p {
  font-size: 14px;
  font-weight: 400;
  color: hsl(223, 15%, 57%);
  line-height: 20px;
  margin-top: 12px;
}

.input_list_label {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  line-height: 20px;
  margin-bottom: 0.1rem;
}

.input_list_String_element {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  animation: slidefadein 0.5s;
}

.slidefadein {
  animation: slidefadein 0.5s;
}

.slidefadeout,
.slidefadeout > * {
  animation: slidefadeout 0.5s;
}

@keyframes slidefadein {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slidefadeout {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
}

.input_list_element {
  display: flex;
  flex-direction: column;
  border: 1px solid hsl(222, 45%, 96%);
  padding: 16px;
  border-radius: 6px;
  animation: slidefadein 0.5s;
}

.input_list_element[open] > *:not(summary) {
  margin-top: 16px;
}

.input_list_element input,
.input_list_String_element input {
  border: 1px solid #CBD5EC;
  outline: none;
  font-size: 14px;
  line-height: 1.6;
  color: #535D79;
  width: 100%;
  margin: 4px 0;
  padding: 8px;
  background-color: #F8FAFF;
  caret-color: hsl(207, 88%, 52%);
  margin-bottom: -2px;
  border-radius: 8px;
   
}

 

.input_list_element button,
.input_list_String_element button {
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: hsl(223, 15%, 57%);
}

 
.input_list_element summary {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  color: #666;
  cursor: pointer;
  border: none;
  outline: none;
  text-align: left;
}

.section_actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
}

.section_actions button {
  width: 100%;
  /* padding: 6px 16px; */
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  color: hsl(207, 88%, 52%);
  /* background-color: #fff; */
  border-radius: 6px;
  text-align: left;
  cursor: pointer;
}

.slide_up_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: hsl(223, 15%, 57%);
}

 

.input_list_elements .dragging {
  opacity: 0.5;
}
 

.mobilecontainer {
  display: flex;
  justify-content: space-between;
}
.dlo {
  padding: 0.5rem;
  background-color: rgb(102, 108, 255);
  border-radius: 6px;
  color: white;
}
.downloadConatiner {
  position: relative;
}
.underconatiner {
  position: absolute;
  border-radius: 10px;
  width: 8rem;
  background-color: white;

  display: flex;
  flex-direction: column;
  gap: 4px;
  z-index: 98;
}
.butondonw {
  border: 1px solid #eeee;
  padding: 0.4rem;
  border-radius: 5px;
}

.aibuttt {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
  color: #7557cd !important;
  font-weight: 500;
  cursor: pointer;
  border: none;
  background-color: #f4f1ff !important;
  font-size: 12px !important;
  border-radius: 2rem !important;
  border: 1px solid #d5ceee !important; 
  padding-right: 10px;
  padding-left: 10px;
}
.jobRole {
  width: 100%;
  padding: 12px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #535D79;
  background-color: #F8FAFF;
  border: 1px solid #CBD5EC;
  border-radius: 8px;
  transition: all 0.15s ease-in-out;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15), 0px 0px -2px rgba(0, 0, 0, 0.15);
}

.task {
  width: 100%;
  
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #535D79;
  background-color: #F8FAFF;
 
  border-radius: 8px;
  transition: all 0.15s ease-in-out;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15), 0px 0px -2px rgba(0, 0, 0, 0.15);
}

.jobRole::placeholder {
  color: #9CA3AF;
}

.jobRole:focus {
  outline: none;
  border-color: #6366F1;
  box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.1);
  background-color: #FFFFFF;
}

.jobRole:hover {
  border-color: #D1D5DB;
}
.jasole {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  resize: vertical;
  min-height: 100px;
}
.drop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-top: -3rem;
  margin-left: -6rem;
  padding: 0.5rem;
  color: white;
  font-weight: 500;
  min-width: 100px;
  border-radius: 0.5rem;
  cursor: pointer;
  border: none;
  background: linear-gradient(270deg, #a066ff 16.03%, #666cff 88.19%);
  font-size: 14px;
}
.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  width: 100%;
  border-radius: 4px;
  border: none;
  padding: 10px 20px;
  background: linear-gradient(270deg, #a066ff 16.03%, #666cff 88.19%);
}
.rq {
  font-size: 14px;
  border: 2px solid #dee2e6;
  border-radius: 9px;
  background-color: white;
}
.da {
  background-color: #f9faff;
  border-radius: 8px;
  border: 1px solid #838fa0;
}
.backgrounddd {
  background: linear-gradient(270deg, #a066ff 16.03%, #666cff 88.19%);
}
.mb {
  width: 100%;
}

 
.modelBody {
  padding: 0px !important;
  border-radius: 40px !important;
}
.modelBody  *{
    font-family: "inter";
  }

  .close {
    display: flex;
    justify-content: end;
    position: absolute;
    top: 1rem;
    right: 1rem; 
  }
  
  .closeButton {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: none;
    background: #f8fafc;
    color: #64748b;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }

  .closeButton:hover {
    background: #f1f5f9;
    color: #475569;
    transform: scale(1.05);
  }

  .closeButton svg {
    width: 16px;
    height: 16px;
  }

  /* Update modal header to account for absolute positioning of close button */
  .modalHeader {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    padding-right: 2rem; /* Make space for close button */
  }

  .modalTitle {
    font-size: 1.5rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #1e293b; /* Darker color for better contrast */
  }

  .second {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 54px 48px 32px 48px !important;
    border-radius: 16px;
  }

  @media screen and (max-width: 768px) {
    .second {
      padding: 24px 16px 16px 16px !important;
      gap: 16px;
      border-radius: 12px;
    }
  }

  .containerModal {
       display: flex;
       flex-direction: column;
       gap: 32px;
  }
  .headtitle {
    font-size: 28px;
    color: #8061DD;
  }
  .headtitlparagraph {
    font-size: 16px;
    color: #757F9B;
  }
  .iconcut {
    cursor: pointer;
  }
  .iconcut:hover {
    transform: rotate(90deg);
  
    transition: transform 0.5s;
  }
.dropdowncolor {
  color: rgb(249, 111, 89);
}
.label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #3C445C;
  line-height: 20px;
}
.mian {
  right: 0px;
  top: 32px;
  right: 10px;
  position: absolute;
}
.addButtons {
  color: #6b4dc4;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.details_collapsed {
  background-color: #ffffff;
  
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 16px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
}

/* Remove background color when details is open */
.details_collapsed[open] {
  background-color: transparent;
  box-shadow: none;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 0px;
}

.InputContainer {
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  justify-content: space-between; /* Adds space between the two containers */
}

input::placeholder {
  color: #8391b7; /* Change to your desired color */
  opacity: 1; /* Ensure full opacity */
  font-size: 14px;
  
}

@media screen and (min-width: 768px) {
  .aro {
    display: none;
  }
  .mobilecontainer {
    display: none;
  }
 
}

 

.section_fields_each {
  display: flex;
  column-gap: 32px;
  row-gap: 24px;
  flex-wrap: wrap;
}

.eachContainer {
  width: 47%;

}
.eachContainer input {
 
  display: flex;
  flex-wrap: wrap;
  
}

.aiButtonDiv {
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
}
.spinner {
  width: 18px;
  height: 18px;
  border-radius: 50%; /* Makes the spinner circular */
  position: relative;
}

.spinner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  background: white; /* White gap */
  padding: 2px; /* Adjust this to control the thickness of the white gap */
}

.spinner::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  padding: 2px; /* Border thickness */
  background: conic-gradient(#8061DD, #d8b7ef); /* Gradient border */
  -webkit-mask: 
    radial-gradient(farthest-side, transparent calc(100% - 2px), black 0); /* Creates a hole in the middle */
  mask: 
    radial-gradient(farthest-side, transparent calc(100% - 2px), black 0);
  animation: spin 1s linear infinite;
}
.ModalinputContainerMain {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.ModalButtonContainerMain {
  display: flex;
  justify-content: end;
  align-items: end;
}
.ModalButtonContainer {
  display: flex;
  gap: 16px;
}
.skipandgenerate {
  padding: 12px 24px; /* Combined padding values */
  border: 1px solid #CBD5EC;
  border-radius: 10rem;
  font-size: 14px;
  color: #535D79;
  cursor: pointer;
}
.generateModalButton {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #8061DD;
  border-radius: 10rem;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .aibuttt {
    font-size: 13px;
    padding: 0.4rem;
    margin-left: -5rem;
  }
  .eachContainer {
    width: 100% !important;
  
  }
  .section_fields {
    grid-template-columns: 1fr; /* Switch to one column on mobile */
  }
}

.taskContainer {
  border: 1px solid #CBD5EC;
  background: #F8FAFF;
  border-radius: 8px;
  padding: 12px; 
   
}

.suggestionTitle {
  font-size: 13px;
  color: #6c757d;
  margin-bottom: 8px;
}

.suggestions {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 8px;
}

.suggestionItem {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 8px ;
  padding-top: 4px;
  padding-bottom: 4px;
  background: white;
  border: 1px solid #E6DDFF;
  border-radius: 6px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s ease;
  gap: 8px;
}

.suggestionItem:hover {
  background: #f1f3f5;
  border-color: #dee2e6;
}

.crossIcon {
  color: #6c757d;
  font-size: 16px;
  font-weight: bold;
}

.suggestionItem:hover .crossIcon,
.suggestionItem:hover .plusIcon {
  color: #0d6efd;
}

@media (max-width: 768px) {
  .suggestions {
    grid-template-columns: 1fr;
  }
}

.selectedTasks {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
  min-height: 32px;
  padding: 4px;
}

.taskTag {
  background-color: #f0f0f0;
  border-radius: 16px;
  padding: 4px 12px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  max-width: 100%;
  word-break: break-word;
}

.removeTag {
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: #666;
  background: none;
  border: none;
  padding: 0 4px;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.removeTag:hover {
  color: #ff4444;
}

.suggestions {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.suggestionItem {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  background-color: #ffffff;
  border-radius: 40px;
  cursor: pointer;
  transition: background-color 0.2s;
  color: #737B93;
}

.suggestionItem:hover {
  background-color: #e9ecef;
}

.plusIcon {
  color: #666;
}

.resultsHeader {
  text-align: center;
  margin-bottom: 2rem;
  padding: 1rem;
}

.jobRoleHighlight {
  color: #666cff;
  font-weight: 600;
}

.regenerateBtn {
  margin-top: 1rem;
  background: linear-gradient(270deg,#a066ff 16.03%,#666cff 88.19%);
  border: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
}

.regenerateBtn:hover {
  background: linear-gradient(270deg,#8952d3 16.03%,#5256d3 88.19%);
}

.pointsList {
  max-height: 60vh;
  overflow-y: auto;
  padding: 1rem;
}

.pointItem {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem;
  margin-bottom: 0.75rem;
  background: white;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.pointItem:hover {
  border-color: #666cff;
  box-shadow: 0 2px 8px rgba(102, 108, 255, 0.1);
}

.pointAction {
  padding: 0.5rem;
  cursor: pointer;
}

.checkIcon {
  color: #28a745;
}

.plusIcon {
  color: #666cff;
}

.pointText {
  flex: 1;
  font-size: 0.875rem;
  line-height: 1.5;
}

@media screen and (max-width: 768px) { 
  .tipleft {
    width: 50%;
  }
}

.customQuill {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #cbd5ec;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.05);
  font-family: "inter";
}

.customQuill :global(.ql-toolbar) {
  border: none !important;
}

.customQuill :global(.ql-container) {
  border: none !important;
}

.customQuill :global(.ql-editor) {
  min-height: 120px;
}

/* This targets the entire Quill wrapper */
.customQuill :global(.quill) {
  border: 1px solid #ddd;
  border-radius: 8px;
}

.customQuill :global(.ql-editor ul) {
  padding-left: 0;
}

.customQuill :global(.ql-editor li) {
  padding-left: 0;
}

.customQuill :global(.ql-editor li::before) {
  margin-right: 0.5em;
  margin-left: 0;
}

.spinnerinKeyPoints {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  margin-right: 8px;
  display: inline-block;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media screen and (max-width: 768px) {  
  .tipparMobile {
    display: block;
  }
  .tippara {
    display: none;
  }
  .TipsContainer {
    padding: 8px !important;
  }
}

.resultsContainer {
  max-height: 60vh;
  overflow-y: auto;
  padding: 1rem;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.resultsContainer::-webkit-scrollbar {
  width: 6px;
}

.resultsContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.resultsContainer::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.pointItem {
  background: white;
  transition: all 0.2s ease;
}

.pointItem:hover {
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.iconWrapper {
  transition: all 0.2s ease;
}

.iconWrapper:hover {
  transform: scale(1.1);
}

.titleWrapper {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 0.5rem;
}

.titleIcon {
  font-size: 1.5rem;
}

.pointCount {
  font-size: 0.875rem;
  color: #666cff;
  background: rgba(102, 108, 255, 0.1);
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  margin-left: 0.75rem;
}

.resultsWrapper {
  background: #f8fafc;
  border-radius: 16px;
  /* padding: 1.5rem; */
  margin: 1.5rem 0;
}

.resultsContainer {
  max-height: 60vh;
  overflow-y: auto;
  padding-right: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.resultsContainer::-webkit-scrollbar {
  width: 4px;
}

.resultsContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.resultsContainer::-webkit-scrollbar-thumb {
  background: #666cff;
  border-radius: 10px;
}

.pointItem {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1.25rem;
  margin-bottom: 1rem;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.selectedPoint {
  border: 2px solid #666cff;
  background: rgba(102, 108, 255, 0.02);
}

.selectionIndicator {
  cursor: pointer;
  border-radius: 50%;
  padding: 8px;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconWrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #e0e0e0;
  position: relative;
}

.icon {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.checkIcon {
  color: white;
}

.plusIcon {
  color: #666;
}

.pointContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.pointText {
  margin: 0;
  font-size: 0.95rem;
  line-height: 1.6;
  color: #334155;
}

.selectedBadge {
  font-size: 0.75rem;
  color: #666cff;
  background: rgba(102, 108, 255, 0.1);
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  width: fit-content;
}

.ModalButtonContainer {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.editButton, .regenerateButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 2rem;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  border: none;
  transition: all 0.2s ease;
}

.editButton {
  background: white;
  color: #64748b;
  border: 1px solid #e2e8f0;
}

.regenerateButton {
  background: #8061DD;
  color: white;
}

.buttonIcon {
  font-size: 0.875rem;
}

/* Hover Effects */
.pointItem:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.editButton:hover {
  background: #f8fafc;
  border-color: #cbd5e0;
}

.regenerateButton:hover {
  box-shadow: 0 4px 12px rgba(102, 108, 255, 0.2);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
   
  .pointItem {
    padding: 1rem;
  }

  .ModalButtonContainer {
    flex-direction: column;
  }

  .editButton, .regenerateButton {
    width: 100%;
    justify-content: center;
  }
}

.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.spinnerWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}

.spinner {
  width: 20px;
  height: 20px;
  position: relative;
}

.spinnerInner {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  border-top-color: currentColor;
  border-radius: 50%;
  animation: spinner-rotate 0.6s linear infinite;
}

@keyframes spinner-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modalContainer {
  position: relative;
  padding: 1.5rem;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.modalTitle {
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.titleIcon {
  font-size: 1.5rem;
}

.generateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  text-align: center;
  padding: 2rem;
}

.generateContent {
  max-width: 400px;
}

.generateButton {
  background: linear-gradient(270deg,#a066ff 16.03%,#666cff 88.19%);
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 8px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 2rem auto 0;
  cursor: pointer;
  transition: all 0.2s ease;
}

.generateButton:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.generateButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.skillsContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0.5rem;
}

.categorySection {
  background: #ffffff;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid #E6DDFF;
}

.categoryHeader {
  margin-bottom: 24px;
}

.categoryTitleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.categoryTitle {
  color: #8061DD;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.01em;
  display: flex;
  align-items: center;
  gap: 8px;
}

.categoryTitle::before {
  content: '';
  display: block;
  width: 4px;
  height: 24px;
  background: linear-gradient(270deg, #a066ff 16.03%, #666cff 88.19%);
  border-radius: 2px;
}

.skillCount {
  background: #F4F1FF;
  color: #8061DD;
  font-size: 14px;
  padding: 4px 12px;
  border-radius: 20px;
  font-weight: 500;
  border: 1px solid #E6DDFF;
}

.categoryDivider {
  height: 1px;
  background: linear-gradient(90deg, #E6DDFF 0%, rgba(230, 221, 255, 0) 100%);
}

.skillCard {
  background: #ffffff;
  border: 1px solid #E6DDFF;
  border-radius: 12px;
  transition: all 0.2s ease;
}

.skillCardSelected {
  background: #F4F1FF;
  border-color: #8061DD;
}

.skillContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
}

.skillName {
  color: #535D79;
  font-size: 14px;
  font-weight: 500;
}

.skillToggle {
  width: 28px;
  height: 28px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #E6DDFF;
  transition: all 0.2s ease;
}

.skillCardSelected .skillToggle {
  background: linear-gradient(270deg, #a066ff 16.03%, #666cff 88.19%);
  border-color: transparent;
}

.skillToggleIcon {
  color: #8061DD;
  transition: all 0.2s ease;
}

.skillCardSelected .skillToggleIcon {
  color: #ffffff;

}

/* Hover States */
.skillCard:hover {
  border-color: #8061DD;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(128, 97, 221, 0.1);
}

.regenerateBtn {
  background: #F4F1FF;
  border: 1px solid #E6DDFF;
  color: #8061DD;
  padding: 12px 24px;
  border-radius: 24px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
}

.regenerateBtn:hover {
  background: #E6DDFF;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(128, 97, 221, 0.1);
}

.regenerateContainer {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  padding-top: 24px;
  border-top: 1px solid #E6DDFF;
}

.point_container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.input_wrapper {
  width: 100%;
}

.input_wrapper input {
  width: 100%;
}

.progress_container {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 4px 0;
}

.progress_track_wrapper {
  flex: 1;
  min-width: 100px;
}

.controls_wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  white-space: nowrap;
}

.percentage {
  font-size: 0.875rem;
  font-weight: 500;
  min-width: 45px;
}

.improve_button {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px !important;
  background:#f4f1ff !important;
  border: none;
  cursor: pointer;
  font-size: 0.875rem;
  color: #7557cd !important;
  transition: all 0.2s ease; 
  border: 1px solid #6B4DC4 !important;
}

.improve_button:hover {
  background: rgba(107, 77, 196, 0.2);
}

.improve_icon {
  font-size: 14px;
}

.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(17, 24, 39, 0.85);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 1rem;
  overflow-y: auto;
}

.modal_content {
  background: #ffffff;
  border-radius: 16px;
  width: 100%;
  max-width: 700px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 2rem auto;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(107, 77, 196, 0.1);
}

.modal_header {
  padding: 1.5rem;
  background: linear-gradient(to right, #6B4DC4, #8B5CF6);
  border-radius: 16px 16px 0 0;
  color: white;
  position: sticky;
  top: 0;
  z-index: 10;
}

.header_content {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.modal_icon {
  font-size: 1.5rem;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
  padding: 0.5rem;
  border-radius: 12px;
}

.header_text h3 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: #fff;
}

.header_text p {
  margin: 0.25rem 0 0;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.9);
}

.modal_body {
  flex: 1;
  overflow-y: auto;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background: #f8fafc;
}

.modal_section {
  background: #ffffff;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(107, 77, 196, 0.1);
  transition: transform 0.2s ease;
}

.modal_section:hover {
  transform: translateY(-2px);
}

.section_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.section_header h4 {
  font-size: 1rem;
  font-weight: 600;
  color: #1f2937;
  margin: 0;
}

.editable_text {
  width: 100%;
  min-height: 100px;
  padding: 1rem;
  border: 2px solid #e5e7eb;
  border-radius: 8px;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #374151;
  transition: all 0.2s ease;
  resize: vertical;
}

.editable_text:focus {
  outline: none;
  border-color: #6B4DC4;
  box-shadow: 0 0 0 3px rgba(107, 77, 196, 0.1);
}

.pro_badge {
  background: linear-gradient(135deg, #6B4DC4, #8B5CF6);
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 6px;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.05em;
}

.example_badge {
  background: linear-gradient(135deg, #F59E0B, #FBBF24);
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 6px;
  font-size: 0.75rem;
  font-weight: 600;
}

.tips_list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.tips_list li {
  display: flex;
  gap: 0.5rem;
  align-items: baseline;
  color: #4B5563;
  font-size: 0.875rem;
  line-height: 1.5;
}

.tip_bullet {
  color: #6B4DC4;
  font-size: 1.25rem;
}

.example_card {
  background: rgba(107, 77, 196, 0.05);
  border: 1px solid rgba(107, 77, 196, 0.1);
  border-radius: 8px;
  padding: 1rem;
}

.example_card p {
  color: #4B5563;
  font-size: 0.875rem;
  line-height: 1.5;
  margin: 0;
}

.modal_footer {
  padding: 1.5rem;
  border-top: 1px solid #e5e7eb;
  background: #ffffff;
  border-radius: 0 0 16px 16px;
  position: sticky;
  bottom: 0;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  color: #fff !important;
}

.close_button_secondary {
  padding: 0.625rem 1.25rem;
  background: #ffffff;
  border: 2px solid #e5e7eb;
  border-radius: 8px;
  color: #4B5563;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.close_button_secondary:hover {
  background: #f3f4f6;
  border-color: #d1d5db;
}

.save_button {
  padding: 0.625rem 1.25rem;
  background: linear-gradient(135deg, #6B4DC4, #8B5CF6);
  border: none;
  border-radius: 8px;
  color: #fff !important;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(107, 77, 196, 0.1);
}

.save_button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(107, 77, 196, 0.2);
}

@media (max-width: 640px) {
  .modal_overlay {
    padding: 0;
  }

  .modal_content {
    height: 100vh;
    max-height: none;
    border-radius: 0;
    margin: 0;
  }

  .modal_header {
    border-radius: 0;
  }

  .modal_section {
    padding: 1rem;
  }

  .modal_footer {
    border-radius: 0;
    padding: 1rem;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.modal_section {
  animation: slideIn 0.3s ease forwards;
}

.modal_body::-webkit-scrollbar {
  width: 6px;
}

.modal_body::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.modal_body::-webkit-scrollbar-thumb {
  background: #6B4DC4;
  border-radius: 3px;
}

.modal_body::-webkit-scrollbar-thumb:hover {
  background: #5B3DA4;
}

/* Progress Bar Styles */
.progress_container {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.progress_track_wrapper {
  flex: 1;
  padding: 0.5rem 0;
}

.progress_track {
  position: relative;
  overflow: hidden;
}

.progress_fill {
  transition: width 0.8s ease-out, background-color 0.3s ease;
  position: relative;
  overflow: hidden;
}

.controls_wrapper {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.reflection {
  pointer-events: none;
  will-change: transform;
}

.improve_button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  /* padding: 0.5rem 1rem; */
  border: none;
  border-radius: 8px;
  background: linear-gradient(135deg, #6B4DC4, #8B5CF6);
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.improve_icon {
  font-size: 1.125rem;
}

/* Modal Styles */
.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(17, 24, 39, 0.85);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 1rem;
}

.modal_content {
  background: #ffffff;
  border-radius: 16px;
  width: 100%;
  max-width: 700px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(107, 77, 196, 0.1);
}

.modal_header {
  padding: 1.5rem;
  background: linear-gradient(to right, #6B4DC4, #8B5CF6);
  border-radius: 16px 16px 0 0;
  color: white;
}

.header_content {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.modal_icon {
  font-size: 1.5rem;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
  padding: 0.5rem;
  border-radius: 12px;
}

.header_text h3 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
}

.header_text p {
  margin: 0.25rem 0 0;
  font-size: 0.875rem;
  opacity: 0.9;
}

.close_button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: white !important;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
}

.modal_body {
  flex: 1;
  overflow-y: auto;
  padding: 1.5rem;
  background: #f8fafc;
}

.modal_section {
  background: #ffffff;
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(107, 77, 196, 0.1);
}

.section_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.section_header h4 {
  font-size: 1rem;
  font-weight: 600;
  color: #1f2937;
  margin: 0;
}

.text_counter {
  font-size: 0.875rem;
  color: #6B7280;
}

.text_counter_warning {
  color: #EF4444;
}

.editable_text {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  font-size: 0.875rem;
  line-height: 1.5;
  resize: vertical;
  min-height: 100px;
}

.tips_list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tips_list li {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 0.5rem 0;
  font-size: 0.875rem;
  color: #4B5563;
}

.tip_bullet {
  color: #6B4DC4;
  font-size: 1.25rem;
  line-height: 1;
}

.pro_badge {
  background: linear-gradient(135deg, #F59E0B, #FBBF24);
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 600;
}

/* AI Improvements Section */
.improvements_section {
  margin-top: 1.5rem;
}

.improvement_card {
  background: #ffffff;
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid rgba(107, 77, 196, 0.1);
  transition: all 0.2s ease;
}

.improvement_card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.version_badge {
  background: rgba(107, 77, 196, 0.1);
  color: #6B4DC4;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 500;
}

.metric_badge {
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 600;
}

.percentage {
  background: rgba(16, 185, 129, 0.1);
  color: #059669;
}

.number {
  background: rgba(59, 130, 246, 0.1);
  color: #2563EB;
}

.currency {
  background: rgba(245, 158, 11, 0.1);
  color: #D97706;
}

.use_button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background: transparent;
  border: 1px solid #6B4DC4;
  color: #6B4DC4;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.use_button:hover {
  background: #6B4DC4;
  color: white;
}

/* Loading Spinner */
.spinner {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Styles */
@media (max-width: 640px) {
  .modal_overlay {
    padding: 0;
  }

  .modal_content {
    height: 100vh;
    max-height: none;
    border-radius: 0;
  }

  .modal_header {
    border-radius: 0;
  }

  .modal_section {
    padding: 1rem;
  }

  .improvement_card {
    padding: 1rem;
  }
}

.enhancementHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
  margin-bottom: 20px;
  gap: 16px;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.headerContent {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.titleGroup {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 32px;
}

.titleGroup h4 {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #1A1A1A;
  letter-spacing: -0.02em;
  line-height: 1;
}

.titleIcon {
  color: #6366F1;
  font-size: 20px;
  /* display: flex; */
  /* align-items: center; */
  margin-right: 10px;
}

.pillBadge {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 12px;
  background: rgba(99, 102, 241, 0.1);
  border-radius: 100px;
  font-size: 13px;
  font-weight: 500;
  color: #6366F1;
  height: 24px;
}

.dot {
  width: 5px;
  height: 5px;
  background: #6366F1;
  border-radius: 50%;
  flex-shrink: 0;
}

.subtitle {
  margin: 0;
  font-size: 14px;
  color: #6B7280;
  line-height: 1.4;
}

.enhanceButton {
  position: relative;
  padding: 12px 24px;
  border: none;
  border-radius: 12px;
  background: linear-gradient(135deg, #6B4DC4 0%, #4D7CC4 100%);
  color: white;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(107, 77, 196, 0.2);
  min-width: 200px;
  overflow: hidden;
}

.enhanceButton:hover {
  box-shadow: 0 6px 20px rgba(107, 77, 196, 0.3);
}

.enhanceButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.buttonInner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.buttonContent {
  display: flex;
  align-items: center;
  gap: 8px;
}

.buttonIcon {
  font-size: 18px;
  color: #FFD700;
  filter: drop-shadow(0 0 4px rgba(255, 215, 0, 0.5));
}

.buttonText {
  font-weight: 600;
  letter-spacing: 0.5px; 
  color: #fff !important;
}

.reenhanceText {
  display: flex;
  align-items: center;
  gap: 6px;
}

.sparkle {
  display: inline-block;
  font-size: 16px;
}

.loadingState {
  display: flex;
  align-items: center;
  gap: 12px;
}

.loadingRipple {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
}

.loadingRipple div {
  position: absolute;
  border: 2px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loadingRipple div:nth-child(2) {
  animation-delay: -0.5s;
}

.creditCounter {
  display: flex;
  align-items: center;
}

.creditBadge {
  background: rgba(255, 255, 255, 0.15);
  padding: 4px 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 4px;
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.creditAmount {
  font-weight: 700;
  font-size: 14px;
}

.creditLabel {
  font-size: 12px;
  opacity: 0.9;
}

@keyframes ripple {
  0% {
    top: 12px;
    left: 12px;
    width: 0;
    height: 0;
    opacity: 0.5;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 24px;
    height: 24px;
    opacity: 0;
  }
}

/* Add a subtle gradient animation to the button background */
.enhanceButton::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

.enhanceButton:hover::before {
  left: 100%;
}

/* Add a pulsing effect to the button when loading */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

.enhanceButton:disabled {
  animation: pulse 2s infinite;
}

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {
  .enhanceButton {
    width: 100%; /* Full width on mobile */
    min-width: unset;
    padding: 10px 16px; /* Slightly smaller padding */
    font-size: 14px; /* Smaller font size */
  }

  .buttonInner {
    justify-content: space-between; /* Space out elements */
    gap: 8px;
  }

  .buttonContent {
    gap: 6px;
  }

  .buttonIcon {
    font-size: 16px; /* Smaller icon */
  }

  .creditBadge {
    padding: 3px 8px; /* Smaller padding */
    font-size: 12px; /* Smaller font */
  }

  .creditAmount {
    font-size: 12px;
  }

  .creditLabel {
    font-size: 10px;
  }

  .loadingState {
    gap: 8px;
    font-size: 13px;
  }

  .loadingRipple {
    width: 20px; /* Smaller loading animation */
    height: 20px;
  }

  .sparkle {
    font-size: 14px; /* Smaller sparkle */
  }

  /* Stack elements vertically if space is too tight */
  @media screen and (max-width: 360px) {
    .buttonInner {
      flex-direction: column;
      gap: 6px;
    }

    .creditCounter {
      width: 100%;
      justify-content: center;
    }

    .enhanceButton {
      padding: 12px 16px; /* Increase vertical padding for stacked layout */
    }
  }
}

/* Add smooth transitions for responsive changes */
.enhanceButton,
.buttonInner,
.buttonContent,
.creditBadge {
  transition: all 0.3s ease;
}

.headerTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* margin-bottom: 10px; */
}

 

:root {
  --primary-color: #6B4DC4;
  --warning-color: #FF6B6B;
  --success-color: #4CAF50;
  --medium-color: #FFD93D;
}

.progressDashboard {
  
  display: flex;
  gap: 24px;
  padding: 8px;
  /* position: absolute; 
  right: 0;
  top:  -30px; */
}

.progressMetrics {
  /* display: flex;
  gap: 20px; */
  position: relative;
}

.progressCard {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 16px 24px;
  border-radius: 16px;
  background: #FFFFFF;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  position: absolute;
  width: 250px; 
  right: 0; 
  top: -80px;
  /* z-index: 100;  */
}

.progressCard::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, 
    rgba(107, 77, 196, 0.5),
    rgba(107, 77, 196, 1)
  );
  transition: all 0.3s ease;
}

.progressCard:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 30px rgba(107, 77, 196, 0.15); 
  z-index: 100;
}

.progressRing {
  position: relative;
  width: 60px;
  height: 60px;
}

.progressRing svg {
  transform: rotate(-90deg);
}

.progressBg {
  stroke: rgba(0, 0, 0, 0.1);
}


.progressFill {
  /* transform-origin: center;
  transform: rotate(-90deg);
  transition: stroke-dashoffset 0.5s ease; */
  stroke-linecap: round;
}

.lowProgress .progressFill {
  stroke: #FF6B6B;
}

.mediumProgress .progressFill {
  stroke: #FFD93D;
}

.highProgress .progressFill {
  stroke: #4CAF50;
}

.progressValue {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: 600;
  color: #394056;
}

.progressInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.progressStatus {
  font-size: 14px;
  font-weight: 600;
  color: #394056;
}

.remainingItems {
  position: relative;
}

.remainingItems > span {
  font-size: 13px;
  color: #6B4DC4;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}

.itemsList {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border-radius: 12px;
  padding: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  min-width: 220px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.3s ease;
  z-index: 100;
}

.remainingItems:hover .itemsList {
  opacity: 1;
  visibility: visible;
  transform: translateY(5px);
}

.itemTag {
  padding: 6px 12px;
  background: #F8F9FA;
  border-radius: 6px;
  font-size: 13px;
  color: #6B4DC4;
  margin-bottom: 4px;
  transition: all 0.2s ease;
}

.itemTag:hover {
  background: #EFF1F5;
}

/* Animations for different progress states */
.lowProgress {
  animation: pulseWarning 2s infinite;
}

.highProgress {
  animation: celebrateProgress 1s ease-out;
}

@keyframes pulseWarning {
  0% { box-shadow: 0 4px 20px rgba(255, 107, 107, 0.1); }
  50% { box-shadow: 0 4px 25px rgba(255, 107, 107, 0.2); }
  100% { box-shadow: 0 4px 20px rgba(255, 107, 107, 0.1); }
}

@keyframes celebrateProgress {
  0% { transform: scale(1); }
  50% { transform: scale(1.02); }
  100% { transform: scale(1); }
}

/* Responsive design */
@media (max-width: 768px) {
  .progressCard {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 16px;
  }
  
  .progressInfo {
    align-items: center;
  }
  
  .itemsList {
    left: 50%;
    transform: translateX(-50%) translateY(10px);
  }
  
  .remainingItems:hover .itemsList {
    transform: translateX(-50%) translateY(5px);
  }
}

/* Desktop View */




.progressRing {
  position: relative;
  display: inline-flex;
}

.progressBg {
  stroke: #eee;
}

.progressFill {
  stroke-linecap: round;
  transition: stroke 0.3s ease;
}

.progressValue {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  font-size: 14px;
  font-weight: 600;
}

/* .lowProgress {
  stroke: #FF6B6B;
}

.mediumProgress {
  stroke: #FFD93D;
}

.highProgress {
  stroke: #4CAF50;
} */

  /* Add these styles to your existing CSS */

.completeProgress {
  border-color: rgba(76, 175, 80, 0.5) !important;
  box-shadow: 0 0 30px rgba(76, 175, 80, 0.2) !important;
  background: rgba(76, 175, 80, 0.05) !important;
}

.completeMark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  width: 30px;
  height: 30px;
}

.checkmark {
  width: 100%;
  height: 100%;
  transform: rotate(-0deg) !important;
}

.celebrationWrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}

.celebrationText {
  color: #4CAF50;
  font-weight: 500;
  font-size: 11px;
}

.confetti {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 50px;
  height: 50px;
}

.confettiPiece {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: linear-gradient(
    45deg,
    #4CAF50,
    #45a049,
    #388e3c
  );
}

.confettiPiece:nth-child(1) { background: #4CAF50; }
.confettiPiece:nth-child(2) { background: #FFD700; }
.confettiPiece:nth-child(3) { background: #FF6B6B; }
.confettiPiece:nth-child(4) { background: #40C4FF; }
.confettiPiece:nth-child(5) { background: #FF4081; }
.confettiPiece:nth-child(6) { background: #7C4DFF; }

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.progressFill.completeProgress {
  stroke: #4CAF50;
  animation: pulse 2s infinite;
}

/* Enhance existing hover effect for complete state */
.progressCard.completeProgress:hover {
  transform: translateY(-4px);
  box-shadow: 0 0 40px rgba(76, 175, 80, 0.3) !important;
}

/* display none in mobile view */
@media (max-width: 768px) {
  .progressCard {
    display: none;
  }
}

.showMoreButton {
  width: 100%;
  padding: 10px;
  margin-top: 12px;
  background: linear-gradient(to right, #f8f9ff, #f1f4ff);
  border: 1px solid #e5e7ff;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.showMoreButton:hover {
  background: linear-gradient(to right, #f1f4ff, #e8edff);
  border-color: #d8dcff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07);
}

.showMoreContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.showMoreText {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #6B4DC4;
}

.itemCount {
  background-color: #6B4DC4;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
}

/* Add subtle animation for the gradient */
@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.showMoreButton:hover {
  background-size: 200% 200%;
  animation: gradientShift 3s ease infinite;
}

.benefitsSection {
  margin-top: 1rem;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 8px;
}

.benefitsText {
  font-size: 0.9rem;
  color: #555;
}

.benefitsList {
  margin-top: 0.5rem;
  list-style: none;
  padding-left: 0;
}

.benefitsList li {
  margin: 0.5rem 0;
  font-size: 0.85rem;
  color: #666;
}


.titleIconM {
  display: inline-block;
  margin-right: 8px;
  font-size: 1.2em;
}

.titleTextM {
  font-weight: 600;
  background: #8061DD;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.badge {
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  margin-left: 12px;
  font-size: 0.75rem;
  font-weight: 500;
  color: #4f46e5;
  background: rgba(79, 70, 229, 0.1);
  border-radius: 9999px;
}

.headtitlparagraphM {
  color: #6b7280;
  font-size: 0.95rem;
  margin-top: 4px;
}

/* Update the date input styles to match text input */
 
.date_container {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background-color: #f8faff;
  border-radius: 6px;
  border: 1px solid #cbd5ec;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.05);
  width: 100%;
}

.date_input_wrapper {
  position: relative;
  flex: 1;
  /* min-width: 110px; */
}

.date_input_wrapper input[type="text"] {
  width: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  color: #535D79;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  font-family: "inter";
}

.date_input_wrapper input[type="text"]:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.hidden_date_input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: -1;
}

.date_separator {
  color: #cbd5ec;
  margin: 0 4px;
}

.end_date_section {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
}

.present_checkbox {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-left: auto;
  white-space: nowrap;
  margin-top: 6px;
}

.present_checkbox input[type="checkbox"] {
  width: 14px;
  height: 14px;
  margin: 0;
  accent-color: #6B4DC4;
  cursor: pointer;
  flex: none;
  margin-top: 2px;
}

.present_checkbox label {
  font-size: 13px;
  color: #535D79;
  cursor: pointer;
  margin: 0;
}

/* Remove any conflicting styles */
.date_input {
  position: relative;
  display: flex;
  flex-direction: column;
}

/* Remove these if they're not being used */
.date_range,
.end_date_wrapper {
  display: none;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .date_container {
    /* flex-direction: column; */
    align-items: stretch;
    gap: 12px;
    padding: 12px;
  }

  .date_separator {
    display: none;
  }

  .end_date_section {
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
  }

  .present_checkbox {
    margin-left: 0;
    margin-top: 4px;
  }

  .date_input_wrapper {
    width: 100%;
  }
}

.input_date input
 {

  border: none;
  outline: none;
  font-size: 14px;
  line-height: 1.6;
  color: #535D79;
  min-width: none;
  margin: 4px 0;
  padding: 8px;
  background-color: #f8faff;
  margin-bottom: -2px;
  border-radius: 6px;
  border: 1px solid #cbd5ec;
  /* box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.05); */
  font-family: "inter";
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(15, 23, 42, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(8px);
}

.customModal {
  background: white;
  border-radius: 24px;
  width: 90%;
  max-width: 680px;
  height: auto;
  max-height: 85vh;
  position: relative;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.modalContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modalHeader {
  padding: 24px 32px;
  border-bottom: 1px solid #E2E8F0;
  background: white;
  position: relative;
}

.titleWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;
}

.titleWrapper h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  background: linear-gradient(135deg, #6366F1, #8B5CF6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.titleIcon {
  font-size: 28px;
}

.subtitle {
  margin: 0;
  color: #64748B;
  font-size: 14px;
}

.closeButton {
  position: absolute;
  top: 24px;
  right: 24px;
  background: #F1F5F9;
  border: none;
  color: #64748B;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
}

.closeButton:hover {
  background: #E2E8F0;
  color: #1E293B;
  transform: rotate(90deg);
}

.modalBody {
  flex: 1;
  overflow-y: auto;
  padding: 24px 32px;
}

.emptyState {
  text-align: center;
  padding: 48px 24px;
}

.emptyStateIcon {
  width: 64px;
  height: 64px;
  background: linear-gradient(135deg, #6366F1, #8B5CF6);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 24px;
  color: white;
  font-size: 32px;
}

.skillsContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.skillsMetrics {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 16px;
  background: #F8FAFC;
  border-radius: 12px;
}

.metricItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.metricValue {
  font-size: 24px;
  font-weight: 600;
  color: #6366F1;
}

.metricLabel {
  font-size: 13px;
  color: #64748B;
}

.metricDivider {
  width: 1px;
  height: 40px;
  background: #E2E8F0;
}

.skillsScrollArea {
  overflow-y: auto;
  max-height: 400px;
  padding-right: 8px;
}

.skillsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 12px;
  padding: 4px;
}

.skillPill {
  background: #F8FAFC;
  border: 1px solid #E2E8F0;
  border-radius: 12px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  cursor: pointer;
  transition: all 0.2s;
}

.skillPill:hover {
  background: #F1F5F9;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.skillPill.selected {
  background: #EEF2FF;
  border-color: #6366F1;
}

.skillName {
  font-size: 14px;
  font-weight: 500;
  color: #1E293B;
  flex: 1;
}

.pillIcon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border: 1px solid #CBD5E1;
  color: #64748B;
  transition: all 0.2s;
}

.selectedIcon {
  background: #6366F1;
  border-color: #6366F1;
  color: white;
}

.modalFooter {
  padding-top: 16px;
  border-top: 1px solid #E2E8F0;
  display: flex;
  justify-content: flex-end;
}

.regenerateButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 20px;
  background: linear-gradient(135deg, #6366F1, #8B5CF6);
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.regenerateButton:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(99, 102, 241, 0.4);
}

.regenerateIcon {
  font-size: 14px;
}

.generateButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 14px 28px;
  background: linear-gradient(135deg, #6366F1, #8B5CF6);
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin: 24px auto 0;
  transition: all 0.2s;
}

.generateButton:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(99, 102, 241, 0.4);
}

.loadingState {
  display: flex;
  align-items: center;
  gap: 8px;
}

.spinner {
  width: 18px;
  height: 18px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top-color: white;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Custom Scrollbar */
.skillsScrollArea {
  scrollbar-width: thin;
  scrollbar-color: #CBD5E1 #F8FAFC;
}

.skillsScrollArea::-webkit-scrollbar {
  width: 6px;
}

.skillsScrollArea::-webkit-scrollbar-track {
  background: #F8FAFC;
  border-radius: 3px;
}

.skillsScrollArea::-webkit-scrollbar-thumb {
  background: #CBD5E1;
  border-radius: 3px;
}

.skillsScrollArea::-webkit-scrollbar-thumb:hover {
  background: #94A3B8;
}

.loadingState {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}

.loadingAnimation {
  text-align: center;
  padding: 32px;
}

.loadingIcon {
  width: 80px;
  height: 80px;
  background: linear-gradient(135deg, #6366F1, #8B5CF6);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 24px;
  color: white;
  font-size: 40px;
  animation: pulse 2s infinite;
}

.loadingText h3 {
  color: #1E293B;
  font-size: 20px;
  margin: 0 0 8px;
}

.loadingText p {
  color: #64748B;
  font-size: 14px;
  margin: 0;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(99, 102, 241, 0.4);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(99, 102, 241, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(99, 102, 241, 0);
  }
}

.CertificationSuggestion {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 12px;
  background: linear-gradient(165deg, #ffffff 0%, #fcfcff 100%);
  padding: 1.2rem;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(124, 105, 232, 0.15);
  box-shadow: 
    0 4px 16px rgba(124, 105, 232, 0.08),
    0 1px 3px rgba(124, 105, 232, 0.1);
}

.CertificationSuggestionHeading {
  font-size: 12px;
  font-weight: 600;
  color: #7c69e8;
  margin-bottom: 1.2rem;
  letter-spacing: 1.5px;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  padding-left: 12px;
}

.CertificationSuggestionHeading::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 16px;
  background: linear-gradient(to bottom, #7c69e8, #43ffe6);
  border-radius: 4px;
}

.certificationGrid {
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0.5rem;
}

.certificationGrid::before,
.certificationGrid::after {
  content: '';
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  filter: blur(40px);
  opacity: 0.1;
  z-index: 0;
}

.certificationGrid::before {
  background: #7c69e8;
  top: -20px;
  left: -20px;
}

.certificationGrid::after {
  background: #43ffe6;
  bottom: -20px;
  right: -20px;
}

.certificationCard {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(8px);
  border-radius: 10px;
  padding: 1.2rem 1.8rem;
  position: relative;
  width: 100%;
  max-width: 400px;
  box-shadow: 
    0 4px 12px rgba(124, 105, 232, 0.08),
    0 2px 4px rgba(124, 105, 232, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(124, 105, 232, 0.1);
  z-index: 1;
}

.certificationCard::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    transparent 0%,
    rgba(124, 105, 232, 0.03) 50%,
    transparent 100%
  );
  border-radius: inherit;
}

.certName {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 1.4;
  text-align: center;
  background: linear-gradient(90deg, #394056, #7c69e8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cursor {
  display: inline-block;
  width: 2px;
  height: 1.2em;
  background: linear-gradient(to bottom, #7c69e8, #43ffe6);
  margin-left: 4px;
  vertical-align: middle;
  border-radius: 1px;
  animation: blink 1s step-end infinite;
  box-shadow: 0 0 8px rgba(124, 105, 232, 0.4);
}


/* Add these styles to your CSS module */
.date_input_wrapper input[type="month"] {
  appearance: none;
  -webkit-appearance: none;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 14px;
  color: #333;
  background-color: #fff;
  width: 100%;
  cursor: pointer;
  font-family: inherit;
}



.datepicker {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #cbd5ec;
  border-radius: 6px;
  font-size: 14px;
  color: #535D79;
  background-color: #f8faff;
  cursor: pointer;
  font-family: "inter";
}

.datepicker:disabled {
  background-color: #f0f2f7;
  cursor: not-allowed;
}

.react-datepicker {
  font-family: "inter", sans-serif;
  border: 1px solid #cbd5ec;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.react-datepicker__header {
  background-color: #f8faff;
  border-bottom: 1px solid #cbd5ec;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-top: 12px;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
  padding: 4px 8px;
  border: 1px solid #cbd5ec;
  border-radius: 4px;
  background-color: white;
}

.react-datepicker__month-container {
  float: left;
  background: white;
  border-radius: 8px;
}

.react-datepicker__month {
  margin: 0.4em;
  text-align: center;
}

.react-datepicker__day--selected {
  background-color: #6B4DC4 !important;
  color: white !important;
}

.react-datepicker__day:hover {
  background-color: #f0f2f7;
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .datepicker {
    font-size: 16px;
    padding: 10px 12px;
  }
  
  .react-datepicker__month-container {
    width: 100%;
  }
  
  .react-datepicker__month {
    margin: 0.2em;
  }
}

/* Custom styling for mobile */
@media (max-width: 768px) {
  .date_input_wrapper input[type="month"] {
    font-size: 16px; /* Larger font size for mobile */
    padding: 10px 12px; /* Larger touch target */
  }
}

/* Style the calendar icon */
.date_input_wrapper input[type="month"]::-webkit-calendar-picker-indicator {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B4DC4' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='3' y='4' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Cline x1='16' y1='2' x2='16' y2='6'%3E%3C/line%3E%3Cline x1='8' y1='2' x2='8' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='10' x2='21' y2='10'%3E%3C/line%3E%3C/svg%3E");
  cursor: pointer;
  width: 20px;
  height: 20px;
  padding: 4px;
}

/* Remove default calendar icon in Edge */
.date_input_wrapper input[type="month"]::-ms-clear,
.date_input_wrapper input[type="month"]::-ms-reveal {
  display: none;
}

@keyframes blink {
  from, to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .CertificationSuggestion {
    padding: 1rem;
    margin: 0.8rem 0 1.2rem;
  }
  
  .certificationCard {
    padding: 1rem 1.4rem;
  }
  
  .certName {
    font-size: 14px;
  }
  
  .certificationGrid::before,
  .certificationGrid::after {
    width: 80px;
    height: 80px;
    filter: blur(30px);
  }
}

@media (max-width: 480px) {
  .CertificationSuggestion {
    padding: 0.8rem;
    margin: 0.6rem 0 1rem;
  }
  
  .certificationCard {
    padding: 0.8rem 1.2rem;
  }
  
  .certName {
    font-size: 13px;
  }
}

.doneButton {
  background-color: #22c55e;
  color: white;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 6px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

@media (max-width: 640px) {
  .ModalButtonContainer {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 0 8px;
  }

  .editButton,
  .regenerateButton,
  .doneButton {
    width: 100%;
    padding: 8px 12px;
    font-size: 13px;
    justify-content: center;
  }

  .buttonIcon {
    font-size: 12px;
  }
}

/* For even smaller screens */
@media (max-width: 380px) {
  .editButton,
  .regenerateButton,
  .doneButton {
    padding: 6px 10px;
    font-size: 12px;
  }

  .buttonIcon {
    font-size: 11px;
  }
}
