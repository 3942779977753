.pageWrapper {
  background: linear-gradient(135deg, #f8fafc 0%, #eef2ff 100%);
  background-image: 
    radial-gradient(circle at 25px 25px, rgba(99, 102, 241, 0.1) 2%, transparent 0%),
    radial-gradient(circle at 75px 75px, rgba(168, 85, 247, 0.1) 2%, transparent 0%);
  background-size: 100px 100px;
  min-height: 100vh;
  padding: 4rem 1rem;
  position: relative;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  border-radius: 24px;
}

.floatingBadge {
  position: absolute;
  top: 24px;
  right: 24px;
  background: linear-gradient(45deg, #6366f1, #a855f7);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 100px;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-weight: 600;
  font-size: 0.875rem;
  box-shadow: 
    0 4px 6px rgba(79, 70, 229, 0.2),
    0 0 0 2px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  z-index: 10;
}

.badgeIcon {
  animation: pulse 2s infinite;
}

.badgeTimer {
  background: rgba(255, 255, 255, 0.15);
  padding: 0.25rem 0.5rem;
  border-radius: 100px;
  font-size: 0.75rem;
  font-weight: 500;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.header {
  text-align: center;
  margin-bottom: 4rem;
}

.title {
  font-size: 3rem;
  font-weight: 800;
  background: linear-gradient(45deg, #6366f1, #a855f7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
  letter-spacing: -0.02em;
  line-height: 1.2;
}

.titleAccent {
  font-size: 2.5rem;
  margin-left: 0.5rem;
}

.subtitle {
  font-size: 1.25rem;
  color: #64748b;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
}

.highlight {
  background: linear-gradient(45deg, #6366f1, #a855f7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.periodToggle {
  display: inline-flex;
  background: #f1f5f9;
  padding: 0.5rem;
  border-radius: 100px;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.periodButton {
  padding: 0.75rem 1.5rem;
  border-radius: 100px;
  border: none;
  background: transparent;
  color: #64748b;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
}

.activePeriod {
  background: white;
  color: #1e293b;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.saveBadge {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #4f46e5;
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 100px;
  font-size: 0.75rem;
  font-weight: 600;
}

.plansContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}

.planCard {
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  border-radius: 24px;
  border: 1px solid rgba(99, 102, 241, 0.1);
  box-shadow: 
    0 4px 6px -1px rgba(0, 0, 0, 0.05),
    0 10px 15px -3px rgba(0, 0, 0, 0.1);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  position: relative;
}

.planCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
}

.popularPlan {
  border: 2px solid transparent;
  background-image: 
    linear-gradient(white, white), 
    linear-gradient(45deg, #6366f1, #a855f7);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  transform: scale(1.02);
}

.popularBadge {
  position: absolute;
  top: 12px;
  right: 12px;
  background: linear-gradient(45deg, #6366f1, #a855f7);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: 0 4px 15px rgba(168, 85, 247, 0.3);
  animation: shimmer 2s infinite;
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  z-index: 2;
}

@keyframes shimmer {
  0% {
    background: linear-gradient(45deg, #6366f1, #a855f7);
  }
  50% {
    background: linear-gradient(45deg, #a855f7, #6366f1);
  }
  100% {
    background: linear-gradient(45deg, #6366f1, #a855f7);
  }
}

.popularBadge svg {
  animation: starPulse 1.5s ease-in-out infinite;
}

@keyframes starPulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.planHeader {
  padding: 2rem;
  color: white;
  text-align: center;
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, rgba(99, 102, 241, 0.1), rgba(168, 85, 247, 0.1));
    z-index: 0;
  }
}

.planName {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.pricing {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 0.25rem;
  margin-bottom: 0.5rem;
}

.currency {
  font-size: 1.5rem;
  font-weight: 500;
}

.amount {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
}

.period {
  font-size: 1rem;
  opacity: 0.8;
}

.discount {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  margin-top: 0.5rem;
}

.originalPrice {
  text-decoration: line-through;
  opacity: 0.7;
}

.saveTag {
  background: rgba(255, 255, 255, 0.2);
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  font-size: 0.875rem;
}

.planFeatures {
  padding: 2rem;
}

.feature {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
  color: #475569;
  font-size: 0.95rem;
}

.checkIcon {
  color: #4f46e5;
  flex-shrink: 0;
}

.planButton {
  background: linear-gradient(45deg, #6366f1, #a855f7);
  color: white;
  font-weight: 600;
  padding: 1rem 2rem;
  border-radius: 12px;
  border: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(79, 70, 229, 0.2);
  width: calc(100% - 4rem);
  margin: 0 2rem 1.5rem;
  background-size: 200% 200%;
  animation: gradient-shift 4s ease infinite;
}

.planButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(79, 70, 229, 0.3);
}

.popularButton {
  background: linear-gradient(45deg, #6366f1, #a855f7);
  color: white;
}

.guaranteeSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
  color: #64748b;
  font-size: 0.875rem;
  border-top: 1px solid #e2e8f0;
}

.trustSection {
  background: linear-gradient(to right, rgba(255,255,255,0.9), rgba(255,255,255,0.95), rgba(255,255,255,0.9));
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 2rem;
  margin-top: 4rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(99, 102, 241, 0.1);
}

.trustGrid {
  display: flex;
  justify-content: center;
  gap: 3rem;
  align-items: center;
}

.trustCard {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.trustIconWrapper {
  width: 40px;
  height: 40px;
  background: linear-gradient(45deg, #6366f1, #a855f7);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trustIcon {
  color: white;
  width: 20px;
  height: 20px;
}

.trustCard span {
  color: #64748b;
  font-size: 0.9rem;
  font-weight: 500;
}

.testimonialSection {
  margin-top: 4rem;
  text-align: center;
}

.testimonialSection h2 {
  font-size: 2rem;
  color: #1e293b;
  margin-bottom: 2rem;
}

.testimonialGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 2rem;
}

.testimonialCard {
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
  border: 1px solid rgba(99, 102, 241, 0.1);
  &:hover {
    border-color: transparent;
    background-image: 
      linear-gradient(white, white), 
      linear-gradient(45deg, rgba(99, 102, 241, 0.2), rgba(168, 85, 247, 0.2));
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }
}

.testimonialHeader {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.testimonialAvatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}

.testimonialInfo h4 {
  font-weight: 600;
  margin: 0;
}

.testimonialInfo p {
  color: #64748b;
  margin: 0;
  font-size: 0.9rem;
}

.testimonialContent {
  color: #334155;
  line-height: 1.6;
  font-size: 0.95rem;
}

.testimonialRating {
  color: #a855f7;
  display: flex;
  gap: 2px;
  margin-top: 1rem;
  justify-content: center;
}

@media (max-width: 768px) {
  .pageWrapper {
    padding: 2rem 1rem;
  }

  .container {
    padding: 2rem 1rem;
  }

  .title {
    font-size: 2.25rem;
  }

  .floatingBadge {
    top: 16px;
    right: 16px;
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
  }

  .plansContainer {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .popularPlan {
    transform: none;
  }

  .trustGrid {
    flex-direction: column;
    gap: 1.5rem;
  }

  .testimonialSection {
    margin-top: 4rem;
    padding-top: 3rem;
  }

  .testimonialTitle {
    font-size: 1.75rem;
  }

  .testimonialGrid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .badgeTimer {
    display: none;
  }
}

/* Add a subtle animation for the trust cards */
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}

.trustCard:hover {
  animation: float 2s ease-in-out infinite;
}

.guarantee {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin: 1.5rem;
  padding: 1rem;
  background: linear-gradient(to right, rgba(99, 102, 241, 0.05), rgba(168, 85, 247, 0.05));
  border-radius: 12px;
  border: 1px solid rgba(99, 102, 241, 0.1);
  transition: all 0.3s ease;
}

.guaranteeIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: linear-gradient(45deg, #6366f1, #a855f7);
  border-radius: 8px;
  color: white;
  flex-shrink: 0;
}

.guaranteeContent {
  display: flex;
  flex-direction: column;
}

.guaranteeTitle {
  color: #1e293b;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25;
}

.guaranteeText {
  color: #64748b;
  font-size: 0.75rem;
  margin-top: 0.125rem;
}

.guarantee:hover {
  background: linear-gradient(to right, #f1f5f9, #e2e8f0);
  border-color: #cbd5e1;
}

@media (max-width: 768px) {
  .guarantee {
    margin: 1rem;
    padding: 0.75rem;
  }
  
  .guaranteeIcon {
    width: 28px;
    height: 28px;
  }
  
  .guaranteeTitle {
    font-size: 0.8125rem;
  }
  
  .guaranteeText {
    font-size: 0.6875rem;
  }
}

@keyframes gradient-shift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}