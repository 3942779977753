@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
.main {
    height: 100vh;
    background: url(https://studio.softr.io/media/shapes-4Y7OLELW.svg);
    background-repeat: no-repeat;
    background-size: 150%;
    /* background-size: cover; */
    background-position: center;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;

}
.box {
    width: 450px;
    box-shadow: 0 3px 16px rgba(40, 25, 45, .08);
    position: relative;
    padding: 40px;
    background: white;
    border-radius: 16px;
    margin: 28px 0px;
}
.title{
    text-align: center;
    margin-bottom: 32px;
    font-weight: 700;
    font-size: 24px;
    font-family: Inter;

}

.para {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 32px;
}
.button {
    width: 100%;
    background-color: #FAFAFC;
    box-sizing: border-box;
    font-style: normal;
    font-family: Inter;
    color: #212121;
    appearance: none;
    padding: 9px 16px;
    border: 1px solid #F0F0F4;
    border-radius: 8px;
    font-variant: none;
}

.btn {
    margin-top: 32px;
    height: 48px;
    font-size: 16px;
    cursor: pointer;  
    color: #28192D;
    background-color: #EEA941;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    width: 100%;
    cursor: pointer;
    font-family: Inter;
    height: 40px;
    border-radius: 8px;
}

.down {
    position: absolute;
    font-weight: 500;
    left: 0px;
    right: 0px;
    bottom: -65px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    color:#383B3D;
    font-family: Inter;

}
.signup {
    color: #4D85D5;;
    cursor: pointer;
}

 