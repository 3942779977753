@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.bodys{
     /* background:linear-gradient(132deg, #5003b1, #0f062c 25%, #0f062c 74%, #5003b1); */
     /* background-color: red; */
     
     padding: 0px!important;
     border-radius: 40px !important;
   
     font-family: Arial, sans-serif;
    }

.close {
    
    display: flex;
    justify-content: end;
    position: relative;
    top:-1rem;
    right: -1rem;
}

.closeButton {
    /* color:white; */
    cursor: pointer;
    /* background-color: #4e4e4e; */
    /* background: linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%); */
     height: 2rem;
     width: 2rem;
    border-radius: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border:2px solid white; */
    /* box-shadow: 0 0 20px 2px rgba(0,0,0,.3); */
}
 
.priceDiv{
    height: 20rem;
    border: 1px solid #ddd;
    border-radius: 20px;
    box-shadow: 0 8px 16px hsla(0,0%,69%,.2);
    cursor: pointer;
    overflow: hidden;
    transition: all .2s;
    padding: 0;
}

.priceDiv:hover {
    transform: translateY(-2px);
    border-color: black;
}
.iconcut{
    /* color:white; */
    cursor: pointer;
} 
.iconcut:hover{ 
  
  /* rotate 90 degree */
  transform: rotate(90deg); 
  /* give smotth rotation  */
  transition: transform 0.5s;
  

}

 

.heading{
    font-size: 2rem;
    font-weight: 700;
    color: #000;
    font-family: "Montserrat", sans-serif;
    line-height: 40px;
}

@media only screen and (max-width: 600px) {
    .bodys{
    background-image: none;
    }
    .heading{ 
        font-size: 1.5rem;
        line-height: 34px;
    }
}
.subheading {
    margin-top: 20px ;
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
}

.planheader {
    background: #000;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase;
    
}
.buttons {
    border: none;
    border-radius: 6px;
    box-shadow: 0 0 15px transparent;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    font-size: 13px;
    font-weight: 700;
    height: 20px;
    justify-content: center;
    min-height: 30px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    text-decoration: none;
    transition: all .15s linear;
    user-select: none;
    white-space: nowrap;
    text-transform: none;
    /* border-radius: 50px; */
    background: #111;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.ddo {
    display: flex;
    justify-content: center;
    align-items: center;
}
.featuresContainer {
     
    border-radius: 8px;
    padding: 15px 20px;
    margin-bottom: 20px;
  }
  
  .featuresList {
    list-style-type: none;
    padding-left: 0;
    font-size: 0.95rem;
    color: #666;
  }
  
  .featuresList li {
    margin-bottom: 10px;
  }
  
  .featuresList li strong {
    color: #333;
  }
  
  .paymentContainer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  
  .termsContainer {
    margin-top: 15px;
    font-size: 0.85rem;
    color: #777;
  }
  .second {
    align-items: flex-start;
    background: #f3f2fd;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 32px;
    padding-top: 32px;
    gap: 32px;
     min-height: 460px;
     border-radius: 9px;
  } 

  .price {
    display: flex;
    flex-direction: column; 
    justify-content: space-between;
    gap: 40px;
   
    padding: 30px;
    
  } 
  .payy {
    display: flex;
    flex-direction: column;
    justify-content:  center;
    width: 100%;

  }
  .navbarheading{
    font-size: 25px;
    font-weight: 700;
    letter-spacing: "0.8px";
    color: black; 
    position: relative;
  }
  .number {
    font-size: 48px;
    line-height: 56px;
    font-weight: 500;
  }
  .bewbumb{
    font-size: 24px;
    line-height: 28px; 
    font-weight: 500;
  }
  .ddool {
    display: flex;
     flex-direction: column;
   
    gap: 4px
  } 
  .dldod {
    display: flex;
    align-items: baseline;
     
    gap: 4px
  }
  .hh {
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
  }
  .ff{
    font-size: 11px;
  }
  .list {
    font-size: 13px; 
    display: flex;
    justify-content: space-between;
  }
  .ul li {
    list-style: none;
  }
 .ul {
   display: flex;
   flex-direction: column;
   gap: 10px;
   margin-top: 1rem;
  }
  .premium {
    font-size: 13px;
    color:  rgb(83, 69, 255);
    position: absolute;
    top: 1.6rem;
    right: 0rem;
    font-weight: 700;
  }
  .ddlo {
    
    position: relative;
    
  }
  .billngperiond {
    font-size: 13px;
    color: #9d9696ee
    
  }
  .pay {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: flex-start;
   padding: 10px;
  }
  .payyy {
    border-radius: 5px;
    border:1px solid #e9e9e9;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }
  .ffo {
    font-weight: 400; 
    /* padding: 10px; */
  }
  .dddodod {
    background-color: #514eea;
    border-radius: 4px;
    color: #fff;
    font-size: 9px;
    font-weight: 500;
    padding: 0 4px;
    text-transform: uppercase;
    margin-left: 3px;
  }
  .active {
    border-color: #514eea;
  } 
  .pro {
    color: #514eea;
    font-weight: 600;
  }
  .dsfs {
    font-size: 16px;
    font-weight: 500;
    color: #3f3f3f;
  }
  .mondyback {
    font-size: 10px;
    font-weight: 700;
    color: rgb(17, 133, 17); 
    display: flex;
    justify-content: center;
    /* align-items: center; */
    width: 100%;
  }
  .notlogin {
    background: linear-gradient(to left top, rgb(156, 62, 255), rgb(83, 69, 255));
    color: white;
    width: 100%;
    font-weight: 500; 
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .4rem;
    border-radius: 10px; 
    cursor: pointer;
  }

.modalWrapper :global(.modal-content) {
  border-radius: 16px;
  border: none;
  background: #f8fafc;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.modernBody {
  padding: 0;
  position: relative;
  overflow: hidden;
}

.closeBtn {
  position: absolute;
  top: 16px;
  right: 16px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: #64748b;
  font-size: 1rem;
  z-index: 10;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentWrapper {
  display: flex;
  min-height: 400px;
}

.leftSection {
  flex: 1;
  padding: 2rem;
  background: linear-gradient(135deg, #4f46e5 0%, #7c3aed 100%);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.badge {
  background: rgba(255, 255, 255, 0.15);
  padding: 0.5rem 1rem;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 0.875rem;
}

.crownIcon {
  color: #ffd700;
}

.title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  line-height: 1.2;
}

.subtitle {
  font-size: 1.25rem;
  opacity: 0.9;
  margin-bottom: 2.5rem;
  line-height: 1.6;
  font-weight: 400;
}

.pricingBox {
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  border-radius: 12px;
  margin-bottom: 1.5rem;
}

.priceTag {
  display: flex;
  align-items: baseline;
  gap: 0.25rem;
  margin-bottom: 0.25rem;
}

.currency {
  font-size: 1.25rem;
}

.amount {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1;
}

.period {
  font-size: 1rem;
  opacity: 0.8;
}

.savings {
  font-size: 0.75rem;
  opacity: 0.8;
}

.upgradeBtn {
  background: white;
  color: #4f46e5;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 12px;
  font-weight: 600;
  font-size: 0.875rem;
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.buttonIcon {
  color: #4f46e5;
}

.rightSection {
  flex: 1;
  padding: 2rem;
  background: white;
  display: flex;
  flex-direction: column;
}

.featuresTitle {
  font-size: 1.25rem;
  font-weight: 700;
  color: #1e293b;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.rocketIcon {
  color: #4f46e5;
}

.featuresList {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.featureItem {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.featureItem:hover {
  background: #f1f5f9;
}

.checkIcon {
  color: #4f46e5;
  flex-shrink: 0;
}

.featureContent {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.featureTitle {
  font-weight: 500;
  color: #1e293b;
  font-size: 0.875rem;
}

.featureDescription {
  color: #64748b;
  font-size: 0.875rem;
}

@media (max-width: 768px) {
  .contentWrapper {
    flex-direction: column;
  }
  
  .leftSection, .rightSection {
    padding: 1.5rem;
  }
  
  .title {
    font-size: 1.5rem;
  }
  
  .featuresList {
    margin-bottom: 2rem;
  }
  
  .pricingBox {
    margin: 2rem 0;
  }
}