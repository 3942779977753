.data-sharing-container {
    display: flex;
    min-height: 100vh;
    background: linear-gradient(135deg, #1a1f35 0%, #131b2e 100%);
    padding: 2.5rem;
    color: #fff;
  }
  
  .content-wrapper {
    flex: 3;
     
    margin-right: 2.5rem;
  }
  
  .hero-section {
    display: flex;
    gap: 3rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 24px;
    padding: 2.5rem;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 2.5rem;
  }
  
  .hero-content {
    flex: 1;
  }
  
  .hero-title {
    font-size: 2.8rem;
    color: #fff;
    margin-bottom: 1.2rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    font-weight: 700;
    letter-spacing: -0.5px;
    background: linear-gradient(135deg, #64b5f6 0%, #2196f3 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .title-icon {
    font-size: 2.4rem;
    filter: drop-shadow(0 2px 4px rgba(33, 150, 243, 0.3));
  }
  
  .hero-description {
    font-size: 1.2rem;
    line-height: 1.7;
    margin-bottom: 2.5rem;
    color: #a0aec0;
  }
  
  .stats-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    margin-top: 2rem;
  }
  
  .stat-item {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 1.5rem;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: transform 0.3s ease;
  }
  
  .stat-item:hover {
    transform: translateY(-5px);
    background: rgba(255, 255, 255, 0.08);
  }
  
  .stat-item svg {
    font-size: 1.8rem;
    color: #2196f3;
    margin-bottom: 0.8rem;
  }
  
  .stat-item span {
    display: block;
    font-size: 1.8rem;
    font-weight: 700;
    color: #fff;
    margin-bottom: 0.3rem;
  }
  
  .stat-item p {
    color: #a0aec0;
    font-size: 0.9rem;
  }
  
  .warning-title {
    font-size: 3.5rem;
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  @media (max-width: 768px) {
    .warning-title {
      font-size: 2.2rem;
      margin-bottom: 1rem;
      gap: 0.3rem;
    }
  }
  
  @media (max-width: 480px) {
    .warning-title {
      font-size: 1.8rem;
    }
  }
  
  .warning-subheader {
    font-size: 1.2rem;
    color: #a0aec0;
    margin-bottom: 1.5rem;
  }
  
  .point {
    background: rgba(255, 255, 255, 0.05);
    padding: 1rem;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
    margin-bottom: 0.8rem;
  }
  
  .point:hover {
    transform: translateY(-2px);
    background: rgba(255, 255, 255, 0.08);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .point-title {
    font-size: 1.2rem;
    color: #64b5f6;
    margin-bottom: 0.5rem;
    display: block;
  }
  
  .point-description {
    color: #a0aec0;
    line-height: 1.4;
  }
  
  .cta-text {
    color: #ffffff;
  }
  
  .stat-icon {
    color: #4dabf7;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .stat-number {
    color: #ffffff;
    font-size: 1.8rem;
    font-weight: bold;
  }
  
  .stat-label {
    color: #e0e0e0;
    margin: 0.5rem 0;
  }
  
  .stat-subtitle {
    color: #b0b0b0;
    font-size: 0.9rem;
  }
  
 
  
  .video-wrapper {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    width: 360px;
    aspect-ratio: 9/16;
    background: #000;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  .video-wrapper {
 
 
    max-width: 100%; /* Ensure it doesn't exceed the container width */
    overflow: hidden; /* Hide any overflow */
  }
  

  
  .video-wrapper video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
 
  
  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    opacity: 0;
    transition: all 0.4s ease;
  }
  
  .video-wrapper:hover .video-overlay {
    opacity: 1;
  }
  
  .play-icon {
    font-size: 4rem;
    color: #fff;
    filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.4));
    transform: translateY(10px);
    transition: all 0.4s ease;
  }
  
  .features-section {
    margin-bottom: 2.5rem;
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.8rem;
  }
  
  .feature-card {
    background: rgba(255, 255, 255, 0.05);
    padding: 2rem;
    border-radius: 16px;
    text-align: center;
    transition: all 0.4s ease;
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
  }
  
  .feature-card:hover {
    transform: translateY(-8px);
    background: rgba(255, 255, 255, 0.08);
  }
  
  .feature-card svg {
    font-size: 2.4rem;
    color: #8061DD;
    margin-bottom: 1.2rem;
    filter: drop-shadow(0 2px 4px rgba(33, 150, 243, 0.3));
  }
  
  .feature-card h3 {
    color: #fff;
    margin-bottom: 0.8rem;
    font-size: 1.3rem;
    font-weight: 600;
  }
  
  .feature-card p {
    color: #a0aec0;
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .upload-section {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 24px;
    padding: 2.5rem;
    text-align: center;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .section-title {
    font-size: 2rem;
    color: #fff;
    margin-bottom: 1.2rem;
    font-weight: 700;
  }
  
  .upload-description {
    color: #a0aec0;
    margin-bottom: 2rem;
    font-size: 1.1rem;
    line-height: 1.6;
  }
  
  .upload-button, .analyze-button {
    display: inline-flex;
    align-items: center;
    gap: 0.8rem;
    padding: 1rem 2rem;
    font-size: 1.1rem;
    color: #fff;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
  }
  
  .upload-button {
    background: linear-gradient(135deg, #2196f3 0%, #1976d2 100%);
    box-shadow: 0 4px 15px rgba(33, 150, 243, 0.3);
  }
  
  .analyze-button {
    background: linear-gradient(135deg, #00bcd4 0%, #0097a7 100%);
    box-shadow: 0 4px 15px rgba(0, 188, 212, 0.3);
  }
  
  .pdf-info {
    margin-top: 2rem;
    padding: 1.5rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .pdf-name {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    color: #fff;
    font-weight: 500;
    font-size: 1.1rem;
  }
  
  .sidebar {
    flex: 1;
    max-width: 340px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 24px;
    padding: 2rem;
    height: fit-content;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .sidebar-section {
    margin-bottom: 2.5rem;
  }
  
  .sidebar-title {
    font-size: 1.5rem;
    color: #fff;
    margin-bottom: 1.5rem;
    padding-bottom: 0.8rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .story-card {
    background: rgba(255, 255, 255, 0.05);
    padding: 1.5rem;
    border-radius: 12px;
    margin-bottom: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .story-header {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    margin-bottom: 0.8rem;
  }
  
  .story-icon {
    color: #2196f3;
    font-size: 1.2rem;
  }
  
  .story-header h3 {
    color: #fff;
    font-size: 1.1rem;
    font-weight: 500;
  }
  
  .story-card p {
    color: #a0aec0;
    font-size: 0.9rem;
    line-height: 1.6;
  }
  
  .role-item {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    margin-bottom: 0.8rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
  }
  
  .role-item:hover {
    transform: translateX(8px);
    background: rgba(255, 255, 255, 0.08);
  }
  
  .role-item svg {
    color: #2196f3;
  }
  
  .role-item span {
    color: #fff;
    font-size: 1rem;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .data-sharing-container {
      flex-direction: column;
      padding: 1.5rem;
    }
    
    .content-wrapper {
      margin-right: 0;
      margin-bottom: 2rem;
      max-width: 100%;
    }
    
    .hero-section {
      flex-direction: column;
      padding: 1rem;
    }
    
    .video-wrapper {
      margin: 0 auto;
    }
    
    .sidebar {
      max-width: 100%;
    }
    
    .features-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 640px) {
    .stats-grid,
    .features-grid {
      grid-template-columns: 1fr;
    }
    
    .hero-title {
      font-size: 2.2rem;
    }
    
    .upload-button, 
    .analyze-button {
      width: 100%;
      justify-content: center;
    }
    
    .pdf-info {
      flex-direction: column;
      gap: 1rem;
      text-align: center;
    }
  }

  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s ease;
  }
  
  .video-overlay:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .job-listings {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .job-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    padding: 1.2rem;
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }

  .job-card:hover {
    transform: translateY(-5px);
    background: rgba(255, 255, 255, 0.08);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  }

  .company-info {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .company-logo {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    object-fit: contain;
    background: rgba(255, 255, 255, 0.1);
    padding: 0.5rem;
    flex-shrink: 0;
  }

  .company-details {
    flex: 1;
  }

  .company-details h3 {
    font-size: 1.1rem;
    margin: 0 0 0.3rem 0;
    color: #fff;
    font-weight: 500;
  }

  .company-name {
    color: #a0aec0;
    font-size: 0.9rem;
  }

  .job-details {
    margin-left: 3.5rem;
  }

  .job-meta {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.8rem;
  }

  .salary {
    color: #4ade80;
    font-weight: 600;
    font-size: 0.95rem;
  }

  .location {
    color: #a0aec0;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    font-size: 0.9rem;
  }

  .job-tags {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
  }

  .tag {
    background: rgba(255, 255, 255, 0.1);
    padding: 0.25rem 0.75rem;
    border-radius: 999px;
    font-size: 0.8rem;
    color: #e0e0e0;
    backdrop-filter: blur(10px);
  }

  .posted-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .posted-date {
    color: #a0aec0;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }

  .apply-btn {
    background: #8061DD;
    color: white;
    border: none;
    padding: 0.5rem 1.2rem;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 0.9rem;
    font-weight: 500;
    box-shadow: 0 4px 15px rgba(33, 150, 243, 0.2);
  }

  .apply-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(33, 150, 243, 0.3);
  }

  /* Responsive styles */
  @media (max-width: 640px) {
    .job-details {
      margin-left: 0;
    }

    .job-meta {
      flex-direction: column;
      gap: 0.5rem;
    }

    .posted-info {
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start;
    }

    .apply-btn {
      width: 100%;
    }
  }

  .button-group {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin: 1rem 0;
  }

  .download-button {
    background-color: #4CAF50; /* or any other color you prefer */
  }

  .download-button:hover {
    background-color: #45a049;
  }
  .cta-banner {
    background: linear-gradient(135deg, #2196f3 0%, #1976d2 100%);
    padding: 1.5rem;
    border-radius: 12px;
    color: white;
    text-align: center;
    margin-top: 1.5rem;
    box-shadow: 0 4px 15px rgba(33, 150, 243, 0.3);
  }

  .cta-button {
    background: rgba(255, 255, 255, 0.1);
    color: white;
    padding: 1rem 2rem;
    border-radius: 30px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    font-weight: bold;
    margin-top: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    backdrop-filter: blur(10px);
  }

  .cta-button:hover {
    background: rgba(255, 255, 255, 0.15);
    transform: translateY(-2px);
  }

  .icon {
    font-size: 2rem;
    margin-right: 1rem;
    filter: drop-shadow(0 2px 4px rgba(33, 150, 243, 0.3));
  }

  .fire-icon {
    font-size: 2rem;
    margin-bottom: 1rem;
    display: inline-block;
  }

  .key-points {
    display: grid;
    gap: 0.8rem;
  }

  .view-more-btn {
    background: rgba(255, 255, 255, 0.1);
    color: #8061DD;
    padding: 0.5rem 1.2rem;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    font-weight: bold;
    margin-top: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    backdrop-filter: blur(10px);
  }

  .view-more-btn:hover {
    background: rgba(255, 255, 255, 0.15);
    transform: translateY(-2px);
  }

  .ai-features {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }

  .feature-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.08);
  }

  .feature-item:hover {
    transform: translateX(8px);
    background: rgba(255, 255, 255, 0.08);
  }

  .feature-icon-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background: rgba(33, 150, 243, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  .feature-icon {
    color: #2196f3;
    font-size: 1.2rem;
  }

  .feature-content h3 {
    color: #fff;
    font-size: 1rem;
    margin: 0 0 0.2rem 0;
  }

  .feature-content p {
    color: #a0aec0;
    font-size: 0.9rem;
    margin: 0;
    line-height: 1.4;
  }

  .feature-badge {
    background: rgba(76, 175, 80, 0.2);
    color: #4caf50;
    padding: 0.3rem 0.8rem;
    border-radius: 20px;
    font-size: 0.9rem;
    font-weight: 500;
  }

  .try-ai-btn {
    width: 100%;
    padding: 1rem;
    background: #8061DD;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 0.9rem;
    font-weight: 500;
    box-shadow: 0 4px 15px rgba(33, 150, 243, 0.2);
  }

  .try-ai-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(33, 150, 243, 0.3);
  }
  .ai-builder-section {
    padding: 3rem 2rem;
    background: linear-gradient(to right, #f8f9fa, #ffffff);
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    margin: 2rem 0;
  }
  
  .ai-builder-content {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    gap: 3rem;
    align-items: center;
  }
  
  .ai-builder-text {
    padding-right: 2rem;
  }
  
  .section-title {
    font-size: 2.2rem;
    font-weight: 700;
    color: #2d3748;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  
  .title-icon {
    color: #4299e1;
    font-size: 1.8rem;
  }
  
  .section-description {
    font-size: 1.1rem;
    color: #4a5568;
    line-height: 1.6;
    margin-bottom: 2rem;
  }
  
  .builder-features {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .feature-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.1rem;
    color: #4a5568;
  }
  
  .feature-icon {
    color: #4299e1;
    font-size: 1.2rem;
  }
  
  .builder-actions {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
  
  .primary-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.875rem 1.5rem;
    background-color: #4299e1;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .primary-button:hover {
    background-color: #3182ce;
  }
  
  .arrow-icon {
    font-size: 1rem;
    margin-left: 0.5rem;
  }
  
  .time-estimate {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #718096;
    font-size: 0.9rem;
  }
  
  .clock-icon {
    color: #718096;
  }
  
  .ai-builder-preview {
    position: relative;
  }
  
  .preview-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .preview-header {
    padding: 1rem;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .live-badge {
    background-color: #48bb78;
    color: white;
    padding: 0.25rem 0.75rem;
    border-radius: 999px;
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .preview-content {
    padding: 1.5rem;
  }
  
  .preview-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .ai-builder-content {
      grid-template-columns: 1fr;
    }
  
    .ai-builder-text {
      padding-right: 0;
    }
  
    .preview-card {
      margin-top: 2rem;
    }
  }
  
  @media (max-width: 768px) {
    .ai-builder-section {
      padding: 2rem 1rem;
    }
  
    .builder-actions {
      flex-direction: column;
      align-items: stretch;
    }
  
    .time-estimate {
      text-align: center;
    }
  }
  .ai-builder-section {
    padding: 3rem 2rem;
    background: #111827; /* Dark background matching existing theme */
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    margin: 2rem 0;
    border: 1px solid #3a3a3a;
  }
  
  .ai-builder-content {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    gap: 3rem;
    align-items: center;
  }
  
  .section-title {
    font-size: 2.2rem;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  
  .title-icon {
    color: #60a5fa;
    font-size: 1.8rem;
  }
  
  .section-description {
    font-size: 1.1rem;
    color: #d1d5db;
    line-height: 1.6;
    margin-bottom: 2rem;
  }
  
  .feature-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.1rem;
    color: #e2e8f0;
    background: rgba(255, 255, 255, 0.05);
    padding: 1rem;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .feature-icon {
    color: #60a5fa;
    font-size: 1.2rem;
  }
  
  .builder-actions {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
  
  .primary-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.875rem 1.5rem;
    background-color: #60a5fa;
    color: #1a1a1a;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .primary-button:hover {
    background-color: #3b82f6;
  }
  
  .arrow-icon {
    font-size: 1rem;
    margin-left: 0.5rem;
  }
  
  .time-estimate {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #9ca3af;
    font-size: 0.9rem;
  }
  
  .clock-icon {
    color: #9ca3af;
  }
  
  .ai-builder-preview {
    position: relative;
  }
  
  .preview-card {
    background: #2d2d2d;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    border: 1px solid #3a3a3a;
  }
  
  .preview-header {
    padding: 1rem;
    border-bottom: 1px solid #3a3a3a;
    background: #262626;
  }
  
  .live-badge {
    background-color: #059669;
    color: white;
    padding: 0.25rem 0.75rem;
    border-radius: 999px;
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .preview-content {
    padding: 1.5rem;
  }
  
  .preview-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    border: 1px solid #3a3a3a;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .ai-builder-content {
      grid-template-columns: 1fr;
    }
  
    .ai-builder-text {
      padding-right: 0;
    }
  
    .preview-card {
      margin-top: 2rem;
    }
  }
  
  @media (max-width: 768px) {
    .ai-builder-section {
      padding: 2rem 1rem;
    }
  
    .builder-actions {
      flex-direction: column;
      align-items: stretch;
    }
  
    .time-estimate {
      text-align: center;
    }
  }
  .ai-builder-section {
    padding: 3rem 2rem;
    background: #111827; /* Dark background matching existing theme */
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    margin: 2rem 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .ai-builder-content {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    gap: 3rem;
    align-items: center;
  }
  
  .section-title {
    font-size: 2.2rem;
    font-weight: 700;
    color: #f3f4f6; /* Light text color */
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    justify-content: center;
  }
  
  .title-icon {
    color: #3b82f6; /* Blue accent color */
    font-size: 1.8rem;
  }
  
  .section-description {
    font-size: 1.1rem;
    color: #9ca3af; /* Gray text color */
    line-height: 1.6;
    margin-bottom: 2rem;
  }
  
  .feature-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.1rem;
    color: #e5e7eb;
    background: rgba(55, 65, 81, 0.5); /* Slightly lighter background */
    padding: 1rem;
    border-radius: 8px;
    border: 1px solid rgba(75, 85, 99, 0.5);
    margin-bottom: 1rem;
  }
  
  .feature-icon {
    color: #8061DD; /* Blue accent color */
    font-size: 1.2rem;
  }
  
  .primary-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.875rem 1.5rem;
    background-color: #8061DD; /* Blue accent color */
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .primary-button:hover {
    background-color: #2563eb;
  }
  
  .time-estimate {
    color: #9ca3af; /* Gray text color */
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .preview-card {
    background: #1f2937; /* Slightly lighter than main background */
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(75, 85, 99, 0.5);
  }
  
  .preview-header {
    padding: 1rem;
    border-bottom: 1px solid rgba(75, 85, 99, 0.5);
    background: rgba(31, 41, 55, 0.8);
  }
  
  .live-badge {
    background-color: #059669; /* Green accent */
    color: white;
    padding: 0.25rem 0.75rem;
    border-radius: 999px;
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .ai-builder-content {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 768px) {
    .ai-builder-section {
      padding: 2rem 1rem;
    }
  
    .builder-actions {
      flex-direction: column;
      align-items: stretch;
    }
  }
  .highlight {
    color: #007bff;
    font-weight: 600;
  }
  
  .warning-description {
    font-size: 1.1rem;
    color: #666;
    margin-top: 0.5rem;
    line-height: 1.4;
  }
 

 

  .job-badge {
    font-size: 0.75rem;
    padding: 4px 12px;
    border-radius: 20px;
    font-weight: 500;
    letter-spacing: 0.3px;
    display: inline-flex;
    align-items: center;
  }
  
  .featured {
    background-color: rgba(79, 70, 229, 0.1); /* Light indigo background */
   
    border: 1px solid rgba(79, 70, 229, 0.2);
    transition: all 0.2s ease;
  }
  
  .featured:hover {
    background-color: rgba(79, 70, 229, 0.15);
  }
  
 


  .video-container {
    margin: 1rem 0;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .feature-video {
    display: block;
    max-width: 100%;
    height: auto;
  }
  .badges-wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  
  .trending-badge {
    background-color: #fff3e0;  /* Light orange/peach background */
    color: #ff6b00;            /* Darker orange text */
    border: 1px solid #ffe0b2; /* Subtle border */
    animation: pulse 2s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

  .gradient-text {
    background: linear-gradient(90deg, #2563eb, #7c3aed);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  
  .accent-text {
    color: #6366f1;
    font-weight: 500;
  }
  
  .warning-title {
    font-size: 2.5rem;
    line-height: 1.2;
    letter-spacing: -0.02em;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
  }
  
  .warning-icon {
    font-size: 2rem;
    animation: pulse 2s infinite;
  }
  
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
  }

  .title-wrapper {
    position: relative;
    text-align: center;
    padding: 2rem 0;
  }

  .warning-title {
    font-size: 3.5rem;
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .gradient-text {
    background: linear-gradient(135deg, #3b82f6, #8b5cf6, #ec4899);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .highlight-text {
    color: #2563eb;
    font-weight: 700;
    text-shadow: 0 0 20px rgba(37, 99, 235, 0.2);
  }

  .floating-emoji {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2.5rem;
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5));
  }

  .title-badges {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 1.5rem;
  }

  .badge {
    
    backdrop-filter: blur(10px);
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    font-weight: 500;
    color: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .badge:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
  }

  .warning-subheader {
    font-size: 1.5rem;
    color: #4b5563;
    text-align: center;
    margin-top: 1.5rem;
    font-weight: 500;
  }

  .highlight {
    color: #2563eb;
    font-weight: 700;
    padding: 0 0.3rem;
    position: relative;
  }

  .highlight::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background: rgba(37, 99, 235, 0.1);
    z-index: -1;
    border-radius: 4px;
  }

  /* Add a subtle animation for the gradient */
  @keyframes gradient {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }

  .gradient-text {
    background-size: 200% auto;
    animation: gradient 5s ease infinite;
  }

  /* DataSharing.module.css */

/* Button styles */
.upload-button {
    background: linear-gradient(90deg, #4e54c8, #8f94fb); /* Gradient background */
    color: #fff; /* Text color */
    border-radius: 25px; /* Rounded corners */
    padding: 10px 20px; /* Padding */
    border: none; /* No border */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Shadow effect */
    cursor: pointer; /* Pointer cursor */
    display: flex; /* Flexbox for alignment */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    transition: transform 0.2s ease; /* Smooth transition for hover effects */
  }
  
  .upload-button:hover {
    transform: scale(1.05); /* Scale up on hover */
  }
  
  .upload-button:active {
    transform: scale(0.95); /* Scale down on tap */
  }
  
  .loading-icon {
    animation: spin 1s linear infinite; /* Loading spinner animation */
    margin-right: 10px; /* Space between icon and text */
  }
  
  /* Keyframes for loading spinner */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  /* Add this to your DataSharing.module.css */

/* Dark Mode Styles for Download Button Wrapper */

.download-button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align items */
    padding: 20px; /* Add padding for spacing */
    background: rgba(55, 65, 81, 0.5);
    border-radius: 8px; /* Rounded corners */
    border: 1px solid rgba(75, 85, 99, 0.5);
  }
  
  .download-description {
    text-align: center; /* Center text */
    margin-bottom: 15px; /* Space between description and button */
  }
  
  .download-title {
    font-size: 1.5rem; /* Larger font size for title */
    font-weight: bold; /* Bold title */
    color: #ffffff; /* White color for title */
  }
  
  .download-text {
    font-size: 1rem; /* Standard font size for text */
    color: #cccccc; /* Light gray color for description text */
    margin-top: 5px; /* Space above description text */
  }
  
  .upload-button {
    background-color: #007bff; /* Primary button color */
    color: white; /* Text color */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    padding: 10px 20px; /* Padding for button */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
  }
  
  .upload-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  
  .loading-icon {
    margin-right: 5px; /* Space between icon and text */
  }

/* Add this to your CSS file */

 
 
/* Modal Overlay */
.custom-modal-overlay {
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 15px; /* Add padding to ensure modal doesn't touch screen edges */
}

/* Modal Content */
.custom-modal {
    background: linear-gradient(135deg, #1a1a1a, #2d2d2d);
    border-radius: 20px;
    width: 100%;
    max-width: 900px;
    max-height: 90vh; /* Prevent modal from being taller than viewport */
    overflow-y: auto; /* Enable scrolling if content is too tall */
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

/* Content Wrapper for split layout */
.modal-content-wrapper {
    display: flex;
    gap: 30px;
    padding: 30px;
}

/* Left side content */
.modal-content {
    flex: 1;
    padding-right: 20px;
}

/* Right side video */
.modal-video {
    flex: 1;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    min-height: 400px;
    background: #000;
}

.features-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

/* Close Button */
.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #fff;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.close-button:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: rotate(90deg);
}

/* Modal Title */
.modal-title {
    margin: 0 0 20px 0;
    font-size: 32px;
    font-weight: 700;
    background: linear-gradient(135deg, #8061DD, #60a5fa);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 1.2;
}

/* Modal Description */
.modal-description {
    font-size: 16px;
    color: #e0e0e0;
    line-height: 1.6;
    margin-bottom: 25px;
}

/* Features Section */
.features-section {
    background: rgba(255, 255, 255, 0.05);
    padding: 20px;
    border-radius: 12px;
    margin: 20px 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Key Features Title */
.modal-key-features {
    font-weight: 600;
    color: #fff;
    margin-bottom: 15px;
    font-size: 18px;
}

/* Modal Features List */
.modal-features-list {
    padding-left: 20px;
    color: #e0e0e0;
    margin: 0;
}

.modal-features-list li {
    margin-bottom: 10px;
    line-height: 1.4;
}

/* Modal Button */
.modal-close-button {
    background: #8061DD;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.3s ease;
    width: 100%;
    margin-top: 20px;
}

.modal-close-button:hover {
    background: #6f50d0;
    transform: translateY(-2px);
}

/* Responsive Design */
@media (max-width: 768px) {
    .modal-content-wrapper {
        flex-direction: column;
    }

    .modal-content {
        padding-right: 0;
    }

    .modal-video {
        min-height: 300px;
        display: none;
    }

    .custom-modal {
        padding: 20px;
    }

    .modal-title {
        font-size: 24px;
    }
}

.ai-title-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .ai-icon {
    color: #4F46E5;
    filter: drop-shadow(0 0 8px rgba(79, 70, 229, 0.3));
  }
  
  .ai-text {
    background: linear-gradient(135deg, #4F46E5, #06B6D4);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: bold;
    text-shadow: 0 2px 4px rgba(79, 70, 229, 0.2);
  }
  @media (max-width: 768px) {
    .warning-title {
      font-size: 2.2rem; /* Smaller font size for mobile */
      margin-bottom: 1rem; /* Reduced margin */
      gap: 0.3rem; /* Smaller gap between flex items */
    }
    .badge {
    
        backdrop-filter: blur(10px);
        padding: 0.5rem 0.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .warning-title {
      font-size: 1.8rem; /* Even smaller for very small devices */
    }
  }