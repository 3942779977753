 

.maincontainer{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    /* background-color: #f6f6f6; */
    gap: 10px;
    background: #F6F8FA
}



.leftcontainer{
    width: 65%;
    
    
}

@media screen and (max-width: 737px) { 
  
    .leftcontainer{
        width: 95%;
        
        
    }
}

.Rightcontainer{
    width: 23%;
   
} 


.topContianer {
    /* background-color: #f2f0ff;  */
    background: linear-gradient(114deg, #485ece -9.09%, #210b70 97.53%);
    height: 107px;
    
    overflow: hidden;
    display: flex;
    justify-content: center;
   
}

.textcontainer{
 
    color: white;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}
.mainhe{
    font-size: 26px;
    font-weight: 600;
}
.paragr{
    font-size: 13px;
    font-weight: 500;
    line-break: 18px;
}
.imgsection{
    display: flex;
    justify-content: end;
    align-items: end;
   
}

.topimage{
    height: 7rem;
    
}



.leftcontanier{
    margin-top: 2rem;
}
.compnaydesccont{
   
    padding: 1rem;
    border-left: 4px solid rgb(160, 102, 255);
    display: flex;
    flex-direction: column;
    /* border-radius: 10px; */
}
.compnaytitle{
    font-size: 36px;
    font-weight: 600;
    line-height: 130%;
    margin-bottom: 0;
    margin-top: 1rem;
}

.imagecontainerdetails{
  height: 100px;
 width: 100px;
 background-color: white;
 display: flex;
 justify-content: center;
 align-items: center;
 border-radius: 8px;
}

.imagesmain{
  width: 90px;
}

.descrr{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.ccss{
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;
}

.jondescrptionconta {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    position: relative;
}

.jondescrption {
    display: flex;
    flex-direction: column;
 
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    position: relative;
}

.customcontent span{
    font-size: 14px !important;
}

.customcontent li{
    font-size: 14px !important;
    padding: 0px !important;
    margin: 0px;
}

@media screen and (max-width: 737px) {
    /* Your CSS rules for laptop screens go here */
    .Rightcontainer{
        display: none !important;
    }
     
    .mainhe{
        font-size:14px;
         position: relative;
         left: 1rem;
    }
    .jondescrption {
        padding: 0rem;
     }
   .paragr{
    display: none;
   }
    .topimage{
        height: 5.3rem;
        width: 10rem;
        
    }

    .compnaytitle{
        font-size: 16px; }

        .customcontent ul{
            font-size: 14px !important;
            padding: 5px !important;
            margin: 0px;
        }
        .topContianer {
            height: 80px;
        }
        .imagecontainerdetails{
            height: 60px;
            width: 60px;
            border-radius: 8px;
        }

        .leftcontanier{
            margin-top: 1rem;
        }
}

.leftside{
    height: 1.6rem;
    background-color: rgb(160, 102, 255);
    position: absolute;
    width: 4px;
    border-radius: 10px;
    left: 0;
}

.dfss{
    display: flex;
    gap: 10px;
    justify-content: center;
}
.ddef{
    
    display: flex;
    flex-direction: column;
    gap: 10px; 
    width: 50%;
}
.innter{
    border: 1px solid #DADCE0;  
      border-radius: 12px;
      padding: 16px;
      min-height: 100px;
      background-color: white;
      display: flex;
      justify-content: space-between;
      
} 
.innter1{ 
    font-size: 16px;
    line-height: 120%;
    margin-bottom: 12px;
    color: var(--themeHeading);
    font-weight: 500;
}

.ddea {
    color: #727272;
    font-weight: 500;
}

.imagg{
    width: 5rem;
    object-fit: cover;
}

@media screen and (max-width: 737px) { 
    .dfss{ 
        flex-direction: column;
    }
   .ddef{
  width: 100%;
   }
   .innter1{
    font-size: 14px;
   }
   .ddea {
    font-size: 13px;
   }
}

.applyConatiner{
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 12px 12px 5px 5px;
    background-color: white;
    padding: 16px;
}

.coureseConatiner{
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 5px;
    background-color: white;
    padding: 16px;
}

.resumeConatiner{
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 5px;
    background-color: white;
    padding: 16px; 
    position: relative;
    overflow: hidden;
}
.rightmaincontainer{
    margin-top: 3rem;
    position: sticky;
    top: 6rem;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.iconcontainer{
    display: flex;
    justify-content: space-between;
}

.applybutton{
    background: #fb246a;
    border-radius: 8px;
    color: #fff;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    padding: 0 10px;
    position: relative;
    white-space: nowrap;
    flex: 1;
    cursor: pointer;
    text-decoration: none;

}

.sharebutton{
    border-radius: 8px;
    border: 1px solid #E2E2E2;
    cursor: pointer;
    height: 40px;
    padding-left: 12px;
    padding-right: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    color: #383838;
    font-size: 14px;
}

.hightleif {
    background: #f7f8fa; 
    padding: 2px 10px;
    color: gray; 
    border-radius: 10px;
    font-size: 12px;
    text-transform: capitalize;
    display: flex ;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.dwi{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    align-items: center;
}
.cousrsub{
    display: flex;
    flex-direction: column;
    gap: 10px;

}

.innercourse {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.ddir{
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
}

.ddire{
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-direction: column;
}
.lle{
    font-size: 14px;
    font-weight: 500;
}
.ddir1{
    font-weight: 500;
    font-size: 14px;
}
.eef{
    background: #edf3f8;
    padding: 10px;
    border-radius: 10px;
    font-size: 12px;
    text-align: center;
    margin-top: 2rem;
}
.eeff{
   
    padding: 10px;
    border-radius: 10px;
    font-size: 12px;
    text-align: center;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.hei{
    color: white;
    background: linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%);
    padding: 0.55rem;
    border-radius: 15px;
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;
}

.new{
    position: absolute;
    background-color: rgb(160, 102, 255);
    color: white;
    font-size: 10px;
    transform: rotate(-45deg);
    padding: 0.1rem;
    top: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    left: -15px;
    width:4rem;
    font-weight: 600;
}