 
.photoWrapper {
    display: flex;
    gap: 2rem;
    padding: 2rem;
    overflow: hidden;
    width: 100%;
   
}

.photo {
    border-radius: 16px;
    overflow: hidden;
    width: 90px;
    height: 90px;
    position: relative;
    flex-shrink: 0;
    transition: transform 0.25s ease;
    animation: fromlefttoright 10s linear infinite;
    & img {
        width: inherit;
        height: inherit;
        object-fit: contain;
    }

    &::after {
        content: "";
        position: absolute;
        top: -1rem;
        bottom: -1rem;
        left: -16rem;
        background: linear-gradient(90deg, transparent, white, transparent);
        width: 16rem;
        opacity: 0;
        transform: rotate(6deg);
    }

    &:hover::after {
        left: 100%;
        opacity: 0.15;
        transition: inset 1s;
    }

    &:hover {
        transform: scale(1.1) !important;
    }

    &.even {
        transform: rotate(2deg);
    }

    &:not(.even) {
        transform: rotate(-2deg);
    }
}



@keyframes fromlefttoright {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
