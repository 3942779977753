.main {
    /* background: #F6F8FA; */
    position: relative;
    overflow: hidden;
    z-index: 9;
    padding-top: 30px;
    padding-bottom: 50px;
    min-height: calc(100vh - 35px);
    display: flex;
    align-items: center; 
    background-image: url(https://res.cloudinary.com/dvepwlz9j/image/upload/v1723608213/bg-banner_bmwftx.svg);
    background-repeat: no-repeat;
    background-position: 0px 0px;
    background-size: cover;
     

} 

/* .buttonAi {
    background:linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%) ;
    font-weight: 500;
    color: white;
    margin-right: 1rem;
    color: white;
        font-weight: 500;
        padding:10px 20px 10px 10px; 
        border-radius: 50px; 
    cursor: pointer; 
    text-align: center;
} */

.imagecon::after{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    background-image: url(https://d8it4huxumps7.cloudfront.net/uploads/images/655ed96514f0e_1.png?d=1370x863);
    height: 70%;
    width: 50%;
    background-size: cover;
    background-position: top right;
    background-repeat: no-repeat;
    z-index: -1;} 

    .heading {
      font-weight: 600;
      font-size: 50px;
      /* margin-bottom: 2rem; */
    } 

    

    .findjobs{
        
        font-weight: 500;
        padding:10px 20px 10px 20px; 
        border-radius: 50px; 
        border: 1px solid rgb(160, 102, 255);
        height: 52px; 
        cursor: pointer;
    }

    
    .buttons {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.2rem;
        max-width: 650px;
        margin-top: 3rem;
    }

    .leftSection {
      
        margin-bottom: 8rem; 
        /* margin-top: 3rem; */ 
        
    }
 .truted {
    margin-top: 4rem;
    position: relative;
 }
    @media only screen and (max-width: 747px) {
        .heading {
            font-weight: 600;
            font-size: 25px;
          }  
          .leftSection > p {
            font-size: 15px;
          }

          .postjobs{
             
            font-weight: 500;
            padding:5px 11px 8px 15px; 
            margin-left: 10px; 
            color: #fb246a;
    
        } 
        .findjobs{
            padding:5px 11px 8px 15px; 
        }

        .imagecon {
            display: flex;
            justify-content: center;
             
        }
        .main {
          padding-top: 0px;
        } 
        .imagecon::after{ 
            display: none;
        }
        .leftSection {
      
            margin-bottom: 0rem; 
            /* margin-top: -1.8rem; */
        }
    }

    .lefttotla{
        display: flex;
        align-items: center;
    }
    .imgcontainer{
        width: 40px;
        height: 40px;
        border-radius: 89.6px;
        border: 1.6px solid #FFF;
        background: #d3d3d3 50% / cover no-repeat;
        box-shadow: -1.6px 1.6px 12px #00000040;
    }
    .imgss{
        overflow: hidden;
        border-radius: 89.6px;
    
    }
    .namecontainer{
        display: flex;
         flex-direction: column;
         margin-left: 10px;
          justify-content: center;
          width: 85%;
          overflow: hidden;
    }
    .names{
        font-size: 12px;
        line-height: 120%;
        margin-bottom: 4px;
        color: #747474;
    }
    .email {
     
        font-size: 12px;
        line-height: 15px;
        font-weight: 600;
        margin: 0;
    }


    .statusbar{
        margin-top: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .online {
        height: 6px;
        width: 65%;
        background-color: #dadce0;
        position: relative;
        border-radius: 8px;
      }
      .fill {
        border-radius: 8px;
        background: linear-gradient(90deg, #01a768, #05c165);
        height: 6px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        overflow: hidden;
    }
    
    .fill::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 200%;
        background: linear-gradient(90deg, #ebfdf1, #afd6c3, #01a768, #05c165);
        animation: progressAnimation 6s linear infinite;
    }
    
    @keyframes progressAnimation {
        0% {
            transform: translateX(-50%);
        }
        100% {
            transform: translateX(50%);
        }
    }
      .percent{
        font-size: 12px;
        margin-left: 6px;
      }

      .statuscontainerre{
       margin-top: 2rem;
        display: flex;
        
      }
      .statusinnerconteiner{
        width: 19rem;
        border: 2px solid #eee;
        padding: 7px 10px;
      border-radius: 10px;
      border-top:4px solid rgb(160, 102, 255) ;
      background-color: white;
    }

    .completeProfileContainer{
        display: flex;
        justify-content: center;
    }
    .completeProfileButton{
        background-color: #e5f1fc;
        margin-top: 4px;
        padding: 7.5px 10px;
        font-size: 12px;
        font-weight: 400;
        border-radius: 8px;
        cursor: pointer;
    }
    .button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.2rem 1.5rem;
        border-radius: 16px;
        font-weight: 500;
        cursor: pointer;
        backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.15);
        height: 75px;
        transition: all 0.3s ease;
    }

    .buttonContent {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: 1rem;
    }

    .buttonTitle {
        font-size: 1rem;
        font-weight: 600;
    }

    .buttonSubtitle {
        font-size: 0.8rem;
        opacity: 0.9;
        margin-top: 0.2rem;
    }

    .buttonIcon {
        width: 28px;
        height: 28px;
        object-fit: contain;
    }

    .arrowIcon {
        opacity: 0.8;
    }

    .buttonAi {
        background: linear-gradient(135deg, rgba(160, 102, 255, 0.9) 0%, rgba(102, 108, 255, 0.9) 100%);
        color: white;
        box-shadow: 0 4px 20px rgba(160, 102, 255, 0.15);
    }

    .findjobs {
        background: linear-gradient(135deg, rgba(255, 107, 107, 0.9) 0%, rgba(249, 111, 89, 0.9) 100%);
        color: white;
        box-shadow: 0 4px 20px rgba(249, 111, 89, 0.15);
    }

    .jobMatching {
        background: linear-gradient(135deg, rgba(76, 175, 80, 0.9) 0%, rgba(69, 160, 73, 0.9) 100%);
        color: white;
        box-shadow: 0 4px 20px rgba(76, 175, 80, 0.15);
    }

    .resumeAnalysis {
        background: linear-gradient(135deg, rgba(33, 150, 243, 0.9) 0%, rgba(25, 118, 210, 0.9) 100%);
        color: white;
        box-shadow: 0 4px 20px rgba(33, 150, 243, 0.15);
    }

    .button:hover {
        border-color: rgba(255, 255, 255, 0.3);
        box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
    }

    @media (max-width: 768px) {
        .buttons {
            grid-template-columns: 1fr;
            gap: 1rem;
            width: 100%;
        }
        
        .button {
            height: 65px;
            padding: 1rem;
        }
        
        .buttonTitle {
            font-size: 0.95rem;
        }
        
        .buttonSubtitle {
            font-size: 0.75rem;
        }
    }
      
.cardGrid {
    position: relative;
    z-index: 1;
}

/* Background decorative elements */
.cardGrid::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
    height: 200px;
    background-image: radial-gradient(circle, #e0e0e0 1px, transparent 1px);
    background-size: 15px 15px;
    opacity: 0.5;
    z-index: -1;
}

/* Curved line decoration */
.cardGrid::after {
    content: '';
    position: absolute;
    bottom: -50px;
    left: -50px;
    width: 200px;
    height: 200px;
    border: 2px dashed #e0e0e0;
    border-radius: 50%;
    z-index: -1;
}

/* Additional curved elements */
.curvedLine {
    position: absolute;
    width: 150px;
    height: 150px;
    border: 2px solid #f0f0f0;
    border-radius: 50%;
    z-index: -1;
}

.curvedLine1 {
    top: 20%;
    right: -75px;
    border-left: none;
    border-bottom: none;
}

.curvedLine2 {
    bottom: 10%;
    left: -75px;
    border-right: none;
    border-top: none;
}

/* Dot pattern */
.dotPattern {
    position: absolute;
    width: 150px;
    height: 150px;
    background-image: 
        radial-gradient(circle, #e0e0e0 1px, transparent 1px),
        radial-gradient(circle, #e0e0e0 1px, transparent 1px);
    background-size: 20px 20px;
    background-position: 0 0, 10px 10px;
    opacity: 0.3;
    z-index: -1;
}

.dotPattern1 {
    top: 10%;
    right: 10%;
}

.dotPattern2 {
    bottom: 10%;
    left: 10%;
}

/* Peeking character decoration */
.peekingCharacter {
    position: absolute;
    left: -130px;  /* Moved further left */
    bottom: -10%;     /* Positioned higher */
    width: 150px; /* Larger size */
    height: 150px;
    z-index: 1;
    background-image: url('https://cdn3d.iconscout.com/3d/premium/thumb/ai-robot-3d-icon-download-in-png-blend-fbx-gltf-file-formats--future-robotic-personal-robotics-chat-bot-chatting-metaverse-pack-science-technology-icons-4846837.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none; /* Prevents interference with clicks */
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .peekingCharacter {
        width: 150px;
        height: 150px;
        left: -60px;
    }
}

@media (max-width: 992px) {
    .peekingCharacter {
        width: 120px;
        height: 120px;
        left: -40px;
    }
}

@media (max-width: 768px) {
    .peekingCharacter {
        width: 100px;
        height: 100px;
        left: -20px;
        top: 10%; /* Move higher on mobile */
    }
}

@media (max-width: 576px) {
    .peekingCharacter {
        width: 80px;
        height: 80px;
        left: -10px;
        top: 5%;
    }
}

/* Optional: Hide on very small screens if it becomes too crowded */
@media (max-width: 360px) {
    .peekingCharacter {
        display: none;
    }
}

/* Add animation if desired */
@keyframes peek {
    0% { transform: translateY(-50%) translateX(-5px); }
    50% { transform: translateY(-50%) translateX(0); }
    100% { transform: translateY(-50%) translateX(-5px); }
}

.peekingCharacter {
    animation: peek 3s ease-in-out infinite;
}
      