.main {
    min-width: 100%;
    min-height: 90vh;
    border-radius: 10px;
}

.main *{
  font-family: "inter";
}
 
.do {
    color: #e3c04c; 
    margin-left: 10px;
}
.custom {
    max-width: 400px; 
  height: auto;     
  padding: 10px;    
} 
.dor {
    width: 100%;
     height: 1px;
      background-color: #ffe58f;
      margin-top: 1rem ;
}
 
.circlesection {
    padding: 1rem; 
     display: flex;  
     flex-direction: column; 
     padding-bottom: 0px;

} 
.llod {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.suffestions {
  
   padding-bottom: .5rem; 
} 
.dddk {
  font-size: 12px; 
  color: brown;
  font-weight: 700; 
  margin-bottom: 3px;
}
 

.circle {
    position: relative;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    background-color: #eee;
    overflow: hidden;
  }
  
  .halfCircle, .coverHalf {
    position: absolute;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    clip: rect(0, 5rem, 10rem, 0);
    transform-origin: center center;
  }
  
  .fill {
    position: absolute;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    /* clip: rect(0, 5rem, 10rem, 0); */
    background-color: #7dc1ee;
  }
  
  .coverHalf {
    transform: rotate(180deg);
  }
  
  .innermask {
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 8rem;
    height: 8rem;
    background-color: white;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  
  .percn {
    font-size: 20px;
    font-weight: 700;
  }
  .pregresBar {
    margin-left: 2rem;  
    width: 60%; 
    display: flex;
    justify-content: center;
    align-items: center; 
    flex-direction: column;
  }
  .flfo {
    border-radius: 16px; 
    padding-top: 20px;
    padding-bottom: 32px;
    padding-left: 24px;
    padding-right: 24px;
    cursor: pointer;
    background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */

    
  } 
  
  .flfoac {
     padding-bottom: 0px;
  }

  .flfoake {
    border: 1px solid  rgb(126, 130, 247);
  }
  .containerp{
    width: 100%;
    max-width: 400px; /* Adjust as needed */
    margin: 6px auto;
     
  }
  
  .barp {
    position: relative;
    background-color: #eee;
    height: 8px;
    border-radius: 0.75rem;
    overflow: hidden;
    border-radius: 109px;
  }
  
  .fillp {
  
    height: 100%;
    border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
    transition: width 0.3s ease;
  }
  
  .labelp {
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: 700;
  }

  .dloooo {
    font-size: 12px;
    margin-bottom: 4px;
    font-weight: bold;
  } 

  .dlooood  {
    font-size: 12px;
    margin-bottom: 4px;
    font-weight: bold;
   
  } 
  .dlooood:hover  {
    color: rgb(102, 108, 255);
  } 
.toe {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
  .headree {
    font-size: 14px;
    font-weight: 500; 
    display: flex;
    justify-content: space-between;
  } 
  .firstno {
    display: flex;
  }
  .ddepal {
    font-size: 16px;
    font-weight: 500; 
    display: flex;
    justify-content: center;
    color: #414B67;
    flex-direction: column;
  } 
  .dooso {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px; 
    color: #747779;
  }
  .llo {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .customCircuit {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  }
  .issis {
    padding: 0 20px;     
  }
  .rotr {
 
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%; 
    margin-top: 8px;
  }
  .percentagetext {
    font-size: 24px; /* Adjust size as needed */
    font-weight: 700;
    margin-bottom: 0.1rem; /* Space between percentage and "Overall Score" */
  }
  
  .scorete {
    font-size: 14px; /* Adjust size as needed */
    font-weight: 400; 
  }
   

  .full{
    max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
   font-size: 13px; 
  margin-top: 24px;
   display: flex  ;
   flex-direction: column;
   gap: 12px;
  }
  .full ul {
    margin-top: 10px;
  }
.EcahConatinerMain {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
  .sho {
    max-height: 1000px; /* Set this to a value larger than the expected content height */
    transition: max-height 0.5s ease-in-out;
  }

  .blinkdot {
    width: 5px;
    height: 5px;
    background-color: brown;
    border-radius: 50%;
    animation: blink 1s infinite; 
    margin-right: 10px;
  }
  
  .blinkdotp {
    width: 5px;
    height: 5px;
    background-color: purple;
    border-radius: 50%;
    animation: blink 1s infinite; 
    margin-right: 10px;
  }

  .blinkdotb {
    width: 5px;
    height: 5px;
    background-color: blue;
    border-radius: 50%;
    animation: blink 1s infinite; 
    margin-right: 10px;
  }

  @keyframes blink {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
  .maint { 
    position: relative;
  }
  .ddd {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  
    backdrop-filter: blur(3px);
    z-index: 10;
  }
 .blur {
  filter: blur(5px);
  pointer-events: none;
 }
  .ddodd {
    background: linear-gradient(135deg, #8B5CF6, #6366F1);
    border-radius: 24px;
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px;
    width: 90%;
    max-width: 480px;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
                0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .dd {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    margin: 0;
  }
  .paraa {
    font-size: 14px;
    line-height: 1.6;
    opacity: 0.9;
    margin: 0;
  }
  .upgradde {
    display: flex;
    justify-content: flex-end;
  }
  .eeo {
    background-color: white;
    color: #6366F1;
    padding: 12px 24px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 12px;
    transition: all 0.2s ease;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    border: none;
  }
  .eeo:hover {
    transform: translateY(-1px);
    box-shadow: 0 6px 8px -1px rgba(0, 0, 0, 0.15);
  }
  .atir {
    color: rgb(102, 108, 255);
  }
  .dorr {
    width: 100%;
     height: 1px;
      background-color: #eeee;
      margin-top: 1rem ;
}
.dok {
  color: #b8b3b3ee; 
  margin-left: 10px;
}
.button {

}

.ResumeBuilder_buttonsall__4y5cd {
  padding: .5rem;
  background-color: rgb(102, 108, 255);
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  color: white;
  display: flex;
  width: 100px;
  justify-content: center;
  align-items: center;
}

.scoreContainerMain {
  height: 112px;
  background-color: #ffffff;
  margin-bottom: 32px;
  border-radius: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 12px;
  padding-left: 12px;
  position: relative;
  z-index: 1; /* Ensure content stays above the pseudo-element */
  overflow: hidden; /* Ensure rounded corners on the gradient border */
}

.scoreContainerMain::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  padding: 1.5px; /* Adjust to control the border thickness */
  background: linear-gradient(
    90deg,
    #9f7fff 0%,
    #81c6fe 18%,
    #2530ff 37%,
    #43ffe6 56%,
    #fc58db 69%,
    #81c6fe 98%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0); /* Creates a mask for padding */
  -webkit-mask-composite: xor;
  mask-composite: exclude; /* Creates the inner transparent area */
}

.upparScoreContainer {
  /* height: 112px; */
  position: relative;
  display: flex;
}

.circularScoreContainerAnalysis {
  width: 100px;
  height: 100px;
  /* margin-right: 24px;  */
  padding: 10px;
  margin-left: 0;
  width: 30%;
}

.circularScoreContainerAnalysis :global(.CircularProgressbar) {
  margin-top: -.8rem;
  width: 100% !important;
  height: 100% !important;
  }
.ScoreContainer {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
 
}
.score {
  font-size: 18px;
  font-weight: 500;
  color: #394056;
}
.scorepara {
  font-size: 14px;
  font-weight: 400;
  color: #6B748E;
}
.SwitchATSEditorContainer {
  border: 1.5px solid #C7CFE3;
  height: 44px;
  border-radius: 10rem;
  
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.eachSwitch {
  width: 49%;
  height: 36px;
  border-radius: 10rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #535D79;
}
.activeswitch {
  background-color: #ffffff;
  color: #8061DD;
  font-weight: 500;
}
.EachInnerContainer {
  border: 2px soldi red;
}

.eachScorePercentage {
  font-size: 16px;
  font-weight: 500;
  color: #4C5671;
  width: 42px;
  background-color: #FFDFD4;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 12px;
  color: #E04132;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rightSideConatainer {
  display: flex;
  align-items: center;
  gap: 16px;
  color: #98A2BF;
}
.leftSideContainerTitle {
   color: #414B67;
   font-size: 16px;
   font-weight: 500;
}
.leftSideContainerSubTitle {
   color: #5F6880;
   font-size: 14px;
   font-weight: 400;
}
.leftSideContainerSubTitleSpan {
  color: #5F6880;
  font-size: 14px;
  font-weight: 600;
}
.innerContainerUniversal {
  border: 1px solid #D5DAE6;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  gap: 12px;
}
.eachHeading {
  font-size: 14px;
  font-weight: 500;
  color: #535D79;
}
.eachSubHeading {
  font-size: 14px;
  font-weight: 400;
  color: #535D79;
}

.upgradeContainer {
  margin-top: 1.5rem;
  padding: 1rem;
  border-radius: 12px;
  background: linear-gradient(to right, rgba(126, 130, 247, 0.1), rgba(126, 130, 247, 0.05));
}

.upgradeContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
}

.upgradeInfo {
  flex: 1;
}

.upgradeTitle {
  font-size: 1.1rem;
  font-weight: 600;
  color: #414B67;
  margin-bottom: 0.25rem;
}

.upgradeDescription {
  font-size: 0.9rem;
  color: #6B748E;
  line-height: 1.4;
}

.upgradeButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: linear-gradient(to right, #7E82F7, #6366F1);
  color: white;
  padding: 0.6rem 1.2rem;
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.2s ease;
}

.upgradeButton:hover {
  box-shadow: 0 2px 8px rgba(126, 130, 247, 0.25);
}

.arrowIcon {
  width: 16px;
  height: 16px;
}

/* Responsive adjustments */
@media screen and (max-width: 640px) {
  .upgradeContent {
    flex-direction: column;
    text-align: center;
    gap: 1rem;
  }

  .upgradeButton {
    width: 100%;
    justify-content: center;
  }
}

/* Add at the bottom of the file */
@media screen and (max-width: 768px) {
  .main {
      margin-top: 32px;
  }
}

.twobutton {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 32px;
  margin-top: 32px;
}

.button1 {
  border: 1px solid #BFC7DC;
  color: #535D79;
  background-color: #F3F6FD;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 248px;
  height: 44px;
  border-radius: 2rem;
  padding: .6rem;
  cursor: pointer;
  transition: all 0.2s ease;

}

.button1:hover {
  background-color: #EBEEFF;
  border-color: #8061DD;
  color: #8061DD;
}

.button2 {
  background-color: #8061DD;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 248px;
  height: 44px;
  border-radius: 2rem;
  padding: .6rem;
  cursor: pointer;
  transition: all 0.2s ease;
  gap: 8px;
}

.button2:hover {
  background-color: #6B4ED0;
}

@media (max-width: 768px) {
  .button1, .button2 {
    width: 160px;
    height: 40px;
    font-size: 13px;
  }
  
  .twobutton {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}