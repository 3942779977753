@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap');

.main {
    min-height: 100vh;
    background: #f4f7fe;
    position: relative;
    overflow: hidden;
    font-family: 'Plus Jakarta Sans', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

/* Add these new styles for animated background lines */
.main {
    /* Keep existing styles */
    position: relative;
}

/* Animated Background Lines */
.main::before,
.main::after {
    content: '';
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    pointer-events: none;
}

 

.main::after {
    background: 
        repeating-linear-gradient(45deg,
            rgba(99, 102, 241, 0.01) 0px,
            rgba(99, 102, 241, 0.01) 1px,
            transparent 1px,
            transparent 10px
        ),
        repeating-linear-gradient(-45deg,
            rgba(99, 102, 241, 0.01) 0px,
            rgba(99, 102, 241, 0.01) 1px,
            transparent 1px,
            transparent 10px
        );
    background-size: 30px 30px;
    animation: movePattern 15s linear infinite;
}

/* Add flowing lines */
.flowingLines {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
    overflow: hidden;
}

.flowingLine {
    position: absolute;
    width: 2px;
    height: 100%;
    background: linear-gradient(
        to bottom,
        transparent,
        rgba(99, 102, 241, 0.05),
        rgba(99, 102, 241, 0.1),
        rgba(99, 102, 241, 0.05),
        transparent
    );
    animation: flowUp 8s linear infinite;
}

.flowingLine:nth-child(1) { left: 20%; animation-delay: 0s; }
.flowingLine:nth-child(2) { left: 40%; animation-delay: -2s; }
.flowingLine:nth-child(3) { left: 60%; animation-delay: -4s; }
.flowingLine:nth-child(4) { left: 80%; animation-delay: -6s; }

/* Diagonal lines */
.diagonalLine {
    position: absolute;
    width: 150%;
    height: 1px;
    background: linear-gradient(
        90deg,
        transparent,
        rgba(99, 102, 241, 0.05),
        transparent
    );
    animation: rotateDiagonal 20s linear infinite;
    transform-origin: left top;
}

.diagonalLine:nth-child(1) { top: 20%; animation-delay: 0s; }
.diagonalLine:nth-child(2) { top: 40%; animation-delay: -5s; }
.diagonalLine:nth-child(3) { top: 60%; animation-delay: -10s; }
.diagonalLine:nth-child(4) { top: 80%; animation-delay: -15s; }

/* Animations */
@keyframes moveGrid {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 50px 50px;
    }
}

@keyframes movePattern {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 30px 30px;
    }
}

@keyframes flowUp {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(-100%);
    }
}

@keyframes rotateDiagonal {
    0% {
        transform: rotate(45deg) translateX(-100%);
    }
    100% {
        transform: rotate(45deg) translateX(100%);
    }
}
/* AI-themed Background Animation Elements */
.bgAnimation {
    position: fixed;
     
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
}

.resumeShape {
    position: absolute;
    width: 250px;
    height: 350px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 20px;
    border: 1px solid rgba(99, 102, 241, 0.2);
    box-shadow: 0 8px 25px rgba(99, 102, 241, 0.1);
    z-index: 1;
}

.resumePreview {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 12px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}

.resumeLine {
    height: 8px;
    background: rgba(99, 102, 241, 0.2);
    border-radius: 4px;
    animation: pulse 2s infinite;
}

.resumeLine.short { width: 30%; }
.resumeLine.medium { width: 60%; }
.resumeLine.long { width: 80%; }

.shape1 { 
    top: 10%; 
    left: 5%; 
    transform: rotate(-15deg);
    background-image: url('https://myfuse.in/static/media/rt11.02eedae0fce794ec7c8c.png'); /* Add your resume template image */
    background-size: cover;
    background-position: start;
    z-index: 1;
}

.shape2 { 
    bottom: 10%; 
    right: 5%; 
    transform: rotate(15deg);
    background-image: url('https://myfuse.in/static/media/rt8.b9882cc811dac92fb880.png'); /* Add your resume template image */
    background-size: cover;
    background-position: start;
}

@keyframes pulse {
    0% { opacity: 0.3; }
    50% { opacity: 0.6; }
    100% { opacity: 0.3; }
}

/* Add glowing effect */
.resumeShape::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    background: linear-gradient(45deg, 
        rgba(99, 102, 241, 0.1), 
        rgba(168, 85, 247, 0.1)
    );
    filter: blur(20px);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.resumeShape:hover::after {
    opacity: 1;
}

.formContainer {
    background: rgba(255, 255, 255, 0.98);
    border-radius: 24px;
    padding: 2.5rem;
    width: 90%;
    max-width: 420px;
    position: relative;
    z-index: 4;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.03);
    backdrop-filter: blur(10px);
    margin-bottom: 3rem;
}

.logoContainer {
    text-align: center;
    margin-bottom: 2rem;
    position: relative;
}

.logo {
    font-size: 2rem;
    font-weight: 700;
    color: #1e293b;
    margin: 0;
    letter-spacing: -0.5px; 
    cursor: pointer;
}

.logoDot {
    color: rgb(249, 111, 89);
    text-shadow: 0 0 20px rgba(99, 102, 241, 0.5);
}

.tagline {
    color: #64748b;
    font-size: 0.9rem;
    margin-top: 0.5rem;
    text-align: center;
    background: linear-gradient(90deg, #6366F1, #A855F7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.inputGroup {
    margin-bottom: 1.25rem;
    position: relative;
}

.input {
    width: 100%;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid rgba(99, 102, 241, 0.2);
    border-radius: 12px;
    color: #1e293b;
    font-size: 0.95rem;
    transition: all 0.3s ease;
}

.input:focus {
    border-color: #6366F1;
    box-shadow: 0 0 0 4px rgba(99, 102, 241, 0.1);
    outline: none;
}

.input::placeholder {
    color: #94a3b8;
}

.submitButton {
    width: 100%;
    padding: 1rem;
    background: linear-gradient(45deg, #6366F1, #A855F7);
    border: none;
    border-radius: 12px;
    color: white;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 1rem;
    position: relative;
    overflow: hidden;
}

.submitButton::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.2),
        transparent
    );
    transition: 0.5s;
}

.submitButton:hover::before {
    left: 100%;
}

.submitButton:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(99, 102, 241, 0.3);
}

.googleButton {
    width: 100%;
    
    background: white;
    border: 1px solid rgba(99, 102, 241, 0.2);
    border-radius: 12px;
    color: #1e293b;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-bottom: 1.5rem;
}

.googleButton:hover {
    background: #f8fafc;
    border-color: #6366F1;
}

.divider {
    display: flex;
    align-items: center;
    text-align: center;
    color: #64748b;
    margin: 1.5rem 0;
    font-size: 0.9rem;
}

.divider::before,
.divider::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid rgba(99, 102, 241, 0.2);
}

.divider span {
    padding: 0 1rem;
}

.links {
    margin-top: 1.5rem;
    text-align: center;
}

.forgotPassword {
    color: #6366F1;
    font-size: 0.9rem;
    cursor: pointer;
    transition: color 0.3s ease;
}

.forgotPassword:hover {
    color: #A855F7;
}

.signup {
    color: #64748b;
    font-size: 0.9rem;
    margin-top: 1rem;
}

.signup span {
    background: linear-gradient(90deg, #6366F1, #A855F7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.signup span:hover {
    opacity: 0.8;
}

/* Neural network pattern overlay */
.neuralPattern {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(rgba(99, 102, 241, 0.1) 1px, transparent 1px);
    background-size: 30px 30px;
    pointer-events: none;
    opacity: 0.5;
}

@media (max-width: 480px) {
    .formContainer {
        padding: 2rem 1.5rem;
        margin-bottom: 0rem;
    }
    
    .resumeShape {
        opacity: 0.2;
    }
    
    .neuralPattern {
        opacity: 0.3;
    }
}

/* Job Portal Floating Elements */
/* Floating Elements */
.floatingElements {
    position: fixed;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 2;
}

/* Company Logos */
.companyLogo {
    position: absolute;
    width: 50px;
    height: 50px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(99, 102, 241, 0.2);
}

/* Enhanced Job Cards */
.jobCard {
    position: absolute;
    width: 300px;
    padding: 24px;
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    border: 1px solid rgba(99, 102, 241, 0.1);
    box-shadow: 0 8px 25px rgba(99, 102, 241, 0.1);
    overflow: hidden;
}

.jobBadge {
    position: absolute;
    top: 12px;
    right: 12px;
    padding: 4px 12px;
    border-radius: 20px;
    color: white;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.jobHeader {
    margin-bottom: 16px;
}

.jobHeader h3 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #1e293b;
    margin: 0 0 8px 0;
}

.companyInfo {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 0.9rem;
}

.companyName {
    color: #6366F1;
    font-weight: 500;
}

.jobLocation {
    color: #64748b;
    display: flex;
    align-items: center;
    gap: 4px;
}

.jobSalary {
    background: rgba(99, 102, 241, 0.1);
    padding: 8px 16px;
    border-radius: 12px;
    color: #6366F1;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
}

.salaryIcon {
    font-size: 1.1rem;
}

.jobTags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 20px;
}

.tag {
    padding: 6px 12px;
    background: rgba(99, 102, 241, 0.1);
    color: #6366F1;
    border-radius: 8px;
    font-size: 0.8rem;
    font-weight: 500;
    transition: all 0.3s ease;
}

.tag:hover {
    background: rgba(99, 102, 241, 0.2);
}

.applyButton {
    width: 100%;
    padding: 12px;
    background: linear-gradient(45deg, #6366F1, #A855F7);
    color: white;
    border: none;
    border-radius: 12px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.applyButton:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(99, 102, 241, 0.3);
}

/* Update job card positions */
.jobCard:nth-child(1) { top: 15%; left: 5%; }
.jobCard:nth-child(2) { top: 40%; right: 5%; }
.jobCard:nth-child(3) { bottom: 15%; left: 15%; }

/* Responsive adjustments */
@media (max-width: 768px) {
    .jobCard {
        width: 280px;
        padding: 20px;
    }
    
    .jobCard:nth-child(1) { top: 10%; left: 50%; transform: translateX(-50%); }
    .jobCard:nth-child(2) { top: 35%; left: 50%; transform: translateX(-50%); }
    .jobCard:nth-child(3) { top: 60%; left: 50%; transform: translateX(-50%); }
}

/* Skill Badges */
.skillBadge {
    position: absolute;
    padding: 8px 16px;
    background: rgba(255, 255, 255, 0.95);
    border-radius: 20px;
    border: 1px solid rgba(99, 102, 241, 0.2);
    color: #1e293b;
    font-size: 0.8rem;
    white-space: nowrap;
}

/* Stats Cards */
.statsCard {
    position: absolute;
    width: 120px;
    height: 80px;
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(10px);
    border-radius: 12px;
    border: 1px solid rgba(99, 102, 241, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.statsNumber {
    font-size: 1.2rem;
    font-weight: 600;
    color: #6366F1;
}

.statsLabel {
    font-size: 0.7rem;
    color: #64748b;
}

/* Notification Dots */
.notificationDot {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #6366F1;
    box-shadow: 0 0 10px rgba(99, 102, 241, 0.5);
}

/* Connection Lines */
.connectionLine {
    position: absolute;
    background: linear-gradient(90deg, 
        rgba(99, 102, 241, 0.05) 0%,
        rgba(99, 102, 241, 0.2) 50%,
        rgba(99, 102, 241, 0.05) 100%
    );
    height: 2px;
    transform-origin: left center;
}

/* Pulse Animation */
@keyframes pulse {
    0% { transform: scale(1); opacity: 0.5; }
    50% { transform: scale(1.1); opacity: 0.8; }
    100% { transform: scale(1); opacity: 0.5; }
}

/* Float Animation */
@keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0px); }
}

/* Rotate Animation */
@keyframes rotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

/* Apply animations to elements */
.skillBadge {
    animation: float 3s ease-in-out infinite;
}

.statsCard {
    animation: float 4s ease-in-out infinite;
}

.notificationDot {
    animation: pulse 2s ease-in-out infinite;
}

.connectionLine {
    animation: pulse 3s ease-in-out infinite;
}

/* Position elements */
.skillBadge:nth-child(1) { top: 15%; left: 10%; }
.skillBadge:nth-child(2) { top: 25%; right: 15%; }
.skillBadge:nth-child(3) { bottom: 20%; left: 20%; }

.statsCard:nth-child(1) { top: 30%; right: 10%; }
.statsCard:nth-child(2) { bottom: 25%; left: 15%; }

.companyLogo:nth-child(1) { top: 40%; left: 25%; }
.companyLogo:nth-child(2) { bottom: 35%; right: 20%; }

/* Responsive adjustments */
@media (max-width: 768px) {
    .floatingElements {
        opacity: 0.3;
    }
    
    .skillBadge, .statsCard, .jobCard {
        transform: scale(0.8);
    }
}

/* Add these styles for better feature positioning */
.floatingElements {
    position: fixed;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
}

.leftElements {
    position: absolute;
    left: 20%;
    top: 70%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 3;
}

.rightElements {
    position: absolute;
    right: 20%;
    top: 30%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 3;
}

.featuresContainer {
    position: absolute;
    bottom: 2%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 20px;
    justify-content: center;
    width: 100%;
    max-width: 800px;
    pointer-events: auto;
    z-index: 3;
}

.featureCard {
    background: rgba(255, 255, 255, 0.95);
    border-radius: 16px;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    gap: 12px;
    box-shadow: 0 4px 12px rgba(99, 102, 241, 0.1);
    border: 1px solid rgba(99, 102, 241, 0.1);
    backdrop-filter: blur(10px);
}

.featureIcon {
    font-size: 1.5rem;
}

.featureText {
    color: #333;
    font-weight: 500;
    font-size: 0.9rem;
}

.skillBadge {
    background: rgba(255, 255, 255, 0.95);
    border-radius: 16px;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    gap: 12px;
    box-shadow: 0 4px 12px rgba(99, 102, 241, 0.1);
    border: 1px solid rgba(99, 102, 241, 0.1);
    pointer-events: auto;
    backdrop-filter: blur(10px);
}

.skillIcon {
    font-size: 1.5rem;
}

.skillInfo {
    display: flex;
    flex-direction: column;
}

.skillName {
    color: #333;
    font-weight: 600;
    font-size: 0.9rem;
}

.skillDesc {
    color: #666;
    font-size: 0.8rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .featuresContainer {
        flex-direction: column;
        align-items: center;
        bottom: 5%;
        gap: 10px; 
        display: none;
    }

    .leftElements, .rightElements {
        display: none;
    }
}

/* Decorative background lines */
.leftElements::before,
.rightElements::before {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    background: linear-gradient(
        to bottom,
        transparent,
        rgba(99, 102, 241, 0.2),
        rgba(99, 102, 241, 0.3),
        rgba(99, 102, 241, 0.2),
        transparent
    );
    left: -20px;
    top: 0;
}

.rightElements::before {
    left: auto;
    right: -20px;
}

/* Enhanced skill badges */
.skillBadge {
    background: rgba(255, 255, 255, 0.95);
    border-radius: 16px;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    gap: 12px;
    box-shadow: 
        0 4px 12px rgba(99, 102, 241, 0.1),
        0 0 0 1px rgba(99, 102, 241, 0.1);
    pointer-events: auto;
    backdrop-filter: blur(10px);
    position: relative;
    overflow: hidden;
}

/* Animated background line for skill badges */
.skillBadge::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background: linear-gradient(
        to bottom,
        #6366F1,
        #A855F7
    );
    opacity: 0.7;
}

/* Subtle background pattern */
.skillBadge::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
        radial-gradient(
            circle at 100% 100%,
            rgba(99, 102, 241, 0.1) 0%,
            transparent 50%
        );
    opacity: 0.5;
}

/* Enhanced hover effect */
.skillBadge:hover {
    transform: translateY(-2px);
    box-shadow: 
        0 8px 20px rgba(99, 102, 241, 0.15),
        0 0 0 1px rgba(99, 102, 241, 0.2);
}

.skillBadge:hover::before {
    opacity: 1;
}

/* Connecting lines between badges */
.leftElements .skillBadge:not(:last-child)::after,
.rightElements .skillBadge:not(:last-child)::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 50%;
    width: 2px;
    height: 20px;
    background: linear-gradient(
        to bottom,
        rgba(99, 102, 241, 0.3),
        transparent
    );
}

/* Enhanced skill info */
.skillInfo {
    position: relative;
    z-index: 1;
}

.skillName {
    color: #1e293b;
    font-weight: 600;
    font-size: 0.95rem;
    letter-spacing: -0.2px;
}

.skillDesc {
    color: #64748b;
    font-size: 0.8rem;
    font-weight: 500;
}

.skillIcon {
    font-size: 1.5rem;
    position: relative;
    z-index: 1;
}

/* Adjust positioning for better visual flow */
.leftElements {
    left: 8%;
}

.rightElements {
    right: 8%;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .leftElements::before,
    .rightElements::before {
        display: none;
    }
}