.main {
    position: fixed;
    top: 32px;
     min-width: calc(100% - 280px  - 50px);
    z-index: 99; 
    left:   calc(261px + 32px) ;
     
}
.container {
    width: 100%;
    height:64px; 
    background-color: white;
    border-radius: 16px;
    display: flex;
    font-family: "inter";
    align-items: center;
    justify-content: space-between;
}
.leftside {
    display: flex;
    gap: 40px; 
    margin-left: 32px;
    color: #909AB6;
    font-family: "inter";
    
}

.leftside span {
    font-family: "inter";
    font-size: 14px;
    height: 50px;
    display: flex;
    align-items: center;
   cursor: pointer;
   font-weight: 500;
   line-height: 80%;
}
.leftside span:hover {
    color: #5860FC;
    font-weight: 600;
}
.rightSide {
    display: flex;
    justify-content: end;
    /* width: 70%; */
    margin-right: 32px;
    font-size: 14px; 
    color: black;
}
.active {
    position: relative;
    color: #5860FC;
    font-weight: 600;
}

.underline {
    position: absolute;
    bottom: -6px;
    right: -3px;
    width: 100%;
    height: 3px !important;
    background-color: #5860FC;
    border-radius: 16px;

}

.rightSide img {
    width: 22px;
    margin-right: 10px;
    border-radius: 50%;
}
.eachContTitle {
    color: #8F99B5;
}
.profileDropdown {
    position: relative;
    cursor: pointer;
}

.profileTrigger {
    display: flex;
    align-items: center;
    gap: 8px;
}

.dropdownMenu {
    position: absolute;
    top: calc(100% + 18px);
    right: -10px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    min-width: 200px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: all 0.2s ease-in-out;
}

.profileDropdown:hover .dropdownMenu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.dropdownItem {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px 16px;
    color: #4B5563;
    transition: all 0.2s ease;
}

.dropdownItem:hover {
    background: #F3F4F6;
    color: #5860FC;
}

.dropdownItem svg {
    color: #909AB6;
}

.dropdownItem:hover svg {
    color: #5860FC;
}

.divider {
    height: 1px;
    background: #E5E7EB;
    margin: 8px 0;
}