.container {
    max-width: 800px;
    margin: 2rem auto;
    padding: 0 1rem;
  }
  
  .title {
    text-align: center;
    color: #2c3e50;
    margin-bottom: 1rem;
  }
  
  .description {
    text-align: center;
    color: #666;
    margin-bottom: 2rem;
  }
  
  .uploadForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }
  
  .uploadBox {
    width: 100%;
    max-width: 500px;
    border: 2px dashed #ccc;
    border-radius: 8px;
    padding: 2rem;
    text-align: center;
    background-color: #f8f9fa;
    position: relative;
    transition: opacity 0.3s ease;
  }
  
  .fileInput {
    width: 100%;
    margin-bottom: 1rem;
  }
  
  .supportedFormats {
    color: #666;
    font-size: 0.9rem;
  }
  
  .submitButton {
    padding: 0.8rem 2rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
  
  .submitButton:hover {
    background-color: #0056b3;
  }
  
  .submitButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .scoreContainer {
    margin-top: 2rem;
    text-align: center;
  }
  
  .scoreCircle {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #28a745;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-weight: bold;
    margin: 2rem auto;
  }
  
  .recommendations {
    text-align: left;
    max-width: 600px;
    margin: 2rem auto;
    padding: 1rem;
    background-color: #f8f9fa;
    border-radius: 8px;
  }
  
  .recommendations h3 {
    color: #2c3e50;
    margin-bottom: 1rem;
  }
  
  .recommendations ul {
    padding-left: 1.5rem;
  }
  
  .recommendations li {
    margin-bottom: 0.5rem;
    color: #666;
  }
  
  .headerSection {
    text-align: center;
    margin-bottom: 3rem;
    padding: 2rem 0;
    background: linear-gradient(to right, #f8f9fa, #e9ecef);
    border-radius: 10px;
  }
  
  .pricingCard {
    max-width: 400px;
    margin: 0 auto 3rem;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background: white;
    text-align: center;
  }
  
  .pricingHeader {
    margin-bottom: 2rem;
  }
  
  .crownIcon {
    color: #ffd700;
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .price {
    font-size: 2.5rem;
    color: #2c3e50;
    margin: 1rem 0;
  }
  
  .currency {
    font-size: 1.5rem;
    vertical-align: super;
  }
  
  .amount {
    font-weight: bold;
  }
  
  .period {
    font-size: 1rem;
    color: #666;
  }
  
  .features {
    text-align: left;
  }
  
  .feature {
    display: flex;
    align-items: center;
    margin: 1rem 0;
    color: #2c3e50;
  }
  
  .checkIcon {
    color: #28a745;
    margin-right: 1rem;
  }
  
  .uploadBox {
    position: relative;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .uploadIcon {
    font-size: 3rem;
    color: #007bff;
    margin-bottom: 1rem;
  }
  
  .fileInput {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  
  .uploadLabel {
    font-size: 1.2rem;
    color: #2c3e50;
    margin: 1rem 0;
    cursor: pointer;
  }
  
  .fileSelected {
    text-align: center;
  }
  
  .changeFile {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
    margin-top: 0.5rem;
  }
  
  .paymentButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 1rem 2rem;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .paymentButton:hover {
    background-color: #218838;
    transform: translateY(-1px);
  }
  
  .lockIcon {
    font-size: 1rem;
  }
  
  .scoreInner {
    width: 130px;
    height: 130px;
    background: white;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .scoreNumber {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
  }
  
  .scoreLabel {
    font-size: 0.9rem;
    color: #666;
  }
  
  .statsContainer {
    display: flex;
    justify-content: center;
    gap: 3rem;
    margin-top: 2rem;
  }
  
  .stat {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .statNumber {
    font-size: 2.5rem;
    font-weight: bold;
    color: #007bff;
    margin-bottom: 0.5rem;
  }
  
  .statLabel {
    color: #666;
    font-size: 0.9rem;
  }
  
  .featureIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: rgba(40, 167, 69, 0.1);
    border-radius: 50%;
    margin-right: 1rem;
    color: #28a745;
  }
  
  .pricingCard {
    background: linear-gradient(145deg, #ffffff, #f8f9fa);
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .uploadBox {
    background: linear-gradient(145deg, #ffffff, #f8f9fa);
    border: 2px dashed rgba(0, 123, 255, 0.3);
    transition: all 0.3s ease;
  }
  
  .uploadBox:hover {
    border-color: #007bff;
    background: linear-gradient(145deg, #f8f9fa, #ffffff);
  }
  
  .paymentButton {
    background: linear-gradient(45deg, #28a745, #20c997);
    box-shadow: 0 4px 15px rgba(40, 167, 69, 0.2);
  }
  
  .paymentButton:hover {
    background: linear-gradient(45deg, #218838, #1ca38b);
    box-shadow: 0 6px 20px rgba(40, 167, 69, 0.3);
  }
  
  .headerSection {
    background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
    padding: 3rem 1rem;
    border-radius: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  }
  
  .title {
    font-size: 2.5rem;
    background: linear-gradient(45deg, #2c3e50, #3498db);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 1rem;
  }
  
  /* Add responsive design */
  @media (max-width: 768px) {
    .statsContainer {
      flex-direction: column;
      gap: 1.5rem;
    }
  
    .stat {
      padding: 1rem;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 10px;
    }
  
    .title {
      font-size: 2rem;
    }
  }
  
  /* Modal Styles */
  .modalWrapper :global(.modal-content) {
    border: none;
    border-radius: 24px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }
  
  .modernBody {
    padding: 0;
    position: relative;
    background: linear-gradient(to right, #f8f9fa, #ffffff);
  }
  
  .closeBtn {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: #666;
    font-size: 1.5rem;
    z-index: 10;
    cursor: pointer;
  }
  
  .contentWrapper {
    display: flex;
    min-height: 600px;
  }
  
  .leftSection {
    flex: 1;
    padding: 3rem;
    background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
    position: relative;
  }
  
  .rightSection {
    flex: 1;
    padding: 3rem;
    background: white;
  }
  
  .badge {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    background: rgba(255, 215, 0, 0.1);
    border-radius: 30px;
    color: #ffd700;
    margin-bottom: 1.5rem;
    font-weight: 600;
  }
  
  .modalTitle {
    font-size: 2.25rem;
    font-weight: 700;
    color: #2c3e50;
    margin-bottom: 1rem;
    line-height: 1.2;
  }
  
  .modalSubtitle {
    color: #666;
    font-size: 1.1rem;
    margin-bottom: 2rem;
  }
  
  .fileInfoCard {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1.25rem;
    background: white;
    border-radius: 12px;
    margin-bottom: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  }
  
  .fileIconWrapper {
    width: 48px;
    height: 48px;
    background: rgba(0, 123, 255, 0.1);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .fileIcon {
    color: #007bff;
    font-size: 1.5rem;
  }
  
  .fileDetails {
    display: flex;
    flex-direction: column;
  }
  
  .fileName {
    font-weight: 600;
    color: #2c3e50;
  }
  
  .fileSize {
    font-size: 0.9rem;
    color: #666;
  }
  
  .pricingBox {
    background: white;
    padding: 2rem;
    border-radius: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    margin-bottom: 2rem;
  }
  
  .priceHeader {
    text-align: center;
    color: #666;
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }
  
  .priceTag {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 0.25rem;
    margin-bottom: 1.5rem;
  }
  
  .currency {
    font-size: 2rem;
    font-weight: 600;
    color: #2c3e50;
  }
  
  .amount {
    font-size: 4rem;
    font-weight: 700;
    color: #2c3e50;
    line-height: 1;
  }
  
  .period {
    font-size: 1.25rem;
    color: #666;
  }
  
  .upgradeBtn {
    width: 100%;
    padding: 1.25rem;
    background: linear-gradient(45deg, #007bff, #0056b3);
    border: none;
    border-radius: 12px;
    color: white;
    font-weight: 600;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 123, 255, 0.2);
  }
  
  .upgradeBtn:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 123, 255, 0.3);
  }
  
  .securePayment {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 1rem;
    color: #666;
    font-size: 0.9rem;
  }
  
  .featuresList {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .featureItem {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    padding: 1.25rem;
    background: #f8f9fa;
    border-radius: 12px;
    transition: all 0.3s ease;
  }
  
  .featureIconWrapper {
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #007bff;
  }
  
  .featureContent {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .featureTitle {
    font-weight: 600;
    color: #2c3e50;
  }
  
  .featureDescription {
    font-size: 0.9rem;
    color: #666;
  }
  
  .testimonial {
    margin-top: 2rem;
    padding: 1.5rem;
    background: #f8f9fa;
    border-radius: 12px;
    border-left: 4px solid #007bff;
  }
  
  .testimonialContent {
    font-style: italic;
    color: #2c3e50;
    margin-bottom: 0.5rem;
  }
  
  .testimonialAuthor {
    font-size: 0.9rem;
    color: #666;
  }
  
  @media (max-width: 768px) {
    .contentWrapper {
      flex-direction: column;
    }
  
    .leftSection,
    .rightSection {
      padding: 2rem;
    }
  
    .modalTitle {
      font-size: 1.75rem;
    }
  
    .amount {
      font-size: 3rem;
    }
  }
  
  .buttonIcon {
    animation: pulse 2s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  
  /* Add these styles if they're missing */
  .pricingBox {
    background: rgba(255, 255, 255, 0.1);
    padding: 1.5rem;
    border-radius: 12px;
    margin: 1.5rem 0;
  }
  
  .priceTag {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 0.25rem;
  }
  
  .currency {
    font-size: 1.5rem;
    color: #2c3e50;
  }
  
  .amount {
    font-size: 3rem;
    font-weight: bold;
    color: #2c3e50;
  }
  
  .period {
    color: #666;
  }
  
  .savings {
    text-align: center;
    color: #28a745;
    font-size: 0.9rem;
    margin-top: 0.5rem;
  }
  
  .buttonIcon {
    margin-right: 0.5rem;
  }
  
  .featuresList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .checkIcon {
    color: #28a745;
  }
  
  /* Fix modal overlay */
  :global(.modal-backdrop) {
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  /* Fix modal animation */
  :global(.modal) {
    transition: opacity 0.3s ease-in-out;
  }
  
  /* Fix mobile responsiveness */
  @media (max-width: 768px) {
    .contentWrapper {
      flex-direction: column;
    }
  
    .leftSection,
    .rightSection {
      padding: 1.5rem;
    }
  
    .modalWrapper :global(.modal-dialog) {
      margin: 0.5rem;
    }
  
    .pricingBox {
      padding: 1rem;
    }
  
    .amount {
      font-size: 2.5rem;
    }
  }
  
  /* Update color scheme with more vibrant professional colors */
  :root {
    --primary-color: #0052cc;      /* Bright professional blue */
    --secondary-color: #0747a6;    /* Deep blue */
    --accent-color: #ffab00;       /* Warm orange accent */
    --accent-light: #fff0b3;       /* Light warm accent */
    --text-primary: #172b4d;       /* Rich dark blue text */
    --text-secondary: #42526e;     /* Medium blue-grey text */
    --background-light: #f4f5f7;   /* Clean light background */
    --background-white: #ffffff;   /* Pure white */
    --success-color: #36b37e;      /* Fresh green */
    --hover-color: #0065ff;        /* Bright blue for hover */
    --shadow-color: rgba(9, 30, 66, 0.25); /* Professional shadow */
  }
  
  /* Enhance gradient backgrounds */
  .headerSection {
    background: linear-gradient(135deg, #f4f5f7 0%, #ffffff 100%);
    box-shadow: 0 4px 20px rgba(9, 30, 66, 0.1);
  }
  
  .modernBody {
    background: linear-gradient(to right, #ffffff, #f4f5f7);
  }
  
  /* Update button styles with more pop */
  .upgradeBtn {
    background: linear-gradient(45deg, var(--primary-color), var(--hover-color));
    box-shadow: 0 4px 12px rgba(0, 82, 204, 0.3);
    transition: all 0.3s ease;
  }
  
  .upgradeBtn:hover {
    background: linear-gradient(45deg, var(--hover-color), var(--primary-color));
    box-shadow: 0 6px 16px rgba(0, 82, 204, 0.4);
    transform: translateY(-2px);
  }
  
  /* Make feature icons more prominent */
  .featureIconWrapper {
    background: linear-gradient(135deg, var(--accent-light), #fff);
    color: var(--primary-color);
    box-shadow: 0 2px 8px rgba(9, 30, 66, 0.1);
  }
  
  /* Enhanced badge styling */
  .badge {
    background: linear-gradient(45deg, var(--accent-light), #fff);
    color: var(--primary-color);
    border: 1px solid rgba(0, 82, 204, 0.1);
    box-shadow: 0 2px 4px rgba(9, 30, 66, 0.1);
  }
  
  .crownIcon {
    color: var(--accent-color);
  }
  
  /* Enhanced pricing box */
  .pricingBox {
    background: linear-gradient(145deg, #ffffff, #f8f9fa);
    border: 1px solid rgba(9, 30, 66, 0.1);
    box-shadow: 0 4px 16px rgba(9, 30, 66, 0.1);
  }
  
  .currency,
  .amount {
    background: linear-gradient(45deg, var(--primary-color), var(--hover-color));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }
  
  /* Make checkmarks more vibrant */
  .checkIcon {
    color: var(--success-color);
    filter: drop-shadow(0 2px 4px rgba(54, 179, 126, 0.2));
  }
  
  /* Enhanced testimonial styling */
  .testimonial {
    background: linear-gradient(to right, var(--background-light), var(--background-white));
    border-left: 4px solid var(--accent-color);
    box-shadow: 0 2px 8px rgba(9, 30, 66, 0.1);
  }
  
  /* Enhanced feature items */
  .featureItem {
    background: linear-gradient(145deg, var(--background-white), var(--background-light));
    border: 1px solid rgba(9, 30, 66, 0.1);
    box-shadow: 0 2px 8px rgba(9, 30, 66, 0.05);
    transition: all 0.3s ease;
  }
  
  .featureItem:hover {
    border-color: var(--primary-color);
    background: var(--background-white);
    transform: translateX(8px);
    box-shadow: 0 4px 12px rgba(9, 30, 66, 0.1);
  }
  
  /* Enhanced text styling */
  .modalTitle {
    color: var(--text-primary);
    font-weight: 700;
    letter-spacing: -0.5px;
  }
  
  .modalSubtitle {
    color: var(--text-secondary);
    line-height: 1.6;
  }
  
  /* Add shimmer effect to important elements */
  .upgradeBtn::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: translateX(-100%);
    animation: shimmer 3s infinite;
  }
  
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
  
  /* Enhanced hover states */
  .featureIconWrapper:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }
  
  /* Add subtle animations */
  .modalTitle, .modalSubtitle, .pricingBox {
    animation: fadeInUp 0.5s ease-out;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Enhanced mobile styles */
  @media (max-width: 768px) {
    .upgradeBtn {
      background: var(--primary-color);
      box-shadow: 0 4px 8px rgba(0, 82, 204, 0.2);
    }
    
    .featureItem {
      transform: none;
      margin: 8px 0;
    }
  }
  
  .errorMessage {
    color: #e53e3e;
    text-align: center;
    margin-top: 1rem;
    padding: 0.5rem;
    border-radius: 0.375rem;
    background-color: #fff5f5;
    border: 1px solid #feb2b2;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .uploading {
    opacity: 0.7;
    pointer-events: none;
  }
  
  .uploadBox {
    transition: opacity 0.3s ease;
  }
  
  .errorMessage {
    background-color: #fff5f5;
    border: 1px solid #feb2b2;
    color: #e53e3e;
    padding: 0.75rem;
    border-radius: 0.375rem;
    margin-top: 1rem;
    text-align: center;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .uploading {
    opacity: 0.7;
    pointer-events: none;
  }
  
  .uploadBox {
    transition: opacity 0.3s ease;
  }
  
  .errorMessage {
    background-color: #fff5f5;
    border: 1px solid #feb2b2;
    color: #e53e3e;
    padding: 0.75rem;
    border-radius: 0.375rem;
    margin-top: 1rem;
    text-align: center;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .categoryCard {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .categoryProgress {
    height: 8px;
    background: #f0f0f0;
    border-radius: 4px;
    margin: 1rem 0;
    overflow: hidden;
  }
  
  .progressBar {
    height: 100%;
    background: #28a745;
    transition: width 0.3s ease;
  }
  
  .timesIcon {
    color: #dc3545;
  }
  
  .categoriesGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
    margin-top: 2rem;
  }
  
  .analysisContainer {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 2rem;
  }
  
  .scoreHeader {
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .scoreSubtitle {
    color: #666;
    font-size: 1.1rem;
  }
  
  .totalScoreSection {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
  }
  
  .scoreCircle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .scoreInner {
    background: white;
    border-radius: 50%;
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .scoreNumber {
    font-size: 3rem;
    font-weight: bold;
    color: #28a745;
  }
  
  .scoreLabel {
    font-size: 1rem;
    color: #666;
  }
  
  .categoryCard {
    background: white;
    border-radius: 16px;
    padding: 2rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.5rem;
  }
  
  .categoryHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .categoryTitle {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .categoryIcon {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background: rgba(40, 167, 69, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #28a745;
  }
  
  .categoryScore {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .scoreValue {
    color: #28a745;
  }
  
  .scoreMax {
    color: #666;
  }
  
  .categoryProgress {
    height: 8px;
    background: #f0f0f0;
    border-radius: 4px;
    margin: 1rem 0;
    overflow: hidden;
  }
  
  .progressBar {
    height: 100%;
    background: linear-gradient(90deg, #28a745, #20c997);
  }
  
  .detailsContainer {
    margin-top: 2rem;
  }
  
  .detailsList {
    list-style: none;
    padding: 0;
    margin: 1rem 0;
  }
  
  .detailsList li {
    margin: 0.5rem 0;
    padding: 0.5rem;
    background: #f8f9fa;
    border-radius: 4px;
    font-size: 0.9rem;
  }
  
  .checkIcon {
    color: #28a745;
  }
  
  .timesIcon {
    color: #dc3545;
  }
  
  @media (max-width: 768px) {
    .categoriesGrid {
      grid-template-columns: 1fr;
    }
    
    .categoryCard {
      padding: 1.5rem;
    }
  }
  
  .featuresTitle {
    margin-bottom: 2rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: #2d3748;
  }
  
  .featuresTitleWrapper {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  
  .rocketIcon {
    color: #4f46e5;
    font-size: 1.25rem;
  }
  
  .featuresTitleDivider {
    height: 2px;
    background: linear-gradient(90deg, #4f46e5 0%, rgba(79, 70, 229, 0.1) 100%);
    margin-top: 0.75rem;
    border-radius: 2px;
  }
  
  .scoreBreakdown {
    margin-top: 2rem;
    padding: 2rem;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .categoryScore {
    margin-bottom: 2rem;
    padding: 1.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
  }
  
  .categoryHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .categoryHeader h4 {
    font-size: 1.1rem;
    color: #2d3748;
    margin: 0;
  }
  
  .categoryPoints {
    background: #4f46e5;
    color: white;
    padding: 0.25rem 0.75rem;
    border-radius: 999px;
    font-size: 0.9rem;
  }
  
  .categoryDetails {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  
  .present, .missing {
    background: #f7fafc;
    padding: 1rem;
    border-radius: 8px;
  }
  
  .present h5, .missing h5 {
    color: #4a5568;
    margin-bottom: 0.75rem;
    font-size: 0.9rem;
  }
  
  .present ul, .missing ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .present li, .missing li {
    font-size: 0.9rem;
    color: #718096;
    padding: 0.25rem 0;
  }
  
  .present li::before {
    content: "✓";
    color: #48bb78;
    margin-right: 0.5rem;
  }
  
  .missing li::before {
    content: "•";
    color: #e53e3e;
    margin-right: 0.5rem;
  }

  .originalPrice {
    font-size: 0.9em;
    margin-right: 8px;
  }
  
  .strikethrough {
    text-decoration: line-through;
    color: #999;
  }
  
  .saveTag {
    background: #ff5722;
    color: white;
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 0.9em;
    margin: 8px 0;
    display: inline-block;
    font-weight: bold;
  }
  
  
  
  
  