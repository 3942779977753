/* Base Styles with Modernized Variables */
:root {
  --primary: #4f46e5;
  --primary-dark: #3730a3;
  --surface: #ffffff;
  --text: #1a1a1a;
  --text-secondary: #6b7280;
  --gradient-hero: linear-gradient(135deg, #2b3467 0%, #1a1f3d 100%);
  --card-shadow: 0 10px 30px rgba(0,0,0,0.05);
  --card-shadow-hover: 0 20px 40px rgba(0,0,0,0.12);
}

.container {
  background: #f8fafc;
  min-height: 100vh;
  padding: 2rem 1rem;
   
  margin: 0 auto;
  overflow-x: hidden;
}

.categoryNav {
  position: sticky;
  top: 0;
  z-index: 10;
  background: rgba(248, 250, 252, 0.85);
  backdrop-filter: blur(12px);
  padding: 1rem;
  margin: 0 auto 2rem;
  border-bottom: 1px solid rgba(226, 232, 240, 0.8);
}

.categoryScrollContainer {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;
}

.categories {
  display: flex;
  gap: 0.75rem;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  padding: 0.5rem;
  scroll-snap-type: x mandatory;
}

.categories::-webkit-scrollbar {
  display: none;
}

.scrollButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  border: 1px solid #e2e8f0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 20;
  transition: all 0.3s ease;
}

.scrollButton:hover {
  background: #f8fafc;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.leftScroll {
  left: 0;
}

.rightScroll {
  right: 0;
}

.categoryBtn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.85rem 1.5rem;
  border-radius: 14px;
  background: white;
  color: #64748b;
  font-weight: 600;
  border: 1px solid #e2e8f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap;
  scroll-snap-align: start;
}

.categoryBtn.active {
  background: linear-gradient(135deg, #3b82f6 0%, #60a5fa 100%);
  color: white;
  border: none;
  box-shadow: 0 8px 16px rgba(59, 130, 246, 0.25);
}

.categoryIcon {
  font-size: 1.4rem;
}

.blogContent {
  max-width: 1200px;
  margin: 0 auto;
}

.blogGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

.blogCard {
  background: white;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  cursor: pointer;
  transition: all 0.3s ease;
}

.blogCard:hover {
  transform: translateY(-4px);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.cardImage {
  position: relative;
  padding-top: 66%; /* 3:2 aspect ratio */
  overflow: hidden;
}

.cardImage img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.blogCard:hover .cardImage img {
  transform: scale(1.05);
}

.cardOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: linear-gradient(180deg, rgba(0,0,0,0.4) 0%, transparent 100%);
}

.readTime {
  background: rgba(255, 255, 255, 0.9);
  padding: 0.4rem 0.8rem;
  border-radius: 20px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  color: #374151;
  backdrop-filter: blur(4px);
}

.cardTag {
  background: rgba(255, 255, 255, 0.9);
  padding: 0.4rem 0.8rem;
  border-radius: 20px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  color: #374151;
  backdrop-filter: blur(4px);
}

.cardContent {
  padding: 1.25rem;
}

.cardContent h3 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 0.5rem;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cardContent time {
  font-size: 0.875rem;
  color: #6b7280;
}

.categoryTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  padding: 0 0.5rem;
}

.categoryTitle h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.scrollButtons {
  display: flex;
  gap: 0.5rem;
}

.scrollButton {
  background: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.scrollButton:hover {
  background: #f3f4f6;
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.scrollButton svg {
  width: 24px;
  height: 24px;
  color: #4b5563;
}

.categoryGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
}

.categoryGrid.scrollable {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  padding: 1rem 0;
  gap: 1.5rem;
}

.categoryGrid.scrollable::-webkit-scrollbar {
  display: none;
}

.categoryGrid.scrollable .blogCard {
  flex: 0 0 280px;
  margin-right: 0;
}

.categoryBtn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.85rem 1.5rem;
  border-radius: 14px;
  background: white;
  color: #64748b;
  font-weight: 600;
  border: 1px solid #e2e8f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap;
  scroll-snap-align: start;
}

.categoryBtn.active {
  background: linear-gradient(135deg, #3b82f6 0%, #60a5fa 100%);
  color: white;
  border: none;
  box-shadow: 0 8px 16px rgba(59, 130, 246, 0.25);
}

.categoryIcon {
  font-size: 1.4rem;
}

.blogCard {
  background: white;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  cursor: pointer;
  transition: all 0.3s ease;
}

.blogCard:hover {
  transform: translateY(-4px);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.cardImage {
  position: relative;
  padding-top: 66%; /* 3:2 aspect ratio */
  overflow: hidden;
}

.cardImage img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.blogCard:hover .cardImage img {
  transform: scale(1.05);
}

.cardOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: linear-gradient(180deg, rgba(0,0,0,0.4) 0%, transparent 100%);
}

.readTime {
  background: rgba(255, 255, 255, 0.9);
  padding: 0.4rem 0.8rem;
  border-radius: 20px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  color: #374151;
  backdrop-filter: blur(4px);
}

.cardTag {
  background: rgba(255, 255, 255, 0.9);
  padding: 0.4rem 0.8rem;
  border-radius: 20px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  color: #374151;
  backdrop-filter: blur(4px);
}

.cardContent {
  padding: 1.25rem;
}

.cardContent h3 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 0.5rem;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cardContent time {
  font-size: 0.875rem;
  color: #6b7280;
}

.categoryTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  padding: 0 0.5rem;
}

.categoryTitle h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.scrollButtons {
  display: flex;
  gap: 0.5rem;
}

.scrollButton {
  background: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.scrollButton:hover {
  background: #f3f4f6;
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.scrollButton svg {
  width: 24px;
  height: 24px;
  color: #4b5563;
}

@media (max-width: 768px) {
  .container {
    padding: 1rem 0.25rem;
  }

  .categorySection {
    padding: 1rem 0.5rem;
    margin-bottom: 1.5rem;
    border-radius: 16px;
  }

  .categoryGrid {
    gap: 0.75rem;
  }

  .blogCard {
    border-radius: 12px;
  }

  .cardImage {
    padding-top: 56%; /* 16:9 aspect ratio for more compact look */
  }

  .cardContent {
    padding: 0.75rem;
  }

  .cardContent h3 {
    font-size: 0.95rem;
    margin-bottom: 0.25rem;
    -webkit-line-clamp: 2;
  }

  .cardContent time {
    font-size: 0.75rem;
  }

  .cardOverlay {
    padding: 0.5rem;
  }

  .readTime, .cardTag {
    padding: 0.25rem 0.5rem;
    font-size: 0.7rem;
  }

  .categoryHeader {
    margin-bottom: 0.75rem;
  }

  .categoryTitle {
    font-size: 1.1rem;
  }

  .scrollButton {
    width: 32px;
    height: 32px;
  }

  .scrollButton svg {
    width: 16px;
    height: 16px;
  }
}

/* Add smooth scroll behavior for category navigation */
.categories {
  scroll-behavior: smooth;
}

/* Improve touch interactions */
@media (hover: none) {
  .categoryBtn:active {
    transform: scale(0.98);
  }

  .blogCard:active {
    transform: scale(0.98);
  }
}

/* Enhanced Hero Section */
.heroSection {
  background: linear-gradient(135deg, #0061ff 0%, #60efff 100%);
  padding: 6rem 2rem;
  color: white;
  position: relative;
  overflow: hidden;
}

.heroSection::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: url('/path-to-pattern.svg') repeat; */
  opacity: 0.1;
  animation: slide 20s linear infinite;
}

.heroContent {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
}

.heroContent h1 {
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 1rem;
  line-height: 1.2;
}

.heroContent p {
  font-size: 1.2rem;
  opacity: 0.9;
  margin-bottom: 2rem;
}

.searchContainer {
  max-width: 600px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 50px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.searchContainer input {
  flex: 1;
  background: transparent;
  border: none;
  padding: 1rem;
  color: white;
  font-size: 1rem;
}

.searchContainer input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.searchContainer button {
  background: white;
  color: #0061ff;
  border: none;
  padding: 0.8rem 2rem;
  border-radius: 25px;
  font-weight: 600;
  cursor: pointer;
  margin-right: 0.5rem;
}

.popularTags {
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
}

.tagPill {
  background: rgba(255, 255, 255, 0.1);
  padding: 0.5rem 1.5rem;
  border-radius: 25px;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
}

.tagPill.active {
  background: white;
  color: #0061ff;
}

.featuredSection {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;
  margin-bottom: 3rem;
  max-width: 1200px;
  margin: 0 auto 3rem;
}

.sectionTitle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  color: white;
}

.featuredBlog {
  background: white;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  border: 1px solid #e2e8f0;
  height: fit-content;
  will-change: transform;
  transform-origin: center center;
}

.featuredImage {
  position: relative;
  height: 360px;
  overflow: hidden;
}

.featuredImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.featuredBadge {
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  background: rgba(59, 130, 246, 0.95);
  color: white;
  padding: 0.7rem 1.4rem;
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  font-size: 0.95rem;
  backdrop-filter: blur(4px);
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.3);
}

.featuredContent {
  padding: 2rem;
}

.featuredTag {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.6rem 1.2rem;
  background: #f0f9ff;
  color: #3b82f6;
  border-radius: 50px;
  font-size: 0.95rem;
  font-weight: 500;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
}

.featuredContent h2 {
  font-size: 1.8rem;
  font-weight: 700;
  color: #1e293b;
  margin-bottom: 1rem;
  line-height: 1.3;
}

.featuredContent p {
  color: #64748b;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  font-size: 1.05rem;
}

.featuredMeta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #64748b;
  font-size: 0.95rem;
  padding-top: 1rem;
  border-top: 1px solid #f1f5f9;
}

/* Recent Posts */
.recentPosts {
  background: white;
  border-radius: 24px;
  padding: 1.5rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  border: 1px solid #e2e8f0;
  height: fit-content;
}

.recentPosts h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 2px solid #f1f5f9;
}

.recentPost {
  display: flex;
  gap: 1rem;
  padding: 1rem 0;
  border-bottom: 1px solid #f1f5f9;
  cursor: pointer;
  will-change: transform;
}

.recentPost:last-child {
  border-bottom: none;
}

.recentPost img {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  object-fit: cover;
}

.recentPost h4 {
  font-size: 0.95rem;
  font-weight: 500;
  color: #1e293b;
  margin-bottom: 0.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.recentPost time {
  font-size: 0.8rem;
  color: #64748b;
}

/* Category Sections */
.categorySection {
  scroll-margin-top: 100px; /* Accounts for sticky header */
  padding: 2rem 1rem;
  background: white;
  border-radius: 24px;
  margin-bottom: 3rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
}

.categoryHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  padding: 0 0.5rem;
}

.categoryTitle {
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.categoryIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
}

.categoryGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
}

.categoryGrid.scrollable {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  padding: 1rem 0;
  gap: 1.5rem;
}

.categoryGrid.scrollable::-webkit-scrollbar {
  display: none;
}

.categoryGrid.scrollable .blogCard {
  flex: 0 0 280px;
  margin-right: 0;
}

.scrollButtons {
  display: flex;
  gap: 0.5rem;
}

.scrollButton {
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

.scrollButton:hover {
  background: #f9fafb;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.scrollButton svg {
  width: 20px;
  height: 20px;
  color: #4b5563;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .featuredSection {
    grid-template-columns: 1fr;
  }

  .featuredImage {
    height: 300px;
  }

  .recentPosts {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }

  .recentPosts h3 {
    grid-column: 1 / -1;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 1rem 0.5rem;
  }

  .featuredContent h2 {
    font-size: 1.5rem;
  }

  .recentPosts {
    grid-template-columns: 1fr;
  }

  .categoryGrid {
    grid-template-columns: 1fr;
  }

  .categoryTitle {
    font-size: 1.3rem;
  }
}

/* Animation and Interaction Styles */
.featuredBlog,
.recentPost,
.blogCard {
  transition: all 0.3s ease;
}

/* Progress Bar */
.progressBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: #3b82f6;
  transform-origin: 0%;
  z-index: 1000;
}

/* Animation Helper Classes */
.fadeIn {
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

.slideUp {
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.5s ease forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Hover Effects */
.blogCard:hover .cardImage img {
  transform: scale(1.05);
}

.categoryBtn:hover {
  transform: translateY(-2px);
}

.recentPost:hover {
  transform: translateX(8px);
}

/* Loading States */
.skeleton {
  background: linear-gradient(
    90deg,
    #f1f5f9 0%,
    #e2e8f0 50%,
    #f1f5f9 100%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  to {
    background-position: -200% 0;
  }
}

/* Smooth Scrolling Behavior */
html {
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
}
.categoryGrid {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;     /* Firefox */
}

.categoryGrid::-webkit-scrollbar {
  display: none;  /* Chrome, Safari and Opera */
}

.recentPosts {
  max-height: 87vh; /* or whatever height you prefer */
  overflow-y: auto;
  /* Add smooth scrolling */
  scroll-behavior: smooth;
}