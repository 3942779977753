/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;500;600;700;900&display=swap');

/* Base styles */
:root {
  --primary-color: rgb(16, 61, 128);
  --secondary-color: rgb(22, 80, 167);
  --text-color: rgb(35, 35, 36);
  --gray-color: rgb(104, 104, 104);
}

/* Layout */
.resume {
  min-width: 42rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5in;
  font-family: "Merriweather", serif;  
  letter-spacing: 0.02rem;
}

.resume * {
  font-family: inherit; 
  font-size: 14px;
}

/* Header styles */
.headerBar {
  width: 100%;
  height: .8rem;
  background-color: var(--primary-color);
}

.header {
  min-width: fit-content;
}

.fullName {
  font-size: 40px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.1rem;
  color: var(--primary-color);
}

.jobTitle {
  font-size: 20px;
  font-weight: 550;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}

/* Section Title styles */
.sectionTitleWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.sectionTitle {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.2rem;
  color: var(--secondary-color);
  margin-bottom: 1rem;
  white-space: nowrap;
}

.dividerLine {
  flex: 1;
  border-bottom: 2px dotted var(--secondary-color);
  margin-bottom: 12px;
}

/* Contact Info */
.contactInfo {
   
  font-weight: 400;
  color: var(--text-color);
  text-align: center;
  align-items: center;
  margin: .5rem 0 1rem;
}

/* Text styles */
.resumesampletextabout,
.resumesampletext {
 
  line-height: 130%;
  display: flex;
  gap: .3rem;
}

.resumesampletext {
  font-weight: 300;
}

.resumesampletextabout {
  font-weight: 400;
}

.resumesampletexttop {
 
  font-weight: 400;
  color: var(--text-color);
  text-align: center;
  align-items: center;
  margin: .5rem 0 1rem;
}

/* Headings */
.resumesampleh2 {
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0;
  margin-bottom: .5rem;
  display: flex;
  width: 100%; 
} 
.collegeName {
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0;
}

.resumesampleh3,
.resumesampleh4 {
   
  font-weight: 700;
  line-height: 17px;
  color: var(--gray-color);
  padding-bottom: .25rem;
  margin-bottom: 0;
}

/* Lists */
.points {
  
  padding: 0;
  margin-top: -.5rem;
}

.li {
  padding: 0 0 .3rem .25rem;
  line-height: 120%;
  font-weight: 400;
  margin: 0;
  list-style: none;
  display: flex;
}

.li::before {
  content: "•";
  color: black;
  font-weight: bold;
  display: inline-block;
  width: 1em;
}

/* Skills section */
.skillsContainer {
  display: flex;
  flex-direction: column;
}

.skillSet {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.firstSkillSet {
  width: 30%;
  margin-right: 10px;
}

.otherSkillSet {
  width: 100%;
  margin-bottom: 10px;
}

/* Utility classes */
.bold {
  font-weight: 500;
}

.description {
 
  font-style: italic;
}

.eeor {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* Links */
.resume a {
  color: #3498db;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}
.educationMainContainer {
  display: flex;
  flex-direction: column;
 
}

.resume a:hover {
  color: #2980b9;
}
 .collegeContainer {
  display: flex;
  justify-content: space-between;
 
margin-bottom: 0rem;
padding-bottom: 0rem;
 }
 .degreeContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
 }
 .projectDetails{
  font-weight: 400;
 }
 .removefontwidth {
  font-weight: 400;
  display: flex;
  justify-content: space-between;
 } 

 /* .awardsli {
  border: 1px solid red;
 }
 .awardpoint {
  border: 1px solid blue;
 }  */

.awarddescription {
  margin-bottom: 0px !important;
}
.awarddescription p {
  margin-bottom: 0px !important;
}