.container {
 
  /* margin: 1rem auto; */
  /* padding: 0 1rem; */
}

.title {
  text-align: center;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.description {
  text-align: center;
  color: #666;
  margin-bottom: 2rem;
}

.uploadForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.uploadBox {
  width: 100%;
  max-width: 500px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  background-color: #f8f9fa;
  position: relative;
  transition: opacity 0.3s ease;
}

.fileInput {
  width: 100%;
  margin-bottom: 1rem;
}

.supportedFormats {
  color: #666;
  font-size: 0.9rem;
}

.submitButton {
  padding: 0.8rem 2rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.submitButton:hover {
  background-color: #0056b3;
}

.submitButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.scoreContainer {
  margin-top: 2rem;
  text-align: center;
}

.scoreCircle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #28a745;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  margin: 2rem auto;
  transition: box-shadow 0.3s ease;
}

.recommendations {
  text-align: left;
  max-width: 600px;
  margin: 2rem auto;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.recommendations h3 {
  color: #2c3e50;
  margin-bottom: 1rem;
}

.recommendations ul {
  padding-left: 1.5rem;
}

.recommendations li {
  margin-bottom: 0.5rem;
  color: #666;
}

.headerSection {
  position: relative;
  padding: 4rem 2rem;
  text-align: center;
  overflow: hidden;
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(240,242,245,0.5) 100%);
  border-radius: 24px;
  margin-bottom: 3rem;
}

.headerContent {
  position: relative;
  z-index: 2;
}

.glowingBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.title {
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  letter-spacing: -0.02em;
  line-height: 1.2;
}

.gradientText {
  background: linear-gradient(135deg, #4285f4, #34a853);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.subtitleContainer {
  max-width: 800px;
  margin: 0 auto;
}

.description {
  font-size: 1.25rem;
  color: #666;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.stats {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  margin-top: 2rem;
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.2s ease;
}

.statNumber {
  font-size: 2.5rem;
  font-weight: 700;
  color: #4285f4;
  margin-bottom: 0.5rem;
}

.statLabel {
  font-size: 0.9rem;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.statDivider {
  height: 40px;
  width: 1px;
  background: linear-gradient(to bottom, transparent, #ddd, transparent);
}

@media (max-width: 768px) {
  .headerSection {
    padding: 3rem 1rem;
  }

  .title {
    font-size: 2.5rem;
  }

  .description {
    font-size: 1.1rem;
  }

  .stats {
    flex-direction: column;
    gap: 1.5rem;
  }

  .statDivider {
    display: none;
  }
}

.pricingCard {
  max-width: 400px;
  margin: 0 auto 3rem;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: white;
  text-align: center;
}

.pricingHeader {
  margin-bottom: 2rem;
}

.crownIcon {
  color: #ffd700;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.price {
  font-size: 2.5rem;
  color: #2c3e50;
  margin: 1rem 0;
}

.currency {
  font-size: 1.5rem;
  vertical-align: super;
}

.amount {
  font-weight: bold;
}

.period {
  font-size: 1rem;
  color: #666;
}

.features {
  text-align: left;
}

.feature {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  color: #2c3e50;
}

.checkIcon {
  color: #28a745;
  margin-right: 1rem;
  transition: transform 0.2s ease;
}

.uploadBox {
  position: relative;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.uploadIcon {
  font-size: 3rem;
  color: #007bff;
  margin-bottom: 1rem;
}

.fileInput {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.uploadLabel {
  font-size: 1.2rem;
  color: #2c3e50;
  margin: 1rem 0;
  cursor: pointer;
}

.fileSelected {
  text-align: center;
}

.changeFile {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 0.5rem;
}

.paymentButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem 2rem;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.paymentButton:hover {
  background-color: #218838;
  transform: translateY(-1px);
}

.lockIcon {
  font-size: 1rem;
}

.scoreInner {
  width: 130px;
  height: 130px;
  background: white;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.scoreNumber {
  font-size: 2.5rem;
  font-weight: bold;
  color: #2c3e50;
}

.scoreLabel {
  font-size: 0.9rem;
  color: #666;
}

.statsContainer {
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-top: 2rem;
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.statNumber {
  font-size: 2.5rem;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 0.5rem;
}

.statLabel {
  color: #666;
  font-size: 0.9rem;
}

.featureIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: rgba(40, 167, 69, 0.1);
  border-radius: 50%;
  margin-right: 1rem;
  color: #28a745;
}

.pricingCard {
  background: linear-gradient(145deg, #ffffff, #f8f9fa);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.uploadBox {
  background: linear-gradient(145deg, #ffffff, #f8f9fa);
  border: 2px dashed rgba(0, 123, 255, 0.3);
  transition: all 0.3s ease;
}

.uploadBox:hover {
  border-color: #007bff;
  background: linear-gradient(145deg, #f8f9fa, #ffffff);
}

.paymentButton {
  background: linear-gradient(45deg, #28a745, #20c997);
  box-shadow: 0 4px 15px rgba(40, 167, 69, 0.2);
}

.paymentButton:hover {
  background: linear-gradient(45deg, #218838, #1ca38b);
  box-shadow: 0 6px 20px rgba(40, 167, 69, 0.3);
}

.headerSection {
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  padding: 3rem 1rem;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.title {
  font-size: 2.5rem;
  background: linear-gradient(45deg, #2c3e50, #3498db);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
}

/* Add responsive design */
@media (max-width: 768px) {
  .statsContainer {
    flex-direction: column;
    gap: 1.5rem;
  }

  .stat {
    padding: 1rem;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
  }

  .title {
    font-size: 2rem;
  }
}

/* Modal Styles */
.modalWrapper :global(.modal-content) {
  border: none;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.modernBody {
  padding: 0;
  position: relative;
  background: linear-gradient(to right, #f8f9fa, #ffffff);
}

.closeBtn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: #666;
  font-size: 1.5rem;
  z-index: 10;
  cursor: pointer;
}

.contentWrapper {
  display: flex;
  min-height: 600px;
}

.leftSection {
  flex: 1;
  padding: 3rem;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  position: relative;
}

.rightSection {
  flex: 1;
  padding: 3rem;
  background: white;
}

.badge {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  background: rgba(255, 215, 0, 0.1);
  border-radius: 30px;
  color: #ffd700;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.modalTitle {
  font-size: 2.25rem;
  font-weight: 700;
  color: #2c3e50;
  margin-bottom: 1rem;
  line-height: 1.2;
}

.modalSubtitle {
  color: #666;
  font-size: 1.1rem;
  margin-bottom: 2rem;
}

.fileInfoCard {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.25rem;
  background: white;
  border-radius: 12px;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.fileIconWrapper {
  width: 48px;
  height: 48px;
  background: rgba(0, 123, 255, 0.1);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fileIcon {
  color: #007bff;
  font-size: 1.5rem;
}

.fileDetails {
  display: flex;
  flex-direction: column;
}

.fileName {
  font-weight: 600;
  color: #2c3e50;
}

.fileSize {
  font-size: 0.9rem;
  color: #666;
}

.pricingBox {
  background: white;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
}

.priceHeader {
  text-align: center;
  color: #666;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.priceTag {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 0.25rem;
  margin-bottom: 1.5rem;
}

.currency {
  font-size: 2rem;
  font-weight: 600;
  color: #2c3e50;
}

.amount {
  font-size: 4rem;
  font-weight: 700;
  color: #2c3e50;
  line-height: 1;
}

.period {
  font-size: 1.25rem;
  color: #666;
}

.upgradeBtn {
  width: 100%;
  padding: 1.25rem;
  background: linear-gradient(45deg, #007bff, #0056b3);
  border: none;
  border-radius: 12px;
  color: white;
  font-weight: 600;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 123, 255, 0.2);
}

.upgradeBtn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 123, 255, 0.3);
}

.securePayment {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
  color: #666;
  font-size: 0.9rem;
}

.featuresList {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.featureItem {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1.25rem;
  background: #f8f9fa;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.featureIconWrapper {
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #007bff;
}

.featureContent {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.featureTitle {
  font-weight: 600;
  color: #2c3e50;
}

.featureDescription {
  font-size: 0.9rem;
  color: #666;
}

.testimonial {
  margin-top: 2rem;
  padding: 1.5rem;
  background: #f8f9fa;
  border-radius: 12px;
  border-left: 4px solid #007bff;
}

.testimonialContent {
  font-style: italic;
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.testimonialAuthor {
  font-size: 0.9rem;
  color: #666;
}

@media (max-width: 768px) {
  .contentWrapper {
    flex-direction: column;
  }

  .leftSection,
  .rightSection {
    padding: 2rem;
  }

  .modalTitle {
    font-size: 1.75rem;
  }

  .amount {
    font-size: 3rem;
  }
}

.buttonIcon {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

/* Add these styles if they're missing */
.pricingBox {
  background: rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 12px;
  margin: 1.5rem 0;
}

.priceTag {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 0.25rem;
}

.currency {
  font-size: 1.5rem;
  color: #2c3e50;
}

.amount {
  font-size: 3rem;
  font-weight: bold;
  color: #2c3e50;
}

.period {
  color: #666;
}

.savings {
  text-align: center;
  color: #28a745;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.buttonIcon {
  margin-right: 0.5rem;
}

.featuresList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.checkIcon {
  color: #28a745;
}

/* Fix modal overlay */
:global(.modal-backdrop) {
  background-color: rgba(0, 0, 0, 0.5);
}

/* Fix modal animation */
:global(.modal) {
  transition: opacity 0.3s ease-in-out;
}

/* Fix mobile responsiveness */
@media (max-width: 768px) {
  .contentWrapper {
    flex-direction: column;
  }

  .leftSection,
  .rightSection {
    padding: 1.5rem;
  }

  .modalWrapper :global(.modal-dialog) {
    margin: 0.5rem;
  }

  .pricingBox {
    padding: 1rem;
  }

  .amount {
    font-size: 2.5rem;
  }
}

/* Update color scheme with more vibrant professional colors */
:root {
  --primary-color: #0052cc;      /* Bright professional blue */
  --secondary-color: #0747a6;    /* Deep blue */
  --accent-color: #ffab00;       /* Warm orange accent */
  --accent-light: #fff0b3;       /* Light warm accent */
  --text-primary: #172b4d;       /* Rich dark blue text */
  --text-secondary: #42526e;     /* Medium blue-grey text */
  --background-light: #f4f5f7;   /* Clean light background */
  --background-white: #ffffff;   /* Pure white */
  --success-color: #36b37e;      /* Fresh green */
  --hover-color: #0065ff;        /* Bright blue for hover */
  --shadow-color: rgba(9, 30, 66, 0.25); /* Professional shadow */
}

/* Enhance gradient backgrounds */
.headerSection {
  background: linear-gradient(135deg, #f4f5f7 0%, #ffffff 100%);
  box-shadow: 0 4px 20px rgba(9, 30, 66, 0.1);
}

.modernBody {
  background: linear-gradient(to right, #ffffff, #f4f5f7);
}

/* Update button styles with more pop */
.upgradeBtn {
  background: linear-gradient(45deg, var(--primary-color), var(--hover-color));
  box-shadow: 0 4px 12px rgba(0, 82, 204, 0.3);
  transition: all 0.3s ease;
}

.upgradeBtn:hover {
  background: linear-gradient(45deg, var(--hover-color), var(--primary-color));
  box-shadow: 0 6px 16px rgba(0, 82, 204, 0.4);
  transform: translateY(-2px);
}

/* Make feature icons more prominent */
.featureIconWrapper {
  background: linear-gradient(135deg, var(--accent-light), #fff);
  color: var(--primary-color);
  box-shadow: 0 2px 8px rgba(9, 30, 66, 0.1);
}

/* Enhanced badge styling */
.badge {
  background: linear-gradient(45deg, var(--accent-light), #fff);
  color: var(--primary-color);
  border: 1px solid rgba(0, 82, 204, 0.1);
  box-shadow: 0 2px 4px rgba(9, 30, 66, 0.1);
}

.crownIcon {
  color: var(--accent-color);
}

/* Enhanced pricing box */
.pricingBox {
  background: linear-gradient(145deg, #ffffff, #f8f9fa);
  border: 1px solid rgba(9, 30, 66, 0.1);
  box-shadow: 0 4px 16px rgba(9, 30, 66, 0.1);
}

.currency,
.amount {
  background: linear-gradient(45deg, var(--primary-color), var(--hover-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

/* Make checkmarks more vibrant */
.checkIcon {
  color: var(--success-color);
  filter: drop-shadow(0 2px 4px rgba(54, 179, 126, 0.2));
}

/* Enhanced testimonial styling */
.testimonial {
  background: linear-gradient(to right, var(--background-light), var(--background-white));
  border-left: 4px solid var(--accent-color);
  box-shadow: 0 2px 8px rgba(9, 30, 66, 0.1);
}

/* Enhanced feature items */
.featureItem {
  background: linear-gradient(145deg, var(--background-white), var(--background-light));
  border: 1px solid rgba(9, 30, 66, 0.1);
  box-shadow: 0 2px 8px rgba(9, 30, 66, 0.05);
  transition: all 0.3s ease;
}

.featureItem:hover {
  border-color: var(--primary-color);
  background: var(--background-white);
  transform: translateX(8px);
  box-shadow: 0 4px 12px rgba(9, 30, 66, 0.1);
}

/* Enhanced text styling */
.modalTitle {
  color: var(--text-primary);
  font-weight: 700;
  letter-spacing: -0.5px;
}

.modalSubtitle {
  color: var(--text-secondary);
  line-height: 1.6;
}

/* Add shimmer effect to important elements */
.upgradeBtn::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: translateX(-100%);
  animation: shimmer 3s infinite;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

/* Enhanced hover states */
.featureIconWrapper:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

/* Add subtle animations */
.modalTitle, .modalSubtitle, .pricingBox {
  animation: fadeInUp 0.5s ease-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Enhanced mobile styles */
@media (max-width: 768px) {
  .upgradeBtn {
    background: var(--primary-color);
    box-shadow: 0 4px 8px rgba(0, 82, 204, 0.2);
  }
  
  .featureItem {
    transform: none;
    margin: 8px 0;
  }
}

.errorMessage {
  color: #e53e3e;
  text-align: center;
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 0.375rem;
  background-color: #fff5f5;
  border: 1px solid #feb2b2;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.uploading {
  opacity: 0.7;
  pointer-events: none;
}

.uploadBox {
  transition: opacity 0.3s ease;
}

.errorMessage {
  background-color: #fff5f5;
  border: 1px solid #feb2b2;
  color: #e53e3e;
  padding: 0.75rem;
  border-radius: 0.375rem;
  margin-top: 1rem;
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.uploading {
  opacity: 0.7;
  pointer-events: none;
}

.uploadBox {
  transition: opacity 0.3s ease;
}

.errorMessage {
  background-color: #fff5f5;
  border: 1px solid #feb2b2;
  color: #e53e3e;
  padding: 0.75rem;
  border-radius: 0.375rem;
  margin-top: 1rem;
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.categoryCard {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.categoryProgress {
  height: 8px;
  background: #f0f0f0;
  border-radius: 4px;
  margin: 1rem 0;
  overflow: hidden;
}

.progressBar {
  height: 100%;
  background: #28a745;
  transition: width 0.3s ease;
}

.timesIcon {
  color: #dc3545;
}

.categoriesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
}

.analysisContainer {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
}

.scoreHeader {
  text-align: center;
  margin-bottom: 3rem;
}

.scoreSubtitle {
  color: #666;
  font-size: 1.1rem;
}

.totalScoreSection {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.scoreCircle {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: box-shadow 0.3s ease;
}

.scoreInner {
  background: white;
  border-radius: 50%;
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.scoreNumber {
  font-size: 3rem;
  font-weight: bold;
  color: #28a745;
}

.scoreLabel {
  font-size: 1rem;
  color: #666;
}

.categoryCard {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.categoryHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.categoryTitle {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.categoryIcon {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background: rgba(40, 167, 69, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #28a745;
}

.categoryScore {
  font-size: 1.5rem;
  font-weight: bold;
}

.scoreValue {
  color: #28a745;
}

.scoreMax {
  color: #666;
}

.categoryProgress {
  height: 8px;
  background: #f0f0f0;
  border-radius: 4px;
  margin: 1rem 0;
  overflow: hidden;
}

.progressBar {
  height: 100%;
  background: linear-gradient(90deg, #28a745, #20c997);
}

.detailsContainer {
  margin-top: 2rem;
}

.detailsList {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}

.detailsList li {
  margin: 0.5rem 0;
  padding: 0.5rem;
  background: #f8f9fa;
  border-radius: 4px;
  font-size: 0.9rem;
}

.checkIcon {
  color: #28a745;
}

.timesIcon {
  color: #dc3545;
}

@media (max-width: 768px) {
  .categoriesGrid {
    grid-template-columns: 1fr;
  }
  
  .categoryCard {
    padding: 1.5rem;
  }
}

.featuresTitle {
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: #2d3748;
}

.featuresTitleWrapper {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.rocketIcon {
 
  font-size: 1.25rem;
}

.featuresTitleDivider {
  height: 2px;
  background: linear-gradient(90deg, #4f46e5 0%, rgba(79, 70, 229, 0.1) 100%);
  margin-top: 0.75rem;
  border-radius: 2px;
}

.scoreBreakdown {
  margin-top: 2rem;
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.categoryScore {
  margin-bottom: 2rem;
  padding: 1.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
}

.categoryHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.categoryHeader h4 {
  font-size: 1.1rem;
  color: #2d3748;
  margin: 0;
}

.categoryPoints {
  background: #4f46e5;
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 999px;
  font-size: 0.9rem;
}

.categoryDetails {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.present, .missing {
  background: #f7fafc;
  padding: 1rem;
  border-radius: 8px;
}

.present h5, .missing h5 {
  color: #4a5568;
  margin-bottom: 0.75rem;
  font-size: 0.9rem;
}

.present ul, .missing ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.present li, .missing li {
  font-size: 0.9rem;
  color: #718096;
  padding: 0.25rem 0;
}

.present li::before {
  content: "✓";
  color: #48bb78;
  margin-right: 0.5rem;
}

.missing li::before {
  content: "•";
  color: #e53e3e;
  margin-right: 0.5rem;
}

.analysisContainer {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
}

.scoreHeader {
  text-align: center;
  margin-bottom: 3rem;
}

.scoreSubtitle {
  color: #666;
  font-size: 1.1rem;
}

.categoriesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
}

.categoryCard {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.categoryCard:hover {
  transform: translateY(-5px);
}

.detailsList li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0;
}

.checkIcon {
  color: #28a745;
}

.timesIcon {
  color: #dc3545;
}

.statsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  backdrop-filter: blur(10px);
  margin: 2rem 0;
}

.statCard {
  background: rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.statHeader {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.statIcon {
  font-size: 1.25rem;
  color: var(--primary-color);
}

.statHeader h4 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--text-primary);
}

.statNumber {
  display: block;
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--text-primary);
  margin: 0.5rem 0;
}

.statProgress {
  width: 100%;
  height: 6px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  margin: 0.75rem 0;
}

.progressBar {
  height: 100%;
  border-radius: 3px;
  transition: width 1s ease-in-out;
}

.statLabel {
  display: block;
  font-size: 0.9rem;
  color: var(--text-secondary);
  margin-top: 0.5rem;
}

.upgradeSection {
  /* background: rgba(255, 255, 255, 0.95); */
  backdrop-filter: blur(10px);
  border-radius: 24px;
  /* padding: 4rem 2rem; */
  text-align: center;
  position: relative;
  overflow: hidden;
  margin: 3rem auto;
  max-width: 1200px;
}

.upgradeBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 0;
}

.glowOrb {
  position: absolute;
  width: 400px;
  height: 400px;
  background: radial-gradient(circle, rgba(37, 99, 235, 0.1) 0%, rgba(37, 99, 235, 0) 70%);
  top: -200px;
  right: -200px;
  border-radius: 50%;
  animation: float 8s ease-in-out infinite;
}

.glowOrb2 {
  position: absolute;
  width: 300px;
  height: 300px;
  background: radial-gradient(circle, rgba(99, 102, 241, 0.1) 0%, rgba(99, 102, 241, 0) 70%);
  bottom: -150px;
  left: -150px;
  border-radius: 50%;
  animation: float 10s ease-in-out infinite reverse;
}

@keyframes float {
  0%, 100% { transform: translate(0, 0); }
  50% { transform: translate(20px, -20px); }
}

.upgradeBadge {
  background: linear-gradient(135deg, #3b82f6, #2563eb);
  color: white;
  margin-top: 1rem;
  padding: 0.6rem 1.8rem;
  border-radius: 30px;
  display: inline-flex;
  align-items: center;
  gap: 0.6rem;
  margin-bottom: 2rem;
  font-weight: 600;
  box-shadow: 0 4px 12px rgba(37, 99, 235, 0.2);
}

.upgradeTitle {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  padding-left: 1rem;
  padding-right: 1rem;
}

.gradientText {
  background: linear-gradient(135deg, #3b82f6, #2563eb);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.benefitsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin: 3rem 0;
  padding: 0 1rem;
}

.benefitCard {
  background: white;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}

.benefitIconWrapper {
  background: linear-gradient(135deg, #3b82f6, #2563eb);
  width: 50px;
  height: 50px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1.2rem;
}

.benefitIcon {
  color: white;
  font-size: 1.4rem;
}

.benefitTitle {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.8rem;
  color: #1f2937;
}

.benefitText {
  color: #6b7280;
  line-height: 1.6;
}

.pricingContainer {
  margin-top: 3rem;
  padding: 2rem;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.9));
  border-radius: 20px;
 
}

.priceTag {
  font-size: 3rem;
  font-weight: 700;
  color: #1f2937;
  margin-bottom: 1.5rem;
}

.currency {
  font-size: 1.8rem;
  vertical-align: super;
}

.period {
  font-size: 1.2rem;
  color: #6b7280;
}

.upgradeButton {
  background: linear-gradient(45deg, #2563eb, #3b82f6);
  color: white;
  border: none;
  padding: 1rem 2.5rem;
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 1rem;
}

.upgradeButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(37, 99, 235, 0.2);
}

.price {
  background: rgba(255, 255, 255, 0.2);
  padding: 0.3rem 0.8rem;
  border-radius: 8px;
  font-size: 0.9rem;
}

.moneyBack {
  color: #64748b;
  font-size: 0.9rem;
  margin-top: 1rem;
}

/* Modern Analysis Container */
.analysisContainer {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
  /* background: linear-gradient(145deg, #ffffff, #f8f9fa); */
  border-radius: 30px;
  /* box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05); */
}

/* Enhanced Score Header */
.scoreHeader {
  text-align: center;
  margin-bottom: 4rem;
  position: relative;
}

.scoreHeader h2 {
  font-size: 2.8rem;
  font-weight: 800;
  background: linear-gradient(135deg, #2563eb, #3b82f6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
}

.scoreSubtitle {
  font-size: 1.2rem;
  color: #64748b;
  max-width: 600px;
  margin: 0 auto;
}

/* Total Score Section */
.totalScoreSection {
  position: relative;
  margin: 4rem 0;
}

.scoreCircle {
  width: 220px;
  height: 220px;
  margin: 0 auto;
  border-radius: 50%;
  background: linear-gradient(135deg, #3b82f6, #2563eb);
  padding: 3px;
  position: relative;
  box-shadow: 0 10px 30px rgba(37, 99, 235, 0.2);
}

.scoreInner {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.scoreNumber {
  font-size: 4rem;
  font-weight: 800;
  background: linear-gradient(135deg, #2563eb, #3b82f6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1;
  margin-bottom: 0.5rem;
}

.scoreLabel {
  font-size: 1.1rem;
  color: #64748b;
  font-weight: 500;
}

/* Categories Grid */
.categoriesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  gap: 2rem;
  margin: 4rem 0;
}

.categoryCard {
  background: white;
  border-radius: 24px;
  padding: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  border: 1px solid rgba(37, 99, 235, 0.1);
}

.categoryCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  border-color: rgba(37, 99, 235, 0.2);
}

.categoryHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.categoryTitle {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.categoryIcon {
  width: 48px;
  height: 48px;
  background: linear-gradient(135deg, rgba(59, 130, 246, 0.1), rgba(37, 99, 235, 0.1));
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2563eb;
  font-size: 1.4rem;
}

.categoryTitle h4 {
  font-size: 1.3rem;
  font-weight: 600;
  color: #1f2937;
  margin: 0;
}

.categoryScore {
  background: linear-gradient(135deg, #3b82f6, #2563eb);
  padding: 0.5rem 1rem;
  border-radius: 12px;
  color: white;
}

.scoreValue {
  font-size: 1.4rem;
  font-weight: 700;
}

.scoreMax {
  font-size: 1.1rem;
  opacity: 0.8;
  margin-left: 2px;
}

/* Progress Bar */
.categoryProgress {
  height: 10px;
  background: #f1f5f9;
  border-radius: 10px;
  margin: 1.5rem 0;
  overflow: hidden;
}

.progressBar {
  height: 100%;
  background: linear-gradient(90deg, #3b82f6, #2563eb);
  border-radius: 10px;
  transition: width 1.5s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Details Container */
.detailsContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  margin-top: 2rem;
}

.present, .missing {
  background: #f8fafc;
  padding: 1.5rem;
  border-radius: 16px;
}

.present h5, .missing h5 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.detailsList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.detailsList li {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  padding: 0.75rem 0;
  color: #4b5563;
  font-size: 1rem;
  line-height: 1.5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.detailsList li:last-child {
  border-bottom: none;
}

.checkIcon {
  color: #10b981;
  font-size: 1.2rem;
  flex-shrink: 0;
}

.timesIcon {
  color: #ef4444;
  font-size: 1.2rem;
  flex-shrink: 0;
}

/* Stats Container */
.statsContainer {
  display: flex;
  justify-content: center;
  gap: 4rem;
  margin-top: 4rem;
  padding: 3rem;
  
  border-radius: 24px;
}

.stat {
  text-align: center;
  padding: 1rem 2rem;
  background: linear-gradient(135deg, #f8fafc, #ffffff);
  border-radius: 16px;
  border: 1px solid rgba(37, 99, 235, 0.1);
  transition: all 0.3s ease;
}

.stat:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 30px rgba(37, 99, 235, 0.1);
}

.statNumber {
  display: block;
  font-size: 3rem;
  font-weight: 800;
  background: linear-gradient(135deg, #2563eb, #3b82f6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.5rem;
}

.statLabel {
  color: #64748b;
  font-size: 1.1rem;
  font-weight: 500;
}

/* Decorative Elements */
.decorativeCircle {
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(135deg, rgba(59, 130, 246, 0.1), rgba(37, 99, 235, 0.1));
}

.circle1 {
  width: 100px;
  height: 100px;
  top: -20px;
  left: -20px;
}

.circle2 {
  width: 150px;
  height: 150px;
  bottom: -30px;
  right: -30px;
  display: none;
  opacity: 0.5;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .categoriesGrid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

@media (max-width: 768px) {
  .analysisContainer {
    padding: 1.5rem;
  }

  .scoreHeader h2 {
    font-size: 2.2rem;
  }

  .scoreCircle {
    width: 180px;
    height: 180px;
  }

  .scoreNumber {
    font-size: 3rem;
  }

  .statsContainer {
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
  }

  .stat {
    width: 100%;
  }
}

/* Animations */
@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

@keyframes pulse {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.05); }
}

@keyframes glow {
  0%, 100% { box-shadow: 0 0 20px rgba(37, 99, 235, 0.2); }
  50% { box-shadow: 0 0 30px rgba(37, 99, 235, 0.4); }
}

.scoreCircle {
  animation: glow 3s infinite;
}

.categoryIcon {
  animation: pulse 2s infinite;
}

.stat {
  animation: float 3s infinite;
}

/* Loading States */
.shimmer {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
}




.categoryScore {
    position: relative;
    background: linear-gradient(145deg, #ffffff, #f3f4f6);
    padding: 12px 16px;
    border-radius: 16px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease;
  }
  
  .categoryScore:hover {
    transform: translateY(-2px);
  }
  
  .scoreWrapper {
    display: flex;
    align-items: baseline;
    gap: 4px;
    position: relative;
  }
  
  .scoreValue {
    font-size: 28px;
    font-weight: 700;
    background: linear-gradient(45deg, #3b82f6, #2563eb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    animation: scoreAppear 0.6s cubic-bezier(0.26, 0.53, 0.74, 1.48);
  }
  
  .scoreGlow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    background: radial-gradient(circle, rgba(59, 130, 246, 0.5) 0%, rgba(37, 99, 235, 0) 70%);
    border-radius: 50%;
    opacity: 0;
    animation: glowPulse 2s ease-in-out infinite;
  }
  
  .scoreMax {
    font-size: 16px;
    color: #64748b;
    opacity: 0;
    animation: fadeIn 0.3s ease forwards 0.3s;
  }
  
  .scoreProgress {
    height: 6px;
    background: #e2e8f0;
    border-radius: 3px;
    margin-top: 8px;
    overflow: hidden;
  }
  
  .progressFill {
    height: 100%;
    background: linear-gradient(90deg, #3b82f6, #2563eb);
    border-radius: 3px;
    transform-origin: left;
    animation: progressFill 1.2s cubic-bezier(0.26, 0.53, 0.74, 1.48) forwards;
    position: relative;
  }
  
  .progressFill::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    animation: shimmer 2s infinite;
  }
  
  @keyframes scoreAppear {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  @keyframes glowPulse {
    0% {
      width: 0;
      height: 0;
      opacity: 0;
    }
    50% {
      width: 100px;
      height: 100px;
      opacity: 0.5;
    }
    100% {
      width: 0;
      height: 0;
      opacity: 0;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(5px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes progressFill {
    0% {
      transform: scaleX(0);
    }
    100% {
      transform: scaleX(1);
    }
  }
  
  @keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  
  /* Add hover effects */
  .categoryScore:hover .scoreValue {
    animation: scoreJump 0.5s ease;
  }
  
  .categoryScore:hover .progressFill {
    filter: brightness(1.1);
  }
  
  @keyframes scoreJump {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-4px);
    }
  }

.perfectScore {
  border: 2px solid #34a853;
  background: linear-gradient(to bottom right, rgba(52, 168, 83, 0.05), rgba(52, 168, 83, 0.1));
}

.perfectScoreMessage {
  padding: 1.5rem;
  background: rgba(52, 168, 83, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.perfectIcon {
  color: #34a853;
  font-size: 1.5rem;
  flex-shrink: 0;
}

.perfectText {
  flex: 1;
}

.perfectText h5 {
  color: #34a853;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.perfectText p {
  color: #4a4a4a;
  margin: 0;
  font-size: 0.9rem;
  line-height: 1.4;
}

.categoryCard.perfectScore {
  position: relative;
  overflow: hidden;
}

.categoryCard.perfectScore::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 50px 50px 0;
  border-color: transparent #34a853 transparent transparent;
} 

.statsContainer {
    width: 100%;
    padding: 1rem;
    border-radius: 12px;
  }
  
  .statsGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    width: 100%;
  }
  
  .statCard {
    background: var(--card-bg);
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease;
  }
  
  .statHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  
  .statIconWrapper {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .strengthCard .statIconWrapper {
    background: var(--success-gradient);
  }
  
  .improvementCard .statIconWrapper {
    background: var(--warning-gradient);
  }
  
  .scoreCard .statIconWrapper {
    background: var(--primary-gradient);
  }
  
  .statIcon {
    color: white;
    font-size: 1.25rem;
  }
  
  .statNumber {
    font-size: 2rem;
    font-weight: 700;
    color: var(--text-primary);
  }
  
  .statInfo h4 {
    margin: 0 0 0.5rem 0;
    font-size: 1rem;
    color: var(--text-secondary);
  }
  
  .progressBar {
    width: 100%;
    height: 6px;
    background: var(--progress-bg);
    border-radius: 3px;
    overflow: hidden;
  }
  
  .progressFill {
    height: 100%;
    border-radius: 3px;
  }
  
  .strengthCard .progressFill {
    background: var(--success-gradient);
  }
  
  .improvementCard .progressFill {
    background: var(--warning-gradient);
  }
  
  .scoreCard .progressFill {
    background: var(--primary-gradient);
  }
  
  /* CSS Variables */
  :root {
    --bg-gradient: linear-gradient(145deg, #ffffff, #f8fafc);
    --card-bg: #ffffff;
    --text-primary: #1a1a1a;
    --text-secondary: #666666;
    --progress-bg: #f1f5f9;
    --success-gradient: linear-gradient(135deg, #22c55e, #16a34a);
    --warning-gradient: linear-gradient(135deg, #f59e0b, #d97706);
    --primary-gradient: linear-gradient(135deg, #3b82f6, #2563eb);
  }
  
  /* Responsive Design */
  @media screen and (max-width: 1024px) {
    .statsGrid {
      grid-template-columns: repeat(3, 1fr);
      gap: 0.75rem;
    }
    
    .statCard {
      padding: 1.25rem;
    }
    
    .statNumber {
      font-size: 1.75rem;
    }
  }
  
  @media screen and (max-width: 768px) {
    .statsGrid {
      grid-template-columns: repeat(2, 1fr);
    }
    
    .scoreCard {
      grid-column: span 2;
    }
  }
  
  @media screen and (max-width: 480px) {
    .statsGrid {
      grid-template-columns: 1fr;
    }
    
    .scoreCard {
      grid-column: span 1;
    }
    
    .statCard {
      padding: 1rem;
    }
    
    .statNumber {
      font-size: 1.5rem;
    }
  }


  .headerSection {
    position: relative;
    min-height: 600px;
    padding: 4rem 2rem;
    overflow: hidden;
    background: linear-gradient(to bottom, #0A1930, #162440);
  }
  
  .backgroundElements {
    position: absolute;
    inset: 0;
    pointer-events: none;
  }
  
  .gradientOrb1 {
    position: absolute;
    top: -10%;
    right: -5%;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    background: radial-gradient(circle at center, rgba(99, 102, 241, 0.15), transparent 70%);
    filter: blur(40px);
  }
  
  .gradientOrb2 {
    position: absolute;
    bottom: -10%;
    left: -5%;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background: radial-gradient(circle at center, rgba(59, 130, 246, 0.15), transparent 70%);
    filter: blur(40px);
  }
  
  .gridPattern {
    position: absolute;
    inset: 0;
    background-image: linear-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px),
                      linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
    background-size: 30px 30px;
    opacity: 0.5;
  }
  
  .headerContent {
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }
  
  .heroWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    align-items: center;
  }
  
  .heroLeft {
    padding-right: 2rem;
  }
  
  .titleWrapper {
    position: relative;
    margin-bottom: 2rem;
  }
  
  .mainTitle {
    font-size: 4rem;
    font-weight: 800;
    line-height: 1.1;
    color: #ffffff;
    margin-bottom: 1.5rem;
  }
  
  .gradientText {
    background: linear-gradient(135deg, #6366f1, #3b82f6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .titleAccent {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100px;
    height: 4px;
    background: linear-gradient(90deg, #6366f1, transparent);
    border-radius: 2px;
  }
  
  .subtitle {
    font-size: 1.25rem;
    color: #94a3b8;
    margin-bottom: 3rem;
    line-height: 1.6;
  }
  
  .statsGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }
  
  .statCard {
   
    padding: 1.5rem;
    border-radius: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: transform 0.3s ease;
  }
  
  .statCard:hover {
    transform: translateY(-5px);
    background: rgba(255, 255, 255, 0.08);
  }
  
  .statValue {
    font-size: 2rem;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 0.5rem;
  }
  
  .statLabel {
    font-size: 0.875rem;
    color: #94a3b8;
  }
  
  .statProgress {
    height: 3px;
    background: linear-gradient(90deg, #6366f1, #3b82f6);
    border-radius: 1.5px;
    margin-top: 1rem;
    width: 60%;
  }
  
  .heroRight {
    position: relative;
  }
  
  .imageWrapper {
    position: relative;
    padding-top: 100%;
  }
  
  .heroImage {
    position: absolute;
    inset: 0;
    /* background: url('/path-to-your-hero-image.png') center center/cover; */
    border-radius: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .imageAccent {
    position: absolute;
    inset: -1px;
    border-radius: 1rem;
    background: linear-gradient(135deg, rgba(99, 102, 241, 0.1), rgba(59, 130, 246, 0.1));
    filter: blur(20px);
    z-index: -1;
  }
  
  @media (max-width: 1024px) {
    .heroWrapper {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  
    .heroLeft {
      padding-right: 0;
    }
  
    .mainTitle {
      font-size: 3rem;
    }
  }
  
  @media (max-width: 768px) {
    .statsGrid {
      grid-template-columns: 1fr;
    }
  
    .mainTitle {
      font-size: 2.5rem;
    }
  }

  .aiLoadingOverlay {
    position: fixed;
    inset: 0;
    background: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .backgroundElements {
    position: absolute;
    inset: 0;
    overflow: hidden;
  }
  
  .gradientOrb1, .gradientOrb2 {
    position: absolute;
    border-radius: 50%;
    filter: blur(80px);
  }
  
  .gradientOrb1 {
    top: -20%;
    right: -10%;
    width: 80vh;
    height: 80vh;
    background: radial-gradient(circle at center,
      rgba(255, 0, 128, 0.15),
      rgba(255, 0, 128, 0) 70%);
    animation: floatAnimation 20s ease-in-out infinite;
  }
  
  .gradientOrb2 {
    bottom: -20%;
    left: -10%;
    width: 70vh;
    height: 70vh;
    background: radial-gradient(circle at center,
      rgba(64, 156, 255, 0.15),
      rgba(64, 156, 255, 0) 70%);
    animation: floatAnimation 25s ease-in-out infinite reverse;
  }
  
  .gridPattern {
    position: absolute;
    inset: 0;
    background-image: 
      linear-gradient(rgba(255, 255, 255, 0.03) 1px, transparent 1px),
      linear-gradient(90deg, rgba(255, 255, 255, 0.03) 1px, transparent 1px);
    background-size: 50px 50px;
    transform: perspective(500px) rotateX(60deg);
    animation: gridMove 20s linear infinite;
  }
  
  .aiLoadingContainer {
    position: relative;
    width: 100%;
    max-width: 600px;
    padding: 2rem;
    text-align: center;
  }
  
  .loadingHeader {
    margin-bottom: 4rem;
  }
  
  .aiLogo {
    width: 80px;
    height: 80px;
    margin: 0 auto 2rem;
    background: linear-gradient(135deg, #FF0080, #409CFF);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .logoInner {
    font-size: 2rem;
    font-weight: 800;
    color: white;
    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .aiLogo::before {
    content: '';
    position: absolute;
    inset: -2px;
    background: linear-gradient(135deg, #FF0080, #409CFF);
    border-radius: inherit;
    opacity: 0.5;
    filter: blur(10px);
  }
  
  .loadingHeader h2 {
    font-size: 2.5rem;
    font-weight: 800;
    background: linear-gradient(135deg, #FF0080, #409CFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 1rem;
  }
  
  .loadingHeader p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.1rem;
  }
  
  .processingSteps {
    margin: 3rem 0;
  }
  
  .step {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    background: rgba(255, 255, 255, 0.03);
    padding: 1.2rem;
    border-radius: 16px;
    margin-bottom: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.05);
    transition: transform 0.3s ease;
  }
  
  .step:hover {
    transform: translateX(10px);
    background: rgba(255, 255, 255, 0.05);
  }
  
  .stepIcon {
    width: 48px;
    height: 48px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
  }
  
  .stepContent {
    flex: 1;
  }
  
  .stepContent span {
    display: block;
    color: white;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  
  .progressBar {
    height: 6px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    overflow: hidden;
  }
  
  .progressFill {
    height: 100%;
    background: linear-gradient(90deg, #FF0080, #409CFF);
    border-radius: 3px;
  }
  
  .loadingIndicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  
  .pulseRings {
    position: relative;
    width: 40px;
    height: 40px;
  }
  
  .ring {
    position: absolute;
    inset: 0;
    border: 2px solid transparent;
    border-top-color: #FF0080;
    border-right-color: #409CFF;
    border-radius: 50%;
    animation: ringRotate 1.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }
  
  .ring:nth-child(2) {
    animation-delay: -0.3s;
    scale: 0.8;
  }
  
  .ring:nth-child(3) {
    animation-delay: -0.6s;
    scale: 0.6;
  }
  
  .loadingIndicator p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.9rem;
  }
  
  @keyframes floatAnimation {
    0%, 100% { transform: translate(0, 0); }
    50% { transform: translate(-30px, 30px); }
  }
  
  @keyframes gridMove {
    0% { transform: perspective(500px) rotateX(60deg) translateY(0); }
    100% { transform: perspective(500px) rotateX(60deg) translateY(50px); }
  }
  
  @keyframes ringRotate {
    0% { transform: rotate(0); }
    100% { transform: rotate(360deg); }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .aiLoadingContainer {
      padding: 1.5rem;
    }
  
    .loadingHeader h2 {
      font-size: 2rem;
    }
  
    .step {
      padding: 1rem;
    }
  
    .stepIcon {
      width: 40px;
      height: 40px;
      font-size: 1.2rem;
    }
  }


  .originalPrice {
    position: relative;
    margin-right: 8px;
    color: #666;
    font-size: 0.8em;
  }
  
  .originalPrice::after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 2px;
    background-color: #ff4444;
    transform: rotate(-10deg);
  }

.lockedContent {
  padding: 2rem;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  backdrop-filter: blur(8px);
}

.lockMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.lockIcon {
  font-size: 2rem;
  color: #666;
}

.unlockButton {
  padding: 0.8rem 1.5rem;
  background: linear-gradient(135deg, #4a90e2 0%, #357abd 100%);
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.unlockButton:hover {
  background: linear-gradient(135deg, #357abd 0%, #2868a9 100%);
}

.specialOffer {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
    justify-content: center;
  }
  
  .originalPrice {
    text-decoration: line-through;
    color: #999;
    font-size: 2rem;
  }
  
  .discount {
    background: #ff4d4d;
    color: white;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.9rem;
    font-weight: bold;
  }
  
  .limitedTime {
    color: #ff4d4d;
    font-weight: bold;
    display: block;
    margin-bottom: 4px;
  }
  
  .securePayment {
    text-align: center;
    color: #666;
    font-size: 0.9rem;
    margin-top: 12px;
  }
  
  .buttonGlow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at center, rgba(255,255,255,0.8) 0%, transparent 70%);
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .upgradeButton:hover .buttonGlow {
    opacity: 0.3;
  }

  