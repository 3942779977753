.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 1rem;
  }
  
  .modalContent {
    background: linear-gradient(145deg, #ffffff, #f8f9ff);
    border-radius: 24px;
    padding: 2.5rem;
    max-width: 650px;
    width: 100%;
    position: relative;
    box-shadow: 0 20px 40px rgba(66, 133, 244, 0.15),
                0 0 100px rgba(66, 133, 244, 0.1);
  }
  
  .closeButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    transition: all 0.2s ease;
  }
  
  .closeButton:hover {
    background: #f5f5f5;
    color: #333;
  }
  
  .campaignHeader {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .headerBadge {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    background: linear-gradient(135deg, #e8f0fe, #d1e3ff);
    color: #4285f4;
    padding: 0.6rem 1.2rem;
    border-radius: 30px;
    margin-bottom: 1.2rem;
    font-weight: 600;
    box-shadow: 0 4px 15px rgba(66, 133, 244, 0.15);
  }
  
  .headerBadge svg {
    color: #4285f4;
  }
  
  .campaignHeader h2 {
    font-size: 1.8rem;
    margin: 0;
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  .campaignHeader p {
    color: #666;
    margin: 0;
    font-size: 1.1rem;
  }
  
  .featuresGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .featureCard {
    background: linear-gradient(145deg, #ffffff, #f8f9ff);
    border-radius: 20px;
    padding: 1.8rem;
    text-align: left;
    border: 1px solid rgba(66, 133, 244, 0.1);
    box-shadow: 0 8px 20px rgba(66, 133, 244, 0.08);
    position: relative;
    overflow: hidden;
  }
  
  .featureCard::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: linear-gradient(90deg, #4285f4, #34a853, #fbbc05, #ea4335);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .featureCard:hover::before {
    opacity: 1;
  }
  
  .featureIcon {
    background: linear-gradient(135deg, #f8f9ff, #ffffff);
    width: 56px;
    height: 56px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.2rem;
    color: #4285f4;
    box-shadow: 0 8px 16px rgba(66, 133, 244, 0.15);
    position: relative;
  }
  
  .featureIcon::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: rgba(66, 133, 244, 0.15);
    filter: blur(8px);
    z-index: -1;
  }
  
  .featureCard h3 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    color: #333;
  }
  
  .featureCard p {
    margin: 0;
    margin-bottom: 1rem;
    color: #666;
    font-size: 0.9rem;
    line-height: 1.5;
  }
  
  .featureCard button {
    background: none;
    border: none;
    color: #4285f4;
    font-weight: 500;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9rem;
  }
  
  .campaignFooter {
    text-align: center;
    border-top: 1px solid #eee;
    padding-top: 1.5rem;
  }
  
  .campaignFooter p {
    margin: 0;
    margin-bottom: 1rem;
    color: #666;
    font-size: 0.9rem;
  }
  
  .mainButton {
    background: linear-gradient(135deg, #4285f4, #357abd);
    color: white;
    border: none;
    padding: 0.9rem 2.5rem;
    border-radius: 12px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0 8px 20px rgba(66, 133, 244, 0.25);
    transition: all 0.3s ease;
  }
  
  .mainButton:hover {
    box-shadow: 0 12px 25px rgba(66, 133, 244, 0.35);
    transform: translateY(-2px);
  }
  
  @media (max-width: 640px) {
    .featuresGrid {
      grid-template-columns: 1fr;
    }
    
    .modalContent {
      padding: 1.5rem;
    }
    
    .campaignHeader h2 {
      font-size: 1.5rem;
    }
  }