.dlo {
    background-color: white;
    position: absolute; 
    right: 0px;
    top: 10px; 
    display: flex;
    gap: 1px; 
   
    width: 6rem;
    height: 3em; 
    border-radius: 30px;
}
.dlod{
    height: 2rem; 
    width: 2rem;
}
.dlod:hover {
    background-color: #6267ff; 
    color: white;
}
.dlll{
    size: 19px;
   width: 8px; 
}

.sslo{
    position:relative !important;
    right: 0px !important;
    bottom:-30px !important;
}

.dldodd img {
   height: 40px;
   width: 40px;
}

.dlsls {
    display: flex; 
     justify-content: space-between;
}
.llos {
    /* border: 2px solid red; */
    color:  #727272;
    display: flex;
    justify-content: center;
    align-items: center; 
    position: absolute;
    right: 20px;
    top: 20px;
} 
.ggo:hover {
    border: 1px solid rgba(0, 0, 0, 0.557);
}

.ggo:hover .llos {
    border-radius: 50%;
    background-color: #6267ff;
    color: white; 
    height: 2rem;
    width: 2rem;
}
@media only screen and (max-width: 1100px) { 
    .dlo { 
        display: none;
    }
}