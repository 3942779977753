@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
 .main{
    min-height: 100vh;
    position: relative;
    overflow-x: hidden;
    background: #f8fafc;
}
 .heading {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 16px;
    letter-spacing: 0;
    text-align: center;
    color:  #5A5D63;
 }

 .nocredit {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 28px;
    color: #5A5D63;
}

.com {
    font-size: 30px;
    margin: 0;
    font-weight: 600;
    letter-spacing: 0.8px;
    color: black;
    margin-bottom: 30px;
}

.logo{
    font-size: 2rem;
    font-weight: 700;
    color: #333;
    margin: 0;
    text-align: center;
    letter-spacing: -0.5px;
}
.logoDot {
    color: #6366F1;
}

.tagline {
    color: #666;
    font-size: 1rem;
    margin-top: 0.5rem;
    text-align: center;
    margin-bottom: 2rem;
}

.inputGroup {
    margin-bottom: 1rem;
}

.inputStyle {
    width: 100%;
    padding: 1rem;
    background: #f8fafc;
    border: 1.5px solid #e2e8f0;
    border-radius: 12px;
    color: #333;
    font-size: 0.95rem;
    transition: all 0.3s ease;
}

.inputStyle:focus {
    border-color: #6366F1;
    background: white;
    box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.1);
    outline: none;
}

.inputStyle::placeholder {
    color: #94a3b8;
}

.btn {
    width: 100%;
    padding: 1rem;
    background: #6366F1;
    border: none;
    border-radius: 12px;
    color: white;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 1.5rem;
}

.btn:hover {
    background: #4f46e5;
    transform: translateY(-1px);
}

.floatingElements {
    position: fixed;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
}

.leftElements, .rightElements {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.leftElements {
    left: 5%;
}

.rightElements {
    right: 5%;
}

.sectorBadge {
    background: rgba(255, 255, 255, 0.95);
    border-radius: 16px;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    gap: 12px;
    box-shadow: 0 4px 12px rgba(99, 102, 241, 0.1);
    border: 1px solid rgba(99, 102, 241, 0.1);
    pointer-events: auto;
    backdrop-filter: blur(10px);
}

.featuresContainer {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 24px;
    justify-content: center;
    width: 100%;
    max-width: 900px;
    pointer-events: auto;
    z-index: 3;
    padding: 0 20px;
}

.featureCard {
    background: rgba(255, 255, 255, 0.98);
    border-radius: 16px;
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(10px);
    transition: all 0.3s ease;
    min-width: 260px;
}

.featureIconWrapper {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(99, 102, 241, 0.1);
    border-radius: 12px;
    transition: all 0.3s ease;
}

.featureContent {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.featureText {
    color: #1e293b;
    font-weight: 600;
    font-size: 1rem;
}

.featureSubText {
    color: #64748b;
    font-size: 0.85rem;
}

.googleButton {
    width: 100%;
    padding: 1rem;
    background: white;
    border: 1.5px solid #e2e8f0;
    border-radius: 12px;
    color: #333;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 1rem 0;
}

.googleButton:hover {
    background: #f8fafc;
    border-color: #6366F1;
}

.divider {
    display: flex;
    align-items: center;
    text-align: center;
    color: #94a3b8;
    margin: 1.5rem 0;
}

.divider::before,
.divider::after {
    content: '';
    flex: 1;
    border-bottom: 1.5px solid #e2e8f0;
}

.divider span {
    padding: 0 1rem;
}

@media (max-width: 1200px) {
    .leftElements, .rightElements {
        display: none;
    }
    
    .featuresContainer {
        flex-wrap: wrap;
        bottom: 2%;
        gap: 16px;
    }

    .featureCard {
        min-width: 220px;
    }
}
.formContainer {
    background: rgba(255, 255, 255, 0.98);
    border-radius: 24px;
    padding: 2.5rem;
    width: 90%;
    max-width: 420px;
    position: relative;
    z-index: 4;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.03);
    backdrop-filter: blur(10px);
    margin-bottom: 3rem;
}
@media (max-width: 768px) {
    .formContainer {
        margin: 1rem;
        padding: 1.5rem;
    }

    .featuresContainer {
        position: relative;
        padding: 20px;
        margin-top: 20px;
        transform: none;
        left: 0;
        display: none;
    }

    .featureCard {
        width: 100%;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loader {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #6366F1;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
}

.gradientBg {
    position: fixed;
    width: 100%;
    height: 100%;
    background: 
        radial-gradient(circle at 0% 0%, rgba(99, 102, 241, 0.15) 0%, transparent 50%),
        radial-gradient(circle at 100% 100%, rgba(168, 85, 247, 0.15) 0%, transparent 50%);
    z-index: 1;
}

.container {
    max-width: 1440px;
    margin: 0 auto;
    padding: clamp(1rem, 3vw, 2rem);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: clamp(2rem, 4vw, 4rem);
    align-items: center;
    min-height: 100vh;
    position: relative;
    z-index: 2;
}

/* Left Section */
.leftSection {
    padding-right: clamp(1rem, 3vw, 2rem);
    margin-bottom: 6rem;  
}

.mainTitle {
    font-size: clamp(2rem, 4vw, 3.5rem);
    font-weight: 700;
    color: #1e293b;
    line-height: 1.2;
    margin-bottom: clamp(0.5rem, 2vw, 1rem);
}

.highlight {
    background: linear-gradient(45deg, #6366F1, #A855F7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.subtitle {
    font-size: clamp(1rem, 1.5vw, 1.2rem);
    color: #64748b;
    margin-bottom: clamp(1.5rem, 3vw, 3rem);
}

.featureGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: clamp(1rem, 2vw, 1.5rem);
}

.featureCard {
    background: white;
    padding: clamp(1rem, 2vw, 1.5rem);
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(168, 85, 247, 0.1);
}

.featureIcon {
    font-size: 2rem;
    margin-bottom: 1rem;
    display: block;
}

.featureCard h3 {
    color: #1e293b;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

.featureCard p {
    color: #64748b;
    font-size: 0.95rem;
}

.featureCard:hover {
    border-color: #6366F1;
}

/* Right Section */
.formWrapper {
    background: white;
    padding: clamp(1.5rem, 3vw, 3rem);
    border-radius: 24px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);
    width: 100%;
    max-width: 520px;
    margin: 0 auto;
}

.formHeader {
    text-align: center;
    margin-bottom: clamp(1.5rem, 3vw, 2rem);
}

.formHeader h2 {
    font-size: clamp(1.5rem, 3vw, 2rem);
    margin-bottom: 0.5rem;
}

.formHeader p {
    color: #64748b;
    font-size: 0.9rem;
    margin-top: 0.5rem;
    text-align: center;
    background: linear-gradient(90deg, #6366F1, #A855F7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.inputGroup {
    display: flex;
    flex-direction: column;
    gap: clamp(0.6rem, 1.5vw, 0.8rem);
}

.nameFields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: clamp(0.5rem, 1.5vw, 1rem);
}

.inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.inputWrapper label {
    font-size: clamp(0.8rem, 1.5vw, 0.9rem);
    color: #64748b;
}

.inputWrapper input {
    width: 100%;
    padding: clamp(0.6rem, 1.2vw, 0.8rem);
    border: 1.5px solid #e2e8f0;
    border-radius: 12px;
    font-size: clamp(0.85rem, 1.2vw, 0.9rem);
}

.inputWrapper input:focus {
    border-color: #A855F7;
    box-shadow: 0 0 0 4px rgba(168, 85, 247, 0.1);
    outline: none;
}

.submitBtn {
    width: 100%;
    padding: clamp(0.8rem, 2vw, 1rem);
    background: linear-gradient(45deg, #6366F1, #A855F7);
    border: none;
    border-radius: 12px;
    color: white;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: clamp(0.8rem, 1.5vw, 1rem);
}

.submitBtn:hover {
    background: linear-gradient(45deg, #5355EA, #9544F6);
    transform: translateY(-2px);
    box-shadow: 0 8px 16px rgba(99, 102, 241, 0.2);
}

.socialLogin {
    margin: 0 0 clamp(1rem, 2vw, 1.5rem) 0;
    display: flex;
    justify-content: center;
    order: -1;
}

/* Responsive Breakpoints */
@media (max-width: 1024px) {
    .container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .leftSection {
        padding-right: 0;
        text-align: center;
    }

    .featureGrid {
        max-width: 800px;
        margin: 0 auto;
    }
}

@media (max-width: 768px) {
    .container {
        padding: 1rem;
    }

    .nameFields {
        grid-template-columns: 1fr;
    }

    .featureGrid {
        grid-template-columns: 1fr;
    }

    .formWrapper {
        padding: 1.5rem;
    }

    .leftSection {
        display: none; /* Hide on mobile for better focus on form */
    }
}

@media (max-width: 480px) {
    .formWrapper {
        padding: 1rem;
         
    }

    .inputWrapper input {
        padding: 0.8rem;
    }

    .submitBtn {
        padding: 0.8rem;
    }
}

/* Touch Device Optimizations */
@media (hover: none) {
    .inputWrapper input,
    .submitBtn {
        min-height: 44px;
    }

    .featureCard {
        transform: none !important;
    }
}

/* Dark Mode Support */
@media (prefers-color-scheme: dark) {
    .main {
        background: #0f172a;
    }

    .formWrapper,
    .featureCard {
        background: #1e293b;
    }

    .inputWrapper input {
        background: #334155;
        border-color: #475569;
        color: #f8fafc;
    }

    .inputWrapper input:focus {
        border-color: #A855F7;
        box-shadow: 0 0 0 4px rgba(168, 85, 247, 0.2);
    }

    .featureCard:hover {
        border-color: #A855F7;
    }
}

.loginLink span, 
.termsWrapper a {
    color: #6366F1;
    transition: color 0.3s ease;
     
}
.loginLink {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    gap: 5px;
}
.loginLink span:hover, 
.termsWrapper a:hover {
    color: #A855F7;
}

.logoText {
    font-size: 2rem;
    font-weight: 700;
    color: #1e293b;
    margin: 0;
    letter-spacing: -0.5px;
    margin-bottom: .5rem;
    cursor: pointer;
}


.logoTextSpan {
    color:rgb(249, 111, 89);;
}

.trustIndicators {
  display: flex;
  justify-content: space-around;
  margin: 2rem 0;
  gap: 2rem;
}

.trustStat {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.statNumber {
  font-size: 1.8rem;
  font-weight: bold;
  background: linear-gradient(45deg, #6366F1, #A855F7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.statLabel {
  font-size: 0.9rem;
  color: #64748b;
  margin-top: 0.25rem;
}

.trustBadges {
  margin: 2rem 0;
  text-align: center;
}

.trustText {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #64748b;
  font-size: 0.9rem;
  margin-top: 1rem;
}

.verifiedBadge {
  background: #22c55e;
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
}