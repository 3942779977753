@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.modalWrapper :global(.modal-content) {
  background: transparent;
  border: none;
  overflow: hidden;
}

.modalBackground {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(8px);
}

.bodys {
  padding: 0 !important;
  border-radius: 32px !important;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.98));
  position: relative;
  overflow: hidden;
  box-shadow: 
    0 20px 40px rgba(0, 0, 0, 0.1),
    0 0 0 1px rgba(255, 255, 255, 0.1);
}

.bodys  *{
    font-family: "inter";
  }
.close {
  display: flex;
  justify-content: end;
  position: absolute;
  top: 1rem;
  right: 1rem; 
}

.closeButton {
  color:#909AB6;
  cursor: pointer;

  height: 2rem;
  width: 2rem;
  border-radius: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconcut {
  cursor: pointer;
}
.iconcut:hover {
  transform: rotate(90deg);

  transition: transform 0.5s;
}

.second {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 32px;
  padding-top: 54px;
  padding-left: 48px;
  padding-right: 48px;
  min-height: 460px;
  border-radius: 16px;
}
.container {
  position: relative;
  padding: 48px;
  min-height: 520px;
}
.topHeadingContainer {
    /* border: 2px solid blue; */
}

.headtitle {
    font-size: 40px;
    font-weight: 800;
    background: linear-gradient(135deg, #8061DD, #9747FF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 16px;
    line-height: 1.2;
}
.headtitlparagraph{
    color: #757F9B;
    font-size: 18px;
    font-weight: 500;
}
.twoboxContainer {
    display: flex;
    justify-content: center;
    gap: 32px;
    margin-bottom: 40px;
}
.SkipStepContainer {
    display: flex;
    justify-content: end;
    color: #909AB6;
    font-size: 16px;
    align-items: center;
    gap: 10px;
}
.eachContainerfirst {
    border: 2px solid #CDD4E6; 
    width: 400px;
    height: 344px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    flex-direction: column; 
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.eachContainerfirst:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(128, 97, 221, 0.1);
}

.eachContainersecond {
    border: 2px solid #BAA3FF; 
    width: 400px;
    height: 344px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background: linear-gradient(90deg, rgba(245, 242, 255, 0.5) 0%, rgba(255, 236, 251, 0.5) 100%);
    flex-direction: column;
    cursor: pointer;
}

.textContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 24px;
}
.toptext {
    color: #535D79; 
    font-weight: 500;
    font-size: 24px;
}
.downtext {
    color: #7E88A7;  
    font-size: 18px;
}
@media screen and (max-width: 768px) { 

    .twoboxContainer {
        flex-wrap: wrap;
    }
   }

.comingSoonBadge {
    position: absolute;
    top: 20px;
    right: -40px;
    background: #8061DD;
    color: white;
    padding: 6px 40px;
    font-size: 12px;
    font-weight: 600;
    transform: rotate(45deg);
    box-shadow: 0 2px 8px rgba(128, 97, 221, 0.2);
    overflow: hidden;
    width: 170px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.comingSoonBadge::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 50%;
    height: 100%;
    background: linear-gradient(
        to right,
        rgba(255,255,255,0) 0%,
        rgba(255,255,255,0.2) 50%,
        rgba(255,255,255,0) 100%
    );
    transform: skewX(-25deg);
    animation: shine 10s infinite ease-in-out;
}

@keyframes shine {
    0% {
        left: -100%;
    }
    20%, 100% {
        left: 200%;
    }
}

.backgroundElements {
  position: absolute;
  inset: 0;
  overflow: hidden;
  pointer-events: none;
}

.gradientOrb {
  position: absolute;
  width: 500px;
  height: 500px;
  background: radial-gradient(circle, rgba(128, 97, 221, 0.1) 0%, transparent 70%);
  top: -250px;
  right: -250px;
  border-radius: 50%;
}

.closeButton {
  position: absolute;
  top: 24px;
  right: 24px;
  background: rgba(144, 154, 182, 0.1);
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  transition: all 0.3s ease;
}

.closeIcon {
  color: #8061DD;
  width: 20px;
  height: 20px;
}

.headerSection {
  text-align: center;
  margin-bottom: 48px;
}

.sparkleContainer {
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-bottom: 24px;
}

.sparkleIcon, .zapIcon {
  color: #8061DD;
  width: 32px;
  height: 32px;
  filter: drop-shadow(0 0 8px rgba(128, 97, 221, 0.3));
}

.headtitle {
  font-size: 40px;
  font-weight: 800;
  background: linear-gradient(135deg, #8061DD, #9747FF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 16px;
  line-height: 1.2;
}

.headtitlparagraph {
  color: #757F9B;
  font-size: 18px;
  font-weight: 500;
}

.twoboxContainer {
  display: flex;
  justify-content: center;
  gap: 32px;
  margin-bottom: 40px;
}

.eachContainersecond, .eachContainerfirst {
  width: 400px;
  height: 360px;
  border-radius: 24px;
  position: relative;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.eachContainersecond {
  background: linear-gradient(135deg, rgba(245, 242, 255, 0.9), rgba(255, 236, 251, 0.9));
  border: 2px solid rgba(186, 163, 255, 0.3);
}

.eachContainerfirst {
  background: rgba(255, 255, 255, 0.9);
  border: 2px solid rgba(205, 212, 230, 0.3);
}

.cardInner {
  height: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  position: relative;
}

.iconContainer {
  background: rgba(128, 97, 221, 0.1);
  padding: 32px;
  border-radius: 24px;
  transition: all 0.3s ease;
}

.iconContainer img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.textContent {
  text-align: center;
}

.textContent h2 {
  font-size: 26px;
  font-weight: 700;
  color: #535D79;
  margin-bottom: 12px;
}

.textContent p {
  font-size: 18px;
  color: #7E88A7;
  font-weight: 500;
}

.arrowIndicator {
  position: absolute;
  bottom: 24px;
  right: 24px;
  background: rgba(128, 97, 221, 0.1);
  padding: 12px;
  border-radius: 50%;
  color: #8061DD;
  opacity: 0;
  transform: translateX(-10px);
  transition: all 0.3s ease;
}

.eachContainersecond:hover .arrowIndicator,
.eachContainerfirst:hover .arrowIndicator {
  opacity: 1;
  transform: translateX(0);
}

.comingSoonBadge {
  position: absolute;
  top: 24px;
  right: -45px;
  background: #8061DD;
  color: white;
  padding: 8px 48px;
  font-size: 12px;
  font-weight: 700;
  transform: rotate(45deg);
  width: 200px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  box-shadow: 0 4px 12px rgba(128, 97, 221, 0.3);
}

.skipStepContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  color: #8061DD;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

@media screen and (max-width: 968px) {
  .container {
    padding: 32px 24px;
  }

  .twoboxContainer {
    flex-direction: column;
    align-items: center;
  }

  .headtitle {
    font-size: 32px;
  }

  .eachContainersecond, .eachContainerfirst {
    width: 100%;
    max-width: 400px;
    height: 320px;
  }

  .cardInner {
    padding: 24px;
  }
}

@keyframes float {
  0%, 100% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
}

@keyframes glow {
  0%, 100% { filter: drop-shadow(0 0 8px rgba(128, 97, 221, 0.3)); }
  50% { filter: drop-shadow(0 0 16px rgba(128, 97, 221, 0.5)); }
}

.sparkleIcon, .zapIcon {
  animation: glow 3s infinite;
}

/* Add shimmer effect to cards */
.eachContainersecond::after,
.eachContainerfirst::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  transform: rotate(45deg);
  animation: shimmer 3s infinite;
  pointer-events: none;
}

@keyframes shimmer {
  0% { transform: translateX(-100%) rotate(45deg); }
  100% { transform: translateX(100%) rotate(45deg); }
}

/* Enhanced Mobile Responsiveness */
@media screen and (max-width: 1024px) {
  .container {
    padding: 32px 20px;
  }

  .headtitle {
    font-size: 32px;
    padding: 0 16px;
  }

  .headtitlparagraph {
    font-size: 16px;
    padding: 0 16px;
  }
}

@media screen and (max-width: 768px) {
  .modalWrapper :global(.modal-dialog) {
    margin: 16px;
  }

  .bodys {
    border-radius: 24px !important;
  }

  .container {
    padding: 24px 16px;
    min-height: auto;
  }

  .closeButton {
    top: 16px;
    right: 16px;
    width: 36px;
    height: 36px;
  }

  .headerSection {
    margin-bottom: 32px;
  }

  .sparkleContainer {
    margin-bottom: 16px;
  }

  .sparkleIcon, .zapIcon {
    width: 24px;
    height: 24px;
  }

  .headtitle {
    font-size: 24px;
    margin-bottom: 12px;
  }

  .headtitlparagraph {
    font-size: 14px;
  }

  .twoboxContainer {
    gap: 20px;
    margin-bottom: 24px;
  }

  .eachContainersecond, .eachContainerfirst {
    height: auto;
    min-height: 280px;
  }

  .cardInner {
    padding: 24px 16px;
    gap: 20px;
  }

  .iconContainer {
    padding: 20px;
    border-radius: 16px;
  }

  .iconContainer img {
    width: 60px;
    height: 60px;
  }

  .textContent h2 {
    font-size: 20px;
    margin-bottom: 8px;
  }

  .textContent p {
    font-size: 14px;
  }

  .comingSoonBadge {
    font-size: 10px;
    padding: 6px 40px;
    width: 160px;
    right: -35px;
    top: 20px;
  }

  .arrowIndicator {
    padding: 8px;
    bottom: 16px;
    right: 16px;
  }

  .skipStepContainer {
    font-size: 14px;
  }
}

/* Small Mobile Devices */
@media screen and (max-width: 375px) {
  .container {
    padding: 20px 12px;
  }

  .headtitle {
    font-size: 22px;
  }

  .eachContainersecond, .eachContainerfirst {
    min-height: 240px;
  }

  .cardInner {
    padding: 20px 12px;
  }

  .iconContainer {
    padding: 16px;
  }

  .iconContainer img {
    width: 50px;
    height: 50px;
  }

  .textContent h2 {
    font-size: 18px;
  }

  .textContent p {
    font-size: 13px;
  }
}

/* Landscape Mode */
@media screen and (max-height: 600px) and (orientation: landscape) {
  .container {
    padding: 20px;
  }

  .twoboxContainer {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .eachContainersecond, .eachContainerfirst {
    width: calc(50% - 10px);
    min-height: 240px;
  }

  .headtitle {
    font-size: 24px;
  }

  .cardInner {
    padding: 20px;
  }
}

/* Dark Mode Support */
@media (prefers-color-scheme: dark) {
  .bodys {
    background: linear-gradient(135deg, rgba(30, 30, 40, 0.95), rgba(20, 20, 30, 0.98));
  }

  .headtitle {
    background: linear-gradient(135deg, #9747FF, #8061DD);
    -webkit-background-clip: text;
  }

  .headtitlparagraph {
    color: #A0A7BC;
  }

  .eachContainersecond {
    background: linear-gradient(135deg, rgba(128, 97, 221, 0.1), rgba(151, 71, 255, 0.1));
    border-color: rgba(186, 163, 255, 0.2);
  }

  .eachContainerfirst {
    background: rgba(30, 30, 40, 0.5);
    border-color: rgba(205, 212, 230, 0.1);
  }

  .textContent h2 {
    color: #E0E0E0;
  }

  .textContent p {
    color: #A0A7BC;
  }
}

/* Touch Device Optimizations */
@media (hover: none) {
  .arrowIndicator {
    opacity: 1;
    transform: translateX(0);
  }

  .eachContainersecond:active,
  .eachContainerfirst:active {
    transform: scale(0.98);
  }
}

/* Loading State */
.loading {
  opacity: 0.7;
  pointer-events: none;
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .sparkleIcon,
  .zapIcon,
  .eachContainersecond,
  .eachContainerfirst,
  .arrowIndicator {
    animation: none;
    transition: none;
  }
}