@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
.container {
  /* max-width: 1324px; */
  margin: 0 auto;
  min-height: 83dvh;
  font-family: "Rubik";
  border-top: 1px solid #e3e9f5;
}

.heading {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 1.6;
  position: relative;
  margin-top: 2rem;
}

.heading::after {
  content: "";
  position: absolute;
  width: 100px;
  height: 2.4px;
  background-color: hsl(207, 88%, 52%);
  bottom: -4px;
  left: 0;
}

.templatesGrid {
  display: flex;
  justify-content: center;
  margin-top: -1rem;
  z-index: 98;
  position: relative;
  background-color: white;
  flex-direction: column;
  box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 50px 50px 0 0;
  padding: 1rem;
}
.inner {
  width: 100%;
}
.beedh {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.templatesGridc {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  gap: 2rem;
  margin-top: 4rem;
}

.template {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin: 0 auto;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  /* background-color: #e7ecf5; */
 
  padding: 1rem;
}

.templatefores {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin: 0 auto;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  /* background-color: #e7ecf5; */
  padding: 1rem;
}

.templatefores .preview {
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  /* box-shadow: 0 0 24px hsl(222, 13%, 80%); */
}

.template .preview {
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 0 24px hsl(222, 13%, 80%);
}

.templatefores .preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
 

.template .preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.template .name {
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0.01rem;
  text-align: center;
}
.showuseresume{
  padding: 0px!important;
  border: none !important;
  box-shadow: none !important;
}
.innercontainer {
  padding-right: 16px;
  padding-left: 16px;
  padding-bottom: 40px;
  padding-top: 24px;
}
 
.show {
  display: none !important;
  
}

.template:hover .show {
  display: flex !important;
}
.but {
  background: linear-gradient(270deg, #a066ff 16.03%, #666cff 88.19%);
  color: white;
  padding: 10px 20px;
  position: absolute;
  width: 80%;
  bottom: 80px;
  border-radius: 10px;
  text-align: center;
}
 

.template:hover {
  background-color: #666bff14 !important;
}
.tickMark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tickMark::before {
  content: "";

  border-width: 0 2px 2px 0;
  border-style: solid;
  border-color: white;
  transform: rotate(45deg);
}
.fk {
  font-size: 30px;
}
.Job_sfas__0AKy9 {
  font-size: 36px !important;
  font-weight: 700;
  margin-bottom:  48px;
}
.Job_sfas__0AKy9 img {
  height: 2rem;
}
@media only screen and (max-width: 747px) {
  .fk {
    font-size: 18px;
  }
  .Job_sfas__0AKy9 {
    font-size: 25px !important;
    font-weight: 700;
  }
  .templatesGrid {
    padding: 0rem;
  }
}
