.main {
    background-color: white; 
    margin-left: 32px;
    margin-top: 32px;
    height: 100vh;
    border-radius: 16px;
   
}
.logocontainer {
    
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 3px;
    padding-top: 16px;
    padding-bottom: 16px;
}
.dot{
    color:rgb(249, 111, 89) ;
}
.maincontentContainer {
    padding-left: 24px;
    padding-right: 24px; 
    margin-top: 10px;

}


.imageConatiner {
     display: flex;
     justify-content: center;
     flex-direction: column;
     align-items: center ;
    position: relative; 
}
.imageConatiner img {
    object-fit: contain;
    height: 100px;
    width: 100px; 
    border-radius: 100%;
}
.name {
    font-size: 18px;
    font-weight: 600;
    font-family: "inter"; 
}
.designation {
    color:#545C72 ;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
}