.main {
  padding: 3rem;
}
.background {
  background: #f8fafc;

}

.beedh {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.Job_sfas__0AKy9 {
  font-size: 36px !important;
  font-weight: 700;
}
.line 
{
    margin-bottom: 48px;
color: #60696c;
text-align: center;
}
.Job_sfas__0AKy9 img {
  height: 2rem;
}
.showuseresume {
  padding: 0px !important;
  border: none !important;
  box-shadow: none !important;
}
.template {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    margin: 0 auto;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    /* background-color: #e7ecf5; */
   
    padding: 1rem;
  }
  .template .preview {
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0 0 24px hsl(222, 13%, 80%);
  }
  .template .preview img {
    width: 100%;
    /* height: 100%; */
    height: 28rem;
   
  }
  
  .template .name {
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: 0.01rem;
    text-align: center;
  }
.name {
  text-align: center;
}
.template img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.template:hover {
  background-color: #666bff14 !important;
}
.tempcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
 row-gap: 40px;
   column-gap:20px ;
}

.showMoreButtonContainer {
    
     
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.3s ease;
    display: flex;
    justify-content: end;
    
}

.showMoreButton {
    background-color: #666bff14;
    color: black;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;

}
@media only screen and (max-width: 747px) {
  .Job_sfas__0AKy9 {
    font-size: 18px !important;
    font-weight: 700;
    margin-left: 20px;
  }
  .main {
    padding: .5rem;
  }
  .template .preview img {
    object-fit: contain;
  }
}
