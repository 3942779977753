.main {
    margin-top: 110px;
    /* width: calc(100% - 32px);  */
    margin-left: 32px;
    min-height: 400px;
    margin-right: 32px;
    position: relative;
    display: flex;
    justify-content: space-evenly; /* Distributes the .eachcont equally with space between */
    align-items: flex-end; /* Aligns .eachcont at the bottom of the .main */
    
}

.mainContianer {
    width: 100%;
    height: 312px;
    border-radius: 16px;
    background-color: #8061DD;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    display: flex; 
   
}
.headingContianer {
     
    margin-top: 32px;
    margin-left: 40px;
}
.rightside {
    display: flex;
    justify-content: end;
    align-items: start;
  
    cursor: pointer; 
    position: absolute; 
    right: 32px;
} 
.head {
    color: #FFFFFF;
    font-size: 22px;
    font-family: "inter";
     letter-spacing: -2%;
}
.dhead {
    color: #D9D0F3;
    font-size: 18px;
    font-family: "inter";
    letter-spacing: -2% !important;
}
.eachcont {
    width: 235px;
    height: 274px;
    border-radius: 8px;
    z-index: 1; 
    transition: all 0.3s ease;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.eachcont:hover {
    transform: translateY(-5px) scale(1.01);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15);
    filter: brightness(1.05);
}

.eachcont:nth-child(1) {
    background-color: #FFC4DA; /* Example color for the second div */
}

.eachcont:nth-child(2) {
    background-color: #F9E79B; /* Example color for the third div */
}

.eachcont:nth-child(3) {
    background-color: #85A5FF; /* Example color for the fourth div */
}

.eachcont:nth-child(4) {
    background-color: #A1DBB7; /* Example color for the fifth div */
}
.rightside img {
    width: 15rem;
    margin-top: 1rem;
}

.eachimageContainer {
    height: 70%;
    width: 100%;
   display: flex;
   justify-content: center;
   align-items: center; 
}
.eachimageContainer img {
    max-width: 100%;  /* Ensure the image doesn't exceed the container width */
    height: auto;     /* Maintain aspect ratio */
    object-fit: contain; /* Ensures the entire image fits within the container */
    margin-top: 2rem;
}
.beloweachImageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

}


.eachimageContainerspecial {
    display: flex;
    position: absolute;
    width: 100%;
    justify-content: center;
}
.eachimageContainerspecial img {
    max-width: 100%;  /* Ensure the image doesn't exceed the container width */
    height: auto;     /* Maintain aspect ratio */
    object-fit: contain; /* Ensures the entire image fits within the container */
}
.beloweachImageContainerspecial {
    display: flex;
    align-items: center;
    justify-content: center;
   width: 100%;
}

.numberContainer {
    background-color: #ffffff;
    border-radius: 8px;
    display: flex;
    width: calc(100% - 15px);
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    bottom: 7px;
    padding: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;  /* Smooth transition for all properties */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.numberContainer:hover {
    transform: translateY(-2px);
    background-color: #f8f8f8;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.numberContainer:active {
    transform: translateY(0px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ContentContainer {
    background-color: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 15px);
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    bottom: 7px;
    padding: 10px;
}
.numberContainerleft {
    width: 28%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.numberContainerright {
    width: 65%;
    height: 100%;
}
.leftround {
    height: 44px;
    width: 44px;
    border-radius: 50%;
    background-color: #5423E7;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.title {
    display: flex;
     
    align-items: center;
    width: 100%;
    height: 100%;
    color: #3F4860;
    font-size: 16px;
    font-family: "inter";
}
.buttonCheck {
    border:1px solid #5423E7 ;
    border-radius: 8px;
    width: 100%;
    padding: 8px 12px 8px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3F4860;
    font-weight: 500;
    cursor: pointer;
}
.contenthead {
    display: flex;
     
    align-items: center;
    width: 100%;
    height: 100%;
    color: #5423E7;
    font-size: 16px;
    font-weight: 500;
    font-family: "inter";
    letter-spacing: -2%;
}
.ContentContainerDown {
    width: 100%;
}
.ContentContainerUp {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.eachContainerMainContainer {
    display: flex;
    width: 100%; 
    margin-right: 32px;
    margin-left: 32px;
    justify-content: space-between;
}

/* Medium screens (tablets, 768px and up) */
@media (min-width: 768px) {
    .eachcont {
        width: 200px;
        height: 240px;
    }
}

/* Large screens (desktops, 1024px and up) */
@media (min-width: 1324px) {
    .eachcont {
        width: 200px;
        height: 240px;
    }
}

/* Extra large screens (larger desktops, 1440px and up) */
@media (min-width: 1440px) {
    .eachcont {
        width: 235px;
        height: 274px;

    }
}

/* Small screens (mobiles, up to 767px) */
@media (max-width: 767px) {
    .eachcont {
        width: 100%; /* Full width for mobile */
        height: auto; /* Auto height to keep proportions */
    }
}