.main {
    padding: 80px 0;
    display: flex;
    justify-content: center; 
    align-items: center;
    /* background: var(--Gradient-New-Home, linear-gradient(86deg, #F0F8FF 1.71%, #FFFCEF 99.56%)); */
} 

.inner{
    width: 100%
}

.headd {
    font-weight: 600;
    font-size: 34px;
    line-height: 140%;
    color:#383838 ;
}

.foo{
    width: 90%; 
 
} 
.dlo {
    display: flex;
    justify-content: end;
    align-items: center;

}

.mak {
    display: flex;
    flex-direction: row;
    align-items: center;
     gap: 1rem;
     overflow: hidden;  
     margin-top: 1rem;
     
}  
 


.inn {
    display: inline-flex; /* Use inline-flex to work with white-space: nowrap */
    border: 1px solid #E7E7F1; 
    padding: 1rem; 
    width: 20rem;
    gap: 1rem; 
    border-radius: 10px; 
    /* justify-content: center; */
    align-items: center;
    flex: none; /* Prevents flexbox from overriding the width */
    animation: fromlefttoright 35s linear infinite; /* Apply the animation */
    white-space: nowrap; /* Prevent line breaks */
    cursor: pointer;
}

 


.inne {
    display: inline-flex; /* Use inline-flex to work with white-space: nowrap */
    border: 1px solid #E7E7F1; 
    padding: 1rem; 
    width: 20rem;
    gap: 1rem; 
    border-radius: 10px; 
    /* justify-content: center; */
    align-items: center;
    flex: none; /* Prevents flexbox from overriding the width */
    animation: righttoleft 35s  linear infinite;
    cursor: pointer;
} 


 
.eloo {
    /* background-color: #f3f1ff; */
    border-radius: 100%; 
    padding: 6px;
}

.innni{
    font-size: 14px; 
    font-weight: 500;
    color: rgb(51 65 85); 
   
}

@keyframes fromlefttoright {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
  }


  @keyframes righttoleft {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }


@media only screen and (max-width: 747px) { 

    .ngcontent{
        font-size: 25px;
    }
    ._ngcontents {
        font-size: 15px;
    }
    
    .headd {
        font-size: 25px;
    }
    .inner{
        width: 90%
    }
    .foo{
        width: 100%; 
     
    } 
}