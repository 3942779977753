.main{
    padding-top: 27px!important;
}
.heading {
    font-size: 32px;
    font-family: "inter";
    font-weight: 400;
} 
.aboutmeContainer {
    min-height: 158px;
    background-color: #ffffff; 
    border-radius: 16px;
    margin-top: 16px;
    padding: 24px;
}
.subhedingContainer {
   display: flex;
   justify-content: space-between;
}
.subheading {
    color: #414B67; 
    font-size: 16px;
    font-family: "inter";
    font-weight: 500;
    margin-bottom: 10px;
}
.aboutme {
    font-size: 14px;
    font-weight: 400;
    color: #4B5163;
}
