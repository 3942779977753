.cont {
    font-family: Arial, sans-serif;
            line-height: 1.6;
            color: #333;
            background-color: #f4f4f4;
            padding: 20px;
            margin: 0;
            max-width: 800px;
            margin: auto;
            background: #fff;
            font-size: 13px;
            border-radius: 8px;
            box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

 