.main {
    min-width: 100%;
    min-height: 80vh;
    border-radius: 10px;
}
.main * {
   font-family: "inter";
}
.header { 
font-size: 20px;
 padding: 1rem; 
 font-weight: 600;
 display: flex;
 justify-content: space-between;
}
.do {
    color: #e3c04c; 
    margin-left: 10px;
}
.custom {
    max-width: 400px; /* Set a maximum width */
  height: auto;     /* Let the height adjust based on content */
  padding: 10px;    /* Optional padding */
} 
.dor {
    width: 100%;
     height: 1px;
      background-color: #ffe58f;
      margin-top: 1rem ;
}
.dor2 {
  width: 100%;
   height: 1px;
    background-color: #ffe58f;
  
}
.content {
  
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    border-radius: .25rem;
 
}

.jd {
  display: flex;
    width: 100%;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #3C445C;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    font-family: "inter";
} 
.tot {
  box-shadow: 1px 1px 10px -2px rgba(0, 0, 0, 0.15);
   border-radius: 16px;
  background-color: #ffffff;
  min-height: 613px;
  padding: 24px;
}
 

.textBox {
  width: 100%;
  height: 200px;
  border: none;
  /* resize: none; */
  outline: none;
  font-size: 14px;
  line-height: 1.5;
  color: #6b7280; /* Light gray text color */
  background-color: transparent;
}
.test {
  display: flex;
    height: 100%;
    flex-grow: 1;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
}

.buttons {
    font-size: 14px;
    background-color: #8061DD; 
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10rem;
    width: 248px;
    height: 44px;
    cursor: pointer;
}
.buttong {
    padding: .5rem;
    background-color: green; 
    color: white;
    width: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px; 
     
}
.buttonb {
    padding: .5rem;
    background-color: #886902; 
    color: white;
     font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;   
    cursor: pointer;
}
.buttoncontainwer {
    display: flex;
    justify-content: end;
    margin-top: 16px;
}
.spedocont {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center; 
    width: 100%; 
    position: relative;
  }
  .alllor {
    /* padding: 1rem; */
  } 
  .lso {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  } 
  .ffo {
    font-size: 19px;
  } 
  .dfdf {
   font-size: 16px;
  border: 1px solid #E6DDFF;
   padding-left: 12px;
   padding-right: 8px;
   padding-top: 4px;
   padding-bottom: 4px;
   border-radius: 10rem; 
   color: #737B93;
   font-weight: 500;
  } 
  .dd {
    display: flex;
    align-items: center; 
    flex-wrap: wrap;
    gap: 12px;
  }
  .lloooo {
    font-size: 15px;
    padding: 1rem;
    text-align: center; 
    background-color: antiquewhite; 
    margin-top: 1rem;
    border-radius: 8px;
  }
  .dfld{
   display: flex;
   gap: 10px;
  } 
  .loos {
    list-style: none;
    display: flex;
    justify-content: center;
      overflow: auto;    
      gap: 20px;
  }
  .customCircuit {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  }

  .percentagetext {
    font-size: 24px; /* Adjust size as needed */
    font-weight: 700;
    margin-bottom: 0.1rem; /* Space between percentage and "Overall Score" */
  }
  
  .scorete {
    font-size: 14px; /* Adjust size as needed */
    font-weight: 400; 
  } 

  .pregresBar {
    margin-left: 2rem;  
    width: 60%; 
    display: flex;
    justify-content: center;
    align-items: center; 
    flex-direction: column;
  }

  .containerp{
    width: 100%;
    max-width: 400px; /* Adjust as needed */
    margin: 6px auto;
     
  }

  .dloooo {
    font-size: 12px;
    margin-bottom: 4px;
    font-weight: bold;
  } 
  .barp {
    position: relative;
    background-color: #eee;
    height: 8px;
    border-radius: 0.75rem;
    overflow: hidden;
    border-radius: 109px;
  }
  .fillp {
  
    height: 100%;
    border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
    transition: width 0.3s ease;
  }

  .ishika {
    display: flex;
  }

  .rowishika {
     display: flex;
     justify-content: space-evenly;
     align-items: center;
     padding: 10px;
     border-top: 1px solid #f0f0f0;
     color: #7b7b7b;
     font-weight: 500;
     font-size: 14px;
  }

  .actiroishika {
    color: rgb(102, 108, 255);
    font-weight: 600;
    border-bottom: 3px solid rgb(102, 108, 255);
  }

  .flfo { 
    margin-top: 1rem;
    border-radius: 16px; 
    padding-top: 20px;
    padding-bottom: 32px;
    padding-right: 24px;
    padding-left: 24px;
    cursor: pointer;
    background-color: #ffffff;
    box-shadow: 1px 1px 10px -2px rgba(0, 0, 0, 0.15);
  
     display: flex;
     flex-direction: column;
     gap: 16px;
  } 

  .flfoac {
    border: 1px solid  brown;
  }
.opneskil {
  padding-bottom: 20px !important;
  gap: 0 !important;
}
  .headree {
    font-size: 14px;
    font-weight: 500; 
    display: flex;
    justify-content: space-between;
  } 

  .firstno {
    display: flex;
  }

  .ddepal {
    font-size: 16px !important;
    font-weight: 500; 
    display: flex;
    align-items: center; 
    padding: 7px;
    color: #414B67;
    justify-content: space-between;
    
  } 
.ecahScoreMian {
  display: flex;
  align-items: center;
  gap: 10px;
}
  .blinkdot {
    width: 4px;
    height: 25px;
    background-color: brown;
    border-radius: 10px;
    animation: blink 1s infinite; 
    margin-right: 10px;
  }
 
  .full{
    max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
   font-size: 13px;
  }
  .full ul {
    margin-top: 10px;
  }

 .showMoreBtn {
  font-size: 14px;
  color: rgb(64, 64, 245);
  font-weight: 600;
 }
 .proffestionald {
  font-size: 14px;
  border: 1px solid #a066ff;
  border-radius: 10px;
  padding: 4px;  
  margin-top: 10px;
 }
 
 .aibuttonf {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #8061DD;
  font-weight: 500;
  border-radius: 10rem;
  cursor: pointer;
  border: 1px solid #8061DD;
  
  font-size: 14px;
 }
 .boroerr {
  border-radius: 10rem;
  padding: 0.3rem;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #8061DD;
  color: white;
 }
 .dfroore {
  display: flex;
  gap: 10px;
  justify-content: end;
 }
 .mainsfr {
  padding: 10px;
 }
 .headingiro {
  font-size: 16px; 
  text-align: center;
  padding: .5rem;
  font-weight: 600;
 }

 .TipsContainer {
  margin-top: 1rem;
  margin-bottom: 2rem;
  border-radius: 12px;
  background: white; /* Background color of the main content */
  padding: 1rem;
  min-height: 120px;
  display: flex;
  position: relative;
  z-index: 1; /* Ensure content stays above the pseudo-element */
  overflow: hidden; /* Ensure rounded corners on the gradient border */
}
.tipleft {
  width: 30%;
}
.tipleft img {
  width: 100%;
  object-fit: contain;
}
.tipright {
  display: flex;

  justify-content: center;
  flex-direction: column;
  width: 70%;
  padding-left: 15px;
}
.tipheading {
  font-size: 12px;
  color: #7c69e8;
  font-weight: 600;
}
.tippara {
  font-size: 14px;
  color: #60539c;
}
.TipsContainer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  padding: 1.5px; /* Adjust to control the border thickness */
  background: linear-gradient(
    90deg,
    #9f7fff 0%,
    #81c6fe 18%,
    #2530ff 37%,
    #43ffe6 56%,
    #fc58db 69%,
    #81c6fe 98%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0); /* Creates a mask for padding */
  -webkit-mask-composite: xor;
  mask-composite: exclude; /* Creates the inner transparent area */
}


.container {
  width: 100%;
  min-height: 613px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff; /* Light background for card */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.label {
  font-size: 14px;
  font-weight: 500;
  color: #333; /* Darker text color for label */
  margin-bottom: 8px;
  display: block;
}

.textBoxContainer {
  position: relative;
  padding: 12px;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #D8DCEC; /* Light gray border */
  height: 505px;
  overflow: hidden;
}

.placeholderText {
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px;
  font-size: 14px;
  line-height: 1.5;
  color: #8391B7; /* Light gray text color */
  pointer-events: none;
  white-space: pre-wrap; /* Maintains line breaks */
}

.textBox {
  width: 100%;
  height: inherit;
  border: none;
  resize: none;
  outline: none;
  font-size: 14px;
  line-height: 1.5;
  color: #000;
  background-color: transparent; /* Transparent to show placeholder */
  z-index: 1;
  position: relative;
}

.SwitchATSEditorContainer {
  border: 1.5px solid #C7CFE3;
  height: 44px;
  border-radius: 10rem;
  
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.eachSwitch {
  width: 49%;
  height: 36px;
  border-radius: 10rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #535D79;
}
.activeswitch {
  background-color: #ffffff;
  color: #8061DD;
  font-weight: 500;
}
.scoreContainerMain {
  height: 340px;
  background-color: #ffffff;
  margin-bottom: 32px;
  border-radius: 16px;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-right: 24px;
  padding-left: 24px;
  position: relative;
  z-index: 1; /* Ensure content stays above the pseudo-element */
  overflow: hidden; /* Ensure rounded corners on the gradient border */
}

.scoreContainerMain::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  padding: 1.5px; /* Adjust to control the border thickness */
  background: linear-gradient(
    90deg,
    #9f7fff 0%,
    #81c6fe 18%,
    #2530ff 37%,
    #43ffe6 56%,
    #fc58db 69%,
    #81c6fe 98%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0); /* Creates a mask for padding */
  -webkit-mask-composite: xor;
  mask-composite: exclude; /* Creates the inner transparent area */
}

.upparScoreContainer {
  height: 145px;
  position: relative;
  display: flex;
}
.downScoreContainer {
  margin-top: 32px;
  
  height: calc(90% - 145px);
  display: flex;
  flex-direction: row;
  column-gap: 46px;
  flex-wrap: wrap; 
}
.circularScoreContainer {
  height: 100%;
  width: 40%;
  /* border: 2px solid blue; */
}
.ScoreContainer {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
 
}
.score {
  font-size: 18px;
  font-weight: 500;
  color: #394056;
}
.scorepara {
  font-size: 14px;
  font-weight: 400;
  color: #6B748E;
}
.eachScoreHeadingContainer {
  width: 45%;
  display: flex;
 
}
  .eachScoreHeadingContainer {
  display: flex;
  /* justify-content: space-between; */
}
.eachScoreHeadingContainerUnder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.eachScoreDotContainer {
  display: flex;
  justify-content: center;
  align-items: center; 
}
.eachScoreDot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
}
/* Target dots within specific containers */
.eachScoreHeadingContainer:nth-child(1) .eachScoreDot {
  background-color: #5860FC; /* Match Score green */
}

.eachScoreHeadingContainer:nth-child(2) .eachScoreDot {
  background-color: #AC8FFF; /* Relevant Experience blue */
}

.eachScoreHeadingContainer:nth-child(3) .eachScoreDot {
  background-color: #CF8CFF; /* Skills Matched orange */
}

.eachScoreHeadingContainer:nth-child(4) .eachScoreDot {
  background-color: #9DB7FF; /* Overall Skills purple */
}

.eachScoreHeadingContainer:nth-child(5) .eachScoreDot {
  background-color: #FFC4DA; /* Format Readability yellow */
}
.eachScoreHeading {
  font-size: 16px;
  font-weight: 500;
  color: #4C5671;
}
.eachScorePercentage {
  font-size: 16px;
  font-weight: 500;
  color: #4C5671;
  width: 42px;
  background-color: #FFDFD4;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 12px;
  color: #E04132;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circularScoreContainer {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  padding: 10px;
  margin-left: 0;
}

.circularScoreContainer :global(.CircularProgressbar) {
  margin-top: -1.5rem;
  width: 100% !important;
  height: 100% !important;
}

/* Modal Styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.modalOverlay.show {
  opacity: 1;
  visibility: visible;
}

.modalContent {
  background: white;
  border-radius: 16px;
  width: 85%;
  max-width: 800px;
  max-height: 85vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.headerContent {
  padding: 24px 32px;
  border-bottom: 1px solid #f0f0f0;
  background: #ffffff;
}

.jobTitleWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.jobTitle {
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0;
  line-height: 1.3;
}

.jobStatus {
  background: #e6f7ff;
  color: #0066cc;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
}

.companyInfo {
  display: flex;
  align-items: center;
  gap: 16px;
}

.companyLogo {
  width: 40px;
  height: 40px;
  background: #f5f5f5;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.companyDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.companyName {
  font-size: 18px;
  font-weight: 600;
  color: #2d3748;
}

.locationInfo {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #718096;
  font-size: 14px;
}

.jobHighlights {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
  padding: 16px;
  background: #f8fafc;
  border-radius: 12px;
  margin: 16px 32px;
}

.highlightItem {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.highlightIcon {
  font-size: 20px;
}

.highlightItem h4 {
  margin: 0;
  color: #4a5568;
  font-size: 14px;
}

.highlightItem p {
  margin: 4px 0 0;
  color: #1a202c;
  font-weight: 500;
}

.jobContent {
  padding: 0 32px 32px;
}

.jobSection {
  margin: 24px 0;
  padding-bottom: 24px;
  border-bottom: 1px solid #e2e8f0;
}

.jobSection h2 {
  font-size: 18px;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.sectionIcon {
  font-size: 20px;
}

.skillTags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.skillTag {
  background: #8061DD;
  color: white;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.2s;
}

.skillTag:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(128, 97, 221, 0.2);
}

.requirementsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
}

.requirementCard {
  background: #f8fafc;
  border-radius: 12px;
  padding: 16px;
}

.requirementCard h3 {
  font-size: 16px;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 12px;
}

.requirementCard ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.requirementCard li {
  padding: 8px 0;
  padding-left: 24px;
  position: relative;
  color: #4a5568;
}

.requirementCard li:before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #48bb78;
}

.benefitsList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 16px;
}

.benefitItem {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  background: #f8fafc;
  border-radius: 12px;
  transition: all 0.2s;
}

.benefitItem:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.modalFooter {
  background: #f8fafc;
  border-top: 1px solid #e2e8f0;
  padding: 16px 32px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.proceedButton, .cancelButton {
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s;
}

.proceedButton {
  background: #8061DD;
  color: white;
  border: none;
}

.proceedButton:hover {
  background: #6f50d2;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(128, 97, 221, 0.2);
}

.cancelButton {
  background: white;
  border: 1px solid #e2e8f0;
  color: #4a5568;
}

.cancelButton:hover {
  background: #f8fafc;
  border-color: #cbd5e0;
}

.buttonSpinner {
  width: 16px;
  height: 16px;
  border: 2px solid #ffffff;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
