@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* padding: 1rem; */
}

.modalContent {
  background: linear-gradient(145deg, #F6F3FF 0%, #FFFFFF 100%);
  border-radius: 24px;
  padding: 32px;
  max-width: 850px;
  width: 100%;
  max-height: 85vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 
    0 20px 40px rgba(128, 97, 221, 0.15),
    0 0 0 1px rgba(128, 97, 221, 0.05),
    inset 0 0 80px rgba(128, 97, 221, 0.03);
  
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.backgroundElements {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
  border-radius: 32px;
  opacity: 0.6;
}

.gradientOrb {
  position: absolute;
  width: 600px;
  height: 600px;
  background: radial-gradient(circle, rgba(128, 97, 221, 0.15), transparent);
  border-radius: 50%;
  top: -300px;
  right: -300px;
  filter: blur(40px);
}

.gradientOrbSecond {
  position: absolute;
  width: 400px;
  height: 400px;
  background: radial-gradient(circle, rgba(97, 179, 221, 0.1), transparent);
  border-radius: 50%;
  bottom: -200px;
  left: -200px;
  filter: blur(40px);
}

.closeButton {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #909AB6;
  z-index: 2;
  backdrop-filter: blur(4px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.closeButton:hover {
  background: #FFFFFF;
  transform: rotate(90deg);
  box-shadow: 0 4px 12px rgba(128, 97, 221, 0.15);
}

.iconWrapper {
  background: linear-gradient(135deg, rgba(128, 97, 221, 0.12) 0%, rgba(128, 97, 221, 0.06) 100%);
  padding: 12px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0 0 1px rgba(128, 97, 221, 0.2);
}

.iconWrapper::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.2) 0%, transparent 60%);
  animation: rotateGradient 8s linear infinite;
}

.questionSection {
  margin-bottom: 2.5rem;
  position: relative;
  z-index: 1;
}

.sectionHeader {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 0.75rem;
  position: relative;
}

.sectionIcon {
  color: #8061DD;
  width: 20px;
  height: 20px;
  filter: drop-shadow(0 2px 4px rgba(128, 97, 221, 0.3));
}

.questionSection h1 {
  font-size: 20px;
  font-weight: 600;
  background: linear-gradient(135deg, #535D79 0%, #8061DD 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1.3;
  text-shadow: 0 2px 4px rgba(128, 97, 221, 0.1);
}

.questionSection p {
  color: #757F9B;
  margin-bottom: 1.25rem;
  font-size: 14px;
  line-height: 1.5;
  opacity: 0.9;
}

.optionsGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  perspective: 1000px;
}

.optionButton {
  padding: 12px 20px;
  border-radius: 10px;
  border: 1.5px solid rgba(203, 213, 236, 0.7);
  background: rgba(255, 255, 255, 0.9);
  color: #535D79;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  font-family: "inter";
  backdrop-filter: blur(4px);
  box-shadow: 
    0 2px 6px rgba(128, 97, 221, 0.05),
    inset 0 0.5px 0 0.5px rgba(255, 255, 255, 0.5);
  position: relative;
  overflow: hidden;
}

.optionButton::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

.optionButton:hover {
  border-color: #8061DD;
  transform: translateY(-2px) scale(1.02);
  box-shadow: 
    0 4px 15px rgba(128, 97, 221, 0.15),
    0 0 0 1px rgba(128, 97, 221, 0.1),
    inset 0 0.5px 0 0.5px rgba(255, 255, 255, 0.5);
}

.optionButton:hover::before {
  left: 100%;
}

.selected {
  background: linear-gradient(135deg, #8061DD 0%, #9747FF 100%);
  color: white !important;
  border: none;
  box-shadow: 
    0 4px 15px rgba(128, 97, 221, 0.25),
    0 0 0 1px rgba(128, 97, 221, 0.1),
    inset 0 0 20px rgba(255, 255, 255, 0.2);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.selected:hover {
  border: none;
  transform: translateY(-2px) scale(1.02);
  box-shadow: 
    0 6px 20px rgba(128, 97, 221, 0.3),
    0 0 0 1px rgba(128, 97, 221, 0.15),
    inset 0 0 20px rgba(255, 255, 255, 0.25);
}

@keyframes rotateGradient {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes float {
  0%, 100% { transform: translateY(0) rotate(0); }
  50% { transform: translateY(-5px) rotate(-2deg); }
}

.floating {
  animation: float 3s ease-in-out infinite;
}

/* Add glowing dots in background */
.modalContent::before,
.modalContent::after {
  content: '';
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(128, 97, 221, 0.1) 0%, transparent 70%);
  filter: blur(20px);
  z-index: 0;
}

.modalContent::before {
  top: -60px;
  right: -60px;
  animation: pulse 4s ease-in-out infinite;
}

.modalContent::after {
  bottom: -60px;
  left: -60px;
  animation: pulse 4s ease-in-out infinite reverse;
}

@keyframes pulse {
  0%, 100% { transform: scale(1); opacity: 0.5; }
  50% { transform: scale(1.2); opacity: 0.8; }
}

/* Responsive styles */
@media (max-width: 768px) {
  .modalContent {
    padding: 24px 20px;
    margin: 16px;
    border-radius: 20px;
    width: calc(100% - 32px);
    max-height: 85vh;
  }

  .optionsGrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .optionButton {
    width: 100%;
    text-align: center;
    padding: 10px 16px;
  }

  .questionSection {
    margin-bottom: 2rem;
  }

  .questionSection h1 {
    font-size: 18px;
  }

  .questionSection p {
    font-size: 13px;
  }

  .imageCarousel {
    height: 240px;
  }
}

/* Add small mobile breakpoint */
@media (max-width: 480px) {
  .modalContent {
    padding: 20px 16px;
    margin: 12px;
  }

  .questionSection h1 {
    font-size: 16px;
  }

  .optionButton {
    padding: 10px 14px;
    font-size: 12px;
  }
}


/* Add these to your existing CSS */
.bottomButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.skipButton {
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  color: #909AB6;
  font-size: 14px;
  cursor: pointer;
  padding: 8px 16px;
  transition: all 0.3s ease;
}

.skipButton:hover {
  color: #8061DD;
  transform: translateX(4px);
}

.doneButton {
  background: linear-gradient(135deg, #8061DD 0%, #9747FF 100%);
  color: white;
  border: none;
  padding: 10px 24px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.doneButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(128, 97, 221, 0.2);
}

.imageCarousel {
  position: relative;
  width: 100%;
  height: 280px;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 12px;
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.carouselImage {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  background: white;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  margin-top: 0;
}

.carouselImage.active {
  opacity: 1;
}

/* New class for resume option buttons */
.resumeOptionButton {
  composes: optionButton;
  width: 250px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

@media (max-width: 768px) {
  .imageCarousel {
    height: 240px;
  }
  
  .resumeOptionButton {
    width: 100%;
  }
}