.main {
    padding: 80px 0;
    background:  linear-gradient(86deg, #ecf4fb 1.71%, #FFFCEF 99.56%);
} 
.ngcontent {
    font-weight: 600;
    font-size: 34px;
    line-height: 140%;
    color: #383838;
    margin-bottom: 0;
} 
._ngcontents{
    color: #727272;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    display: block;
    margin-top: 10px;
    margin-bottom: 40px;
}


@media only screen and (max-width: 767px) {
    .ngcontent {
        font-size: 20px;
     } 
     ._ngcontents {
        font-size: 14px;
     }

  }