/* Full Preview Mode */
.previewContainer {
  width: 100%;
  min-height: 100vh;
  background: #EBEEF3;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.resumeWrapper {
  width: 595px;
  height: 842px;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  overflow: visible;
  position: relative;
  transform: scale(0.65);
  transform-origin: top center;
  display: flex;
  flex-direction: column;
}

.resumeContent {
  width: 100%;
  flex: 1;
  position: relative;
  padding: 30px;
  box-sizing: border-box;
  overflow: visible;
  display: flex;
  flex-direction: column;
}

/* Grid View Mode */
.gridContainer {
  width: 100%;
  height: 100%;
  position: relative;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gridWrapper {
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  aspect-ratio: 1/1.414; /* A4 aspect ratio */
}

.gridScaler {
  width: 100%;
  height: 100%;
  transform: scale(0.35);
  transform-origin: top center;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.gridContent {
  width:auto;
  /* height: 100%; */
  position: relative;
  /* padding: 10px; */
  /* box-sizing: border-box; */
  /* overflow: hidden; */
  /* display: flex; */
  /* flex-direction: column;  */
  
  border-radius: 8px;
}

/* Print Styles */
@media print {
  .previewContainer, .gridContainer {
    padding: 0;
    background: none;
  }

  .resumeWrapper, .gridWrapper {
    width: 210mm;
    height: 297mm;
    box-shadow: none;
    transform: none;
  }

  .resumeContent, .gridContent {
    margin: 0;
    padding: 30px;
    transform: none;
  }

  .gridContainer {
    padding: 0;
    background: none;
  }

  .gridWrapper {
    width: 210mm;
    height: 297mm;
    box-shadow: none;
  }

  .gridScaler {
    transform: none;
  }

  .gridContent {
    padding: 10px;
  }
}

/* Mobile Responsive */
@media screen and (max-width: 768px) {
  .previewContainer {
    padding: 10px;
  }

  .resumeWrapper {
    width: 100%;
    height: auto;
    min-height: 842px;
    transform: scale(0.9);
  }

  .resumeContent {
    padding: 20px;
  }

  .gridWrapper {
    border-radius: 4px;
  }

  .gridScaler {
    transform: scale(0.3);
  }
}

/* Larger Screens */
@media screen and (min-width: 1200px) {
  .gridScaler {
    transform: scale(0.4);
  }
}