 
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
 

.main {
    margin-left: 32px;
    margin-top: 32px;
}
.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 16px;
    background-color: #ffffff;
    height: calc(95vh - 32px);
    position: relative;
}
.logo {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    padding: 16px;
 
    margin-top: 3px;
}
.dorcolor {
    color: rgb(249, 111, 89);
}

.arrow {
    position: absolute;
    right: -20px;
    top: 66px;
}

.subcontainer {
    width: 100%;
 }
.profileimage {
    border-radius: 50%;
    height: 90px;
}
.profilecontainer {
    margin-top: 2rem;
 display: flex;
 justify-content: center;
}
.imageContainer {
    position: relative;
}
.imageContainer span {
    position: absolute;
    top:70%;
    right: -12%;
}
.detailsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 1rem;
}
.name {
    color: #373F54;
    font-size: 18px;
    font-weight: bold;
    font-family: "DM Sans", sans-serif;
     
}
.email {
    color: #545C72;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
}
.exp {
    color: #545C72;
 
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
}
.navigationContainer{
 display: flex;
 flex-direction: column;
 gap: 24px;
 margin-top: 40px;
 margin-left: 24px;
 
}
.ee{
    position: relative;
    font-size: 16px;
    color: #909AB6;
    font-weight: 500;
    font-family: "inter";
    height: 36px; 
    cursor: pointer;
    line-height: 30px;
    transition: color 0.3s ease, font-weight 0.3s ease, margin-left 0.3s ease; /* Smooth transition */
}
.ee span {
    margin-left: 5px; 
    font-family:'inter';
}

.ee:hover {
    color: #5860FC;
    font-weight: 600;
    font-family: "inter";
    margin-left: 5px;
}

.active {
    color: #5860FC;
    font-weight: 600;
    font-family: "inter";
}
.bar {
    position: absolute;
    right: 0px;
    top: 0%;
    background-color: #5860FC;
    height: 100%; 
    width: 4px;
    border-radius: 10px;
}
