@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,500,300,700);

* {
  font-family: Open Sans;
}


 
.footerdistributed{
	/* background: #0C163B; */
	background-color: rgb(25,10,40);
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
	box-sizing: border-box;
	width: 100%;
	text-align: left;
	font: bold 12px sans-serif;
	padding: 55px 50px 20px;
}

.footerdistributed .footerleft,
.footerdistributed .footercenter,
.footerdistributed .footerright{
	display: inline-block;
	vertical-align: top;
	font: bold 12px sans-serif;
}

/* Footer left */

.footerdistributed .footerleft{
	width: 40%;
}

/* The company logo */

.footerdistributed h3{
	color:  #ffffff;
	 

    font-size: 30px;
    margin: 0;
    font-weight: 600;
    letter-spacing: 0.8px;
    
}

.footerdistributed h3 span{
	color:  #f96f59;
}

/* Footer links */

.footerdistributed .footerlinks{
	color:  #ffffff;
	margin: 20px 0 12px;
	padding: 0;
}

.footerdistributed .footerlinks a{
	display:inline-block;
	line-height: 1.8;
  font-weight:400;
	text-decoration: none;
	color:  inherit;
}

.footerdistributed .footercompanyname{
	/* color:  #222; */
    color: white;
	font-size: 13px;
	font-weight: normal;
	margin: 0;
}

/* Footer Center */

.footerdistributed .footercenter{
	width: 35%;
}

.footerdistributed .footercenter i{
	background-color:  #33383b;
	color: #ffffff;
	font-size: 13px;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	text-align: center;
	line-height: 42px;
	margin: 10px 15px;
	vertical-align: middle;
}

.footerdistributed .footercenter i.fa-envelope{
	font-size: 13px;
	line-height: 38px;
}

.footerdistributed .footercenter p{
	display: inline-block;
	color: #ffffff;
  font-weight:400;
	vertical-align: middle;
	margin:0;
}

.footerdistributed .footercenter p span{
	display:block;
	font-weight: normal;
	font-size:13px;
	line-height:2;
}

.footerdistributed .footercenter p a{
	/* color:  #f96f59; */
	text-decoration: none;;
}

.footerdistributed .footerlinks a:before {
  content: "|";
  font-weight:300;
  font-size: 13px;
  left: 0;
  color: #fff;
  display: inline-block;
  padding-right: 5px;
}

.footerdistributed .footerlinks .link1:before {
  content: none;
}

/* Footer Right */

.footerdistributed .footerright{
	width: 20%;
}

.footerdistributed .footercompanyabout{
	line-height: 20px;
	color:  #ffffff;
	font-size: 13px;
	font-weight: normal;
	margin: 0;
}

.footerdistributed .footercompanyabout span{
	display: block;
	color:  #ffffff;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 20px;
}

.footerdistributed .footericons{
	margin-top: 25px; 
	 
}

.footerdistributed .footericons a{
	display: inline-block;
	width: 35px;
	height: 35px;
	cursor: pointer;
	background-color:  #33383b;
	border-radius: 2px;

	font-size: 20px;
	color: #ffffff;
	text-align: center;
	line-height: 35px;

	margin-right: 3px;
	margin-bottom: 5px;
}

.dooo {
	display: flex;
 
    flex-direction: column; 
	gap: 10px;
}

/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 880px) {

	.footerdistributed{
		font: bold 14px sans-serif;
	}

	.footerdistributed .footerleft,
	.footerdistributed .footercenter,
	.footerdistributed .footerright{
		display: block;
		width: 100%;
		margin-bottom: 40px;
		text-align: center;
	}

	.footerdistributed .footercenter i{
		margin-left: 0;
	}

}
