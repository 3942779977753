.mains {
   
   border-radius: 16px;
   border: 1px solid rgba(238, 238, 238, 0.5);
   padding: 2rem;
   margin-top: 1rem;
   background-color: white;
   box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
   transition: all 0.3s ease;
   backdrop-filter: blur(10px);
}

.filterHeader {
  background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
  padding: 12px;
  border-radius: 12px;
  margin-bottom: 1rem;
}

.dasdf {
    font-size: 1.1rem;
    text-align: center;
    font-weight: 700;
    color: #2c3e50;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dlsr {
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    background: #ffffff;
    margin: -2px 0;
    color: #2c3e50;
}

.dlsr:hover {
    background: #f8f9fa;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.fr {
    font-weight: 500;
    margin: -2px 0;
    transition: all 0.2s ease;
    padding: 8px 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    
}

.checkboxWrapper {
    display: flex;
    align-items: center;
    gap: 12px;
}

.checkbox {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid rgb(154, 41, 192);
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease;
    flex: none;
}

.checkbox:checked {
    background-color: rgb(154, 41, 192);
}

.checkbox:checked::after {
    content: '✓';
    position: absolute;
    color: white;
    font-size: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.marginle {
    cursor: pointer;
    color: #4a5568;
    font-weight: 500;
    transition: color 0.2s ease;
}

.marginle:hover {
    color: rgb(88, 17, 175);
}

.ci {
    color: rgb(154, 41, 192);
    font-size: 1.2rem;
}

.pro {
    height: 1px;
    background: linear-gradient(to right, transparent, #eee, transparent);
    margin: 0.5rem 0;
}

.moreLocationsBtn {
  border: none;
  background: none;
  color: #2557a7;
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}

.moreLocationsBtn:hover {
  text-decoration: underline;
}

.moreLocationsBtn:focus {
  outline: none;
  text-decoration: underline;
}

.modalBackdrop {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 9998;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContent {
  background: white;
  padding: 32px;
  border-radius: 16px;
  width: 90%;
  max-width: 800px;
  max-height: 85vh;
  overflow-y: auto;
  z-index: 999999999999;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(238, 238, 238, 0.5);
  margin: 20px;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
}

.modalHeader h3 {
  font-size: 1.5rem;
  color: #2c3e50;
  font-weight: 600;
  margin: 0;
}

.modalGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 16px;
  padding: 8px 0;
}

.closeButton {
  width: 36px;
  height: 36px;
  border: none;
  background: #f8f9fa;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 1.2rem;
  transition: all 0.2s ease;
}

.closeButton:hover {
  background: #eee;
  color: #333;
  transform: rotate(90deg);
}

/* Custom scrollbar for the modal */
.modalContent::-webkit-scrollbar {
  width: 8px;
}

.modalContent::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.modalContent::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  border-radius: 4px;
}

.modalContent::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}