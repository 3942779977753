@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.bodys {
  padding: 0px !important;
  border-radius: 40px !important;
}
.bodys  *{
    font-family: "inter";
  }
.close {
  display: flex;
  justify-content: end;
  position: absolute;
  top: 1rem;
  right: 1rem; 
}

.closeButton {
  color:#909AB6;
  cursor: pointer;

  height: 3rem;
  width: 2rem;
  border-radius: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconcut {
  cursor: pointer;
}
.iconcut:hover {
  transform: rotate(90deg);

  transition: transform 0.5s;
}

.second {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 32px;
  padding-top: 54px;
  padding-left: 48px;
  padding-right: 48px;
  min-height: 460px;
  background-color: #F6F3FF;
  border-radius: 16px;
}
.container {
     display: flex;
     flex-direction: column;
     gap: 48px;

}
.topHeadingContainer {
    /* border: 2px solid blue; */
}

.headtitle {
    font-size: 32px; 
    color:#8061DD ;
    /* line-height: 20px; */
    text-align: center;
}
.headtitlparagraph{
    color: #757F9B;
    font-size: 16px;
    text-align: center;
}
.twoboxContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    /* flex-wrap: wrap; */
}
.SkipStepContainer {
    display: flex;
    justify-content: end;
    color: #909AB6;
    font-size: 16px;
    align-items: center;
    gap: 10px;
}
.SearchContainer { 
  margin-top: 32px;
  height: 48px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdownSecarchMainConatiner {
  background-color: #ffffff;
  border: 1.5px solid #CBD5EC;
  border-radius: 8px;
   width: 493px;
  height: 100%;
  display: flex;
  align-items: center;
   padding-top: 8px;
   padding-bottom: 8px;
   padding-left: 16px;
   padding-right: 16px;
}

.dropdownSecarchConatiner {
  
  border-radius: 8px;
  width:100%;
  position: relative;
  cursor: pointer;
}

.dropdownToggle {
  padding: 8px;
  display: flex;
  align-items: center;
  color: #535D79;
  font-size: 14px;
  gap: 7px;
   text-overflow: ellipsis; 
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  right: -16px;
  width: calc(100% + 32px);
  background-color: #ffffff;
  border: 1.5px solid #CBD5EC;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  font-size: 14px;
  max-height: 430px; /* Optional: limit the height */
  overflow-y: auto; /* Optional: make it scrollable if there are many options */
}

.dropdownItem {
  padding: 8px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}

.dropdownItem:last-child {
  border-bottom: none;
}

.chevronDownIcon {
position: absolute;
right: 0px;
}


.dooo {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selectedTemplate {
  border: 1px solid rgb(102, 108, 255);
  border-radius: 4px;
}
.templateName {
  font-size: 16px;
  color: #535d79;
  margin-bottom: 4px;
  font-weight: 500;
  margin-top: 10px;
}

.ResumeContainer {
display: flex;
flex-wrap: wrap;
gap: 24px;
margin-bottom: 2rem;
align-items: center;
justify-content: center;
}
.resumeImageContainer {
  height: 240px;
  width: 180px;
  overflow: hidden;
  box-shadow: 1.74px 1.74px 5.87px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: flex;
flex-direction: column;
align-items: center;
cursor: pointer;
position: relative;
background-color: #ffffff;
}

.resumeImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  cursor: pointer;
}
@media screen and (max-width: 768px) { 

    .twoboxContainer {
        flex-wrap: wrap;
    }
   }

   @media (min-width: 992px) {
    :global(.modal-lg), :global(.modal-xl) {
        --bs-modal-width: 928px; /* Custom width */
    }
}

.recommendedTemplate {
  position: relative;
  border: 2px solid #4CAF50;
  border-radius: 8px;
  transform: scale(1.02);
  transition: all 0.3s ease;
}

.recommendedBadge {
  position: absolute;
  top: -18px;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(135deg, #4CAF50, #45a049);
  color: white;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  align-items: center;
  gap: 4px;
  z-index: 2;
  white-space: nowrap;
}

.recommendedIcon {
  color: #FFD700;
  font-size: 14px;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.dooo {
  position: relative;
  transition: all 0.3s ease;
  margin-top: 15px; /* Add space for the badge */
}

.dooo:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.resumeImageContainer {
  border-radius: 6px;
  overflow: hidden;
}