.main {
    /* background-color: rgb(58,129,253); */
    /* color: white; */
}
.as{
 text-align: center;
 font-weight: 500;
 font-size: 1.25rem;
}
.imgrea {
    margin-top: 4rem;
}