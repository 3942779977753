.main {
  width: 100%;
  min-height: 80vh;
  border-radius: 10px;
}
.main *{
   font-family: "inter";
}
 
.headerheading {
 
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

 .heading {
  font-size: 32px;
 }
 .recomandedJobs {
  display: flex;

 
 }
 .recomandedJobscontainer {
  font-size: 12px;
  font-weight: 500;
  background-color: #8061DD;
  color: #ffffff;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 10rem;
 }
 .jobCardContainer {
  /* border: 2px solid red; */
display: flex;
justify-content: center;
gap: 12px;
flex-direction: column;
align-items: center;
 } 

 .singleContainer {
  padding: 24px;
  border-radius: 12px;
  min-height: 162px; 
  width: 100%;
  background-color: #ffffff;
  box-shadow: 1px 1px 10px -2px rgba(0, 0, 0, 0.15);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  border: 1px solid transparent;
 }

 .singleContainer:hover {
  border: 1px solid #8061DD30;
 }

 .percentandlogocontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
 }
 .percent {
    font-size: 12px;
    font-weight: 500;
    padding: 6px 14px;
    background-color: #D4F7DA;
    color: #0F571B;
    border-radius: 10rem;
    transition: all 0.3s ease;
 }
 .logo img {
  height: 25px;
  object-fit: contain;
  transition: all 0.3s ease;
 }
 .companyandjobroleContainer {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
 }
 .jobtitle {
   font-size: 16px;
   font-weight: 600;
   line-height: 150%;
   color: #414B67;
 }
 .Companayname {
  font-weight: 500;
  font-size: 16px;
  color:#414B67 ;
 }
 .detailsandApplyNow {
  display: flex;
  margin-top: 12px;
  justify-content: space-between;
 }
.BasicDetails {
  color: #909AB6;
  font-weight: 500;
  font-size: 14px;
}
.saveandappplyButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
 .applynowbutton {
   font-size: 14px;
   color: #8061DD;
   padding: 8px 16px;
   font-weight: 500;
   border-radius: 10rem;
   border: 1.5px solid #D3C4FF;
   display: flex;
   align-items: center;
   gap: 6px;
   cursor: pointer;
   transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
   background-color: transparent;
 }

  @media screen and (max-width: 768px) { 

    .main {
      width: calc( 100% - 20px) ;
       
    }

   }

.lockedJobs {
  color: #8061DD;
  margin-left: 5px;
  font-size: 0.9em;
}

.upgradeContainer {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  text-align: center;
  margin-top: 2rem;
  border: 2px dashed #8061DD;
  cursor: pointer;
  transition: all 0.3s ease;
}

.upgradeContainer:hover {
  background: #f8f5ff;
}

.upgradeContainer h3 {
  color: #333;
  margin: 1rem 0 0.5rem;
}

.upgradeContainer p {
  color: #666;
  margin-bottom: 1rem;
}

.upgradeButton {
  background: #8061DD;
  color: white;
  border: none;
  padding: 0.8rem 2rem;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.upgradeButton:hover {
  background: #6f4fd0;
}

.preview_image {
  padding: 2rem;
  background: #f7fafc;
  border-radius: 12px;
  min-height: 842px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageLoaded {
  opacity: 1;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}