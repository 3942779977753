 /* General Styling */
.resume {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: "Arial", sans-serif;
  letter-spacing: 0.02rem;
  /* background-color: #f4f4f4; */
  /* color: #333; */
}
.user_details {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  font-size: 0.63rem;
  margin-right: auto;
  font-weight: 600;
  margin-top: -0.3rem;
}
.resume a {
  color: #3498db;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.resume a:hover {
  color: #2980b9;
}
.user_details .name {
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.3px;
  margin: 0;
}

.user_details .degree_branch_college {
  font-size: 0.93rem;
  font-weight: 400;
  line-height: 1.4;
  color: #666;
  margin: 0;
}

.user_details .links_and_info {
  display: grid;
  grid-template-columns: 3fr 5fr 3fr;
  row-gap: 0.55rem;
  column-gap: 0.5rem;
}

.user_details .info span {
  font-weight: 400;
  color: #666;
}

.user_details .link a {
  font-weight: 400;
}

.user_image {
  max-width: 5rem;
  max-height: 6.5rem;
}

.user_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ism_logo {
  width: auto;
  height: 5rem;
}

.ism_logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.points{
  font-size: 12px;
   
}
.title{
  font-size: 15px;
  
  margin-bottom: 0rem;
  text-transform:uppercase ;
  /* font-weight:bold; */
}
.left {
  height: 100%;
  grid-area: left;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
/* Header Styling */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .5rem;
  /* background-color: #2c3e50; */
  /* color: #ecf0f1; */
}
 
.user_details {
  display: flex;
  flex-direction: column;
}

.user_details .header_text {
  font-size: .8rem; /* Adjusted font size */
  /* font-weight: 700; */
  /* display: flex; */
  /* justify-content: center; */
  text-align: center;
  /* margin: 0.5rem 0; */
}

.iitb_logo {
  width: 50px; /* Adjusted logo size */
  height: 50px; /* Adjusted logo size */
}

/* Section Styling */
.section {
  font-size: 0.8rem; /* Adjusted font size */
  font-weight: 300;
  line-height: 1.3;
  width: 100%;
  /* margin: 1rem; */
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: .4rem; /* Adjusted padding */
  border-radius: 8px;
}

.section h2, .section h2.format2 {
  font-size: 0.8rem; /* Adjusted font size */
  font-weight: 600;
  /* margin: Orem 0; */
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.section h2.format2 {
  background-color: #3498db;
  color: #fff;
  padding: 0.3rem;
  border-radius: 4px;
}

/* List Styling */
.section ul {
  list-style-type: none;
  padding: 0;
  margin: 0rem 0;
}

.section ul li {
  margin-bottom: 0.1rem;  
}

.section ul li::before {
  content: "\2022";
  color: #3498db;
  font-size: 1rem; /* Adjusted font size */
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/* Table Styling */
.section .table {
  width: 100%;
  border-collapse: collapse;
  font-weight: 500;
  color: #333;
/* margin: 0rem 0; */
}

.section table tr th {
  background-color: #3498db;
  color: #fff;
  border: 2px solid #3498db;
  padding: 0.1rem; /* Adjusted padding */
  font-size: 0.8rem; /* Adjusted font size */
  font-weight: 400;
  text-align: start;
}

.section table tr td {
  border: 1px solid #ddd;
  padding: 0.1rem; /* Adjusted padding */
}

/* Project Styling */
.firstline,
.secondline {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  font-size: 10px;
}

.secondline {
  /* margin-top: .5rem; */
  font-size: 0.9rem; /* Adjusted font size */
  /* color: #7f8c8d; */
  color: #5d5d5d;

}
