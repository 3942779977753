.main {
 
    min-height: 100vh;
    min-width: 100vw;
    display: flex; 
    background-color: rgb(248, 249, 250);
    
}
.leftone {
    width: 20%;
    /* border: 2px solid red;  */
    background-color: white;
}
.RightOne{
    width: 60%;
    margin: 1rem;
    border-radius: 10px;
    /* border: 2px solid red; */

}
.last {
    width: 18%;
    margin-right: 2rem;
}
@media only screen and (max-width: 747px) { 
    .RightOne{
        width: 100%;
        /* border: 2px solid red; */
        margin: 0rem;
    }
}
.leftmain{
    width: 283px;
    background: white;
    height: calc(100vh - 64px);
    overflow-y: auto;
    top: 54px;
    position: fixed;
    box-shadow: 2.00389px -2.00389px 4.00778px #86a0bf0f;
    z-index: 9;

}
.headingleft {
    border-bottom: 1px solid #DADCE0;
    padding: 20px;
    background-color: white;
}
.lefttotla{
    display: flex;
    align-items: center;
}
.imgcontainer{
    width: 40px;
    height: 40px;
    border-radius: 89.6px;
    border: 1.6px solid #FFF;
    background: #d3d3d3 50% / cover no-repeat;
    box-shadow: -1.6px 1.6px 12px #00000040;
}
.imgss{
    overflow: hidden;
    border-radius: 89.6px;

}
.namecontainer{
    display: flex;
     flex-direction: column;
     margin-left: 10px;
      justify-content: center;
      width: 85%;
      overflow: hidden;
}
.names{
    font-weight: 700;
    font-size: 14px;
     display: flex;
}
.email {
 
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    margin: 0;
}
.statusbar{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.greendot{
    display: flex;
    background-color: #34C759;
     border-radius: 50%;
     width: 8px;
     height: 8px;
     margin-left: 4px;
     margin-top: 8px;   
     animation: blink 1s infinite; /* Add animation here */
  }
  @keyframes blink {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
  .online {
    height: 6px;
    width: 80%;
    background-color: #dadce0;
    position: relative;
    border-radius: 8px;
  }
  .fill {
    border-radius: 8px;
    background: linear-gradient(90deg, #01a768 5.56%, #05c165);
    height: 6px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .percent{
    font-size: 12px;
    margin-left: 6px;
  }
  .rightMain {
    font-size: 14px;
    height: auto;
    min-height: 100%;
    /* background-color: aliceblue; */
    
  }
  .mainss{
  border: 2px solid #e5e7eb  ;
  border-radius: 10px;
  padding: 1rem;
  background-color: white;
 box-shadow: 0 6px 12px rgba(30, 10, 58, .04);
  }

  .imagesandcover{
    position: relative;
  }
   
.ass{
  font-size: 12px;
  text-align: center;
  padding: 1rem;
}
  .coverimages{
     width: 100%;
     height: 13rem;
     border-radius: 10px;
  }

  .userimage{
    position: absolute;
    top: 6rem;
    left: 2rem;
    border-radius: 50%; 
    width: 10rem;
    height: 10rem;
    background-color: #fff;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imgg {
    box-shadow: 0 7.94118px 26.47059px #00000024;
    padding: 2%;
    width: 9rem;
    border-radius: 50%; 
    height: 9rem;
  }

  .alltop{
    margin: 10px 0;
    padding: 0 20px;
    list-style: none;
    margin-top: 15px;
  }
  .lists{
    display: flex;
    width: 100%;
    align-items: center;
    transition: .4s;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    padding: 10px 12px;
    color: var(--grey-1100);
    margin-bottom: 4px;
    border-radius: 8px;
    cursor: pointer;
  }
  .lists:hover{
    color: white;
    background: linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%);
    font-weight: 500;
  }

  .active{
    background: linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%);
    color: white;
    font-weight: 500;
  }
  .personelcontainer{
    margin-top: 4rem;
    display: flex;
    position: relative;
    
  }
  .nameha{
    color: #383838;
    font-size: 24px;
    font-weight: 600;
    line-height: 120%;
    margin-bottom: 0;
  }

  .short{
    font-size: 16px;
    font-weight: 500;
  }

  .designation{
    font-size: 14px;
    font-weight: 600;
    color: #727272;
  }
  .leftpartprofile{
    display: flex;
    flex-direction: column;
    width: 70%;
    gap: 5px;
 
  }
  .Rightpartprofile{
    display: flex;
    flex-direction: column;
    width: 29%;
    gap: 5px;
   justify-content: center;
    align-items: center;
    font-weight: 700;
    color: #727272;
    
  }

  @media only screen and (max-width: 1100px) { 
    .leftpartprofile{ 
       width: 90%;
    }
    .personelcontainer{
      flex-direction: column;
      margin-top: 2rem;
      padding: 1rem;
    }
    .Rightpartprofile{
       display: none !important;
       width: 0px;
       }
       .mainss{
        padding: 0rem;
       }
   }

   .lastmain{
    position: fixed;
    background-color: white;
    min-width: 100%;
    min-height: 100%;
   }
   .college{
    font-weight: 600;
    font-size: 14px;
    text-align: center;
   }

   .expericen{
     display: flex;
    flex-direction: column;
     margin-top: 1rem;
     border: 2px solid rgb(241 245 249);
     border-radius: 10px;
     padding: 1rem;
     background-color: white;
     box-shadow: 0 6px 12px rgba(30, 10, 58, .04);
   }
   .imgexp{
    display: flex;
    justify-content: center;
   }
   .experheading{
    font-weight: 600;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
   }
   .expericenimg{
    max-width: 20rem;
    max-height: 20rem;
   }
   .buttonscontainer{
     display: flex; 
     gap: 10px;
     margin-top: 1rem;
     
   }

   .buttonss{
     background: linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%);
      color: white;
      border-radius: 10px;
      padding: 0.5rem 1rem;
      font-size: 12px;
      font-weight: 600;
      border: none;
      cursor: pointer;

   }

   .buttonsswant{
    background: linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%);
     color: white;
     border-radius: 10px;
     padding: 0.5rem 1rem;
     font-size: 12px;
     font-weight: 600;
     border: none;
     cursor: pointer;
     width: 9rem; 
     text-align: center;
     display: flex;
     justify-content: center;
     align-items: center;

  }
  .buttonsswantpoint{
    background: linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%);
     color: white;
     border-radius: 10px;
     padding: 0.5rem 1rem;
     font-size: 10px;
     font-weight: 600;
     border: none;
     cursor: pointer;
     width: 6rem; 
     text-align: center;
     display: flex;
     justify-content: center;
     align-items: center;
     position: absolute;
     right: 0;
     bottom: 0px;

  }

  .buttonssexper{
    background: linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%);
     color: white;
     border-radius: 10px;
     padding: 0.5rem 1rem;
     font-size: 10px;
     font-weight: 600;
     border: none;
     cursor: pointer;
     width: 6rem; 
     text-align: center;
     display: flex;
     justify-content: center;
     align-items: center;
     position: absolute;
     right: 0;
     bottom: 1rem;

  }

  .buttonsseducation{
    background: linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%);
     color: white;
     border-radius: 10px;
     padding: 0.5rem 1rem;
     font-size: 10px;
     font-weight: 600;
     border: none;
     cursor: pointer;
     width: 6rem; 
     text-align: center;
     display: flex;
     justify-content: center;
     align-items: center;
     position: absolute;
     right: 4px;
     bottom: 4px;

  }

   .buildwithresumecontainer{
     display: flex;
   }
   .newbutton{
    color: rgb(160, 102, 255);
    font-size: 12px;
    font-weight: 700;
    position: relative;
    top: -.5rem;
    animation: colorChange 1s infinite alternate;
   }

   @keyframes colorChange {
    0% {
        color: rgb(92, 31, 62);
    }
    50% {
        color: rgb(120, 70, 230);
    }
    100% {
        color: rgb(80, 40, 200);
    }
}
   .downs{
    position: absolute;
    bottom: 0px;
   }

   .follersandfollwingcontainer{
    display: flex;
    gap: 10px;
    color: rgb(71 85 105);
    font-weight: 500;
   }

   .para {
    padding: 1rem;
    font-size: 12px;
  }
 .coverimagescontainer{
  position: relative;
 }

 .location{
  font-size: 13px;
  font-weight: 500;
}
   @media only screen and (max-width: 746px) { 
          .buttonss{
            font-size: 10px !important;
          }
          .newbutton{
            font-size: 11px!important;
          }
        .imgexp{
          display: none;
        }
        .nameha{
          font-size: 16px;
        }
        .short  {
          font-size: 14px;
        }
        .designation{
          font-size: 12px;
        }
        .location{
          font-size: 13px;
        }
        .aboutme{
          font-size: 12px;
        }
        .userimage{
          left: 25%;
           
        }
        .buildwithresumecontainer{
          padding: 1rem;
          padding-top: 0rem;
        }
    }

    .penicon{
      position: absolute;
      right: 1rem;
      top: 1rem;
      color: rgb(71 85 105);
      background-color: white;
      border-radius: 50%;
      height: 2rem;
      width: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .peniconPersonal{
      position: absolute;
      right: 1rem;
      top:-2rem;
      color: rgb(71 85 105);
      background-color: white;
      border-radius: 50%;
      height: 2rem;
      width: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
      .penicon:hover{
        background: linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%);
        color: white;
        }
        .penonhover:hover{
           color: white; 
           cursor: pointer;
        }
        




        .profileImageContainer{
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          
        }

        .editcontianer{
          
             display: flex;
             justify-content: center;
             align-items: center;
             background-color: rgba(0, 0, 0, 0.5);
             z-index: 1;
             opacity: 0;
             transition: all 0.3s ease-in-out;
             
        }

        .uploadCoverContainer{
          border-top: 2px solid #eee;
          margin-top: 1rem;
          display: flex;
          justify-content: end;

          align-items: center;
        }

        .uploadCoverContainerforexperience{
          border-top: 2px solid #eee;
          margin-top: 1rem;
          display: flex;
          justify-content: space-between;

          align-items: center;
        }
        .deleteButton{
          background-color: #f44336;
          color: white;
          border: none;
          padding: 0.5rem 1rem;
          border-radius: 0.5rem;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          font-size: 12px;

        }
        .savebutton{
          background-color: #06448e;
          color: white;
          border: none;
          padding: 0.5rem 1rem;
          border-radius: 0.5rem;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          font-size: 12px;
        }

        .input-container {
          margin-top: 10px;
          display: flex;
          gap: 10px;
          justify-content: end;
        }
        .save-container {
          margin-top: 10px;
          display: flex;
          gap: 10px;
          justify-content: end;
        }
        
        .file-input {
          display: none;
        }
        
        .file-label {
          cursor: pointer;
          font-size: 12px;
          /* Add additional styling here to make it look like a button */
        }

        @media only screen and (max-width: 746px)  {
          .savebutton  .file-label .deleteButton {
            font-size: 12px;
          }
          .coverimages{
            width: 100%;
            height: 7rem;
          }
          .userimage{
            position: absolute;
            top: 3rem;
            left: 1rem;
            border-radius: 50%; 
            width: 7rem;
            height: 7rem;
            
          }
          .imgg {
            
            width: 6rem;
             
            height: 6rem;
          }
        }
        .profilemodel{
           
          border-radius: 50%; 
          width: 10rem;
          height: 10rem;
        
        }
        .profileMianContit{
          display: flex;
          justify-content: center;
          align-items: center;
        }
            .profileMianConti{
              display: flex;
              justify-content: center;
           
            }
        .wrapper{
         display:flex;
        }

        .aipowered{
          text-transform: capitalize;
          background: linear-gradient(90.02deg, #6B57FF 39.45%, #D735FF 52.31%, #FF5C00 73.86%, #FFC01D 99.98%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-shadow: 0px 0.5px 1px rgba(102, 55, 201, 0.25);
          display: inline-block;
          padding: 0 4px;
          font-size: 12px;
          position: relative;
          top: -8px;
        }


        .profileMianConti {
          display: flex;
          flex-direction: column;
          margin: 0 auto;
          border-radius: 8px;
          max-width: 30rem;
         gap: 4px;
         width: 100%;
      }
      
      .profileMianConti label {
          font-weight: bold;
          font-size: 12px;
      }
      
      .profileMianConti input {
           
          padding: 3px;
          font-size: 14px;
          border: 1px solid #ddd;
          border-radius: 4px;
          box-sizing: border-box;
      }
      
      .profileMianConti h2 {
          font-size: 18px;
          margin-bottom: 8px;
      }

      .introheading{
        font-weight: 700;
      }
      .custom-modal .modal-dialog {
    max-width: 800px; /* Custom width */
    /* You can add more styles here as needed */
}

.introMainConti{
  display: flex;
 
}

.imagediv{
 
  max-width: 150px;
  max-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imagediv2{
 
  max-width: 150px;
  max-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.textaree{
  border: 1px solid #ccc; /* Default border style */
  padding: 8px; /* Optional: Adjust padding as needed */
  font-size: 14px; /* Optional: Adjust font size as needed */
  resize: vertical; /* Optional: Allow vertical resizing */
  outline: none; /* Remove default focus border */
  border-radius: 6px;
}

.firstlastcont{
  display: flex;
  gap: 7px;
 
 
}

.firstlastcontinnner{
  display: flex;
  gap: 7px;
 flex-direction: column;
 
}


.innderconfist{
  display: flex;
  flex-direction: column;
  gap: 8px;
  width:20rem;
}
.innerexperer input{
  border: 1px solid #ccc; /* Default border style */
  width: 100%;
  height: 2rem;
  font-size: 14px;
}
.innerexperer label {
  font-size: 13px;
  margin-bottom: 5px;
}

.innereducation{
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.innereducation select{
  border: 1px solid #ccc; /* Default border style */
  border-radius: 4px;
  height: 2rem;
  font-size: 14px;
}
.innereducation label {
  font-size: 13px;
  margin-bottom: 5px;
}

.lable{
  font-size: 13px;
}
.inputtt{
  border: 1px solid #ccc; /* Default border style */
  height: 1rem !important;
}


.innereducation input{
  border: 1px solid #ccc; /* Default border style */
  width: 100%;
  height: 2rem;
  font-size: 14px;
}
.innereducation label {
  font-size: 13px;
  margin-bottom: 5px;
}

.wantinput input{
   border: 1px solid #ccc; /* Default border style */
 
 width: 100%;
}
.wantinput


.innderconfist input {
  width: 100%;
}

.sociallogo{
display: flex;
gap: 6px;
margin-top: 10px;
}

@media only screen and (max-width: 746px)  { 

  .sociallogo{
     padding-left: 1rem;
     margin: 0px;
     
    }
    .imagediv2{
      display: none;
    }
}

.aboutmeConatinaer{
  display: flex;
  flex-direction: column;
  
  
}

.inputcontaineredu input{
   width: 100%; 
   height: 2rem;
   font-size: 14px;
   /* border */
     border: 1px solid #ccc; /* Default border style */
}

.inputcontaineredu{
   display: flex;
   flex-direction: column;
   gap: 4px;
}

.educationConatinaer{
  display: flex;
  flex-direction: column;
 
  gap: 0px;
}

.wantto{
  padding: 1rem;
}

.wantotpara{
  font-weight: 500;
}

.wantContainer{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.addkeypoint{
  font-size: 14px;
  
}
.addkeypointlf{
  font-size: 12px;
  color: blue;
  padding-top: 10px!important;
  top: 10px;
  cursor: pointer;
  position: relative;
}

.keypointinput{
   width: 93%;
   height: 30px;
   margin-top: 10px;
   border: 1px solid #e5e5e5;
}
.keypointcontainer {
  position: relative;
}
.trashcontaiter{
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;

}
.trashcontaiter:hover {
  color: red;
}

.experiencmaincontianer{
  display: flex;
  gap: 10px;
  width: 100%;
}

.role{
  font-weight: 600;
  font-size: 14px;
}
.company {
  font-weight: 500;
}

.eachsectionofexp{
  padding: 10px;
  border: 1px solid #e5e5e5;
   border-radius: 5px;
   margin-top: 10px;
   display: flex;
   position: relative;
   gap: 10px;
 
 

}

.textareacontainer{
  position: relative;
  display: flex;
  flex-direction: column;
}


.informationcontainer{
  width: 100%;
}
.trashcontainer{
  position: absolute;
  right: 10px;
}